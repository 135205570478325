import { Assets, Container } from 'pixi.js';

import app from '../../../../index.entry';
import { PositionType } from '../../../../lib/defs/types';
import { IAnimation } from '../../../../lib/pattern/IAnimation';
import { SpritesheetAnimation } from '../../../../lib/pixi/animation/SpritesheetAnimation';

// types
//-----------------------------------------------------------------------------
export type BulletAnimationOptions = {
    position: PositionType;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    clip: 'clip.bullet.json',
};

/*
    bullet booster animation
*/
export class BulletAnimation extends Container implements IAnimation {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onMove: (row: number) => void;
    // input
    private _options: BulletAnimationOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(options: BulletAnimationOptions) {
        super();
        this._options = options;
    }

    // init
    //-------------------------------------------------------------------------
    static assets(): string[] {
        return Object.values(manifest);
    }

    // impl
    //-------------------------------------------------------------------------
    public async start(): Promise<void> {
        // load assets
        await Assets.load(BulletAnimation.assets());

        // play sound
        app.sound.play('puzzle-bullet.mp3');

        // create clip
        const clip = this._createClip();

        // spawn clip
        this.addChild(clip);

        // animate clip
        await clip.start();

        // despawn clip
        this.removeChild(clip);
    }

    public stop() {}

    // private: events
    //-------------------------------------------------------------------------
    private _onClipFrame(clip: SpritesheetAnimation, frame: number) {
        // determine puzzle column of where bullet is by frame and spawned position
        const column = this._options.position.x + Math.floor((17 * (frame - 5)) / clip.totalFrames);

        // notify
        this.onMove?.(column);
    }

    // private: factory
    //-------------------------------------------------------------------------
    private _createClip(): SpritesheetAnimation {
        // create animation
        const clip = new SpritesheetAnimation(manifest.clip);

        // position clip
        clip.x = -58;
        clip.anchor.set(0, 0.5);

        // handle frame update
        clip.onFrameChange = (frame: number) => this._onClipFrame(clip, frame);

        return clip;
    }
}
