import { Sprite, Texture } from 'pixi.js';

import { MiniFuguBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-------------------------------------------------------------------------
const manifest = {
    image: 'block.fugu.2.png',
};

/*
    mini fugu block view
*/
export class MiniFuguBlockView extends Sprite {
    // init
    //-------------------------------------------------------------------------
    constructor(props: MiniFuguBlockProps) {
        // create base view
        super(Texture.from(manifest.image));
        blockPositionView(this, props);
    }

    static assets(): string[] {
        return Object.values(manifest);
    }
}
