/*
    simple nonblocking lock
*/
export class Lock {
    // fields
    //-------------------------------------------------------------------------
    private _locked = false;

    // properties
    //-------------------------------------------------------------------------
    public get locked(): boolean {
        return this._locked;
    }

    // api
    //-------------------------------------------------------------------------
    public lock(): boolean {
        if (this._locked) {
            return false;
        }
        this._locked = true;
        return true;
    }

    public unlock() {
        this._locked = false;
    }
}
