// rewards
//-----------------------------------------------------------------------------
export type MysteryItemRewardId = 'gift' | 'treasure';
export type MysteryRewardId = 'attack' | MysteryItemRewardId;
export type MysteryItemRewardProps = {
    id: string;
    icon: string;
};

// constants
//-----------------------------------------------------------------------------
export const mysteryItemRewardPropsMap: Record<MysteryItemRewardId, MysteryItemRewardProps> = {
    gift: {
        id: 'g',
        icon: 'icon.gift.png',
    },
    treasure: {
        id: 't',
        icon: 'icon.gem.png',
    },
};
