import { CollideEvent, CollideId } from '../../defs/collide';
import { despawnBlockEntity, PrisonBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { BreakEffect } from '../effects/BreakEffect';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

/*
    prison block collision resolver
*/
export class PrisonCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: PrisonBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        attack: this._resolveAttack,
        block: this._resolveBlock,
        complete: this._resolveComplete,
        tap: this._resolveTap,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: PrisonBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        // invalid effect
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveBlock(event: CollideEvent) {
        // trigger prison
        this._trigger();
        return true;
    }

    private async _resolveAttack(event: CollideEvent) {
        // trigger prison
        this._trigger();
        return true;
    }

    private async _resolveComplete(event: CollideEvent) {
        const c = this._entity.c;

        // despawn on complete
        despawnBlockEntity(this._scene, this._entity);

        // execute break effect
        await new BreakEffect(this._scene, {
            position: c.position.mapPosition,
            size: c.block.props,
            color: 0xeedd00,
        }).execute();

        return true;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _trigger() {
        //TODO: effect

        // play sound
        //app.sound.play('puzzle-prison-poke.mp3', { dupes: 2, pan: mapGetPan(this._entity.c.position.mapPosition) });

        // trigger goal
        this._scene.sessionSystem.triggerGoal('prison', this._entity);
    }
}
