import app from '../../../../../index.entry';
import { CollideEvent, CollideId } from '../../defs/collide';
import type { CandleBlockEntity } from '../../entities/BlockEntity';
import { despawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { mapGetPan } from '../../util/mapTools';
import { BreakEffect } from '../effects/BreakEffect';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

/*
    candle block collision resolver
*/
export class CandleCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: CandleBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        block: this._resolveBlock,
        attack: this._resolveAttack,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: CandleBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        // invalid effect
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveBlock(event: CollideEvent) {
        const collider = event.collider;

        // if block collider and is basic type
        if (collider?.type === 'block' && collider.entity.c.block.props.type === 'basic') {
            // poke candle
            this._poke();
            return true;
        }
        return false;
    }

    private async _resolveAttack(event: CollideEvent) {
        // poke candle
        this._poke();
        return true;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _poke() {
        const position = this._entity.c.position.mapPosition;
        const component = this._entity.c.blockCandle;

        // poke candle component
        component.poke();

        // if candle block empty
        if (component.isEmpty()) {
            // despawn candle block
            despawnBlockEntity(this._scene, this._entity);
        }

        // play sound
        app.sound.play(`puzzle-candle-${component.isEmpty() ? 'destroy' : 'poke'}.mp3`, {
            pan: mapGetPan(position),
        });

        // execute break effect
        return new BreakEffect(this._scene, {
            position,
            size: this._entity.c.block.props,
            color: 0xeeaa22,
        }).execute();
    }
}
