// format
//-----------------------------------------------------------------------------
export const mapFormat = {
    moves: 'm', // <m#>
    goals: 'o', // <o[GOALID#...]>
    rewards: 'r', // <r[REWARDID#...]>
    spawns: 's', // <s[BLOCKID,...]>
    grid: 'g', // <g[GRIDFORMAT]>
};

// GRIDFORMAT
export const mapGridFormat = {
    columns: 'o', // <o#>
    rows: 'r', // <r#>
    cells: 'c', // <c[CELLFORMAT,...]>
};

// CELLFORMAT: <$|<BLOCKID|...>>
export const mapCellFormat = {
    tile: '$',
};
