import { gsap } from 'gsap';
import { Container, Graphics } from 'pixi.js';

import app from '../../../index.entry';
import { BoundsType, SizeType } from '../../../lib/defs/types';
import { IAnimation } from '../../../lib/pattern/IAnimation';
import { pixiToLocalBounds } from '../../../lib/pixi/pixiTools';
import { CircleSpotLight } from '../../lib/ui/lights/CircleSpotLight';
import { SpotLight } from '../../lib/ui/lights/SpotLight';
import { TargetSpotLight } from '../../lib/ui/lights/TargetSpotLight';
import { Pointer, PointerType } from '../../lib/ui/Pointer';
import { MessagePopup2, MessagePopup2Options } from '../popups/MessagePopup2';

// constants
//-----------------------------------------------------------------------------

export type PointerMotion = 'all' | 'point' | 'tap';

export type TargetTipOptions2 = {
    type?: PointerType;
    motion?: PointerMotion;
    pointerOffset?: { x: number; y: number };
    targets: BoundsType[];
    allowInput: boolean;
} & MessagePopup2Options;

enum DIRECTION {
    LEFT,
    RIGHT,
    UP,
    DOWN,
}

/*
    tutorial screen
*/
export class TipScreen2 extends MessagePopup2 {
    // private
    //-------------------------------------------------------------------------
    // input
    private _options: TargetTipOptions2;
    // scene
    private _pointer: Pointer;
    private _lightArea: Container;
    // state
    private _animation: IAnimation;
    // maps
    private _animationFactory = {
        all: this._animateAll,
        point: this._animatePoint,
        tap: this._animateTap,
        left: () => this._animateDirection(DIRECTION.LEFT),
        up: () => this._animateDirection(DIRECTION.UP),
        right: () => this._animateDirection(DIRECTION.RIGHT),
        down: () => this._animateDirection(DIRECTION.DOWN),
    };

    // impl
    //-------------------------------------------------------------------------

    // api
    //-------------------------------------------------------------------------
    public override async spawning(options?: TargetTipOptions2): Promise<void> {
        super.spawning(options);

        this._options = options;
    }

    public override preload() {
        return [...super.preload(), ...app.resource.loadAssets(Pointer.assets())];
    }

    public override resized(size: SizeType): void {
        super.resized(size);
        this.show();
    }

    public show() {
        if (!this._options.targets) return;

        this.content.removeChildren();
        this._spawnLight();
        // spawn light overlay only, update target after everything is spawned (popup for example)
        if (this._options.targets.length === 0) return;
        this._spawnLightMask();
        this._spawnPointer();
        this._spawnAnimation();
    }

    public updateTarget(targets: BoundsType[]) {
        this._options.targets = targets;
        this.show();
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawnLight() {
        // spawn spotlight depending on target count
        const light = (this._lightArea =
            this._options.targets.length > 1 ? this._spawnMultiTargetLight() : this._spawnSingleTargetLight());

        // lower z index to exist under message
        light.zIndex = -1;
        this.content.addChild(light);
        light.start();
        this.content.sortChildren();

        // set input control
        light.interactive = !this._options.allowInput;
    }

    private _spawnLightMask() {
        // draw mask around light target and disable input event on mask
        const { x: targetX, y: targetY, width: targetWidth, height: targetHeight } = this._lightArea;
        const rootWidth = this.root.width;
        const rootHeight = this.root.height;
        [
            { x: 0, y: 0, w: rootWidth, h: targetY },
            { x: 0, y: targetY, w: targetX, h: targetHeight },
            { x: targetX + targetWidth, y: targetY, w: rootWidth - targetX - targetWidth, h: targetHeight },
            { x: 0, y: targetY + targetHeight, w: rootWidth, h: rootHeight - targetY - targetHeight },
        ].forEach(({ x, y, h, w }) => {
            const mask = new Graphics().moveTo(0, 0).beginFill(0, 0.01).drawRect(x, y, w, h).endFill();
            mask.interactive = true;
            mask.zIndex = 10;
            this.content.addChild(mask);
        });
    }

    // private: light
    //-------------------------------------------------------------------------
    private _spawnSingleTargetLight(): SpotLight {
        const bounds =
            this._options.targets.length === 0 ? null : pixiToLocalBounds(this.content, this._options.targets[0]);

        // spawn circle spotlight
        const light = new CircleSpotLight({
            radius: bounds ? Math.max(bounds.width, bounds.height) / 2 : 0,
            height: this.root.height,
            width: this.root.width,
        });

        // no bounds, just return light overlay without actual light
        if (!bounds) return light;

        // position at target center
        light.lightPosition.set(bounds.x + bounds.width / 2, bounds.y + bounds.height / 2);

        return light;
    }

    private _spawnMultiTargetLight() {
        // spawn multitargetted spotlight
        return new TargetSpotLight({
            targets: this._options.targets,
            height: this.root.height,
            width: this.root.width,
        });
    }

    private _spawnPointer() {
        const { type } = this._options;
        if (!type) {
            // No pointer just spotlight
            return;
        }

        const pointer = new Pointer({ type });
        const offset = this._options?.pointerOffset;
        if (offset) {
            pointer.x = offset.x;
            pointer.y = offset.y;
        }
        this.content.addChild(pointer);
        this._pointer = pointer;
    }

    private _spawnAnimation() {
        const { motion } = this._options;
        if (!motion) {
            // No pointer to animate, just spotlight
            return;
        }

        this._animation = this._animationFactory[motion].call(this);
        this._animation.start();
    }

    // private: animation factory
    //-------------------------------------------------------------------------
    private _animateAll(): IAnimation {
        const timeline = gsap.timeline();
        const position = this._pointer.position;

        // prepare variables
        const duration = 0.3;

        // set initial position
        const path = this._options.targets.map((target) => {
            const bounds = pixiToLocalBounds(this.content, target);
            bounds.y += bounds.height;
            return bounds;
        });
        position.set(path[0].x, path[0].y);

        // for each motion path
        for (let i = 1; i < path.length; ++i) {
            const position = path[i];
            timeline.to(this._pointer, { x: position.x, y: position.y, duration, ease: 'none' });
        }

        // animation setup
        timeline.pause().repeat(-1).yoyo(true);

        return {
            start: async () => {
                timeline.play();
            },
            stop: () => {
                timeline.kill();
            },
        };
    }

    private _animatePoint(): IAnimation {
        const pointer = this._pointer;
        const timeline = gsap.timeline();
        const bounds = pixiToLocalBounds(this.content, this._options.targets[0]);
        const x = bounds.x + bounds.width / 2;

        // set initial position
        pointer.position.set(x, bounds.y + bounds.height + 70);

        // animate setup
        timeline.to(pointer, { x, y: bounds.y + bounds.height, duration: 0.7, ease: 'power1.in' });

        return {
            start: async () => timeline.play().repeat(-1).yoyo(true),
            stop: () => timeline.kill(),
        };
    }

    private _animateTap(): IAnimation {
        const pointer = this._pointer;
        const timeline = gsap.timeline();
        const bounds = pixiToLocalBounds(this.content, this._options.targets[0]);

        // anchor center to allow rotation
        pointer.anchor.set(0.5);

        // set initial position
        pointer.position.set(
            bounds.x + (bounds.width + pointer.width) / 2 + pointer.x,
            bounds.y + (bounds.height + pointer.height) / 2 + pointer.y,
        );

        // animate setup
        timeline.to(pointer, { rotation: 0.5, duration: 0.45, ease: 'power1.out' });

        return {
            start: async () => timeline.play().repeat(-1).repeatDelay(0.2).yoyo(true),
            stop: () => timeline.kill(),
        };
    }

    private _animateDirection(direction: DIRECTION): IAnimation {
        const pointer = this._pointer;
        const timeline = gsap.timeline();
        const bounds = pixiToLocalBounds(this.content, this._options.targets[0]);
        const startX = bounds.x + bounds.width / 2;
        const startY = bounds.y + bounds.height / 2;
        let midX = startX;
        let midY = startY;
        let endX = startX;
        let endY = startY;

        switch (direction) {
            case DIRECTION.LEFT: {
                midX = bounds.x;
                endX = bounds.x - bounds.width / 2;
                break;
            }
            case DIRECTION.UP: {
                midY = bounds.y;
                endY = bounds.y - bounds.height / 2;
                break;
            }
            case DIRECTION.RIGHT: {
                midX = bounds.x + bounds.width;
                endX = bounds.x + bounds.width * 1.5;
                break;
            }
            case DIRECTION.DOWN: {
                midY = bounds.y + bounds.height;
                endY = bounds.y + bounds.height * 1.5;
                break;
            }
        }

        if (startX + pointer.width > this.content.width) {
            // flip pointer when it goes off screen
            pointer.scale.x = pointer.scale.x * -1;
        }

        // set initial position
        pointer.position.set(startX, startY);

        // animate setup
        timeline
            .to(pointer, { x: midX, y: midY, alpha: 0.8, duration: 0.7, ease: 'power1.in' })
            .to(pointer, { x: endX, y: endY, alpha: 0, duration: 0.5, ease: 'power1.out' });

        return {
            start: async () => timeline.play().repeatDelay(1).repeat(-1),
            stop: () => timeline.kill(),
        };
    }
}
