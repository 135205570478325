import { Assets, Container } from 'pixi.js';

import app from '../../../../index.entry';
import { PositionType } from '../../../../lib/defs/types';
import { IAnimation } from '../../../../lib/pattern/IAnimation';
import { SpritesheetAnimation } from '../../../../lib/pixi/animation/SpritesheetAnimation';
import { mapGetPan } from '../util/mapTools';

// types
//-----------------------------------------------------------------------------
export type DrillAnimationOptions = {
    position: PositionType;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    clip: 'clip.drill.json',
};

/*
    drill booster animation
*/
export class DrillAnimation extends Container implements IAnimation {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onMove: (row: number) => void;
    // input
    private _options: DrillAnimationOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(options: DrillAnimationOptions) {
        super();
        this._options = options;
    }

    // init
    //-------------------------------------------------------------------------
    static assets(): string[] {
        return Object.values(manifest);
    }

    // impl
    //-------------------------------------------------------------------------
    public async start(): Promise<void> {
        // load assets
        await Assets.load(DrillAnimation.assets());

        // play sound
        app.sound.play('puzzle-drill.mp3', { pan: mapGetPan(this._options.position) });

        // create clip
        const clip = this._createClip();

        // spawn clip
        this.addChild(clip);

        // animate clip
        await clip.start();

        // despawn clip
        this.removeChild(clip);
    }

    public stop() {}

    // private: events
    //-------------------------------------------------------------------------
    private _onClipFrame(clip: SpritesheetAnimation, frame: number) {
        // determine puzzle row of where drill is by frame and spawned position
        const row = this._options.position.y + Math.floor((24 * (frame - 32)) / clip.totalFrames);

        // notify
        this.onMove?.(row);
    }

    // private: factory
    //-------------------------------------------------------------------------
    private _createClip(): SpritesheetAnimation {
        // create animation
        const clip = new SpritesheetAnimation(manifest.clip);

        // position clip
        clip.y = -236;
        clip.anchor.set(0.5, 0);

        // handle frame update
        clip.onFrameChange = (frame: number) => this._onClipFrame(clip, frame);

        return clip;
    }
}
