import app from '../../index.entry';
import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { getStepFinishTime } from '../../replicant/components/bakery';
import bakery, { ProduceEntry, StepData, TimedStep } from '../../replicant/defs/bakery';
import { CakeItemId } from '../../replicant/defs/items';
import { trackCompleteRecipe, trackRecipeStepFinish, trackRecipeStepStart } from '../lib/analytics/bakery';
import { RecipePopup } from '../lib/ui/popups/RecipePopup';
import { HomeScreen } from '../main/home/HomeScreen';
import { CustomerExitFlow } from './customer/CustomerExitFlow';

type BakeData = {
    bakeState: ProduceEntry;
    steps: StepData[];
    timedStep: TimedStep;
    isTimedAndFinished: boolean;
    notStartedTimedStep: boolean;
};

export class RecipeStepFlow implements IFlow {
    private readonly _complete = new NakedPromise<{ goToPuzzle?: boolean }>();
    private _cakeId: CakeItemId;
    private _screen: HomeScreen;
    private _popup: RecipePopup;

    // init
    //-------------------------------------------------------------------------
    constructor(opts: { screen: HomeScreen; popup: RecipePopup; cakeId: CakeItemId }) {
        const { cakeId, screen, popup } = opts;
        this._cakeId = cakeId;
        this._screen = screen;
        this._popup = popup;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        const bakeData = this._generateBakeData();
        const isFinalStep = bakeData.bakeState.step === bakeData.steps.length - 1;
        if (
            (isFinalStep && !bakeData.timedStep) ||
            (isFinalStep && !!bakeData.timedStep && bakeData.isTimedAndFinished)
        ) {
            await this._finalAction(bakeData);
        } else {
            await this._regularAction(bakeData);
        }

        return this._complete;
    }

    private async _actionComplete(opts: { goToPuzzle?: boolean }) {
        this._complete.resolve(opts);
    }

    private async _finalAction(opts: BakeData) {
        const { timedStep, steps, bakeState, isTimedAndFinished, notStartedTimedStep } = opts;
        const continueAction = await this._starStep({
            bakeState,
            steps,
            isTimedAndFinished,
            notStartedTimedStep,
            timedStep,
        });
        if (!continueAction) return;

        if (!timedStep) {
            // timeStep STARTED several minutes ago in a _regularAction execution
            // ----- analytics
            trackRecipeStepStart({
                cakeName: this._cakeId,
                step: bakeState.step,
                type: 'instant',
            });
            // ---------------
        }

        await this._screen.toggleScripted({ scripted: true });

        app.nav.close('recipePopup');
        await this._screen.despawnTableItem();

        // final step with ready click
        if (timedStep) {
            await this._screen.spawnTimedStepAnimation({
                timedStep: timedStep.type,
                icon: steps[bakeState.step].timedStep.icons.start,
                finish: true,
            });
        }

        const customerEntryId = app.server.state.bakery.produce[this._cakeId].customerEntryId;
        await new CustomerExitFlow({ screen: this._screen, customerEntryId }).execute();

        // update cake once we finished the customer sequence to avoid missing any step
        await app.server.invoke.updateBakeStep({ cakeId: this._cakeId });

        // ----- analytics
        trackRecipeStepFinish({
            cakeName: this._cakeId,
            step: bakeState.step,
            type: !!timedStep && isTimedAndFinished ? 'time_consuming' : 'instant',
        });
        trackCompleteRecipe(this._cakeId);
        // ---------------

        this._screen.starView.resumeUpdateAmount();

        await this._screen.toggleScripted({ scripted: false });
        this._actionComplete({});
    }

    private async _regularAction(opts: BakeData) {
        const { bakeState, steps, timedStep, isTimedAndFinished, notStartedTimedStep } = opts;
        const continueAction = await this._starStep({
            bakeState,
            steps,
            isTimedAndFinished,
            notStartedTimedStep,
            timedStep,
        });
        if (!continueAction) return;

        this._screen.playPlayerAnimation('happy', true);

        await this._screen.toggleScripted({ scripted: true });

        let dialogA = true;

        app.nav.close('recipePopup');
        await this._screen.despawnTableItem();

        let timerStart;
        // timed and not started timer
        if (notStartedTimedStep) {
            // ----- analytics
            trackRecipeStepStart({ cakeName: this._cakeId, step: bakeState.step, type: 'time_consuming' });
            // ---------------
            timerStart = app.server.now();
            // mock timer to make sure the player has seen all steps before we update the state
            // re-use client side timer to render 'correct' time in both the scripted step and once we've updated the state
            const mockBakeState = { ...bakeState, startTimestamp: timerStart };
            const finishTime = getStepFinishTime(this._cakeId, mockBakeState);
            await this._screen.spawnTimedStepAnimation({
                timedStep: timedStep.type,
                icon: steps[bakeState.step].timedStep.icons.start,
            });

            const popupPromise = new NakedPromise();
            const popupId = 'waitStepPopup';
            app.nav.open(popupId, {
                type: timedStep.type,
                text: timedStep.description,
                onOk: () => popupPromise.resolve(),
                finishTime,
            });
            await popupPromise;
            app.nav.close(popupId);
        } else {
            if (isTimedAndFinished) {
                dialogA = false;
                await this._screen.spawnTimedStepAnimation({
                    timedStep: timedStep.type,
                    icon: steps[bakeState.step].timedStep.icons.finish,
                    finish: true,
                });
            } else {
                // ----- analytics
                trackRecipeStepStart({ cakeName: this._cakeId, step: bakeState.step, type: 'instant' });
                // ---------------
            }
            const tableIcon = bakery.cakeRecipeMap[this._cakeId][bakeState.step].tableIcon;
            await this._screen.spawnTableItem(tableIcon, true);
        }

        if (dialogA) {
            await this._chefSequence({ dialogs: steps[bakeState.step].chefDialogs.a });
            if (!timedStep) {
                // ----- analytics
                trackRecipeStepFinish({ cakeName: this._cakeId, step: bakeState.step, type: 'instant' });
                // ---------------
            }
        } else {
            await this._chefSequence({
                dialogs: steps[bakeState.step].chefDialogs.b,
                animationMap: isTimedAndFinished ? { 0: 'excited', 1: 'happy' } : null,
            });
            // ----- analytics
            trackRecipeStepFinish({ cakeName: this._cakeId, step: bakeState.step, type: 'time_consuming' });
            // ---------------
        }

        this._screen.starView.resumeUpdateAmount();
        await app.server.invoke.updateBakeStep({ cakeId: this._cakeId, forcedTimedTimestamp: timerStart });
        await this._screen.toggleScripted({ scripted: false });
        this._actionComplete({});
    }

    private async _starStep(opts: {
        bakeState: ProduceEntry;
        steps: StepData[];
        isTimedAndFinished: boolean;
        notStartedTimedStep: boolean;
        timedStep: TimedStep;
    }): Promise<boolean> {
        const { bakeState, steps, isTimedAndFinished, notStartedTimedStep, timedStep } = opts;
        const starsRequired = steps[bakeState.step].starCost;
        if (app.game.player.stars < starsRequired && !isTimedAndFinished) {
            const goToPuzzle = await this._notEnoughStars();
            this._actionComplete({ goToPuzzle });
            return false;
        }

        if (!timedStep || notStartedTimedStep) {
            const global = this._popup.itemButtons[0].toGlobal({ x: 0, y: 0 });
            // mock value to make animation cleaner, resume updater in the end of the flow
            this._screen.starView.forceUpdateAmount(app.server.state.stars - starsRequired);
            await this._screen.starDecreaseAnimation(global);
        }
        return true;
    }

    private async _chefSequence(opts: { dialogs: string[]; animationMap?: Record<number, 'excited' | 'happy'> }) {
        const { dialogs, animationMap } = opts;
        for (let i = 0; i < dialogs.length; i++) {
            const tapPromise = new NakedPromise();
            if (!!animationMap && !!animationMap[i]) {
                this._screen.playPlayerAnimation(animationMap[i], true);
            }
            await this._screen.spawnBubbleTap({ speech: dialogs[i] }, tapPromise);
            await tapPromise;
        }
    }

    private async _notEnoughStars() {
        const noStarsPromise = new NakedPromise<boolean>();
        app.nav.open('notEnoughStarsPopup', {
            onOk: () => noStarsPromise.resolve(true),
            onClose: () => noStarsPromise.resolve(false),
        });
        const goToPuzzle = await noStarsPromise;
        app.nav.close('notEnoughStarsPopup');
        return goToPuzzle;
    }

    private _generateBakeData(): BakeData {
        const bakeState = app.server.state.bakery.produce[this._cakeId];
        const steps = bakery.cakeRecipeMap[this._cakeId];
        const timedStep = steps[bakeState.step].timedStep;
        const finishTime = getStepFinishTime(this._cakeId, bakeState);
        const isTimedAndFinished = bakeState.startTimestamp > 0 && app.server.now() > finishTime;
        const notStartedTimedStep = timedStep && app.server.state.bakery.produce[this._cakeId].startTimestamp === 0;

        return { bakeState, steps, timedStep, isTimedAndFinished, notStartedTimedStep };
    }
}
