import { Container, ITextStyle, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import app from '../../../../index.entry';
import { BasicAsyncHandler } from '../../../../lib/defs/types';
import { pixiSetInterval } from '../../../../lib/pixi/pixiTools';
import {
    uiAlignCenter,
    uiAlignCenterX,
    uiAlignCenterY,
    uiCreateQuad,
    uiSizeToWidth,
} from '../../../../lib/pixi/uiTools';
import { getStepFinishTime } from '../../../../replicant/components/bakery';
import bakery, { ProduceEntry } from '../../../../replicant/defs/bakery';
import { CakeItemId, cakeItemPropsMap } from '../../../../replicant/defs/items';
import { sleep } from '../../../../replicant/util/jsTools';
import { timeFormatCountdown, timeToComponents } from '../../../../replicant/util/timeTools';
import { TextImageButton } from '../buttons/TextImageButton';
import { ScrollBox } from '../containers/ScrollBox';
import { BasicText } from '../text/BasicText';
import { BasicPopup } from './BasicPopup';

const HEIGHT = 800;
const BUTTON_OFFSET_X = 392;

const buttonTextStyle: Partial<ITextStyle> = {
    dropShadow: true,
    dropShadowAngle: Math.PI / 2,
    dropShadowDistance: 3,
    dropShadowAlpha: 0.7,
    dropShadowBlur: 5,
    fill: '#fff',
    fontSize: 32,
    fontWeight: 'bold',
    lineJoin: 'round',
};

// types
//-----------------------------------------------------------------------------
export type RecipePopupOptions = {
    onClose: BasicAsyncHandler;
    cakeId: CakeItemId;
    cakeStatus: ProduceEntry;
};

const manifest = {
    itemFrame: 'frame.list.item.png',
    itemFrameTimer: 'frame.list.timer.png',
    itemFrameReady: 'frame.list.ready.png',
    itemFrameDone: 'frame.list.completed.png',
    timerLightFrame: 'frame.inset.light.png',
    pattern: 'icon.recipe.header.png',
    button: 'button.red.small.png',
    star: 'icon.star.main.png',
    timer: 'icon.timer.png',
    check: 'icon.check.png',
};

/*
    General recipe popup
*/
export class RecipePopup extends BasicPopup {
    // injected
    onItemPress: (id: CakeItemId) => Promise<void>;

    private _itemScroll: ScrollBox;
    private _cakeId: CakeItemId;
    private _playTimer: boolean;

    // used for timed step and timer rendering.
    private _finishTime: number;
    private _isTimerFinished = false;
    // used to redraw item frame in scroll list once the timer reached ready state
    private _timerStepIcon: string;
    private _timerStepKey: string;

    private _itemButtons: TextImageButton[];

    public get itemButtons() {
        return this._itemButtons;
    }

    public override preload() {
        return [
            ...super.preload(),
            ...app.resource.loadAssets([
                ...Object.values(manifest),
                ...Object.keys(cakeItemPropsMap).map((key: CakeItemId) => cakeItemPropsMap[key].icon),
                ...Object.keys(bakery.cakeRecipeMap)
                    .map((key: CakeItemId) => bakery.cakeRecipeMap[key])
                    .flatMap((steps) => steps.map((step) => step.icon)),
            ]),
        ];
    }

    // @ts-ignore
    public override async spawning(options: RecipePopupOptions) {
        super.spawning({
            width: 700,
            height: HEIGHT,
            underlay: 0.7,
            ...options,
        });

        // reset old references
        this._playTimer = false;
        this._finishTime = 0;
        this._isTimerFinished = false;
        this._cakeId = options.cakeId;
        this._itemButtons = [];

        const scroll = this._createItemScroll(options);

        // pre-scroll
        scroll.scroll({ x: 0, y: 130 * options.cakeStatus.step }, 0, true);

        const pattern = Sprite.from(manifest.pattern);
        const cake = cakeItemPropsMap[options.cakeId];
        const cakeIcon = Sprite.from(cake.icon);

        const cakeName = new BasicText({
            text: cake.name,
            style: {
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowDistance: 5,
                dropShadowColor: 0x0c7c7c7,
                fill: 0x00,
                fontSize: 35,
                fontWeight: 'bold',
                lineJoin: 'round',
                stroke: 0xdf886e,
                strokeThickness: 4,
            },
        });

        pattern.addChild(cakeIcon, cakeName);
        uiAlignCenter(pattern, cakeIcon);
        cakeIcon.y -= 10;
        uiAlignCenter(pattern, cakeName);
        cakeName.y += 150;

        this.main.addContent({
            header: {
                content: pattern,
                styles: {
                    position: 'topCenter',
                    marginTop: -48,
                },
            },
            scroll: {
                content: scroll,
                styles: {
                    position: 'bottomCenter',
                    marginBottom: -20,
                },
            },
        });
    }

    public override async spawned(): Promise<void> {
        await super.spawned();
    }

    public override async despawning() {
        this._stopTimerSfx();
        await super.despawning();
    }

    public override async despawned() {
        super.despawned();
    }

    private async _playTimerSfx() {
        this._playTimer = true;
        while (this._playTimer) {
            await app.sound.play('timer.ogg', { volume: 1.6, dupes: 0 });
            await sleep(0.4);
        }
    }

    private _stopTimerSfx() {
        this._playTimer = false;
    }

    private _createItemScroll(opts: RecipePopupOptions) {
        const { cakeId, cakeStatus } = opts;
        const itemScroll = (this._itemScroll = new ScrollBox({
            direction: 'down',
            width: this.base.width - 40,
            height: this.base.height - 370,
        }));

        let y = 8;
        const recipeSteps = bakery.cakeRecipeMap[cakeId];
        let stepIndex = 0;

        for (const { key, icon, timedStep } of recipeSteps) {
            const time = timedStep?.time;
            let itemFrame;
            const isStepDone = stepIndex < cakeStatus.step;
            if (isStepDone) {
                itemFrame = uiCreateQuad(0x0, 0.001, 640, 120);
            } else {
                const frameAsset = time ? manifest.itemFrameTimer : manifest.itemFrame;
                itemFrame = this._createItemFrame(frameAsset);
            }

            itemFrame.y = y;
            y += itemFrame.height + 10;
            uiAlignCenterX(itemScroll, itemFrame);
            itemScroll.content.addChild(itemFrame);

            if (isStepDone) {
                const checkMark = Sprite.from(manifest.check);
                const fadedFrame = new NineSlicePlane(Texture.from(manifest.itemFrameDone), 35, 35, 35, 35);
                fadedFrame.width = 630;
                fadedFrame.height = 120;
                itemFrame.addChild(fadedFrame, checkMark);
                uiAlignCenterY(itemFrame, checkMark);
                checkMark.x += 535;
                fadedFrame.alpha = 0.65;
                this._addStepData(fadedFrame, key, icon);
            } else {
                if (
                    !time ||
                    (!!time && time > 0 && (stepIndex !== cakeStatus.step || cakeStatus.startTimestamp <= 0))
                ) {
                    const labelY = -4;
                    const stepButton = new TextImageButton({
                        text: '[buttonDoIt]',
                        x: -40,
                        image: manifest.button,
                        y: labelY,
                        slice: {
                            width: 226,
                            height: 81,
                            left: 45,
                            top: 0,
                            right: 45,
                            bottom: 0,
                        },
                        style: buttonTextStyle,
                    });
                    itemFrame.addChild(stepButton);
                    uiAlignCenterY(itemFrame, stepButton);
                    stepButton.x += BUTTON_OFFSET_X;
                    this._itemButtons.push(stepButton);

                    const star = Sprite.from(manifest.star);
                    star.scale.set(0.72);
                    stepButton.button.addChild(star);
                    star.x = 120;
                    star.y = 8;

                    const starAmount = new BasicText({
                        text: `${bakery.cakeRecipeMap[this._cakeId][stepIndex].starCost}`,
                        style: buttonTextStyle,
                    });

                    stepButton.button.addChild(starAmount);
                    uiAlignCenterY(stepButton.button, starAmount, labelY);
                    starAmount.x = 174;

                    if (stepIndex > cakeStatus.step) {
                        stepButton.alpha = 0.5;
                    }

                    if (cakeStatus.step === stepIndex) {
                        stepButton.onPress = async () => {
                            await this.onItemPress?.(cakeId);
                        };
                    } else {
                        stepButton.interactive = false;
                    }

                    this._addStepData(itemFrame, key, icon);

                    if (!!time && time > 0 && (stepIndex !== cakeStatus.step || cakeStatus.startTimestamp <= 0)) {
                        const timerIcon = Sprite.from(manifest.timer);
                        timerIcon.x = -6;
                        timerIcon.y = -3;
                        itemFrame.addChild(timerIcon);
                    }
                } else {
                    this._addStepData(itemFrame, key, icon);
                    // timer started, either counting or finished state
                    if (time && time > 0 && stepIndex === cakeStatus.step) {
                        this._timerStepIcon = icon;
                        this._timerStepKey = key;
                        this._finishTime = getStepFinishTime(cakeId, cakeStatus);
                        this._spawnTimer(itemFrame);
                    }
                }
            }

            stepIndex++;
        }

        // padding hack at the bottiom for full scroll view
        const bottomPadding = uiCreateQuad(0x0, 0.001, this.base.width - 70, 30);
        bottomPadding.y = y;
        itemScroll.content.addChild(bottomPadding);

        return itemScroll;
    }

    private _spawnTimer(frame: Container) {
        const timerFrame = new NineSlicePlane(Texture.from(manifest.timerLightFrame), 30, 30, 30, 30);
        timerFrame.width = 220;
        timerFrame.height = 70;
        const timer = Sprite.from(manifest.timer);

        timerFrame.addChild(timer);
        uiAlignCenter(timerFrame, timer);
        timer.x -= 75;
        timerFrame.x += 5;

        const time = new BasicText({
            text: '',
            style: {
                fill: 0x07c2e15,
                fontSize: 27,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'center',
            },
        });

        timerFrame.addChild(time);

        const update = () => {
            const timeLeft = this._finishTime - app.server.now();
            if (timeLeft > 0) {
                time.text = timeFormatCountdown(timeToComponents(this._finishTime - app.server.now()));
                uiAlignCenter(timerFrame, time, 20);
            } else {
                if (!this._isTimerFinished) {
                    this._stopTimerSfx();
                    this._isTimerFinished = true; // toggle to avoid re-rendering multople times
                    const cachedX = frame.x;
                    const cachedY = frame.y;
                    const completedFrame = this._createItemFrame(manifest.itemFrameReady);
                    completedFrame.x = cachedX;
                    completedFrame.y = cachedY;

                    // remove and re-render
                    frame.destroy();

                    const readyButton = new TextImageButton({
                        text: '[buttonReady]',
                        image: manifest.button,
                        y: -4,
                        slice: {
                            width: 226,
                            height: 81,
                            left: 45,
                            top: 0,
                            right: 45,
                            bottom: 0,
                        },
                        style: buttonTextStyle,
                    });

                    readyButton.onPress = async () => {
                        await this.onItemPress?.(this._cakeId);
                    };

                    completedFrame.addChild(readyButton);

                    completedFrame.addChild(readyButton);
                    uiAlignCenterY(frame, readyButton);
                    readyButton.x += BUTTON_OFFSET_X;
                    this._itemButtons.push(readyButton);

                    this._addStepData(completedFrame, this._timerStepKey, this._timerStepIcon);
                    uiAlignCenterX(this._itemScroll, completedFrame);
                    this._itemScroll.content.addChild(completedFrame);
                }
            }
        };

        if (this._finishTime - app.server.now() > 0) {
            this._playTimerSfx();
        }

        // start timer
        update();
        pixiSetInterval(frame, () => update(), 0.9);

        frame.addChild(timerFrame);
        uiAlignCenterY(frame, timerFrame);
        timerFrame.x += 385;
    }

    private _addStepData(frame: Container, key: string, icon: string) {
        const stepIcon = Sprite.from(icon);
        const width = 264;
        const stepText = new BasicText({
            text: key,
            style: {
                // fill: 0x28202a,
                fill: 0x7c2e15,
                fontSize: 26,
                fontWeight: 'bold',
                lineJoin: 'round',
                wordWrap: true,
                wordWrapWidth: width,
            },
        });

        uiSizeToWidth(stepText, width);
        frame.addChild(stepIcon, stepText);
        uiAlignCenterY(frame, stepIcon);

        uiAlignCenterY(frame, stepText);
        stepIcon.x = 18;
        stepText.x = stepIcon.x + stepIcon.width + 10;
    }

    private _createItemFrame(frameAsset: string) {
        const itemFrame = new NineSlicePlane(Texture.from(frameAsset), 35, 35, 35, 35);
        itemFrame.width = 640;
        itemFrame.height = 120;

        return itemFrame;
    }
}
