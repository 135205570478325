import app from '../../index.entry';
import { IFlow } from '../../lib/pattern/IFlow';
import { arraySwap } from '../../replicant/util/jsTools';
import { NavLayer } from '../defs/nav';
import { GameEvent } from '../main/match2-odie/defs/event';
import { BlockEntity } from '../main/match2-odie/entities/BlockEntity';
import { blockIterateAll } from '../main/match2-odie/util/blockTools';
import { PuzzleController } from '../main/puzzle/controller/PuzzleController';

/*
    executes the puzzle tutorial
*/
export class PuzzleTutorialFlow implements IFlow {
    // fields
    //-------------------------------------------------------------------------
    private _controller: PuzzleController;

    // init
    //-------------------------------------------------------------------------
    constructor(controller: PuzzleController) {
        this._controller = controller;
    }

    // impl
    //-------------------------------------------------------------------------
    async execute() {
        // run intro to hats step
        await this._stepHatsIntro();

        // run intro to moves step
        await this._stepMovesIntro();

        // complete tutorial
        await app.server.invoke.tutorialPuzzleComplete();
    }

    // private: steps
    //-------------------------------------------------------------------------
    /*
      shows intro to puzzle hats. shows instructions, points at blocks near
      the hats, and requires user to tap them. other input is blocked.
    */
    private async _stepHatsIntro() {
        const scene = this._controller.screen.scene;

        // get tap blocks
        const blocks = this._getTapBlocks();

        // show tip with these blocks as focus
        app.nav.open('tipScreen2', {
            text: '[tipPuzzleHats]',
            type: 'hand',
            motion: 'tap',
            allowInput: true,
            targets: blocks.map((block) => block.view.getBounds()),
            y: 245,
            width: 660,
        });

        // await requested user input that must be a tap of one of the focus blocks
        await new Promise((resolve) => {
            const listener = (event: GameEvent) => {
                if (event.id === 'input') {
                    if (!blocks.includes(event.cell.base?.entity)) {
                        return true;
                    }
                    scene.events.unsubscribe(listener);
                    resolve(0);
                }

                return false;
            };
            scene.events.subscribe(listener);
        });

        // close
        await app.nav.closeLayer(NavLayer.tip);
    }

    /*
      shows intro to puzzle moves. shows instructions, points at moves panel
      and requires user to hit ok to continue.
    */
    private async _stepMovesIntro() {
        // show tip with moves panel as focus
        await new Promise((resolve) =>
            app.nav.open('tipScreen2', {
                text: '[tipPuzzleMoves]',
                type: 'arrow',
                motion: 'point',
                onOk: resolve,
                targets: [this._controller.screen.header.moves.getBounds()],
                y: 460,
                width: 500,
            }),
        );

        // close
        await app.nav.closeLayer(NavLayer.tip);
    }

    // private: support
    //-------------------------------------------------------------------------
    private _getTapBlocks(): BlockEntity[] {
        const scene = this._controller.screen.scene;
        const blocks: BlockEntity[] = [];

        // get lowest row of color blocks
        blockIterateAll(scene, (block) => {
            if (block.c.position.mapPosition.y === 3) {
                if (block.c.block.blockId !== 'hatAll') blocks.push(block);
            }
        });

        // select middle block as first block. this is the one we'll point at.
        const middle = blocks.findIndex((block) => block.c.position.mapPosition.x === 4);
        if (middle >= 0) arraySwap(blocks, 0, middle);

        return blocks;
    }
}
