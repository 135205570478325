import type { EntityType, Vector2 } from '@play-co/odie';
import { createEntity, DefineEntity, Entity2D } from '@play-co/odie';
import { Container } from 'pixi.js';

import { PositionComponent } from '../components/PositionComponent';
import { TileComponent } from '../components/TileComponent';
import type { GameScene } from '../GameScene';

// types
//-----------------------------------------------------------------------------
const TileEntityDef = DefineEntity(Entity2D, PositionComponent, TileComponent);

export type TileEntity = EntityType<typeof TileEntityDef>;

// factory
//-----------------------------------------------------------------------------
export function spawnTileEntity(scene: GameScene, position: Vector2): TileEntity {
    // create container
    const container = new Container();

    // create entity
    const entity = createEntity(TileEntityDef, {
        view2d: { view: container, layer: 'back' },
        position: { position },
        tile: { mapDef: scene.sessionEntity.c.map.mapDef, position },
    });

    // set interactive
    entity.c.view2d.view.interactive = true;

    // initialize scene
    container.addChild(entity.c.tile.container);

    // add to scene
    scene.addToScene(entity);

    // add to map
    scene.sessionEntity.c.map.setTileEntity(entity, position);

    return entity;
}

export function despawnTileEntity(scene: GameScene, entity: TileEntity) {
    // remove from scene
    scene.removeFromScene(entity);
}
