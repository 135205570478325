import type { Entity, QueriesObject, QueryResults, System } from '@play-co/odie';

import { BasicBlockComponent } from '../components/BasicBlockComponent';
import { BlockComponent } from '../components/BlockComponent';
import { PhaseComponent } from '../components/PhaseComponent';
import type { BasicBlockProps } from '../defs/block';
import { blockPropsMap } from '../defs/block';
import { config } from '../defs/config';
import { SymbolId } from '../defs/symbol';
import { BasicBlockEntity } from '../entities/BlockEntity';
import type { GameScene } from '../GameScene';
import { matchFindGroup } from '../util/matchTools';

/*
    handles basic block specifics
*/
export class BasicBlockSystem implements System {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'blockBasic';
    public static Queries: QueriesObject = {
        block: {
            components: [BlockComponent, BasicBlockComponent],
            added: true,
        },
        phase: {
            components: [PhaseComponent],
            modified: true,
        },
    };

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    public queries!: QueryResults;

    // impl
    //-------------------------------------------------------------------------
    public modifiedQuery(entity: Entity, component: PhaseComponent, properties: any) {
        // if input phase update block symbols
        if (properties.phase && component.phase === 'input') {
            this._updateBlockSymbols();
        }
    }

    public addedToQuery(entity: BasicBlockEntity) {
        // update block symbol
        this._updateBlockSymbol(entity);
    }

    // private: actions
    //-------------------------------------------------------------------------
    private _updateBlockSymbols() {
        // update basic block symbols
        this.queries.block.forEach((entity: BasicBlockEntity) => this._updateBlockSymbol(entity));
    }

    private _updateBlockSymbol(entity: BasicBlockEntity) {
        // get match group, else just this entity
        let group = matchFindGroup(this.scene, entity);
        if (!group) group = { matches: [{ base: entity }], neighbors: [] };

        // get power block symbol given match count else restore to original symbol
        const newSymbolId =
            this._getPowerMatch(group.matches.length) ||
            (blockPropsMap[entity.c.block.blockId] as BasicBlockProps).symbol;

        // change all matching symbols to found symbol id
        for (const match of group.matches) {
            match.base.c.blockBasic.symbolId = newSymbolId;
        }
    }

    // private: support
    //-------------------------------------------------------------------------
    private _getPowerMatch(searchCount: number): SymbolId | undefined {
        let powerBlockId: SymbolId;
        let highCount = 0;

        // find highest matching count and corresponding power block id
        for (const [id, count] of Object.entries(config.blocks.basic.powerMatch)) {
            if (count >= highCount && count <= searchCount) {
                powerBlockId = id as SymbolId;
                highCount = count;
            }
        }

        return powerBlockId;
    }
}
