import { Vector2 } from '@play-co/odie';
import { gsap } from 'gsap';

import { config } from '../../defs/config';
import {
    BlockEntity,
    despawnBlockEntity,
    moveBlockEntity,
    SpiderBlockEntity,
    swapBlockEntities,
} from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { getGravityBounceTweenVars, getGravityDropTweenVars } from '../../systems/phases/FallPhase';
import { blockIsFrozen } from '../../util/blockTools';
import { findDropRow, hasBlockBelow, isDroppableCell, mapToViewPosition } from '../../util/mapTools';
import { IEffect } from './IEffect';

export type SpiderEffectOptions = {
    spiderEntities: SpiderBlockEntity[];
    onSpiderPositionUpdate: (spiderEntity: SpiderBlockEntity) => void;
};

type DownActionType = 'spiderDown' | 'spiderDestroy';
type ActionType = 'spiderUp' | DownActionType;
type AnimationEntityType = 'spider' | 'block';

type SpiderMoveAction = {
    actionType: ActionType;
    spiderEntity: SpiderBlockEntity;
    targetRow: number;
};

type SpiderMoveAnimation = {
    fromRow: number;
    toRow: number;
};

type SpiderMoveUpAnimation = {
    spiderEntity: SpiderBlockEntity;
    blockEntity: BlockEntity;
} & SpiderMoveAnimation;

type SpiderMoveDownAnimation = {
    actionType: DownActionType;
    entity: BlockEntity;
    entityType: AnimationEntityType;
} & SpiderMoveAnimation;

export class SpiderEffect implements IEffect {
    private readonly _scene: GameScene;
    private readonly _options: SpiderEffectOptions;

    constructor(scene: GameScene, options: SpiderEffectOptions) {
        this._scene = scene;
        this._options = options;
    }

    static assets(): string[] {
        return [];
    }

    public async execute() {
        const { spiderEntities } = this._options;

        const moveUpActions: SpiderMoveAction[] = [];
        const moveDownActions: SpiderMoveAction[] = [];

        spiderEntities.forEach((spiderEntity: SpiderBlockEntity) => {
            const { dropRow, shouldKillSpider } = this._getDropRow(spiderEntity);

            if (dropRow) {
                // Build both down and destroy animations at the same time to ensure grid
                // is fully up-to-date while computing future spider/block positions
                moveDownActions.push({
                    actionType: shouldKillSpider ? 'spiderDestroy' : 'spiderDown',
                    spiderEntity,
                    targetRow: shouldKillSpider ? dropRow + 1 : dropRow,
                });
            } else if (this._canSpiderMoveUp(spiderEntity)) {
                moveUpActions.push({
                    actionType: 'spiderUp',
                    spiderEntity,
                    targetRow: spiderEntity.c.position.mapPosition.y - 1,
                });
            }
        });

        if (moveUpActions.length || moveDownActions.length) {
            const upAnimations = this._buildUpAnimations(moveUpActions);
            const downAnimations = this._buildDownAnimations(moveDownActions);

            await Promise.all([
                ...upAnimations.map((animation) => this._runUpAnimation(animation)),
                ...downAnimations.map((animation) => this._runDownAnimation(animation)),
            ]);

            return true;
        }

        return false;
    }

    private _buildUpAnimations(actions: SpiderMoveAction[]): SpiderMoveUpAnimation[] {
        const { map } = this._scene.sessionEntity.c;
        const animations: SpiderMoveUpAnimation[] = [];

        actions.forEach((action) => {
            const { spiderEntity, targetRow } = action;
            const { mapPosition: spiderMapPosition } = spiderEntity.c.position;
            const spiderRow = spiderMapPosition.y;

            const targetPosition = new Vector2(spiderMapPosition.x, targetRow);
            const targetCell = map.getCellAt(targetPosition);
            const targetEntity = targetCell?.base?.entity;

            if (!targetEntity) {
                moveBlockEntity(this._scene, spiderEntity as BlockEntity, targetPosition);
            } else {
                swapBlockEntities(this._scene, spiderEntity as BlockEntity, targetEntity);
            }

            animations.push({
                spiderEntity,
                blockEntity: targetEntity,
                fromRow: spiderRow,
                toRow: targetRow,
            });
        });

        return animations;
    }

    private _buildDownAnimations(actions: SpiderMoveAction[]): SpiderMoveDownAnimation[] {
        const { map } = this._scene.sessionEntity.c;
        const columns: number[] = [];
        const animations: SpiderMoveDownAnimation[] = [];

        let lowestRow = -1;

        // move spiders to new position and find the grid area they affect...
        // ...accumulating animations along the way
        actions.forEach((action) => {
            const { actionType, spiderEntity, targetRow } = action;
            const entityPosition = spiderEntity.c.position.mapPosition;

            columns.push(entityPosition.x);
            lowestRow = Math.max(lowestRow, targetRow);

            moveBlockEntity(this._scene, spiderEntity as BlockEntity, new Vector2(entityPosition.x, targetRow));

            animations.push({
                actionType: actionType as DownActionType,
                entity: spiderEntity as BlockEntity,
                entityType: 'spider',
                fromRow: entityPosition.y,
                toRow: targetRow,
            });
        });

        // iterate through spiders' area of influence...
        // ...accumulating animations along the way
        for (let row = lowestRow; row >= 0; --row) {
            for (let columnIndex = 0; columnIndex < columns.length; ++columnIndex) {
                const column = columns[columnIndex];
                const { actionType } = actions[columnIndex];
                const cell = map.grid[column][row];

                if (isDroppableCell(this._scene, cell)) {
                    const entity = cell.base.entity;
                    const toRow = findDropRow(map, entity);

                    if (toRow !== undefined) {
                        moveBlockEntity(this._scene, entity, new Vector2(column, toRow));

                        animations.push({
                            actionType: actionType as DownActionType,
                            entity,
                            entityType: 'block',
                            fromRow: row,
                            toRow,
                        });
                    }
                }
            }
        }

        return animations;
    }

    private async _runUpAnimation(animation: SpiderMoveUpAnimation) {
        const { spiderEntity, blockEntity } = animation;
        const [spiderViewY, blockViewY] = this._getAnimationYValues(animation);
        const spiderConfig = config.sim.spider;

        spiderEntity.view.y = spiderViewY;

        const timeline = gsap.timeline().to(spiderEntity.view, {
            y: blockViewY,
            duration: this._getAnimationDuration(blockViewY, spiderViewY, spiderConfig.moveSpeed),
            ease: 'power2.inOut',
            onUpdate: this._options.onSpiderPositionUpdate,
            onUpdateParams: [spiderEntity],
        });

        if (blockEntity) {
            blockEntity.view.y = blockViewY;

            timeline.to(blockEntity.view, {
                y: spiderViewY,
                duration: this._getAnimationDuration(spiderViewY, blockViewY, spiderConfig.blockPushSpeed),
                ease: 'power3.in',
            });
        }

        await timeline;
    }

    private async _runDownAnimation(animation: SpiderMoveDownAnimation) {
        type SpiderMoveDownAnimationRunner = (animation: SpiderMoveDownAnimation) => Promise<void>;
        const animationMap: Record<DownActionType, SpiderMoveDownAnimationRunner> = {
            spiderDown: this._runNormalDownAnimation,
            spiderDestroy: this._runDestroyDownAnimation,
        };

        return animationMap[animation.actionType].call(this, animation);
    }

    private async _runNormalDownAnimation(animation: SpiderMoveDownAnimation) {
        const { entity, entityType } = animation;
        const [fromViewY, toViewY] = this._getAnimationYValues(animation);

        entity.view.y = fromViewY;

        await gsap.to(entity.view, {
            y: toViewY,
            duration: this._getAnimationDuration(fromViewY, toViewY, config.sim.spider.moveSpeed),
            ease: 'power2.inOut',
            onUpdate: entityType === 'spider' ? this._options.onSpiderPositionUpdate : undefined,
            onUpdateParams: [entity],
        });
    }

    private async _runDestroyDownAnimation(animation: SpiderMoveDownAnimation) {
        const { entity, entityType } = animation;
        const [fromViewY, toViewY] = this._getAnimationYValues(animation);

        entity.view.y = fromViewY;

        // use FallPhase gravity animation variables
        const timeline = gsap.timeline().to(entity.view, getGravityDropTweenVars(fromViewY, toViewY));

        if (entityType === 'spider') {
            entity.c.blockSpider.view.breakWeb();

            // drop spider off-screen, but don't bounce, then despawn entity
            await timeline;

            despawnBlockEntity(this._scene, entity);
            return;
        }

        await timeline.to(entity.view, getGravityBounceTweenVars(toViewY));
    }

    private _getAnimationYValues(animation: SpiderMoveAnimation): [number, number] {
        const { fromRow, toRow } = animation;
        const { y: fromViewY } = mapToViewPosition(new Vector2(0, fromRow));
        const { y: toViewY } = mapToViewPosition(new Vector2(0, toRow));
        return [fromViewY, toViewY];
    }

    private _getAnimationDuration(fromY: number, toY: number, speed: number): number {
        return Math.abs(toY - fromY) / speed;
    }

    private _getDropRow(spiderEntity: SpiderBlockEntity): { dropRow: number; shouldKillSpider: boolean } {
        const { map } = this._scene.sessionEntity.c;
        const dropRow = findDropRow(map, spiderEntity as BlockEntity);

        if (!dropRow) {
            return { dropRow, shouldKillSpider: false };
        }

        return {
            dropRow,
            shouldKillSpider: !hasBlockBelow(map, spiderEntity as BlockEntity),
        };
    }

    private _canSpiderMoveUp(entity: SpiderBlockEntity): boolean {
        const { mapPosition } = entity.c.position;
        const { map } = this._scene.sessionEntity.c;

        const aboveRow = mapPosition.y - 1;
        const aboveCell = map.getCellAt({ x: mapPosition.x, y: aboveRow });

        const noCellOrDisabledCell = !aboveCell?.enabled;
        const cellIsFrozen = aboveCell?.base && blockIsFrozen(this._scene, aboveCell.base.entity);

        if (noCellOrDisabledCell || cellIsFrozen) {
            return false;
        }

        // no base cell (ie. empty) falls through to height of 1
        const aboveCellHeight = aboveCell.base?.entity.c.block.height || 1;
        return aboveCellHeight === 1;
    }
}
