import { Container, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { uiAlignBottom, uiAlignCenterX } from '../../../lib/pixi/uiTools';
import { flex } from '../../../lib/ui/mods/flexMod';
import { BasicText } from '../../lib/ui/text/BasicText';
import { GoalId } from '../match2-odie/defs/goal';
import { GoalsDef } from '../match2-odie/defs/map';
import { PuzzleGoalIcon } from './PuzzleGoalIcon';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    panel: 'puzzle.score.panel.png',
    lvlBg: 'frame.title.png',
};

/*
    puzzle goals panel
*/
export class PuzzleGoalsPanel extends Sprite {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _goalsContainer: Container;
    private _goalIcons: { [key in GoalId]?: PuzzleGoalIcon } = {};
    private _level: BasicText;
    private _levelBg: NineSlicePlane;

    // properties
    //-------------------------------------------------------------------------
    public get goalIcons() {
        return this._goalIcons;
    }

    // init
    //-------------------------------------------------------------------------
    constructor() {
        // init base
        super(Texture.from(manifest.panel));

        // spawn title text
        this._spawnTitle();

        // spawn level
        this._spawnLevel();
    }

    static assets(): string[] {
        return Object.values(manifest);
    }

    static goalAssets(goalDef: GoalsDef): string[] {
        return Object.keys(goalDef)
            .map((id: GoalId) => PuzzleGoalIcon.assets({ id }))
            .flat();
    }

    // api
    //-------------------------------------------------------------------------
    public init() {
        // init goals
        this._initGoals();
    }

    public setGoals(goalDef: GoalsDef) {
        // remove existing goals
        this.removeChild(this._goalsContainer);

        // spawn goals
        this._spawnGoals(goalDef);
    }

    public setLevel(level: number) {
        this._level.text = `[puzzleLevel|${level}]`;
        uiAlignCenterX(this._levelBg, this._level, 6);
    }

    // private: init
    //-------------------------------------------------------------------------
    private _initGoals() {
        // remove existing goals
        this.removeChild(this._goalsContainer);

        // reset icon map
        this._goalIcons = {};
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawnTitle() {
        // spawn
        const widget = this.addChild(
            new BasicText({
                text: '[puzzleGoal]',
                style: {
                    fill: '#FFF3D5',
                    fontSize: 34,
                    lineJoin: 'round',
                    stroke: '#421D1D',
                    strokeThickness: 4,
                    fontWeight: 'bold',
                    padding: 6,
                    dropShadow: true,
                    dropShadowBlur: 3,
                    dropShadowAlpha: 0.35,
                    dropShadowDistance: 3,
                    dropShadowAngle: Math.PI / 2,
                },
            }),
        );
        uiAlignCenterX(this, widget, 8);
        widget.y = 12;
    }

    private _spawnLevel() {
        const bg = (this._levelBg = this.addChild(new NineSlicePlane(Texture.from(manifest.lvlBg), 20, 20, 20, 20)));
        bg.width = 126;
        bg.height = 50;

        const text = (this._level = bg.addChild(
            new BasicText({
                style: {
                    fill: '#FFF3D5',
                    fontSize: 30,
                    lineJoin: 'round',
                    stroke: '#421D1D',
                    strokeThickness: 4,
                    fontWeight: 'bold',
                    padding: 6,
                },
            }),
        ));
        text.y = 4;
        uiAlignCenterX(bg, text);
        uiAlignCenterX(this, bg);
        uiAlignBottom(this, bg, 42);
    }

    private _spawnGoals(goalDef: GoalsDef) {
        // goalDef = {
        //     basicGreen: 4,
        //     hatAll: 22,
        //     basicPurple: 5,
        //     basicRed: 2,
        // };

        const goals = Object.entries(goalDef);

        // create container
        const container = (this._goalsContainer = flex(new Container(), {
            layout: 'right',
            spacing: 28,
            centerX: 18,
            fitWidth: 160,
            fitHeight: goals.length > 3 ? 56 : 68, //TODO: cleaner
        }));

        // spawn goal icons
        for (const [id, count] of goals) {
            this._spawnGoalIcon(container, id as GoalId, count);
        }

        this.addChild(container);
        container.y = 105;
        uiAlignCenterX(this, container, 13 - goals.length * 1.5);
    }

    private _spawnGoalIcon(container: Container, id: GoalId, count: number) {
        // spawn
        const icon = flex(new PuzzleGoalIcon({ id, count }), { relative: true });
        container.addChild(icon);

        // add to table
        this._goalIcons[id] = icon;
    }
}
