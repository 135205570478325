import { Sprite, Texture } from 'pixi.js';

import { PantherBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    small: 'block.panther.small.png',
    large: 'block.panther.large.png',
};

/*
    panther block view
*/
export class PantherBlockView extends Sprite {
    // init
    //-------------------------------------------------------------------------
    constructor(props: PantherBlockProps) {
        // create base view
        super(Texture.from(props.width === 2 ? manifest.large : manifest.small));
        blockPositionView(this, props);
    }

    static assets(props: PantherBlockProps): string[] {
        return [props.width === 2 ? manifest.large : manifest.small];
    }
}
