import type { Component, Entity2D } from '@play-co/odie';

import { PantherBlockProps } from '../defs/block';
import { PantherBlockView } from '../views/blocks/PantherBlockView';

// types
//-----------------------------------------------------------------------------
export type BasicBlockComponentOptions = {
    props: PantherBlockProps;
};

/*
    panther block component
*/
export class PantherBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockPanther';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: PantherBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: BasicBlockComponentOptions): void {
        // create panther block view
        this.view = new PantherBlockView(options.props);
    }
}
