import { Sprite, Texture } from 'pixi.js';

import { ZombieBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

const manifest = {
    zombie: 'block.zombie.png',
};

export class ZombieBlockView extends Sprite {
    constructor(props: ZombieBlockProps) {
        super(Texture.from(manifest.zombie));
        blockPositionView(this, props);

        this.y += 7;
    }

    static assets(): string[] {
        return [manifest.zombie];
    }
}
