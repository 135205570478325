import { Sprite, Texture } from 'pixi.js';

import { PipeBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    image: 'block.pipe.png',
};

/*
    pipe block view
*/
export class PipeBlockView extends Sprite {
    // init
    //-------------------------------------------------------------------------
    constructor(props: PipeBlockProps) {
        // create base view
        super(Texture.from(manifest.image));
        blockPositionView(this, props);
    }

    static assets(): string[] {
        return [manifest.image];
    }
}
