const PROD = 'https://liff.line.me/2001537777-Wj1OM5OQ';
const DEV = 'https://liff.line.me/2001465148-BNvoxJXv';
const ALPHA = 'https://liff.line.me/2002404695-Pr3Da9xm';
const LOCAL = 'https://localhost:8052';

export function getGameUrl() {
    let url = LOCAL; // No stage -> local dev.
    if (process.env.STAGE === 'prod') url = PROD;
    if (process.env.STAGE === 'dev') url = DEV;
    if (process.env.STAGE === 'alpha') url = ALPHA;

    return url;
}
