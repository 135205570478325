import app from '../../index.entry';
import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { PuzzleCompleteResults } from '../../replicant/components/puzzle';
import gameConfig from '../../replicant/defs/gameConfig';
import { NavLayer } from '../defs/nav';
import { trackPuzzleFinish } from '../lib/analytics/puzzle';
import { HomeScreen } from '../main/home/HomeScreen';
import { PuzzleController, PuzzleResults, RewardState } from '../main/puzzle/controller/PuzzleController';
import { CustomerEnterFlow } from './customer/CustomerEnterFlow';
import { PuzzlePlayFlow } from './PuzzlePlayFlow';

/*
    puzzle complete command
*/
export class PuzzleCompleteFlow implements IFlow {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _controller: PuzzleController;
    private readonly _results: PuzzleResults;

    // init
    //-------------------------------------------------------------------------
    constructor(results: PuzzleResults, puzzleController: PuzzleController) {
        this._results = results;
        this._controller = puzzleController;
    }

    // impl
    //-------------------------------------------------------------------------
    async execute() {
        const result = this._results.result;
        console.log('Puzzle.Stop', result);

        // if quit
        if (result === 'quit') {
            const exitPuzzle = await this._actionQuit();
            if (exitPuzzle) {
                // track exit and go home, do nothing if cancelled
                this._trackComplete();
                await this._actionHome();
            }
            // if win
        } else if (result === 'success') {
            this._trackComplete();
            this._actionSuccess(this._results.rewards);
            // else lose (out of moves)
        } else {
            this._trackComplete();
            this._actionFail();
        }
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _actionQuit() {
        const exitPromise = new NakedPromise<boolean>();
        app.nav.open('confirmPopup', {
            onYes: () => exitPromise.resolve(true),
            onNo: () => exitPromise.resolve(false),
        });
        const exit = await exitPromise;
        app.nav.close('confirmPopup');
        return exit;
    }

    private async _actionFail() {
        if (app.server.state.lives.count > 0) {
            const tryAgainPromise = new NakedPromise<boolean>();
            app.nav.open('levelFailedPopup', {
                onOk: () => tryAgainPromise.resolve(true),
                onClose: () => tryAgainPromise.resolve(false),
                underlay: 0.7,
                level: this._controller.mapLevel,
            });
            const tryAgain = await tryAgainPromise;

            if (tryAgain) {
                const { forceScene } = await this._actionNewGame();
                if (forceScene) {
                    const sceneMap = {
                        homeScreen: () => this._actionHome(),
                    };
                    sceneMap[forceScene]();
                }
                // special RETURN, we're either in a puzzle or already at the forced sceeen (home)
                return;
            }
        } else {
            const tryAgainPromise = new NakedPromise<boolean>();
            app.nav.open('levelFailedPopup', {
                // only close button if not enough lives
                onClose: () => tryAgainPromise.resolve(false),
                underlay: 0.7,
                level: this._controller.mapLevel,
            });
            await tryAgainPromise;
        }

        this._actionHome();
    }

    private async _actionHome(results?: PuzzleCompleteResults) {
        const starHandler = async () => {
            if (results?.stars) {
                await screen.starIncreaseAnimation();
                // allow true state update once animation is finished
                screen.starView.resumeUpdateAmount();
            }
        };

        // close popups
        await app.nav.closeLayer(NavLayer.popup);
        // go home
        let screen: HomeScreen;
        const forcedStarAmount = results?.stars ? Math.max(app.server.state.stars - results.stars, 0) : undefined;
        if (app.server.state.bakery.newCustomers.length > 0) {
            screen = (await app.nav.open('homeScreen', { scripted: true, forcedStarAmount })) as HomeScreen;
            starHandler();

            while (app.server.state.bakery.newCustomers.length > 0) {
                await new CustomerEnterFlow({ screen }).execute();
            }
        } else {
            screen = (await app.nav.open('homeScreen', { forcedStarAmount })) as HomeScreen;
            starHandler();
        }
    }

    private async _actionNewGame() {
        // close popups and and puzzle
        await app.nav.closeLayer(NavLayer.popup);

        // execute puzzle play command
        return new PuzzlePlayFlow().execute();
    }

    private async _actionSuccess(rewards: RewardState) {
        // backend complete current puzzle
        const results = await app.server.invoke.puzzleComplete({
            playerMoves: this._controller.moves,
            mapMoves: this._controller.map.moves,
        });
        console.log('Puzzle.Complete: ActionSuccess', results);

        // play star reward animation in success popup if stars are granted
        const successPopupId = results.stars ? 'levelSuccessPopup' : 'levelSuccessMaxPopup';

        const okPromise = new NakedPromise();
        app.nav.open(successPopupId, {
            onOk: () => okPromise.resolve(),
            level: results.completedLevel,
        });
        await okPromise;
        await app.nav.closeLayer(NavLayer.popup);

        // home screen
        this._actionHome(results);
    }

    private _trackComplete() {
        trackPuzzleFinish({
            puzzleLevel: this._controller.mapLevel,
            puzzleForMaxLevel: app.server.state.puzzle.lastLevel >= gameConfig.puzzle.levels.max,
            result: this._results.result,
            puzzleDuration: (app.server.now() - this._controller.time) / 1000,
            remainingMoves: this._controller.moves,
            limitMoves: this._controller.map.moves,
        });
    }
}
