import { SizeType } from '../../../../lib/defs/types';
import { SymbolId } from './symbol';

// supporting types
//-----------------------------------------------------------------------------
// in order of highest impact first
export const powerBlockTypes = ['cube', 'bomb', 'rocket'] as const;
export type PowerBlockType = (typeof powerBlockTypes)[number];

export const colorIds = ['all', 'blue', 'green', 'purple', 'red', 'yellow'] as const;
export type ColorId = (typeof colorIds)[number];
export type AxisId = 'horizontal' | 'vertical';
export type DirectionId = 'left' | 'right' | 'up' | 'down';

// block id
//-----------------------------------------------------------------------------
export type BlockId =
    | 'android'
    | 'animal'
    | 'basicBlue'
    | 'basicGreen'
    | 'basicPurple'
    | 'basicRed'
    | 'basicYellow'
    | 'bomb'
    | 'cage'
    | 'candle'
    | 'chain'
    | 'chameleon'
    | 'coffin'
    | 'cubeBlue'
    | 'cubeGreen'
    | 'cubePurple'
    | 'cubeRed'
    | 'cubeYellow'
    | 'dog'
    | 'doorAll'
    | 'doorBlue'
    | 'doorGreen'
    | 'doorPurple'
    | 'doorRed'
    | 'doorYellow'
    | 'firebox'
    | 'fugu'
    | 'hatAll'
    | 'hatBlue'
    | 'hatGreen'
    | 'hatPurple'
    | 'hatRed'
    | 'hatYellow'
    | 'hazard'
    | 'ice'
    | 'kappa'
    | 'kettle'
    | 'lock'
    | 'miniFugu'
    | 'mysteryImmobile'
    | 'mysteryMobile'
    | 'pantherLarge'
    | 'pantherSmall'
    | 'pipe'
    | 'prison' // TDOO: rename to beehive
    | 'randomSpawn'
    | 'rocketHorizontal'
    | 'rocketVertical'
    | 'siren'
    | 'skull'
    | 'slime'
    | 'spider'
    | 'statue'
    | 'thrower'
    | 'virus'
    | 'wallAll'
    | 'wallBlue'
    | 'wallGreen'
    | 'wallPurple'
    | 'wallRed'
    | 'wallYellow'
    | 'zombie';

export type BlockType =
    | 'android'
    | 'animal'
    | 'basic'
    | 'bomb'
    | 'cage'
    | 'candle'
    | 'chain'
    | 'chameleon'
    | 'coffin'
    | 'cube'
    | 'dog'
    | 'door'
    | 'firebox'
    | 'fugu'
    | 'hat'
    | 'hazard'
    | 'ice'
    | 'kappa'
    | 'kettle'
    | 'lock'
    | 'miniFugu'
    | 'mystery'
    | 'panther'
    | 'prison'
    | 'randomSpawn'
    | 'rocket'
    | 'siren'
    | 'skull'
    | 'slime'
    | 'spider'
    | 'statue'
    | 'thrower'
    | 'virus'
    | 'wall'
    | 'zombie';

// block options
//-----------------------------------------------------------------------------
//TODO: refactor options
export type CountBlockOptionDef = {
    count: number;
};

export type ColorsBlockOptionDef = {
    colors: ColorId[];
};

export type DirectionBlockOptionDef = {
    direction: DirectionId;
};

export type BlockOptionDef = {} | CountBlockOptionDef | ColorsBlockOptionDef | DirectionBlockOptionDef;

export type BlockOptionType = 'count' | 'countSize' | 'list';

// block types
//-----------------------------------------------------------------------------
export type BaseBlockProps = {
    // short unique id used for encoding
    // WARNING: do NOT change once in production
    id: string;
    // if block can fall or not
    immobile?: boolean;
    // if block uses overlay cell layer instead of base layer
    overlay?: boolean;
    // if overlay should not block tap interaction, like cages
    tappable?: boolean;
    //TODO: remove need for this. its a hack used to make combo glowy look right
    // this block is higher z order than neighbor blocks
    overlap?: boolean;
    // for overlay blocks. this block freezes the block below such
    // that it cannot be affected by other blocks, effects, or collides.
    freeze?: boolean;
    // for overlay blocks. prevents blocks from falling under this block.
    barrier?: boolean;
    // will (should) not be destroyed.
    immortal?: boolean;
    //TODO: refactor options/type
    // used by editor as a list of option choices for the block
    options?: BlockOptionDef[];
    // options type
    optionType?: BlockOptionType;
    // whether to allow this block to be created in editor view
    noEditor?: boolean;
} & Partial<SizeType>;

export type AndroidBlockProps = {
    type: 'android';
} & BaseBlockProps;

export type AnimalBlockProps = {
    type: 'animal';
} & BaseBlockProps;

export type BombBlockProps = {
    type: 'bomb';
    radius: number;
} & BaseBlockProps;

export type BasicBlockProps = {
    type: 'basic';
    color: ColorId;
    symbol: SymbolId;
} & BaseBlockProps;

export type CageBlockProps = {
    type: 'cage';
} & BaseBlockProps;

export type CandleBlockProps = {
    type: 'candle';
} & BaseBlockProps;

export type ChainBlockProps = {
    type: 'chain';
} & BaseBlockProps;

export type ChameleonBlockProps = {
    type: 'chameleon';
} & BaseBlockProps;

export type CoffinBlockProps = {
    type: 'coffin';
} & BaseBlockProps;

export type CubeBlockProps = {
    type: 'cube';
    color: ColorId;
} & BaseBlockProps;

export type DogBlockProps = {
    type: 'dog';
} & BaseBlockProps;

export type DoorBlockProps = {
    type: 'door';
    color: ColorId;
} & BaseBlockProps;

export type FireboxBlockProps = {
    type: 'firebox';
} & BaseBlockProps;

export type FuguBlockProps = {
    type: 'fugu';
} & BaseBlockProps;

export type HatBlockProps = {
    type: 'hat';
    color: ColorId;
} & BaseBlockProps;

export type HazardBlockProps = {
    type: 'hazard';
} & BaseBlockProps;

export type IceBlockProps = {
    type: 'ice';
} & BaseBlockProps;

export type KettleBlockProps = {
    type: 'kettle';
} & BaseBlockProps;

export type LockBlockProps = {
    type: 'lock';
} & BaseBlockProps;

export type MiniFuguBlockProps = {
    type: 'miniFugu';
} & BaseBlockProps;

export type MysteryBlockProps = {
    type: 'mystery';
} & BaseBlockProps;

export type PantherBlockProps = {
    type: 'panther';
} & BaseBlockProps;

export type PipeBlockProps = {
    type: 'pipe';
} & BaseBlockProps;

export type PrisonBlockProps = {
    type: 'prison';
} & BaseBlockProps;

export type RandomSpawnBlockProps = {
    type: 'randomSpawn';
} & BaseBlockProps;

export type RocketBlockProps = {
    type: 'rocket';
} & BaseBlockProps;

export type SirenBlockProps = {
    type: 'siren';
} & BaseBlockProps;

export type SkullBlockProps = {
    type: 'skull';
} & BaseBlockProps;

export type SlimeBlockProps = {
    type: 'slime';
} & BaseBlockProps;

export type SpiderBlockProps = {
    type: 'spider';
} & BaseBlockProps;

export type KappaBlockProps = {
    type: 'kappa';
} & BaseBlockProps;

export type StatueBlockProps = {
    type: 'statue';
} & BaseBlockProps;

export type ThrowerBlockProps = {
    type: 'thrower';
} & BaseBlockProps;

export type VirusBlockProps = {
    type: 'virus';
} & BaseBlockProps;

export type WallBlockProps = {
    type: 'wall';
    color: ColorId;
} & BaseBlockProps;

export type ZombieBlockProps = {
    type: 'zombie';
} & BaseBlockProps;

export type BlockProps =
    | AndroidBlockProps
    | AnimalBlockProps
    | BombBlockProps
    | BasicBlockProps
    | CageBlockProps
    | CandleBlockProps
    | ChainBlockProps
    | ChameleonBlockProps
    | CoffinBlockProps
    | CubeBlockProps
    | DogBlockProps
    | DoorBlockProps
    | FireboxBlockProps
    | HatBlockProps
    | HazardBlockProps
    | IceBlockProps
    | KettleBlockProps
    | LockBlockProps
    | MiniFuguBlockProps
    | MysteryBlockProps
    | PantherBlockProps
    | PipeBlockProps
    | PrisonBlockProps
    | RandomSpawnBlockProps
    | RocketBlockProps
    | SirenBlockProps
    | SkullBlockProps
    | SlimeBlockProps
    | SpiderBlockProps
    | FuguBlockProps
    | KappaBlockProps
    | StatueBlockProps
    | ThrowerBlockProps
    | VirusBlockProps
    | WallBlockProps
    | ZombieBlockProps;

// tables
//-----------------------------------------------------------------------------
const doorOptions: BlockOptionDef[] = [{ count: 0 }, { count: 1 }, { count: 2 }];

export const blockPropsMap: Record<BlockId, BlockProps> = {
    // android
    android: {
        id: 'A',
        type: 'android',
        width: 2,
        height: 2,
        immobile: true,
        options: [{ count: 2 }, { count: 1 }, { count: 0 }],
        optionType: 'list',
    },

    // animal
    animal: {
        id: 'a',
        type: 'animal',
        immobile: true,
    },

    // basic
    basicBlue: {
        id: 'bb',
        type: 'basic',
        color: 'blue',
        symbol: 'spade',
    },
    basicGreen: {
        id: 'bg',
        type: 'basic',
        color: 'green',
        symbol: 'club',
    },
    basicPurple: {
        id: 'bp',
        type: 'basic',
        color: 'purple',
        symbol: 'joker',
    },
    basicRed: {
        id: 'br',
        type: 'basic',
        color: 'red',
        symbol: 'heart',
    },
    basicYellow: {
        id: 'by',
        type: 'basic',
        color: 'yellow',
        symbol: 'diamond',
    },

    // bomb
    bomb: {
        id: 'B',
        type: 'bomb',
        radius: 1,
        overlap: true,
    },

    // cage
    cage: {
        id: 'c',
        type: 'cage',
        overlay: true,
        immobile: true,
        tappable: true,
    },

    // candle
    candle: {
        id: 'C',
        type: 'candle',
        width: 2,
        height: 2,
        immobile: true,
        options: [{ count: 7 }, { count: 1 }, { count: 2 }, { count: 3 }, { count: 4 }, { count: 5 }, { count: 6 }],
        optionType: 'list',
    },

    // chain
    chain: {
        id: 'ch',
        type: 'chain',
        immobile: true,
        options: [{ count: 0 }, { count: 1 }, { count: 2 }],
    },

    // chameleon
    chameleon: {
        id: 'ca',
        type: 'chameleon',
        options: [{ count: 0 }, { count: 1 }, { count: 2 }, { count: 3 }],
        optionType: 'list',
    },

    coffin: {
        id: 'cf',
        type: 'coffin',
        immobile: true,
    },

    // coffins turn into zombies
    zombie: {
        id: 'zo',
        type: 'zombie',
        // zombies are only spawned by destroying coffins
        noEditor: true,
    },

    // cube
    cubeBlue: {
        id: 'ub',
        type: 'cube',
        color: 'blue',
        overlap: true,
    },
    cubeGreen: {
        id: 'ug',
        type: 'cube',
        color: 'green',
        overlap: true,
    },
    cubePurple: {
        id: 'up',
        type: 'cube',
        color: 'purple',
        overlap: true,
    },
    cubeRed: {
        id: 'ur',
        type: 'cube',
        color: 'red',
        overlap: true,
    },
    cubeYellow: {
        id: 'uy',
        type: 'cube',
        color: 'yellow',
        overlap: true,
    },

    // dog
    dog: {
        id: 'd',
        type: 'dog',
        immobile: true,
        options: [{ direction: 'right' }, { direction: 'left' }],
        optionType: 'list',
    },

    // door
    doorAll: {
        id: 'da',
        type: 'door',
        color: 'all',
        immobile: true,
        options: doorOptions,
        optionType: 'list',
    },
    doorBlue: {
        id: 'db',
        type: 'door',
        color: 'blue',
        immobile: true,
        options: doorOptions,
        optionType: 'list',
    },
    doorGreen: {
        id: 'dg',
        type: 'door',
        color: 'green',
        immobile: true,
        options: doorOptions,
        optionType: 'list',
    },
    doorPurple: {
        id: 'dp',
        type: 'door',
        color: 'purple',
        immobile: true,
        options: doorOptions,
        optionType: 'list',
    },
    doorRed: {
        id: 'dr',
        type: 'door',
        color: 'red',
        immobile: true,
        options: doorOptions,
        optionType: 'list',
    },
    doorYellow: {
        id: 'dy',
        type: 'door',
        color: 'yellow',
        immobile: true,
        options: doorOptions,
        optionType: 'list',
    },

    // firebox
    firebox: {
        id: 'fb',
        type: 'firebox',
        immobile: true,
        options: [{ count: 2 }, { count: 1 }, { count: 0 }],
        optionType: 'list',
    },

    // fugu
    fugu: {
        id: 'fu',
        type: 'fugu',
    },

    // hat
    hatAll: {
        id: 'ha',
        type: 'hat',
        color: 'all',
    },
    hatBlue: {
        id: 'hb',
        type: 'hat',
        color: 'blue',
    },
    hatGreen: {
        id: 'hg',
        type: 'hat',
        color: 'green',
    },
    hatPurple: {
        id: 'hp',
        type: 'hat',
        color: 'purple',
    },
    hatRed: {
        id: 'hr',
        type: 'hat',
        color: 'red',
    },
    hatYellow: {
        id: 'hy',
        type: 'hat',
        color: 'yellow',
    },

    // hazard
    hazard: {
        id: 'hz',
        type: 'hazard',
        immobile: true,
    },

    // Standalone ice, does not break into halfIce
    ice: {
        id: 'i',
        type: 'ice',
        overlay: true,
        freeze: true,
    },

    // kettle
    kettle: {
        id: 'kt',
        type: 'kettle',
    },

    // lock
    lock: {
        id: 'l',
        type: 'lock',
        options: [{ count: 1 }, { count: 0 }],
        optionType: 'list',
    },

    // mini from fugu
    miniFugu: {
        id: 'mfu',
        type: 'miniFugu',
        immobile: true,
        noEditor: true,
    },

    // mystery
    mysteryImmobile: {
        id: 'mi',
        type: 'mystery',
        immobile: true,
        options: [{ count: 3 }, { count: 1 }, { count: 2 }],
        optionType: 'list',
    },
    mysteryMobile: {
        id: 'mm',
        type: 'mystery',
        options: [{ count: 2 }, { count: 1 }],
        optionType: 'list',
    },

    // panther
    pantherLarge: {
        id: 'pl',
        type: 'panther',
        width: 2,
        height: 2,
    },
    pantherSmall: {
        id: 'ps',
        type: 'panther',
    },

    // pipe
    pipe: {
        id: 'P',
        type: 'pipe',
        immobile: true,
        immortal: true,
    },

    // prison
    prison: {
        id: 'p',
        type: 'prison',
        immobile: true,
    },

    // random spawn
    // This is a special type of block that is only a placeholder for spawning a random block.
    randomSpawn: {
        id: 'rs',
        type: 'randomSpawn',
        immobile: true,
        immortal: true,
    },

    // rocket
    rocketHorizontal: {
        id: 'rh',
        type: 'rocket',
        overlap: true,
    },
    rocketVertical: {
        id: 'rv',
        type: 'rocket',
        overlap: true,
    },

    // siren
    siren: {
        id: 's',
        type: 'siren',
        width: 2,
        height: 2,
        immobile: true,
        options: [{ colors: ['blue', 'green', 'red', 'yellow'] }],
        optionType: 'list',
    },

    // skull
    skull: {
        id: 'S',
        type: 'skull',
        options: [{ count: 1 }, { count: 0 }],
        optionType: 'list',
    },

    // slime
    slime: {
        id: 'sl',
        type: 'slime',
        overlay: true,
        immobile: true,
        freeze: true,
    },

    // kappa
    kappa: {
        id: 'ka',
        type: 'kappa',
    },

    // spider
    spider: {
        id: 'sp',
        type: 'spider',
        immobile: true,
    },

    // statue
    statue: {
        id: 'st',
        type: 'statue',
        options: [{ count: 2 }, { count: 1 }, { count: 0 }],
        optionType: 'list',
    },

    // thrower
    thrower: {
        id: 't',
        type: 'thrower',
        immobile: true,
        immortal: true,
        options: [{ direction: 'right' }, { direction: 'left' }],
        optionType: 'list',
    },

    // virus
    virus: {
        id: 'vi',
        type: 'virus',
    },

    // wall
    wallAll: {
        id: 'wa',
        type: 'wall',
        color: 'all',
        immobile: true,
        overlay: true,
        freeze: true,
        barrier: true,
        optionType: 'countSize',
    },
    wallBlue: {
        id: 'wb',
        type: 'wall',
        color: 'blue',
        immobile: true,
        overlay: true,
        freeze: true,
        barrier: true,
        optionType: 'countSize',
    },
    wallGreen: {
        id: 'wg',
        type: 'wall',
        color: 'green',
        immobile: true,
        overlay: true,
        freeze: true,
        barrier: true,
        optionType: 'countSize',
    },
    wallPurple: {
        id: 'wp',
        type: 'wall',
        color: 'purple',
        immobile: true,
        overlay: true,
        freeze: true,
        barrier: true,
        optionType: 'countSize',
    },
    wallRed: {
        id: 'wr',
        type: 'wall',
        color: 'red',
        immobile: true,
        overlay: true,
        freeze: true,
        barrier: true,
        optionType: 'countSize',
    },
    wallYellow: {
        id: 'wy',
        type: 'wall',
        color: 'yellow',
        immobile: true,
        overlay: true,
        freeze: true,
        barrier: true,
        optionType: 'countSize',
    },
};

export const colorToBlockId: { [key in ColorId]: { [key in BlockType]?: BlockId } } = {
    all: {},
    blue: {
        basic: 'basicBlue',
        cube: 'cubeBlue',
    },
    green: {
        basic: 'basicGreen',
        cube: 'cubeGreen',
    },
    purple: {
        basic: 'basicPurple',
        cube: 'cubePurple',
    },
    red: {
        basic: 'basicRed',
        cube: 'cubeRed',
    },
    yellow: {
        basic: 'basicYellow',
        cube: 'cubeYellow',
    },
};

export const powerBlockMap: { [key in PowerBlockType]: string } = {
    rocket: 'block.rocket.horizontal.png',
    bomb: 'block.bomb.png',
    cube: 'block.cube.red.png',
};

export const blockColorMap: { [key in ColorId]: any } = {
    all: {
        base: 0xffffff,
        glow: 0xffffff,
    },
    blue: {
        base: 0x23adea,
        glow: 0x00ddff,
    },
    green: {
        base: 0x59cf00,
        glow: 0x00ff44,
    },
    purple: {
        base: 0xb629ec,
        glow: 0xffaa00,
    },
    red: {
        base: 0xef114e,
        glow: 0xff0022,
    },
    yellow: {
        base: 0xf4d100,
        glow: 0xffdd00,
    },
};
