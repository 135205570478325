import { Container } from 'pixi.js';

import { IScreen } from '../IScreen';

/*
    dummy nav screen
*/
export class EmptyScreen implements IScreen {
    // fields
    //-------------------------------------------------------------------------
    public root = new Container();
}
