import { BLEND_MODES, Graphics, ObservablePoint } from 'pixi.js';

import { SpotLight, SpotLightOptions } from './SpotLight';

// types
//-----------------------------------------------------------------------------
export type CircleSpotLightOptions = {
    radius: number;
} & SpotLightOptions;

/*
    circular spotlight
*/
export class CircleSpotLight extends SpotLight {
    // private
    //-------------------------------------------------------------------------
    private readonly _light: Graphics;

    // properties
    //-------------------------------------------------------------------------
    public get lightPosition(): ObservablePoint {
        return this._light.position;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: CircleSpotLightOptions) {
        super(options);

        // spawn circular light
        const light = (this._light = new Graphics().beginFill().drawCircle(0, 0, options.radius).endFill());
        light.blendMode = BLEND_MODES.ERASE;
        this.addChild(light);
    }
}
