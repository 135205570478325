import { Sprite, Texture } from 'pixi.js';

import { ColorId, DoorBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-----------------------------------------------------------------------------
const overlayProps = [
    null,
    {
        asset: 'scrap.chains.0.png',
        x: -1,
        y: 1,
    },
    {
        asset: 'scrap.chains.1.png',
        x: -4,
        y: 1,
    },
];

const colorMap: { [key in ColorId]?: string } = {
    all: 'block.door.all.png',
    blue: 'block.door.blue.png',
    green: 'block.door.green.png',
    purple: 'block.door.purple.png',
    red: 'block.door.red.png',
    yellow: 'block.door.yellow.png',
};

/*
    door block view
*/
export class DoorBlockView extends Sprite {
    // fields
    //-------------------------------------------------------------------------
    // scene
    public _overlay: Sprite;
    // state
    private _count = 0;

    // properties
    //-------------------------------------------------------------------------
    public get count(): number {
        return this._count;
    }

    public set count(value: number) {
        const props = overlayProps[value];
        const overlay = this._overlay;

        // update field
        this._count = value;

        // if props exist for this count, enable overlay from props
        if (props) {
            overlay.texture = Texture.from(props.asset);
            overlay.x = props.x;
            overlay.y = props.y;
            overlay.visible = true;
            // else hide it
        } else {
            overlay.visible = false;
        }
    }

    // init
    //-------------------------------------------------------------------------
    constructor(props: DoorBlockProps) {
        // create base view
        super(Texture.from(colorMap[props.color]));
        blockPositionView(this, props);

        // create count view
        const overlay = (this._overlay = new Sprite());
        this.addChild(overlay);
    }

    static assets(props: DoorBlockProps): string[] {
        return [colorMap[props.color], ...Object.values(overlayProps).map((object) => object?.asset)];
    }
}
