import { Assets } from 'pixi.js';

import { arrayRandom } from '../../../../../replicant/util/jsTools';
import { BlockId } from '../../defs/block';
import { BlockEntity, spawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { blockViewAssets } from '../../util/blockTools';
import { IEffect } from './IEffect';

// types
//-----------------------------------------------------------------------------
export type FireboxEffectOptions = {
    subject: BlockEntity;
};

// constants
//-----------------------------------------------------------------------------
// 50% bomb, 50% rocket
const spawnBlocks: BlockId[] = ['bomb', 'bomb', 'rocketHorizontal', 'rocketVertical'];

/*
    firebox generates a random rocket or bomb
*/
export class FireboxEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _options: FireboxEffectOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: FireboxEffectOptions) {
        this._scene = scene;
        this._options = options;
    }

    static assets(): string[] {
        return spawnBlocks.map((id) => blockViewAssets(id, true)).flat();
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // spawn fire block
        await this._spawnFireBlock();
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _spawnFireBlock() {
        // choose random spawn
        const id = arrayRandom(spawnBlocks);

        // load assets
        await Assets.load(blockViewAssets(id, true));

        // spawn fire block
        spawnBlockEntity(this._scene, { id }, this._options.subject.c.position.mapPosition);
    }
}
