import { BlurFilter, Graphics } from 'pixi.js';

import { pixiConfig } from '../../../defs/config';

// types
//-----------------------------------------------------------------------------
export type SpotLightOptions = {
    opacity?: number;
    height?: number;
    width?: number;
    color?: number;
    blur?: number;
};

/*
    base spot light
*/
export class SpotLight extends Graphics {
    // init
    //-------------------------------------------------------------------------
    constructor(options: SpotLightOptions) {
        super();
        const width = options.width ? options.width : pixiConfig.size.width;
        const height = options.height ? options.height : pixiConfig.size.height;
        const color = options.color || 0;
        const blur = options.blur || 8;

        // init base
        this.moveTo(0, 0)
            .beginFill(color, options?.opacity || 0.7)
            .drawRect(-blur, -blur, width + blur * 2, height + blur * 2)
            .endFill().filters = [new BlurFilter(blur, 5)];
    }

    // init
    //-------------------------------------------------------------------------
    public start() {}
}
