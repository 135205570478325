export type ItemPropsOld = { name: string; icon: string; id: number };
export type ItemProps = { name: string; icon: string };

// Do NOT tamper with the ids, these are the ids/keys used for the state
// 'anniversarie',
export const cakeItemIds = [
    'appleTart',
    'brownSugarOpera',
    'chocoGlazed',
    'frenchFlan',
    'kouignAmann',
    'mangoMousseCake',
    'rareCheese',
    'strawberryShort',
] as const;

export type CakeItemId = (typeof cakeItemIds)[number];

export const cakeItemPropsMap: Record<CakeItemId, ItemProps> = {
    // anniversarie: {
    //     name: '[itemNameAnniversarie]',
    //     icon: `item.cake.anniversarie.png`,
    // },
    appleTart: {
        name: '[itemNameAppletart]',
        icon: `item.cake.appletart.png`,
    },
    brownSugarOpera: {
        name: '[itemNameBrownsugaropera]',
        icon: `item.cake.brownsugaropera.png`,
    },
    chocoGlazed: {
        name: '[itemNameChocoglazed]',
        icon: `item.cake.chocoglazed.png`,
    },
    rareCheese: {
        name: '[itemNameRarecheese]',
        icon: `item.cake.rarecheese.png`,
    },
    strawberryShort: {
        name: '[itemNameStrawberryshort]',
        icon: 'item.cake.strawberryshort.png',
    },
    mangoMousseCake: {
        name: '[itemNameMangoMousse]',
        icon: 'item.cake.mangomousse.png',
    },
    frenchFlan: {
        name: '[itemNamefrenchFlan]',
        icon: 'item.cake.frenchflan.png',
    },
    kouignAmann: {
        name: '[itemNameKouignAmann]',
        icon: 'item.cake.kouignamann.png',
    },
};
