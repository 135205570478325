import { Vector2 } from '@play-co/odie';

import { arrayRandom } from '../../../../replicant/util/jsTools';
import type { MapComponent } from '../components/MapComponent';
import { config } from '../defs/config';
import type { BlockEntity } from '../entities/BlockEntity';
import { moveBlockEntity, spawnBlockEntity } from '../entities/BlockEntity';
import type { GameScene } from '../GameScene';
import { findDropRow, isCellBarrier, isDroppableCell } from './mapTools';

// types
//-----------------------------------------------------------------------------
export type FallAction = {
    entity: BlockEntity;
    fromRow: number;
    toRow: number;
};

/*
    falls all blocks in map. produces all actions taken.
*/
export class MapFaller {
    // fields
    //-------------------------------------------------------------------------
    private readonly _scene: GameScene;
    private readonly _map: MapComponent;
    private readonly _actions: FallAction[] = [];

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene) {
        this._scene = scene;
        this._map = scene.sessionEntity.c.map;
    }

    // api
    //-------------------------------------------------------------------------
    public build(): FallAction[] {
        // build fall actions from existing blocks
        this._buildCurrent();

        // build new spawn actions
        this._buildNew();

        return this._actions;
    }

    // api
    //-------------------------------------------------------------------------
    private _buildCurrent() {
        // for each row from the bottom
        for (let row = this._map.rows - 1; row >= 0; --row) {
            // for each column
            for (let column = 0; column < this._map.columns; ++column) {
                const cell = this._map.grid[column][row];

                if (isDroppableCell(this._scene, cell)) {
                    const entity = cell.base.entity;
                    const toRow = findDropRow(this._map, entity);

                    // if target row available
                    if (toRow !== undefined) {
                        // move entity there
                        moveBlockEntity(this._scene, entity, new Vector2(column, toRow));

                        // add fall action
                        this._actions.push({
                            entity,
                            fromRow: row,
                            toRow,
                        });
                    }
                }
            }
        }
    }

    private _buildNew() {
        // for each column
        for (let column = 0; column < this._map.columns; ++column) {
            const columns = this._map.grid[column];
            const dropRows: number[] = [];

            // for each row until entity
            for (let row = 0; row < this._map.rows; ++row) {
                const cell = columns[row];

                // if enabled
                if (cell.enabled) {
                    // end at first barrier
                    if (isCellBarrier(cell)) {
                        break;
                    }

                    // add drop row
                    dropRows.push(row);
                }
            }

            // for each row in reverse
            let fromRow = config.map.spawnRow;

            for (const row of dropRows.reverse()) {
                // spawn fall block entity at this position
                const entity = this._spawnFallBlock(column, row);

                if (entity) {
                    // add fall action
                    this._actions.push({
                        entity,
                        fromRow,
                        toRow: row,
                    });

                    // decrement from row
                    --fromRow;
                }
            }
        }
    }

    // private: spawners
    //-------------------------------------------------------------------------
    private _spawnFallBlock(column: number, row: number): BlockEntity | undefined {
        const spawns = this._map.mapDef.spawns;

        // require have spawns
        if (spawns.length === 0) {
            return undefined;
        }

        // choose block id
        const id = arrayRandom(this._map.mapDef.spawns);

        // spawn entity
        return spawnBlockEntity(this._scene, { id }, new Vector2(column, row));
    }
}
