import { Vector2 } from '@play-co/odie';

import { CollideEvent, CollideId } from '../../defs/collide';
import { despawnBlockEntity, FuguBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { BreakEffect } from '../effects/BreakEffect';
import { FuguEffect } from '../effects/FuguEffect';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

/*
    fugu block collision resolver
*/
export class FuguCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: FuguBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        block: this._resolveBlock,
        attack: this._resolveAttack,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: FuguBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap() {
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveBlock() {
        await this._attack();
        return true;
    }

    private async _resolveAttack() {
        await this._attack();
        return true;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _attack() {
        const position = this._entity.c.position.mapPosition;

        await this._breakBlock(position);
        await new FuguEffect(this._scene, {
            subject: this._entity,
        }).execute();
    }

    private async _breakBlock(position: Vector2) {
        despawnBlockEntity(this._scene, this._entity);

        await new BreakEffect(this._scene, {
            position,
            size: this._entity.c.block.props,
            color: 0xffcc44,
        }).execute();
    }
}
