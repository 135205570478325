import { waitAFrame } from '@play-co/astro';
import type { Component, Entity2D } from '@play-co/odie';

import { MoveSource } from '../defs/event';
import type { PhaseId } from '../defs/types';
import { GameScene } from '../GameScene';

/*
    game phase state
*/
export class PhaseComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'phase';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;

    // state
    private _phase: PhaseId;
    private _lastPhase: PhaseId;
    // game rounds
    private _round: number;
    // current activation count. if over 0 then phase is active.
    private _activations: number;
    // moves count
    private _moves: number;
    // total moves counter
    private _moveCounter: number;
    // if a resolved collision was player initiated
    private _moved: boolean;

    // properties
    //-------------------------------------------------------------------------
    get phase(): PhaseId {
        return this._phase;
    }

    set phase(phase: PhaseId) {
        // if changing
        if (phase !== this._phase) {
            this._lastPhase = this._phase;
            this._phase = phase;
            this.entity.setModified(PhaseComponent, { phase: true });
        }
    }

    get round(): number {
        return this._round;
    }

    get lastPhase(): PhaseId {
        return this._lastPhase;
    }

    get activations(): number {
        return this._activations;
    }

    get moved(): boolean {
        return this._moved;
    }

    set moved(moved: boolean) {
        this._moved = moved;
    }

    get moves(): number {
        return this._moves;
    }

    set moves(moves: number) {
        this._moves = moves;
    }

    get moveCounter(): number {
        return this._moveCounter;
    }

    // impl
    //-------------------------------------------------------------------------
    init(): void {
        this._phase = 'none';
        this._lastPhase = 'none';
        this._round = 0;
        this._activations = 0;
        // true allows for initial round event
        this._moves = 0;
        this._moveCounter = 0;
        this._moved = true;
    }

    // api
    //-------------------------------------------------------------------------
    public activePush() {
        // increment activations
        ++this._activations;
    }

    public async activePop() {
        // if not in active phase, wait a frame to trigger it
        if (this._phase !== 'active') await waitAFrame();

        // decrement activations
        --this._activations;

        // cannot go negative
        console.assert(this._activations >= 0, 'PhaseComponent.activations is negative');
    }

    public triggerMove(source: MoveSource) {
        // set moved state
        this.moved = true;

        // decrement moves
        --this._moves;

        // increment move counter
        ++this._moveCounter;

        // notify
        (this.entity.scene as GameScene).events.publish({ id: 'move', source });
    }

    public triggerRound() {
        // only if moved
        if (this.moved) {
            // reset moved
            this.moved = false;

            // increment round and notify
            ++this._round;
            this.entity.setModified(PhaseComponent, { round: true });

            // notify round
            (this.entity.scene as GameScene).events.publish({ id: 'round' });
        }
    }
}
