import app from '../../../../../index.entry';
import { blockColorMap, DoorBlockProps } from '../../defs/block';
import { CollideEvent, CollideId } from '../../defs/collide';
import type { DoorBlockEntity } from '../../entities/BlockEntity';
import { despawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { mapGetPan } from '../../util/mapTools';
import { BreakEffect } from '../effects/BreakEffect';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

// fields
//-----------------------------------------------------------------------------
const sounds = ['puzzle-door-destroy.mp3', 'puzzle-door-poke-chain.mp3', 'puzzle-door-poke-board.mp3'];

/*
    door block collision resolver
*/
export class DoorCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: DoorBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        block: this._resolveBlock,
        attack: this._resolveAttack,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: DoorBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        // invalid effect
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveBlock(event: CollideEvent) {
        const collider = event.collider;

        // if block collider
        if (collider?.type === 'block') {
            const colliderProps = collider.entity.c.block.props;

            // if basic block collider
            if (colliderProps.type === 'basic') {
                const doorProps = this._entity.c.block.props as DoorBlockProps;

                // attack door if its all color or matches color of collider
                if (doorProps.color === 'all' || doorProps.color === colliderProps.color) {
                    this._attack();
                    return true;
                }
            }
        }
        return false;
    }

    private async _resolveAttack(event: CollideEvent) {
        // attack door
        this._attack();
        return true;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _attack() {
        const c = this._entity.c;
        const component = c.blockDoor;
        const position = c.position.mapPosition;
        let color: number;

        // play sound based on component count
        app.sound.play(sounds[component.count], { volume: 0.8, pan: mapGetPan(position) });

        // if count is 0
        if (component.count <= 0) {
            // break color matches door
            color = blockColorMap[(c.block.props as DoorBlockProps).color].base;

            // despawn entity
            despawnBlockEntity(this._scene, this._entity);
        } else {
            // break color is chain color
            color = 0x888888;

            // decrement count
            --component.count;
        }

        // execute break effect
        return new BreakEffect(this._scene, {
            position,
            size: this._entity.c.block.props,
            color,
        }).execute();
    }
}
