import type { Component, Entity2D } from '@play-co/odie';

import type { CandleBlockProps, CountBlockOptionDef } from '../defs/block';
import { CandleBlockView } from '../views/blocks/CandleBlockView';

// types
//-----------------------------------------------------------------------------
export type CandleBlockComponentOptions = {
    props: CandleBlockProps;
    option?: number;
};

/*
    candle block component
*/
export class CandleBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockCandle';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: CandleBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: CandleBlockComponentOptions): void {
        // get option def
        const optionDef = options.props.options[
            options.option && options.option < options.props.options.length ? options.option : 0
        ] as CountBlockOptionDef; // create candle block view

        this.view = new CandleBlockView(options.props);

        // set initial count
        this.view.count = optionDef.count;
    }

    // api
    //-------------------------------------------------------------------------
    public isEmpty(): boolean {
        return this.view.count === 0;
    }

    public poke() {
        --this.view.count;
    }
}
