import { Sprite, Texture } from 'pixi.js';

import { LockBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-----------------------------------------------------------------------------
const countAssets = ['block.lock.0.png', 'block.lock.1.png'];

/*
    lock block view
*/
export class LockBlockView extends Sprite {
    // fields
    //-------------------------------------------------------------------------
    private _count = 0;

    // properties
    //-------------------------------------------------------------------------
    public get count(): number {
        return this._count;
    }

    public set count(value: number) {
        // update field
        this._count = value;

        // update view texture based on count
        this.texture = Texture.from(countAssets[value]);
    }

    // init
    //-------------------------------------------------------------------------
    constructor(props: LockBlockProps) {
        // create base view
        super(Texture.from(countAssets[0]));
        blockPositionView(this, props);
    }

    static assets(): string[] {
        return countAssets;
    }
}
