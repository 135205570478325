//TODO: relocate block specific logic (ex: pipe/prison)
import type { Entity, QueriesObject, Query, QueryResults, System } from '@play-co/odie';

import { BlockComponent } from '../components/BlockComponent';
import { GoalId } from '../defs/goal';
import type { MapDef } from '../defs/map';
import { BlockEntity } from '../entities/BlockEntity';
import { createSessionEntity, despawnSessionEntity, spawnSessionEntity } from '../entities/SessionEntity';
import type { GameScene } from '../GameScene';

/*
    game session control
*/
export class SessionSystem implements System {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'session';
    public static Queries: QueriesObject = {
        block: {
            components: [BlockComponent],
            removed: true,
        },
    };

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    public queries!: QueryResults;
    // input
    private _mapDef?: MapDef;

    // impl
    //-------------------------------------------------------------------------
    public init() {}

    public awake() {
        // create session entity
        const sessionEntity = (this.scene.sessionEntity = createSessionEntity(this._mapDef));

        // spawn session entity
        spawnSessionEntity(this.scene, sessionEntity);

        // initialize state
        this._initState();
    }

    public reset() {
        // despawn session entity
        despawnSessionEntity(this.scene, this.scene.sessionEntity);
    }

    public removedFromQuery(entity: Entity, query: Query) {
        // call despawn handler
        this._onBlockDespawn(entity as BlockEntity);
    }

    // api
    //-------------------------------------------------------------------------
    public setMap(mapDef: MapDef) {
        // create session entity with given map
        this._mapDef = mapDef;
    }

    public triggerGoal(goalId: GoalId, source?: BlockEntity) {
        const map = this.scene.sessionEntity.c.map;
        let count = map.goals[goalId];
        if (count > 0) {
            // decrement goal count
            map.goals[goalId] = --count;

            // notify
            this.scene.events.publish({ id: 'goal', goalId, source, count });
        }
    }

    // private: init
    //-------------------------------------------------------------------------
    private _initState() {
        const session = this.scene.sessionEntity;
        const state = session.c.state;
        const map = session.c.map;

        state.cageThrowsRemaining = map.goals.cage || 0;
    }

    // private: events
    //-------------------------------------------------------------------------
    private _onBlockDespawn(entity: BlockEntity) {
        // handle despawn as goal
        this.triggerGoal(entity.c.block.blockId);
    }
}
