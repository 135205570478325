// types
//-----------------------------------------------------------------------------
export type ResolveHandler<T> = (value: T | PromiseLike<T>) => void;
export type RejectHandler<T> = (reason?: any) => void;

/*
    same as a promise but with resolve, reject exposed
*/
export default class NakedPromise<T = void> extends Promise<T> {
    // fields
    //-------------------------------------------------------------------------
    public resolve: ResolveHandler<T>;
    public reject: RejectHandler<T>;

    // api
    //-------------------------------------------------------------------------
    constructor(executor?: (resolve: ResolveHandler<T>, reject: RejectHandler<T>) => void) {
        let capturedResolve: ResolveHandler<T>;
        let capturedReject: RejectHandler<T>;

        // call base with executor wrapper to capture resolve, reject
        super((resolve, reject) => {
            // capture resolve and reject
            capturedResolve = resolve;
            capturedReject = reject;

            // call original executor
            executor?.(resolve, reject);
        });

        // store captured resolve and reject
        this.resolve = capturedResolve;
        this.reject = capturedReject;
    }
}
