import type { Component, Entity2D } from '@play-co/odie';

import { MiniFuguBlockProps } from '../defs/block';
import { MiniFuguBlockView } from '../views/blocks/MiniFuguBlockView';

// types
//-----------------------------------------------------------------------------
export type MiniFuguBlockComponentOptions = {
    props: MiniFuguBlockProps;
};

/*
    mini fugu block component
*/
export class MiniFuguBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockMiniFugu';

    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: MiniFuguBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: MiniFuguBlockComponentOptions): void {
        this.view = new MiniFuguBlockView({ ...options.props });
    }
}
