import type { Component, Entity2D } from '@play-co/odie';

import { FuguBlockProps } from '../defs/block';
import { FuguBlockView } from '../views/blocks/FuguBlockView';

// types
//-----------------------------------------------------------------------------
export type FuguBlockComponentOptions = {
    props: FuguBlockProps;
};

/*
    fugu block component
*/
export class FuguBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockFugu';

    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: FuguBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: FuguBlockComponentOptions): void {
        this.view = new FuguBlockView({ ...options.props });
    }
}
