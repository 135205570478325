import { ITextStyle, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import app from '../../../../index.entry';
import { BasicAsyncHandler } from '../../../../lib/defs/types';
import { uiAlignCenterX, uiAlignCenterY, uiCreateQuad, uiSizeToWidth } from '../../../../lib/pixi/uiTools';
import { CakeItemId, cakeItemPropsMap } from '../../../../replicant/defs/items';
import { TextImageButton } from '../buttons/TextImageButton';
import { ScrollBox } from '../containers/ScrollBox';
import { BasicText } from '../text/BasicText';
import { BasicPopup } from './BasicPopup';

const HEIGHT = 800;
const BUTTON_OFFSET_X = 450;

const buttonTextStyle: Partial<ITextStyle> = {
    dropShadow: true,
    dropShadowAngle: Math.PI / 2,
    dropShadowDistance: 3,
    dropShadowAlpha: 0.7,
    dropShadowBlur: 5,
    fill: '#fff',
    fontSize: 32,
    fontWeight: 'bold',
    lineJoin: 'round',
};

// types
//-----------------------------------------------------------------------------
export type CakeListPopupOptions = {
    onCakePress: (id: CakeItemId) => Promise<void>;
    onClose: BasicAsyncHandler;
    cakes: CakeItemId[];
    scrollIndex: number;
    normalButtonIndex?: number;
    lockScroll?: boolean;
};

const manifest = {
    itemFrame: 'frame.list.item.png',
    button: 'button.red.small.png',
};

/*
    General cake list popup
*/
export class CakeListPopup extends BasicPopup {
    // injected
    onItemPress: (id: CakeItemId) => Promise<void>;
    private _itemButtons: TextImageButton[];

    public get itemButtons() {
        return this._itemButtons;
    }

    public override preload() {
        return [
            ...super.preload(),
            ...app.resource.loadAssets([
                ...Object.values(manifest),
                ...Object.keys(cakeItemPropsMap).map((key: CakeItemId) => cakeItemPropsMap[key].icon),
            ]),
        ];
    }

    // @ts-ignore
    public override async spawning(options: CakeListPopupOptions) {
        super.spawning({
            width: 700,
            height: HEIGHT,
            underlay: 0.7,
            ...options,
        });

        // reset old references
        this._itemButtons = [];

        const scroll = this._createItemScroll(options);

        // pre-scroll
        if (options?.scrollIndex) {
            scroll.scroll({ x: 0, y: 130 * options.scrollIndex }, 0, true);
        }

        // lock scroll once we're focused on the current cake
        scroll.input = !options?.lockScroll;

        const title = new BasicText({
            text: '[popupCakeListTitle]',
            style: {
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowDistance: 5,
                dropShadowColor: 0x0c7c7c7,
                fill: 0x00,
                fontSize: 50,
                fontWeight: 'bold',
                lineJoin: 'round',
                stroke: 0xdf886e,
                strokeThickness: 4,
            },
        });

        this.main.addContent({
            title: {
                content: title,
                styles: {
                    position: 'topCenter',
                    marginTop: -30,
                },
            },
            scroll: {
                content: scroll,
                styles: {
                    position: 'bottomCenter',
                    marginBottom: -20,
                },
            },
        });
    }

    public override async spawned(): Promise<void> {
        await super.spawned();
    }

    public override async despawned() {
        super.despawned();
    }

    private _createItemScroll(opts: CakeListPopupOptions) {
        const { cakes, normalButtonIndex } = opts;
        const itemScroll = new ScrollBox({
            direction: 'down',
            width: this.base.width - 40,
            height: this.base.height - 190,
        });

        let y = 8;
        let stepIndex = 0;
        for (const cakeId of cakes) {
            const frameAsset = manifest.itemFrame;
            const itemFrame = this._createItemFrame(frameAsset);

            const cakeIcon = cakeItemPropsMap[cakeId].icon;
            const cakeSprite = Sprite.from(cakeIcon);
            itemFrame.addChild(cakeSprite);

            cakeSprite.scale.set(0.45);
            cakeSprite.x = 20;

            itemFrame.y = y;
            y += itemFrame.height + 10;
            uiAlignCenterX(itemScroll, itemFrame);
            itemScroll.content.addChild(itemFrame);

            const labelY = -4;
            const button = new TextImageButton({
                text: '[buttonMake]',
                // x: -40,
                image: manifest.button,
                y: labelY,
                slice: {
                    width: 176,
                    height: 81,
                    left: 45,
                    top: 0,
                    right: 45,
                    bottom: 0,
                },
                style: buttonTextStyle,
            });

            if (normalButtonIndex === undefined || stepIndex !== normalButtonIndex) {
                button.alpha = 0.5;
            }

            itemFrame.addChild(button);
            uiAlignCenterY(itemFrame, button);
            button.x += BUTTON_OFFSET_X;
            this._itemButtons.push(button);

            button.onPress = async () => {
                await opts.onCakePress(cakeId);
            };

            const width = 275;
            const cakeName = new BasicText({
                text: cakeItemPropsMap[cakeId].name,
                style: {
                    fill: 0x7c2e15,
                    fontSize: 28,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                    wordWrap: true,
                    wordWrapWidth: width,
                },
            });

            uiSizeToWidth(cakeName, width);
            itemFrame.addChild(cakeName);

            uiAlignCenterY(itemFrame, cakeName);
            cakeName.x = 165;

            stepIndex++;
        }

        // padding hack at the bottiom for full scroll view
        const bottomPadding = uiCreateQuad(0x0, 0.001, this.base.width - 70, 30);
        bottomPadding.y = y;
        itemScroll.content.addChild(bottomPadding);

        return itemScroll;
    }

    private _createItemFrame(frameAsset: string) {
        const itemFrame = new NineSlicePlane(Texture.from(frameAsset), 35, 35, 35, 35);
        itemFrame.width = 640;
        itemFrame.height = 120;

        return itemFrame;
    }
}
