import { waitAFrame } from '@play-co/astro';
import { Scene2D } from '@play-co/odie';
import type { Container } from 'pixi.js';

import { PositionType } from '../../../lib/defs/types';
import Observer from '../../../lib/pattern/Observer';
import { BoosterId } from '../../../replicant/defs/booster';
import { BulletEffect } from './actions/effects/BulletEffect';
import { DartEffect } from './actions/effects/DartEffect';
import { DrillEffect } from './actions/effects/DrillEffect';
import { IEffect } from './actions/effects/IEffect';
import { ShuffleEffect } from './actions/effects/ShuffleEffect';
import { GameEvent } from './defs/event';
import { MapDef } from './defs/map';
import { SessionEntity } from './entities/SessionEntity';
import { BasicBlockSystem } from './systems/BasicBlockSystem';
import { CageBlockSystem } from './systems/CageBlockSystem';
import { ChainBlockSystem } from './systems/ChainBlockSystem';
import { ChameleonBlockSystem } from './systems/ChameleonBlockSystem';
import { CollisionSystem } from './systems/CollisionSystem';
import { DogBlockSystem } from './systems/DogBlockSystem';
import { HiveBlockSystem } from './systems/HiveBlockSystem';
import { MonitorSystem } from './systems/MonitorSystem';
import { PhaseSystem } from './systems/PhaseSystem';
import { PowerBlockSystem } from './systems/PowerBlockSystem';
import { RenderSystem } from './systems/RenderSystem';
import { SessionSystem } from './systems/SessionSystem';
import { SkullBlockSystem } from './systems/SkullBlockSystem';
import { SlimeBlockSystem } from './systems/SlimeBlockSystem';
import { SpiderBlockSystem } from './systems/SpiderBlockSystem';
import { WallBlockSystem } from './systems/WallBlockSystem';

// types
//-----------------------------------------------------------------------------
export interface Options {
    stage: Container;
}

// constants
//-----------------------------------------------------------------------------
export const boosterEffects: {
    [key in BoosterId]: {
        factory: (scene: GameScene, position: PositionType) => IEffect;
        assets: () => string[];
    };
} = {
    dart: {
        factory: (scene, position) => new DartEffect(scene, { position }),
        assets: DartEffect.assets,
    },
    bullet: {
        factory: (scene, position) => new BulletEffect(scene, { row: position.y }),
        assets: BulletEffect.assets,
    },
    drill: {
        factory: (scene, position) => new DrillEffect(scene, { column: position.x }),
        assets: DrillEffect.assets,
    },
    roulette: {
        factory: (scene) => new ShuffleEffect(scene),
        assets: () => [],
    },
};

/*
    match2 puzzle game scene
*/
export class GameScene extends Scene2D {
    // fields
    //-------------------------------------------------------------------------
    // input
    public stage: Container;
    public mapDef: MapDef;
    // events
    public events = new Observer<GameEvent>();
    // state
    private _active = false;
    // scene
    public basicBlockSystem!: BasicBlockSystem;
    public cageBlockSystem!: CageBlockSystem;
    public chameleonBlockSystem!: ChameleonBlockSystem;
    public chainBlockSystem!: ChainBlockSystem;
    public collisionSystem!: CollisionSystem;
    public dogBlockSystem!: DogBlockSystem;
    public hiveBlockSystem!: HiveBlockSystem;
    public monitorSystem!: MonitorSystem;
    public phaseSystem!: PhaseSystem;
    public powerBlockSystem!: PowerBlockSystem;
    public renderSystem!: RenderSystem;
    public sessionSystem!: SessionSystem;
    public skullBlockSystem!: SkullBlockSystem;
    public slimeBlockSystem!: SlimeBlockSystem;
    public spiderBlockSystem!: SpiderBlockSystem;
    public wallBlockSystem!: WallBlockSystem;
    // singletons
    public sessionEntity!: SessionEntity;

    // properties
    //-------------------------------------------------------------------------
    public get active(): boolean {
        return this._active;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: Options) {
        super({ stage: options.stage });

        this.stage = options.stage;

        // add systems in order of execution
        this.sessionSystem = this.addSystem(SessionSystem);
        this.basicBlockSystem = this.addSystem(BasicBlockSystem);
        this.dogBlockSystem = this.addSystem(DogBlockSystem);
        this.chameleonBlockSystem = this.addSystem(ChameleonBlockSystem);
        this.hiveBlockSystem = this.addSystem(HiveBlockSystem);
        this.skullBlockSystem = this.addSystem(SkullBlockSystem);
        this.slimeBlockSystem = this.addSystem(SlimeBlockSystem);
        this.spiderBlockSystem = this.addSystem(SpiderBlockSystem);
        this.powerBlockSystem = this.addSystem(PowerBlockSystem);
        this.cageBlockSystem = this.addSystem(CageBlockSystem);
        this.wallBlockSystem = this.addSystem(WallBlockSystem);
        this.chainBlockSystem = this.addSystem(ChainBlockSystem);
        this.collisionSystem = this.addSystem(CollisionSystem);
        this.phaseSystem = this.addSystem(PhaseSystem);
        this.renderSystem = this.addSystem(RenderSystem);
        this.monitorSystem = this.addSystem(MonitorSystem);

        // enable draw sorting by zIndex
        this.view2d.layers.default.sortableChildren = true;
    }

    // impl
    //-------------------------------------------------------------------------
    override resize(w: number, h: number): void {
        super.resize(w, h);
    }

    // api
    //-------------------------------------------------------------------------
    public async startGame(mapDef: MapDef) {
        this.mapDef = mapDef;

        // set map
        this.sessionSystem.setMap(mapDef);

        // start
        super.start();

        //TODO: shouldnt need this. but apparently pixi issue.
        // wait 3 frames to allow all systems to have started and stepped first frame
        await waitAFrame(3);

        // set active
        this._active = true;
    }

    public stopGame(): void {
        // reset active
        this._active = false;

        // stop
        super.reset();
    }

    public async runBooster(boosterId: BoosterId, position: PositionType) {
        const phase = this.sessionEntity.c.phase;

        // notify
        this.events.publish({ id: 'booster', boosterId });

        // push activations
        phase.activePush();

        // execute booster effect
        await boosterEffects[boosterId].factory(this, position).execute();

        // pop activations
        await phase.activePop();

        // trigger move
        phase.triggerMove('booster');
    }
}
