import { createScheduledActions } from '@play-co/replicant';

import { asyncGetters } from './asyncGetters';
import { bakeryScheduledActions, bakeryScheduledActionsSchema } from './components/bakery';
import { coreScheduledActions, coreScheduledActionsSchema } from './components/core';
import { firstSessionScheduledActions, firstSessionScheduledActionsSchema } from './components/firstSession';
import { computedProperties } from './computedProperties';
import { messages } from './messages';
import { stateSchema } from './state';

export const scheduledActions = createScheduledActions(
    stateSchema,
    {
        ...coreScheduledActionsSchema,
        ...firstSessionScheduledActionsSchema,
        ...bakeryScheduledActionsSchema,
    },
    {
        asyncGetters,
        computedProperties,
        messages,
    },
)({
    ...bakeryScheduledActions,
    ...coreScheduledActions,
    ...firstSessionScheduledActions,
});
