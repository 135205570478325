import { Assets } from 'pixi.js';

import { DrillAnimation } from '../../animations/DrillAnimation';
import { despawnOverlayEntity, spawnOverlayEntity } from '../../entities/OverlayEntity';
import { GameScene } from '../../GameScene';
import { CollisionTracker } from '../../util/CollisionTracker';
import { IEffect } from './IEffect';

// types
//-----------------------------------------------------------------------------
export type DrillEffectOptions = {
    column: number;
};

/*
    drill booster effect
*/
export class DrillEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _column: number;
    // state
    private readonly _collisionTracker = new CollisionTracker();

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: DrillEffectOptions) {
        this._scene = scene;
        this._column = options.column;
    }

    static assets(): string[] {
        return DrillAnimation.assets();
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // load assets
        await Assets.load(DrillEffect.assets());

        // run drill
        await this._runDrill();
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _runDrill() {
        // choose start position at given map column and above the map
        const position = { x: this._column, y: -2 };

        // create animation
        const animation = new DrillAnimation({ position });

        // register events
        animation.onMove = (row) => this._onDrillCollide(row);

        // spawn animation as overlay entity
        const entity = spawnOverlayEntity(this._scene, position, { width: 1, height: 1 }, () => animation);

        // run animation
        await animation.start();

        // despawn entity
        despawnOverlayEntity(this._scene, entity);
    }

    // private: events
    //-------------------------------------------------------------------------
    private _onDrillCollide(row: number) {
        // attack any blocks at this position as a booster
        this._collisionTracker.collideAt(this._scene.sessionEntity.c.map, { x: this._column, y: row }, 'attack', {
            type: 'booster',
        });
    }
}
