import { gsap } from 'gsap';
import { Container } from 'pixi.js';

import { IAnimation } from '../../../../lib/pattern/IAnimation';

// types
//-----------------------------------------------------------------------------
export type WobbleAnimationOptions = {
    target: Container;
};

/*
    wobble the target
*/
export class WobbleAnimation implements IAnimation {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _target: Container;

    // init
    //-------------------------------------------------------------------------
    constructor(options: WobbleAnimationOptions) {
        // set fields
        this._target = options.target;
    }

    // impl
    //-------------------------------------------------------------------------
    public async start(): Promise<void> {
        const scale = this._target.scale;

        // animation extend
        await gsap
            .timeline()
            .to(scale, { x: 1.4, y: 0.6, duration: 0.17, ease: 'power1.out' })
            .to(scale, { x: 0.8, y: 1.2, duration: 0.13, ease: 'power1.inOut' })
            .to(scale, { x: 1, y: 1, duration: 0.13, ease: 'power1.in' });
    }

    public stop() {}
}
