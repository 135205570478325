import gameConfig from '../defs/gameConfig';
import { LanguageId } from '../defs/settings';
import { ReplicantAssetKey } from './messageTemplates';

export type ReplicantCreativeType = ReplicantAssetKey;

export type CreativeText = {
    id: string;
    cta: string;
    text: string;
    title: string;
};

type ReplicantMessagesConfig = {
    [lang: string]: Record<
        ReplicantCreativeType,
        {
            [bucket: string]: { title?: string; text: string; cta: string };
        }
    >;
};

const config: ReplicantMessagesConfig = {
    en: {
        firstSession0: {
            control: {
                text: `A customer has come to buy cakes`,
                cta: 'Go back to the shop!',
            },
        },
        firstSession1: {
            control: {
                text: `The waiting customers are angry!`,
                cta: 'Hurry back to the shop!',
            },
        },
        firstSession2: {
            control: {
                text: `The customers are about to leave the cafe`,
                cta: 'Hurry back to the shop!',
            },
        },
        firstSession3: {
            control: {
                text: `Your shop has gone out of business.`,
                cta: 'Start a new shop!',
            },
        },
        mangoMousseOven0: {
            control: {
                text: `The sponge cake is baked! Let's take it out of the oven!`,
                cta: 'Take out the cake!',
            },
        },
        mangoMousseOven1: {
            control: {
                text: `Hurry! The oven's sponge cake is about to burn!`,
                cta: 'Hurry and take it out!',
            },
        },
        mangoMousseFridge0: {
            control: {
                text: `The mango mousse has cooled! Let's take it out of the refrigerator!`,
                cta: 'Take out the mousse!',
            },
        },
        mangoMousseFridge1: {
            control: {
                text: `Hurry! The mousse is cooling too quickly and becoming too firm!`,
                cta: 'Hurry and take it out!',
            },
        },
        mangoMousseFridge2: {
            control: {
                text: `Quickly, take the mango mousse out of the refrigerator, or it will become rock hard!`,
                cta: 'Take it our immediately!',
            },
        },
        mangoMousseOven2: {
            control: {
                text: `Quickly, if we don't take the sponge cake out of the oven, it will cause a fire!`,
                cta: 'Go extinguish the fire!',
            },
        },
        frenchFlanPot0: {
            control: {
                text: `The custard is ready! Let's remove the pot from the heat!`,
                cta: 'Remove it from the heat!',
            },
        },
        frenchFlanPot1: {
            control: {
                text: `We need to hurry, or the custard will burn!`,
                cta: 'Remove the pot from the heat!',
            },
        },
        frenchFlanPot2: {
            control: {
                text: `Hurry! The pot with custard is burning, and it's going to cause a fire!`,
                cta: 'Go extinguish the fire!',
            },
        },
        frenchFlanOven0: {
            control: {
                text: `The French flan is baked! Let's take it out of the oven!`,
                cta: 'Take out the cake!',
            },
        },
        frenchFlanOven1: {
            control: {
                text: `Hurry! The oven's cake is about to burn!`,
                cta: 'Hurry and take it out!',
            },
        },
        frenchFlanOven2: {
            control: {
                text: `Disaster! The oven's fire has spread, and the shop is on fire!`,
                cta: 'Put out the fire!',
            },
        },
        frenchFlanFridge0: {
            control: {
                text: `The French flan has cooled! Let's take it out of the refrigerator!`,
                cta: 'Take out the cake!',
            },
        },
        frenchFlanFridge1: {
            control: {
                text: `We need to hurry, or the French flan will cool completely, and it will be past its prime!`,
                cta: 'Take it out of the refrigerator!',
            },
        },
        frenchFlanFridge2: {
            control: {
                text: `Quickly, take out the French flan from the refrigerator! Customers are waiting!`,
                cta: 'Take it our immediately!',
            },
        },
        kouignAmannFridge0: {
            control: {
                text: `The Kouign-amann dough has cooled! Let's take it out of the refrigerator!`,
                cta: 'Take out the dough!',
            },
        },
        kouignAmannFridge1: {
            control: {
                text: `Hurry! The Kouign-amann dough is cooling and becoming too stiff!`,
                cta: 'Hurry and take it out!',
            },
        },
        kouignAmannFridge2: {
            control: {
                text: `If we don't take it out of the refrigerator soon, the dough will be ruined!`,
                cta: 'Take it our immediately!',
            },
        },
        kouignAmannOven0: {
            control: {
                text: `The Kouign-amann dough is baked! Let's take it out of the oven!`,
                cta: 'Take out the dough!',
            },
        },
        kouignAmannOven1: {
            control: {
                text: `Hurry! The oven's Kouign-amann is about to burn!`,
                cta: 'Hurry and take it out!',
            },
        },
        kouignAmannOven2: {
            control: {
                text: `Quickly, if we don't remove the Kouign-amann dough from the oven, it will cause a fire!`,
                cta: 'Go extinguish the fire!',
            },
        },
        chocoGlazedOven0: {
            control: {
                text: `The chocolate glazed cake is baked! Let's take it out of the oven!`,
                cta: 'Take out the dough!',
            },
        },
        chocoGlazedOven1: {
            control: {
                text: `The chocolate glazed cake in the oven is about to burn!`,
                cta: 'Hurry and take it out!',
            },
        },
        chocoGlazedOven2: {
            control: {
                text: `If we don't remove the dough from the oven quickly, it will cause a fire!`,
                cta: 'Put out the fire!',
            },
        },
        chocoGlazedPot0: {
            control: {
                text: `The glaze is ready! Let's remove the pot from the heat!`,
                cta: 'Remove it from the heat!',
            },
        },
        chocoGlazedPot1: {
            control: {
                text: `Hurry! Remove the pot from the heat and pour the glaze over the cake!`,
                cta: 'Remove the pot from the heat!',
            },
        },
        chocoGlazedPot2: {
            control: {
                text: `Hurry! Turn off the stove! It's going to cause a fire!`,
                cta: 'Put out the fire!',
            },
        },
        chocoGlazedFridge0: {
            control: {
                text: `The chocolate glaze has cooled! Let's take it out of the refrigerator!`,
                cta: 'Take out the cake!',
            },
        },
        chocoGlazedFridge1: {
            control: {
                text: `Hurry! The cake is ready to eat!`,
                cta: 'Hurry and take it out!',
            },
        },
        chocoGlazedFridge2: {
            control: {
                text: `Quickly, take out the chocolate glazed cake from the refrigerator! Customers are waiting!`,
                cta: 'Take it our immediately!',
            },
        },
    },
    ja: {
        firstSession0: {
            control: {
                text: `お客さんがケーキを買いにお店にきたよ！`,
                cta: 'お店に戻る',
            },
        },
        firstSession1: {
            control: {
                text: `待たされたお客さんが怒っているよ！`,
                cta: '急いでお店に戻る',
            },
        },
        firstSession2: {
            control: {
                text: `お客さんがお店を出ていっちゃうよ`,
                cta: '急いでお店に戻る',
            },
        },
        firstSession3: {
            control: {
                text: `お店がつぶれちゃった、涙`,
                cta: '新しいお店を始める',
            },
        },
        mangoMousseOven0: {
            control: {
                text: `スポンジケーキが焼けました！オーブンから取り出そう！`,
                cta: 'ケーキを取り出す！',
            },
        },
        mangoMousseOven1: {
            control: {
                text: `急いで！オーブンのスポンジケーキが焦げちゃうよ〜`,
                cta: '急いで取り出す！',
            },
        },
        mangoMousseOven2: {
            control: {
                text: `早くオーブンからスポンジケーキを取り出さないと、火事になっちゃうよ！`,
                cta: '火を消す！',
            },
        },
        mangoMousseFridge0: {
            control: {
                text: `マンゴームースが冷えました！冷蔵庫から取り出そう！`,
                cta: 'ムースを取り出す！',
            },
        },
        mangoMousseFridge1: {
            control: {
                text: `急いで！ムースが冷え過ぎて固くなっちゃうよ！`,
                cta: '急いで取り出す！',
            },
        },
        mangoMousseFridge2: {
            control: {
                text: `早く冷蔵庫からマンゴームースを取り出して！カチコチになっちゃうよ！`,
                cta: '直ぐに取り出す！',
            },
        },
        frenchFlanPot0: {
            control: {
                text: `カスタードが出来ました！鍋を火からあげよう！`,
                cta: '火からあげる！',
            },
        },
        frenchFlanPot1: {
            control: {
                text: `早くしないとカスタードが焦げちゃうよ！`,
                cta: '急いで鍋をあげる！',
            },
        },
        frenchFlanPot2: {
            control: {
                text: `急いで！カスタードが入ったお鍋が焦げて、火事になっちゃうよ！`,
                cta: '火を消しに行く！',
            },
        },
        frenchFlanOven0: {
            control: {
                text: `フレンチフランが焼けました！オーブンから取り出そう！`,
                cta: 'ケーキを取り出す！',
            },
        },
        frenchFlanOven1: {
            control: {
                text: `急いで！オーブンのケーキがもう直ぐ焦げちゃうよ！`,
                cta: '急いで取り出す！',
            },
        },
        frenchFlanOven2: {
            control: {
                text: `大変、オーブンの火が燃え移って、お店が火事になっちゃうよ〜`,
                cta: '火を消す！',
            },
        },
        frenchFlanFridge0: {
            control: {
                text: `フレンチフランが冷えました！冷蔵庫から取り出そう！`,
                cta: 'ケーキを取り出す！',
            },
        },
        frenchFlanFridge1: {
            control: {
                text: `早くしないとフレンチフランが冷え切って、食べ頃を過ぎちゃうよ〜`,
                cta: '急いで冷蔵庫から取り出す！',
            },
        },
        frenchFlanFridge2: {
            control: {
                text: `フレンチフランを早く冷蔵庫から取り出して！お客さんが待ってるよ！`,
                cta: '直ぐに取り出す！',
            },
        },
        kouignAmannFridge0: {
            control: {
                text: `クイニーアマンの生地が冷えました！冷蔵庫から取り出そう！`,
                cta: '生地を取り出す！',
            },
        },
        kouignAmannFridge1: {
            control: {
                text: `急いで！クイニーアマンの生地が冷えてカチンコチンになっちゃうよ〜`,
                cta: '急いで取り出す！',
            },
        },
        kouignAmannFridge2: {
            control: {
                text: `早く冷蔵庫から取り出さないと、生地が台無しになっちゃうよ！`,
                cta: '取り出す！',
            },
        },
        kouignAmannOven0: {
            control: {
                text: `クイニーアマンの生地が焼けました！オーブンから取り出そう！`,
                cta: '生地を取り出す！',
            },
        },
        kouignAmannOven1: {
            control: {
                text: `急いで！オーブンのクイニーアマンがもう直ぐ焦げちゃうよ〜`,
                cta: '急いで取り出す！',
            },
        },
        kouignAmannOven2: {
            control: {
                text: `早くオーブンからクイニーアマンの生地を取り出さないと、火事になっちゃうよ！`,
                cta: '火を消しに行く！',
            },
        },
        chocoGlazedOven0: {
            control: {
                text: `チョコレートグレーズの生地が焼けました！オーブンから取り出そう！`,
                cta: '生地を取り出す！',
            },
        },
        chocoGlazedOven1: {
            control: {
                text: `オーブンにあるチョコレートグレーズが生地がもう直ぐ焦げちゃうよ〜`,
                cta: '急いで取り出す！',
            },
        },
        chocoGlazedOven2: {
            control: {
                text: `早くオーブンから生地を取り出さないと、火事になっちゃうよ！`,
                cta: '火を消す！',
            },
        },
        chocoGlazedPot0: {
            control: {
                text: `グレーズが出来ました！鍋を火からあげよう！`,
                cta: '火からあげる！',
            },
        },
        chocoGlazedPot1: {
            control: {
                text: `急いで！鍋を火からあげてグレーズをケーキにかけよう！`,
                cta: '急いで鍋をあげる！',
            },
        },
        chocoGlazedPot2: {
            control: {
                text: `早く〜！鍋の火を消して！火事になっちゃうよ！`,
                cta: '火を消しに行く！',
            },
        },
        chocoGlazedFridge0: {
            control: {
                text: `チョコレートグレーズが冷えました！冷蔵庫から取り出そう！`,
                cta: 'ケーキを取り出す！',
            },
        },
        chocoGlazedFridge1: {
            control: {
                text: `急いで！もうケーキが食べ頃だよ！`,
                cta: '急いで取り出す！',
            },
        },
        chocoGlazedFridge2: {
            control: {
                text: `早くチョコグレーズドケーキを冷蔵庫から取り出して！お客さんが待ってるよ！`,
                cta: '直ぐに取り出す！',
            },
        },
    },
};

// Random among text
export function getCreativeText(
    locale: LanguageId,
    type: ReplicantCreativeType,
    random: () => number,
    substitutions?: { [key: string]: any },
    titleSubstitutions?: { [key: string]: any },
): CreativeText {
    // Fallback locale if we don't have the current locale or its not translated
    const lang = config[locale] ? locale : gameConfig.settings.language;
    const messagesObj = config[lang][type];
    const messagesArr = Object.keys(messagesObj);
    const messageIdx = Math.floor(random() * messagesArr.length);
    const messageId = messagesArr[messageIdx];
    const message = messagesObj[messageId];

    let text = message.text;
    if (substitutions) {
        for (const token in substitutions) {
            text = text.replace(`{${token}}`, substitutions[token]);
        }
    }

    let title = message.title;
    if (title && titleSubstitutions) {
        for (const token in titleSubstitutions) {
            title = title.replace(`{${token}}`, titleSubstitutions[token]);
        }
    }

    return {
        id: messageId,
        cta: message.cta,
        text,
        title,
    };
}

export function getCreativeTextSpecific(
    locale: LanguageId,
    type: ReplicantCreativeType,
    subType: string,
    substitutions?: { [key: string]: any },
    titleSubstitutions?: { [key: string]: any },
): CreativeText {
    // Fallback locale if we don't have the current locale or its not translated
    const lang = config[locale] ? locale : gameConfig.settings.language;
    const messagesObj = config[lang][type];
    const message = messagesObj[subType];

    let text = message.text;
    if (substitutions) {
        for (const token in substitutions) {
            text = text.replace(`{${token}}`, substitutions[token]);
        }
    }

    let title = message.title;
    if (title && titleSubstitutions) {
        for (const token in titleSubstitutions) {
            title = title.replace(`{${token}}`, titleSubstitutions[token]);
        }
    }

    return {
        id: subType,
        cta: message.cta,
        text,
        title,
    };
}
