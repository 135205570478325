import { Container, Sprite } from 'pixi.js';

import { CageBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    image: 'block.cage.front.png',
};

/*
    cage block view
*/
export class CageBlockView extends Container {
    // init
    //-------------------------------------------------------------------------
    constructor(props: CageBlockProps) {
        // create base view
        super();

        //TODO: this is a bit complicated, do later :)
        // spawn back
        //const back = Sprite.from('block.cage.back.png');
        //this.addChild(back);

        // spawn front
        const front = Sprite.from(manifest.image);
        this.addChild(front);

        // position
        blockPositionView(this, props);
    }

    static assets(): string[] {
        return [manifest.image];
    }
}
