import { DisplayObject, FederatedPointerEvent } from 'pixi.js';

import { Lock } from '../../../lib/pattern/Lock';
import { Vector2 } from '../../math/Vector2';

// types
//-----------------------------------------------------------------------------
export type InputEvent = {
    id: number;
    position: Vector2;
};

type AsyncInputHandler = (event: InputEvent) => Promise<any>;
type InputHandler = (event: InputEvent) => any;

/*
    scene: touch input component

    input handlers is async such that while the handler waits it will not be
    called with additional events. ex: for avoiding excess taps from user.
*/
export class TouchInputComponent {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onTap?: AsyncInputHandler;
    public onDown?: InputHandler;
    public onUp?: InputHandler;
    public onOut?: InputHandler;
    public onMove?: InputHandler;
    // input
    private _source?: DisplayObject;
    private _enabled = true;
    private _bubble = true;
    // state
    private _down = false;
    // components
    private _tapLock = new Lock();

    // properties
    //-------------------------------------------------------------------------
    // enabled
    public get enabled(): boolean {
        return this._enabled;
    }

    public set enabled(enabled: boolean) {
        this._enabled = enabled;
        this._updateEnabled();
    }

    // bubble
    public get bubble(): boolean {
        return this._bubble;
    }

    public set bubble(bubble: boolean) {
        this._bubble = bubble;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(source: DisplayObject) {
        // set new source
        this._source = source;

        // register events
        source
            ?.on('pointertap', (event: FederatedPointerEvent) => this._onTap(event))
            .on('pointerdown', (event: FederatedPointerEvent) => this._onDown(event))
            .on('pointerup', (event: FederatedPointerEvent) => this._onUp(event))
            .on('pointerupoutside', (event: FederatedPointerEvent) => this._onUp(event))
            .on('pointerout', (event: FederatedPointerEvent) => this.onOut?.(this._convertEvent(event)))
            .on('pointermove', (event: FederatedPointerEvent) => this.onMove?.(this._convertEvent(event)));

        // initial update
        this._updateEnabled();
    }

    // private: updaters
    //-------------------------------------------------------------------------
    private _updateEnabled() {
        const source = this._source;
        source.interactive = this.enabled;
        source.cursor = this.enabled ? 'pointer' : 'default';
    }

    // private: event handlers
    //-------------------------------------------------------------------------
    private async _onTap(event: FederatedPointerEvent) {
        // dont bubble up
        //if( !this._bubble )            event.stopImmediatePropagation();

        // if down and not already active
        if (this._down && this._tapLock.lock()) {
            // handle tap
            await this.onTap?.(this._convertEvent(event));

            // no longer active
            this._tapLock.unlock();
        }

        // clear down
        this._down = false;
    }

    private _onDown(event: FederatedPointerEvent) {
        // set down
        this._down = true;

        // handle down
        this.onDown?.(this._convertEvent(event));
    }

    private _onUp(event: FederatedPointerEvent) {
        // handle up
        if (this.onUp?.(this._convertEvent(event))) {
            // if handled, clear down to prevent tap.
            this._down = false;
        }
    }

    // private: support
    //-------------------------------------------------------------------------
    private _convertEvent(event: FederatedPointerEvent): InputEvent {
        return {
            id: event.pointerId,
            position: Vector2.from(event.global),
        };
    }
}
