import { Particle } from '../Particle';
import { BehaviorOptions, BehaviorType } from './defs';
import { DragBehavior } from './DragBehavior';
import { ExplodeBehavior } from './ExplodeBehavior';
import { FadeBehavior } from './FadeBehavior';
import { GravityBehavior } from './GravityBehavior';
import { IBehavior } from './IBehavior';
import { KinematicBehavior } from './KinematicBehavior';
import { ScaleBehavior } from './ScaleBehavior';
import { TintBehavior } from './TintBehavior';

// constants
//-----------------------------------------------------------------------------
const behaviorMap: Record<BehaviorType, new (particle: Particle, options: BehaviorOptions) => IBehavior> = {
    drag: DragBehavior,
    explode: ExplodeBehavior,
    fade: FadeBehavior,
    gravity: GravityBehavior,
    kinematic: KinematicBehavior,
    scale: ScaleBehavior,
    tint: TintBehavior,
};

// factory
//-----------------------------------------------------------------------------
export function createBehavior(particle: Particle, options: BehaviorOptions): IBehavior {
    return new behaviorMap[options.type](particle, options);
}
