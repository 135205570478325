import type { Entity, QueriesObject, QueryResults, System } from '@play-co/odie';

import { BlockComponent } from '../components/BlockComponent';
import { PhaseComponent } from '../components/PhaseComponent';
import { SkullBlockComponent } from '../components/SkullBlockComponent';
import { SkullBlockEntity } from '../entities/BlockEntity';
import type { GameScene } from '../GameScene';

/*
    handles skull block specifics
*/
export class SkullBlockSystem implements System {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'blockSkull';
    public static Queries: QueriesObject = {
        block: {
            components: [BlockComponent, SkullBlockComponent],
        },
        phase: {
            components: [PhaseComponent],
            modified: true,
        },
    };

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    public queries!: QueryResults;

    // impl
    //-------------------------------------------------------------------------
    public modifiedQuery(entity: Entity, component: PhaseComponent, properties: any) {
        // if move input phase step skulls
        if (properties.phase && component.phase === 'input' && component.moved) {
            this._stepSkull();
        }
    }

    // private: actions
    //-------------------------------------------------------------------------
    private _stepSkull() {
        // restore skulls
        this.queries.block.forEach((entity: SkullBlockEntity) => {
            const skull = entity.c.blockSkull;

            // if skull idle, restore count
            if (skull.idle) {
                ++skull.count;
            }

            // reset idle state
            skull.idle = true;
        });
    }
}
