import { Layout } from '@pixi/layout';
import { Sprite, Texture } from 'pixi.js';

import { BasicAsyncHandler, SizeType } from '../../../lib/defs/types';
import { TouchInputComponent } from '../../../lib/pixi/components/TouchInputComponent';
import { tween } from '../../../lib/util/tweens';
import { LayoutScreen } from './LayoutScreen';

const ANIM_DURATION = 0.22;

// types
//-----------------------------------------------------------------------------
export type PopupOptions = {
    onClose?: BasicAsyncHandler;
    // if true then onClose will trigger upon tapping popup content
    globalClose?: boolean;
    underlay?: number;
} & SizeType;

// inner classes
//-----------------------------------------------------------------------------
// the layer under the popup
export class Underlay extends Layout {
    constructor(alpha: number) {
        super({
            id: '',
            content: Sprite.from(Texture.WHITE).props({
                tint: 0x1b0e11,
                alpha,
            }),
            styles: {
                width: '100%',
                height: '100%',
            },
        });
    }

    public override resize(width: number, height: number) {
        super.resize(width, height);
        const content = this.content.firstChild as Sprite;

        // update content to fit parent
        content.width = width;
        content.height = height;
    }
}

/*
    base popup screen
*/
export abstract class PopupScreen extends LayoutScreen {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onClose?: BasicAsyncHandler;
    // scene
    protected base: Layout;
    // components
    private _underlayInput: TouchInputComponent;

    // impl
    //-------------------------------------------------------------------------
    public async spawning(options: PopupOptions) {
        const underlay = new Underlay(options.underlay === undefined ? 0 : options.underlay);

        // set fields
        this.onClose = options.onClose;

        // scene
        this.root.addContent({
            // underlay
            underlay: {
                content: underlay,
                styles: {
                    width: '100%',
                    height: '100%',
                },
            },
            // base
            base: (this.base = new Layout({
                id: 'base',
                styles: {
                    width: options.width,
                    height: options.height,
                    position: 'center', // We cant tween scale with just 'center' + pivot
                    // Add margin in combination with center be able to animate in/out with scaling
                    marginLeft: options.width * 0.5,
                    marginTop: options.height * 0.5,
                },
            })),
        });

        // Set pivot to be able to scale in/out
        this.base.pivot.set(options.width * 0.5, options.height * 0.5);

        // setup input
        if (this.onClose) this.base.interactive = !options.globalClose;
        this._underlayInput = Object.assign(new TouchInputComponent(underlay), {
            onTap: async () => this.onClose?.(),
        });
    }

    public async spawned() {
        await this.animateIn();
    }

    public async despawning(): Promise<void> {
        await this.animateOut();
    }

    public despawned() {
        this._underlayInput.enabled = false;

        this.empty();
    }

    public override resized(size: SizeType): void {
        super.resized(size);
    }

    private async animateIn() {
        this.base.scale.set(0);
        await this.base.animate().add(this.base.scale, { x: 1, y: 1 }, ANIM_DURATION, tween.backOut());
    }

    private async animateOut() {
        await this.base.animate().add(this.base.scale, { x: 0, y: 0 }, ANIM_DURATION, tween.backIn());
    }
}
