import { Application, Plugin, PluginOptions, ResizePlugin } from '@play-co/astro';
import { Container } from 'pixi.js';

import { BasicAsyncHandler, SizeType } from '../../lib/defs/types';
import Observer from '../../lib/pattern/Observer';
import TaskQueue from '../../lib/pattern/TaskQueue';
import { pixiGetDt, pixiGetScene } from '../../lib/pixi/pixiTools';
import { arrayCreate, objectAccess } from '../../replicant/util/jsTools';
import { IScreen } from './IScreen';
import { ITransition } from './ITransition';
import { LoaderDefs, LoaderId, LoaderManager, ManualLoader } from './LoaderManager';
import { EmptyScreen } from './screens/EmptyScreen';
import { EmptyTransition } from './transitions/EmptyTransition';

// types
//-----------------------------------------------------------------------------
//TODO: typeify via generic type
export type ScreenId = string;
export type ScreenDef = {
    screen: new () => IScreen;
    transition?: new () => ITransition;
    layer: number;
    loader?: LoaderId;
};
export type ScreenDefs = Record<ScreenId, ScreenDef>;

export interface NavPluginOptions extends PluginOptions {
    // number of layers
    layers: number;
    // screen definitions
    screens: ScreenDefs;
    // loader definitions
    loaders: LoaderDefs;
    // first screen to preload
    preload?: () => ScreenId;
    // default transition
    transition?: new () => ITransition;
}

export type NavEventId = 'spawning' | 'spawned' | 'despawning' | 'despawned';
export type NavEvent = {
    id: NavEventId;
    layerId: number;
    screenId: string;
    screen: IScreen;
    options?: object;
};

// private
type Layer = {
    id: number;
    screen: ScreenState;
    container: Container;
    queue: TaskQueue;
    emptyScreen: ScreenState;
};

type ScreenState = {
    id?: string;
    instance: IScreen;
    initialized: boolean;
    spawned: boolean;
    layer: Layer;
    transition?: new () => ITransition;
    loader?: LoaderId;
};

type StackEntry = {
    open: BasicAsyncHandler;
    close: BasicAsyncHandler;
};

/*
    app navigation service
*/
export class NavPlugin extends Plugin<NavPluginOptions> {
    // fields
    //-------------------------------------------------------------------------
    // events
    public events = new Observer<NavEvent>();
    // input
    private _options: NavPluginOptions;
    private _defaultTransition?: new () => ITransition;
    // scene
    private _root: Container;
    // components
    private _loaderManager: LoaderManager;
    // state
    private _screens: Record<string, ScreenState> = {};
    private _layers: Layer[];
    private _size: SizeType;
    private _loaderManagerInitPromise: Promise<void>;
    private _stack: StackEntry[] = [];

    // properties
    //-------------------------------------------------------------------------
    public get screens(): Record<string, ScreenState> {
        return this._screens;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(app: Application, options: NavPluginOptions) {
        super(app, options);

        // set fields
        this.app = app;
        this._options = options;
        this._defaultTransition = options.transition || EmptyTransition;
    }

    // impl
    //-------------------------------------------------------------------------
    public async init() {
        const resizer: ResizePlugin = this.app.get(ResizePlugin);

        // get root scene
        this._root = pixiGetScene();

        // init layers
        this._initLayers();

        // init loader manager
        this._loaderManager = new LoaderManager({
            root: this._root.addChild(new Container()),
            defs: this._options.loaders,
        });
        this._loaderManagerInitPromise = this._loaderManager.init();

        // initial resize
        this._onResize(resizer.w, resizer.h);

        // register events
        resizer.onResize.connect(this._onResize.bind(this));
    }

    public async prepare() {
        // await loader manager init complete
        await this._loaderManagerInitPromise;

        // run initial  preload
        await this._runInitialPreload();
    }

    public start(): void {}

    public update(): void {
        // get dt
        const dt = pixiGetDt();

        // step spawned layers
        for (const layer of this._layers) {
            if (layer.screen.spawned) layer.screen.instance.step?.(dt);
        }

        // step loader manager
        this._loaderManager.step(dt);
    }

    // api
    //-------------------------------------------------------------------------
    public async open(screenId: ScreenId, options?: object): Promise<IScreen> {
        // access screen for screen id
        const screen = this._accessScreenState(screenId);

        // enqueue open operation
        await screen.layer.queue.add(async () => {
            // switch out current screen
            await this._layerSwitchOut(screen.layer);

            // preload screen
            await this._screenLoad(screen, options, { throttle: true });

            // switch in new screen
            await this._layerSwitchIn(screen.layer, screen, options);
        });

        return screen.instance;
    }

    public async close(screenId: ScreenId): Promise<void> {
        // access screen for screen id
        const screen = this._accessScreenState(screenId);

        // close this layer
        await this.closeLayer(screen.layer.id);
    }

    //TODO: push pop nav for another time
    // public async push(screenId: ScreenId, options?: object): Promise<IScreen> {
    //     // prepare open and corresponding close
    //     const open = async () => this.open(screenId, options);
    //     const close = async () => this.close(screenId);

    //     // push to stack
    //     this._stack.push({ open, close });

    //     // execute open
    //     return open();
    // }

    // public async pop(): Promise<void> {
    //     // pop from stack
    //     const entry = this._stack.pop();

    //     // close that entry
    //     await entry.close();

    //     // open current
    // }

    public async closeLayer(layerId: number): Promise<void> {
        const layer = this._layers[layerId];

        // enqueue close operation
        await layer.queue.add(async () => {
            // close layer by opening empty screen
            await this._layerSwitchOutIn(layer, layer.emptyScreen);
        });
    }

    public async closeLayers(layerIds: number[]): Promise<void> {
        // close all layers at once
        await Promise.all(layerIds.map((id) => this.closeLayer(id)));
    }

    public async preload(screenId: ScreenId, options?: object) {
        // access screen for screen id
        const screen = this._accessScreenState(screenId);

        // call screen preload
        await Promise.all(screen.instance.preload?.(options) || []);
    }

    public loader(loaderId: string): ManualLoader {
        // run manual loader
        return this._loaderManager.runManual(loaderId);
    }

    public get(screenId: string): IScreen {
        // access screen instance
        return this._accessScreenState(screenId)?.instance;
    }

    // private: init
    //-------------------------------------------------------------------------
    private _initLayers() {
        // create number of requested layers
        this._layers = arrayCreate(this._options.layers, (id) => {
            // create render container
            const container = this._root.addChild(new Container());

            // create task queue. disable input for this container while queue is active.
            const queue = new TaskQueue();
            queue.onBegin = () => (container.interactiveChildren = false);
            queue.onEnd = () => (container.interactiveChildren = true);

            // create empty screen state
            const emptyScreen: ScreenState = {
                instance: new EmptyScreen(),
                layer: undefined,
                initialized: true,
                spawned: false,
            };

            // create layer
            const layer: Layer = {
                id,
                screen: emptyScreen,
                container,
                queue,
                emptyScreen,
            };
            emptyScreen.layer = layer;

            return layer;
        });
    }

    private _accessScreenState(id: ScreenId): ScreenState {
        // access (get else create) screen state
        return objectAccess(id, this._screens, () => {
            const def = this._options.screens[id];
            return {
                id,
                instance: new def.screen(),
                initialized: false,
                spawned: false,
                layer: this._layers[def.layer],
                transition: def.transition,
                loader: def.loader,
            };
        });
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _runInitialPreload() {
        const preload = this._options.preload;

        // require launch load request
        if (preload) {
            // access preload screen
            const screen = this._accessScreenState(preload());

            // preload screen using boot loader
            await this._screenLoad(screen, undefined, { loader: 'boot' });
        }
    }

    // private: screen control
    //-------------------------------------------------------------------------
    private async _screenLoad(
        screen: ScreenState,
        options?: object,
        features?: {
            loader?: LoaderId;
            throttle?: boolean;
        },
    ): Promise<void> {
        // get preloads
        const preloads = screen.instance.preload?.(options);

        // run loader
        if (preloads) {
            await this._loaderManager.run(features?.loader || screen.loader || 'default', preloads, features?.throttle);
        }

        // initialize screen if not yet initialized
        if (!screen.initialized) {
            await screen.instance.init?.();
            screen.initialized = true;
        }
    }

    // private: layer control
    //-------------------------------------------------------------------------
    private async _layerSwitchOutIn(layer: Layer, state: ScreenState, options?: object) {
        // switch out
        await this._layerSwitchOut(layer);

        // switch in
        await this._layerSwitchIn(layer, state, options);
    }

    private async _layerSwitchOut(layer: Layer) {
        // run close transition
        await this._layerTransition(layer, false);

        // close out current layer
        await this._layerClose(layer);
    }

    private async _layerSwitchIn(layer: Layer, state: ScreenState, options?: object) {
        // open in new layer
        await this._layerOpen(layer, state, options || {});

        // run open transition
        await this._layerTransition(layer, true);
    }

    private async _layerOpen(layer: Layer, screen: ScreenState, options?: object) {
        // update layer
        layer.screen = screen;

        // notify spawning
        this._publishEvent('spawning', layer, options);

        // handle spawning
        await screen.instance.spawning?.(options);

        // handle current size
        screen.instance.resized?.(Object.assign({}, this._size));

        // add to render scene
        layer.container.addChild(screen.instance.root);

        // handle spawned
        await screen.instance.spawned?.();

        // set spawned
        screen.spawned = true;

        // notify spawned
        this._publishEvent('spawned', layer, options);
    }

    private async _layerClose(layer: Layer) {
        const instance = layer.screen.instance;

        // notify despawning
        this._publishEvent('despawning', layer);

        // handle despawning
        await instance.despawning?.();

        // set despawned
        layer.screen.spawned = false;

        // remove from render scene
        layer.container.removeChild(instance.root);

        // handle despawned
        instance.despawned?.();

        // notify despawned
        this._publishEvent('despawned', layer);
    }

    private async _layerTransition(layer: Layer, open: boolean) {
        // create transition
        const screen = layer.screen;
        const transition = new (screen?.transition || this._defaultTransition)();

        // notify size
        transition.resize?.(this._size);

        // start open else close transition
        return open ? transition.open(screen.instance) : transition.close(screen.instance);
    }

    // private: events
    //-------------------------------------------------------------------------
    private _onResize(width: number, height: number): void {
        // update fields
        this._size = { width, height };

        // notify screens
        for (const layer of this._layers) {
            layer.screen.instance.resized?.({ ...this._size });
        }

        // notify active loader
        this._loaderManager.resized(this._size);
    }

    // private: support
    //-------------------------------------------------------------------------
    private _publishEvent(id: NavEventId, layer: Layer, options?: object) {
        this.events.publish({
            id,
            layerId: layer.id,
            screenId: layer.screen.id,
            screen: layer.screen.instance,
            options,
        });
    }
}
