import type { Component, Entity2D } from '@play-co/odie';

import { VirusBlockProps } from '../defs/block';
import { VirusBlockView } from '../views/blocks/VirusBlockView';

// types
//-----------------------------------------------------------------------------
export type VirusBlockComponentOptions = {
    props: VirusBlockProps;
};

/*
    virus block component
*/
export class VirusBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockVirus';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: VirusBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: VirusBlockComponentOptions): void {
        // create virus block view
        this.view = new VirusBlockView(options.props);
    }
}
