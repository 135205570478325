import { BasicAsyncHandler } from '../../../../lib/defs/types';
import { uiSizeToWidth } from '../../../../lib/pixi/uiTools';
import { BasicText } from '../text/BasicText';
import { BaseSimplePopup } from './BaseSimplePopup';

// types
//-----------------------------------------------------------------------------
export type PromptPopupOptions = {
    title: string;
    message: string;
    onOk: BasicAsyncHandler;
    okText?: string;
    onClose: BasicAsyncHandler;
    closeText?: string;
};

/*
    ui: prompt popup
*/
export class PromptPopup extends BaseSimplePopup {
    // impl
    //-------------------------------------------------------------------------
    // @ts-ignore
    public override async spawning(options: PromptPopupOptions) {
        // create text content
        const content = new BasicText({
            text: options.message,
            style: {
                fill: '#fff',
                fontSize: 26,
                fontWeight: 'bold',
                lineJoin: 'round',
                strokeThickness: 3,
            },
        });

        // limit width
        uiSizeToWidth(content, 460);

        // spawn
        super.spawning({
            header: options.title,
            content,
            buttons: [
                {
                    label: options.closeText || 'TODO',
                    handler: options.onClose,
                },
                {
                    label: options.okText || '[buttonOk]',
                    handler: options.onOk,
                },
            ],
            onClose: options.onClose,
        });
    }
}
