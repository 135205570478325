import { IScreen } from '../IScreen';
import { ITransition } from '../ITransition';

/*
    default empty transition
*/
export class EmptyTransition implements ITransition {
    // impl
    //-------------------------------------------------------------------------
    public async open(screen: IScreen) {}
    public async close(screen: IScreen) {}
}
