import type { Component, Entity2D } from '@play-co/odie';

import { KappaBlockProps } from '../defs/block';
import { KappaBlockView } from '../views/blocks/KappaBlockView';

// types
//-----------------------------------------------------------------------------
export type KappaBlockComponentOptions = {
    props: KappaBlockProps;
};

export type KappaBlockType = 'hard' | 'soft';

/*
    kappa block component
*/
export class KappaBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockKappa';

    // fields
    private _state: KappaBlockType;
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: KappaBlockView;

    // properties
    //-------------------------------------------------------------------------
    // count
    public get state(): KappaBlockType {
        return this._state;
    }

    public set state(state: KappaBlockType) {
        this._state = state;
        // update view
        this.view.statusSprite = state;
    }

    // impl
    //-------------------------------------------------------------------------
    public init(options: KappaBlockComponentOptions): void {
        this.view = new KappaBlockView({ ...options.props, width: 0, height: 0 });

        // set initial state
        this.state = 'hard';
    }
}
