import { Container, DisplayObject } from 'pixi.js';

import { SizeType } from '../../defs/types';
import { pixiSignScale } from '../../pixi/pixiTools';

export function hookMethod<T>(
    klass: any,
    key: string,
    handler: (object: T, original: Function, ...args: any[]) => any,
) {
    const original = klass.prototype[key];

    klass.prototype[key] = function (...args: any[]) {
        return handler(this, () => original.apply(this, args), ...args);
    };
}

// types
//-----------------------------------------------------------------------------
export type Layout = 'down' | 'right';

export interface FlexProps {
    // container
    pivot?: SizeType;
    width?: number;
    height?: number;
    layout?: Layout;
    spacing?: number;
    // child: absolute
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
    centerX?: number;
    centerY?: number;
    // child: relative
    relative?: boolean;
    margin?: number; //TODO: fix
    fitWidth?: number;
    fitHeight?: number;
}

// api
//-----------------------------------------------------------------------------
export function flex<T extends DisplayObject>(target: T, props?: FlexProps): T {
    // access mod
    const mod = flexGet(target) || ((target as any).flex = new FlexMod(target));

    // merge props
    mod.merge(props || {});

    return target;
}

export function flexGet(target: DisplayObject): FlexMod {
    return FlexMod.from(target);
}

/*
    flex layout mod for pixi
*/
class FlexMod implements FlexProps {
    // fields
    //-------------------------------------------------------------------------
    // props: container
    pivot?: SizeType;
    width?: number;
    height?: number;
    layout?: Layout;
    spacing?: number;
    // props: child: absolute
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
    centerX?: number;
    centerY?: number;
    // child: relative
    relative?: boolean;
    margin?: number;
    fitWidth?: number;
    fitHeight?: number;
    // inject
    parent?: FlexMod;
    // input
    private _target: DisplayObject;

    // properties
    //-------------------------------------------------------------------------
    public get target(): DisplayObject {
        return this._target;
    }

    public get scaledWidth(): number {
        return this.baseWidth * this._target.scale.x;
    }

    public get scaledHeight(): number {
        return this.baseHeight * this._target.scale.y;
    }

    public get baseWidth(): number {
        return this.width || this._target.getLocalBounds().width;
    }

    public get baseHeight(): number {
        return this.height || this._target.getLocalBounds().height;
    }

    public set layoutSize(size: number) {
        if (this.layout === 'down') this.height = size;
        else this.width = size;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(target: DisplayObject) {
        this._target = target;
    }

    // api
    //-------------------------------------------------------------------------
    public merge(props: FlexProps) {
        // merge props
        Object.assign(this, props);

        // import initial props from target
        this._importTargetProps();

        // initial update
        this.update();
    }

    public update() {
        // update layout
        this._updateLayout();

        // update size
        this._updateSize();

        // if has parent
        if (this.parent) {
            // update absolute position
            this._updateAbsolutePosition();
        }

        // update pivot
        this._updatePivot();
    }

    static from(target: DisplayObject): FlexMod {
        return (target as any).flex as FlexMod;
    }

    // private: init
    //-------------------------------------------------------------------------
    private _importTargetProps() {
        const bounds = this._target.getLocalBounds();

        // width/height
        if (this.width === undefined) this.width = bounds.width;
        if (this.height === undefined) this.height = bounds.height;
    }

    // private: updaters
    //-------------------------------------------------------------------------
    private _updatePivot() {
        // if have pivot
        if (this.pivot) {
            const { width, height } = this.pivot;
            this._target.pivot.set(this.baseWidth * width || 0, this.baseHeight * height || 0);
        }
    }

    private _updateLayout() {
        const container = this._target as Container;

        // if has layout
        if (this.layout && container) {
            const children = container.children;
            const spacing = this.spacing || 0;
            let offset = 0;
            let size = 0;

            // for each child
            for (const child of children) {
                const mod = FlexMod.from(child);
                // if relative enabled apply layout including spacing and margin
                if (mod?.relative) {
                    const target = mod.target;

                    // update position
                    if (this.layout === 'right') target.x = offset;
                    else target.y = offset;

                    // update offset by size
                    offset += mod.pivot ? 0 : this.layout === 'right' ? mod.scaledWidth : mod.scaledHeight;
                    size = offset;

                    // update offset by spacing and margin
                    offset += spacing + (mod.margin || 0);
                }
            }

            // update size and parent
            this.layoutSize = size;
            this.parent?.update();
        }
    }

    private _updateAbsolutePosition() {
        // if pivoting, ignore width/height
        const width = this.pivot?.width ? 0 : this.scaledWidth;
        const height = this.pivot?.height ? 0 : this.scaledHeight;

        // top
        if (this.top !== undefined) {
            this._target.y = this.top;
            // centerY
        } else if (this.centerY !== undefined) {
            this._target.y = (this.parent.baseHeight - height) / 2 + this.centerY;
            // bottom
        } else if (this.bottom !== undefined) {
            this._target.y = this.parent.baseHeight - height - this.bottom;
        }

        // left
        if (this.left !== undefined) {
            this._target.x = this.left;
            // centerX
        } else if (this.centerX !== undefined) {
            this._target.x = (this.parent.baseWidth - width) / 2 + this.centerX;
            // right
        } else if (this.right !== undefined) {
            this._target.x = this.parent.baseWidth - width - this.right;
        }
    }

    private _updateSize() {
        // if fit width
        if (this.fitWidth) {
            const width = Math.min(this.baseWidth, this.fitWidth);
            if (this.baseWidth > width) pixiSignScale(this._target, width / this.baseWidth);
        }

        // if fit height
        if (this.fitHeight) {
            const height = Math.min(this.baseHeight, this.fitHeight);
            if (this.baseHeight > height) {
                const scale = height / this.baseHeight;

                // if already width scaling, ensure new scale is smaller than current scale
                if (!this.fitWidth || scale < this._target.scale.x) pixiSignScale(this._target, scale);
            }
        }
    }
}

// main
//-----------------------------------------------------------------------------
(function () {
    // hook Container.onChildrenChange
    hookMethod(Container, 'onChildrenChange', (container: Container, original: Function, length: number) => {
        // call original first
        const out = original();

        // if container is modded, call update
        FlexMod.from(container)?.update();

        return out;
    });

    // hook Container.addChild
    hookMethod(Container, 'addChild', (container: Container, original: Function, ...children: DisplayObject[]) => {
        // call original first
        const out = original();

        // if container is modded
        const containerMod = FlexMod.from(container);

        if (containerMod) {
            // for each added child
            for (const child of children) {
                // if child is modded, inject parent and udpate
                const childMod = FlexMod.from(child);
                if (childMod) {
                    childMod.parent = containerMod;
                    childMod.update();
                }
            }
        }

        return out;
    });
})();
