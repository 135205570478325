import { Ticker } from 'pixi.js';

import { BasicHandler, TweenFunction } from '../defs/types';
import { pixiGetDt } from '../pixi/pixiTools';
import Clip from './Clip';

// types
//-----------------------------------------------------------------------------
// events
type ClipEvent = {
    type: 'clip';
    clip: Clip;
};

type FunctionEvent = {
    type: 'function';
    handler: BasicHandler;
    always: boolean;
};

type SetEvent = {
    type: 'set';
    begin: any;
    end: any;
};

type WaitEvent = {
    type: 'wait';
    time: number;
    limit: number;
};

type Event = ClipEvent | FunctionEvent | SetEvent | WaitEvent;

// queue
type Queue = {
    index: number;
    count: number;
    events: Event[];
};

// helpers
//-----------------------------------------------------------------------------
export function animate(): Animation {
    const animation = new Animation();

    // register frame stepper
    const stepper = () => {
        // if active and in scene, continue stepping
        if (animation.active) {
            animation.$step(pixiGetDt());
        }
        // else cancel and unregister stepper
        else {
            animation.cancel();
            Ticker.system.remove(stepper);
        }
    };
    Ticker.system.add(stepper);

    return animation;
}

/*
    tween prop animator. a more flexible and deterministic alternative to gsap.
*/
export class Animation {
    // fields
    //---------------------------------------------------------------------------
    private _layers: Queue[];
    private _active: number;

    // properties
    //---------------------------------------------------------------------------
    public get active() {
        return this._layers.length > 0;
    }

    public get queue(): Queue {
        return this._layers[this._active];
    }

    // init
    //---------------------------------------------------------------------------
    constructor() {
        this._reset();
    }

    // api
    //---------------------------------------------------------------------------
    // queue a clip
    public add(begin: any, end: any, duration: number, tween: TweenFunction): Animation {
        // create new clip
        const clip = new Clip(duration);

        // add initial action
        clip.addAction(begin, end, tween);

        // add clip to queue
        this.queue.events.push({
            type: 'clip',
            clip,
        });

        return this;
    }

    // add to active (last) clip. use if different tweens are desired with different properties.
    public and(begin: any, end: any, tween: TweenFunction): Animation {
        const events = this.queue.events;
        const lastEvent = events[events.length - 1];

        // add action to last entry (should be clip)
        if (lastEvent.type === 'clip') lastEvent.clip.addAction(begin, end, tween);

        return this;
    }

    // directly apply given end values
    public set(begin: any, end: any): Animation {
        // add clip to queue
        this.queue.events.push({
            type: 'set',
            begin,
            end,
        });

        return this;
    }

    // loop all actions so far
    public loop(count: number = Number.MAX_SAFE_INTEGER): Animation {
        // update queue count
        this.queue.count = count;
        return this;
    }

    // queue a function. if always is true, then function will be called even if animation is canceled
    public then(handler: BasicHandler, always = false): Animation {
        // add function to queue
        this.queue.events.push({
            type: 'function',
            handler,
            always,
        });

        return this;
    }

    // queue a delay
    public wait(time: number): Animation {
        // add wait to queue
        this.queue.events.push({
            type: 'wait',
            time,
            limit: time,
        });

        return this;
    }

    // selects or creates a new queue from time 0
    public layer(index?: number): Animation {
        // if create, add new layer
        if (index === undefined) {
            this._addLayer();
            // else select active layer
        } else {
            this._active = index;
        }

        return this;
    }

    // clear current queue
    public clear(): Animation {
        const queue = this.queue;
        if (queue) {
            queue.count = 0;
            queue.events = [];
        }

        return this;
    }

    // cancel animation
    public cancel() {
        // if still active
        if (this.active) {
            // call remaining always functions
            this._callAlwaysFunctions();

            // reset state
            this._reset();
        }

        return this;
    }

    // get a promise at current point in queue
    public promise(): Promise<void> {
        return new Promise((resolve) => this.then(resolve, true));
    }

    // internal api
    //---------------------------------------------------------------------------
    public $step(dt: number) {
        const layers = this._layers;

        // iterate layers. allow midway removal
        for (let i = layers.length; i--; ) {
            // step queue, remove layer if completed. new active will be same index
            // which is next layer
            if (!this._stepQueue(dt, layers[i])) {
                layers.splice(i, 1);
            }
        }
    }

    // private: step control
    //---------------------------------------------------------------------------
    private _stepQueue(dt: number, queue: Queue) {
        const events = queue.events;

        // until all loops complete
        while (queue.count > 0) {
            // for each iteration, step events
            for (; queue.index < events.length; ++queue.index) {
                if (!this._stepEvent(dt, queue, events[queue.index])) return true;
            }

            // reset events
            for (const event of events) this._resetEvent(event);

            // decrement count
            --queue.count;

            // reset index
            queue.index = 0;
        }

        return false;
    }

    private _stepEvent(dt: number, queue: Queue, event: Event): boolean {
        // by entry type
        switch (event.type) {
            // clip
            case 'clip':
                return this._stepClip(dt, event);
            // function
            case 'function':
                return this._stepFunction(dt, queue, event);
            // set
            case 'set':
                return this._stepSet(event);
            // wait
            case 'wait':
                return this._stepWait(dt, event);
        }
        return false;
    }

    private _stepClip(dt: number, event: ClipEvent): boolean {
        // step and remove if completed
        return event.clip.step(dt);
    }

    private _stepFunction(dt: number, queue: Queue, event: FunctionEvent) {
        // call function on final count
        if (queue.count === 1) event.handler();

        return true;
    }

    private _stepSet(event: SetEvent): boolean {
        // apply end to begin object
        Object.assign(event.begin, event.end);

        return true;
    }

    private _stepWait(dt: number, event: WaitEvent) {
        // update time
        event.time -= dt;

        // true if if expired
        return event.time < 0;
    }

    // private: reset
    //---------------------------------------------------------------------------
    private _reset() {
        this._layers = [];
        this._addLayer();
    }

    private _resetEvent(event: Event) {
        // by entry type
        switch (event.type) {
            // clip
            case 'clip':
                event.clip.reset();
                break;
            // wait
            case 'wait':
                event.time = event.limit;
                break;
        }
    }

    // private: support
    //---------------------------------------------------------------------------
    private _addLayer() {
        this._active = this._layers.length;
        this._layers.push({
            count: 1,
            index: 0,
            events: [],
        });
    }

    private _callAlwaysFunctions() {
        // call remaining always functions
        for (const queue of this._layers) {
            for (const event of queue.events) {
                if (event.type === 'function' && event.always) event.handler();
            }
        }
    }
}
