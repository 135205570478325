import { uiAlignCenterX, uiAlignCenterY, uiPivotCenter, uiSizeToWidth } from '../../../../lib/pixi/uiTools';
import { BasicText, BasicTextOptions } from '../text/BasicText';
import { ImageButton, ImageButtonOptions } from './ImageButton';

// types
//-----------------------------------------------------------------------------
export type TextImageButtonOptions = {
    text?: string;
    // fit button to match text otherwise fit text to match button. only
    // recommended with 9slice button.
    fit?: boolean;
    // text offset override
    x?: number;
    y?: number;
} & ImageButtonOptions &
    BasicTextOptions;

/*
    ui: image button with text
*/
export class TextImageButton extends ImageButton {
    // fields
    //-------------------------------------------------------------------------
    private _options0: TextImageButtonOptions;
    private _text: BasicText;

    // properties
    //-------------------------------------------------------------------------
    public get textView(): BasicText {
        return this._text;
    }

    public get text() {
        return this._text.text;
    }

    public set text(text: string) {
        this._text.text = text;
        this._update();
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: TextImageButtonOptions) {
        super(options);

        // set fields
        this._options0 = options;

        // add text
        this._text = this.button.addChild(new BasicText(options));

        // update
        this._update();
    }

    // private: updaters
    //-------------------------------------------------------------------------
    private _update() {
        const options = this._options0;
        const text = this._text;

        // button else text sizing
        if (options.fit) {
            this.button.width = text.width + this.button.height * 0.7;
            uiPivotCenter(this.button);
        } else uiSizeToWidth(text, this.button.width * 0.7);

        // align center with optional y offset
        uiAlignCenterX(this.button, text, options.x || 0);
        uiAlignCenterY(this.button, text, options.y || 0);
    }
}
