import { Sprite, Texture } from 'pixi.js';

import { KappaBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-------------------------------------------------------------------------
const manifest = {
    hard: 'block.kappa.0.png',
    soft: 'block.kappa.1.png',
};

/*
    kappa block view
*/
export class KappaBlockView extends Sprite {
    public set statusSprite(status: 'hard' | 'soft') {
        // update texture
        this.texture = Texture.from(manifest[status]);
    }

    // init
    //-------------------------------------------------------------------------
    constructor(props: KappaBlockProps) {
        // create base view
        super(Texture.from(manifest.hard));
        blockPositionView(this, props);
    }

    static assets(): string[] {
        return Object.values(manifest);
    }
}
