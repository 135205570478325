import { NineSlicePlane, Sprite, Texture } from 'pixi.js';

import app from '../../../../index.entry';
import { pixiSetInterval } from '../../../../lib/pixi/pixiTools';
import { uiAlignCenter, uiSizeToFit } from '../../../../lib/pixi/uiTools';
import { timeFormatCountdown, timeToComponents } from '../../../../replicant/util/timeTools';
import { BasicText } from '../text/BasicText';
import { BasicPopup, BasicPopupOptions } from './BasicPopup';

const HEIGHT = 600;

// types
//-----------------------------------------------------------------------------
export type OutOfLivesPopupOptions = {
    lifeTimeLeft: () => number;
} & BasicPopupOptions;

const manifest = {
    life: 'icon.life.large.png',
    button: 'button.red.small.png',
    frame: 'frame.home.item.png',
};

/*
    Out of lives popup
*/
export class OutOfLivesPopup extends BasicPopup {
    private _timer: BasicText;
    private _lifeTimeLeft: () => number;

    public override preload() {
        return [...super.preload(), ...app.resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: OutOfLivesPopupOptions) {
        super.spawning({
            width: 690,
            height: HEIGHT,
            underlay: 0.7,
            ...options,
        });

        this._lifeTimeLeft = options.lifeTimeLeft;

        const title = new BasicText({
            text: '[popupNoLivesTitle]',
            style: {
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowDistance: 5,
                dropShadowColor: 0x0c7c7c7,
                fill: 0x00,
                fontSize: 50,
                fontWeight: 'bold',
                lineJoin: 'round',
                stroke: 0xdf886e,
                strokeThickness: 6,
            },
        });

        const heart = Sprite.from(manifest.life);

        const zero = new BasicText({
            text: '0',
            style: {
                fill: 0xffffff,
                stroke: 0x05b001c,
                strokeThickness: 2,
                fontSize: 80,
                fontWeight: 'bold',
            },
        });

        heart.addChild(zero);
        uiAlignCenter(heart, zero, 0, -6);

        const timer = this._createTimer();

        // spawn
        this.main.addContent({
            title: {
                content: title,
                styles: {
                    position: 'topCenter',
                    marginTop: 90,
                },
            },
            heart: {
                content: heart,
                styles: {
                    position: 'topCenter',
                    marginTop: 210,
                },
            },
            timer: {
                content: timer,
                styles: {
                    position: 'topCenter',
                    marginTop: 390,
                },
            },
        });
    }

    public override async spawned(): Promise<void> {
        await super.spawned();
    }

    public override async despawned() {
        super.despawned();
    }

    private _createTimer() {
        const frame = new NineSlicePlane(Texture.from(manifest.frame), 28, 28, 28, 28);
        frame.width = 338;
        frame.height = 56;

        this._timer = new BasicText({
            text: ``,
            style: {
                fill: 0x915735,
                fontSize: 28,
                fontWeight: 'bold',
                lineJoin: 'round',
            },
        });

        frame.addChild(this._timer);

        const updateTimer = () => {
            const remaining = this._lifeTimeLeft();
            const parsed = timeFormatCountdown(timeToComponents(remaining > 0 ? remaining : 0));
            this._timer.text = `[popupNoLivesNext|${parsed}]`;
            uiSizeToFit(this._timer, 300, 60);
            uiAlignCenter(frame, this._timer, 0, -6);
        };

        updateTimer();
        pixiSetInterval(
            frame,
            () => {
                updateTimer();
            },
            1,
        );

        return frame;
    }
}
