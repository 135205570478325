import { Vector2 } from '@play-co/odie';
import type { Container } from 'pixi.js';

import { SizeType } from '../../../../lib/defs/types';
import { Graph } from '../../../../lib/pattern/Graph';
import { numberClamp } from '../../../../replicant/util/mathTools';
import { ComboGlowAnimation } from '../animations/ComboGlowAnimation';
import type { Cell, MapComponent } from '../components/MapComponent';
import { BlockId, blockPropsMap, DirectionId } from '../defs/block';
import { config } from '../defs/config';
import type { BlockEntity } from '../entities/BlockEntity';
import { GameScene } from '../GameScene';
import { AndroidBlockView } from '../views/blocks/AndroidBlockView';
import { AnimalBlockView } from '../views/blocks/AnimalBlockView';
import { BasicBlockView } from '../views/blocks/BasicBlockView';
import { BombBlockView } from '../views/blocks/BombBlockView';
import { CageBlockView } from '../views/blocks/CageBlockView';
import { CandleBlockView } from '../views/blocks/CandleBlockView';
import { ChainBlockView } from '../views/blocks/ChainBlockView';
import { ChameleonBlockView } from '../views/blocks/ChameleonBlockView';
import { CoffinBlockView } from '../views/blocks/CoffinBlockView';
import { CubeBlockView } from '../views/blocks/CubeBlockView';
import { DogBlockView } from '../views/blocks/DogBlockView';
import { DoorBlockView } from '../views/blocks/DoorBlockView';
import { FireboxBlockView } from '../views/blocks/FireboxBlockView';
import { FuguBlockView } from '../views/blocks/FuguBlockView';
import { FullIceBlockView } from '../views/blocks/FullIceBlockView';
import { HatBlockView } from '../views/blocks/HatBlockView';
import { HazardBlockView } from '../views/blocks/HazardBlockView';
import { KappaBlockView } from '../views/blocks/KappaBlockView';
import { KettleBlockView } from '../views/blocks/KettleBlockView';
import { LockBlockView } from '../views/blocks/LockBlockView';
import { MiniFuguBlockView } from '../views/blocks/MiniFuguBlockView';
import { MysteryBlockView } from '../views/blocks/MysteryBlockView';
import { PantherBlockView } from '../views/blocks/PantherBlockView';
import { PipeBlockView } from '../views/blocks/PipeBlockView';
import { PrisonBlockView } from '../views/blocks/PrisonBlockView';
import { RocketBlockView } from '../views/blocks/RocketBlockView';
import { SirenBlockView } from '../views/blocks/SirenBlockView';
import { SkullBlockView } from '../views/blocks/SkullBlockView';
import { SlimeBlockView } from '../views/blocks/SlimeBlockView';
import { SpiderBlockView } from '../views/blocks/SpiderBlockView';
import { StatueBlockView } from '../views/blocks/StatueBlockView';
import { ThrowerBlockView } from '../views/blocks/ThrowerBlockView';
import { VirusBlockView } from '../views/blocks/VirusBlockView';
import { WallBlockView } from '../views/blocks/WallBlockView';
import { ZombieBlockView } from '../views/blocks/ZombieBlockView';

// types
//-----------------------------------------------------------------------------
export type BlockIterator = (base: BlockEntity, overlay?: BlockEntity) => boolean | void;

// info
//-----------------------------------------------------------------------------
export function blockIsFrozen(scene: GameScene, entity: BlockEntity) {
    const overlayEntity = blockGetCell(scene, entity)?.overlay?.entity;

    // frozen if same cell has overlay entity with freeze property
    return overlayEntity && entity !== overlayEntity && overlayEntity.c.block.props.freeze;
}

export function blockGetCell(scene: GameScene, entity: BlockEntity): Cell | undefined {
    return scene.sessionEntity.c.map.getCellAt(entity.c.position.mapPosition);
}

// view
//-----------------------------------------------------------------------------
export function blockUpdateDirection(view: Container, direction: DirectionId) {
    // update sprite orientation
    switch (direction) {
        case 'left':
            view.scale.x = -1;
            break;
        case 'right':
            view.scale.x = 1;
            break;
        case 'up':
            view.rotation = -Math.PI / 2;
            break;
        case 'down':
            view.rotation = Math.PI / 2;
            break;
    }
}

export function blockPositionView(view: Container, size: SizeType) {
    // pivot
    view.pivot.set(view.width / 2, view.height / 2);

    // position
    view.x = ((size.width || 1) * config.tile.size) / 2;
    view.y = ((size.height || 1) * config.tile.size) / 2;
    view.x += 9;
    view.y += 8;
}

//TODO: review and consolidate
export function blockPositionView2(view: Container, size: SizeType) {
    view.x = ((size.width || 1) * config.tile.size) / 2;
    view.y = ((size.height || 1) * config.tile.size) / 2;
}

export function blockViewAssets(id: BlockId, effects?: boolean): string[] {
    const props = blockPropsMap[id];
    switch (props.type) {
        case 'android':
            return AndroidBlockView.assets();
        case 'animal':
            return AnimalBlockView.assets();
        case 'basic':
            return BasicBlockView.assets(props, effects);
        case 'bomb':
            return [...BombBlockView.assets(effects), ...(effects ? ComboGlowAnimation.assets() : [])];
        case 'cage':
            return CageBlockView.assets();
        case 'candle':
            return CandleBlockView.assets();
        case 'chain':
            return ChainBlockView.assets();
        case 'chameleon':
            return ChameleonBlockView.assets();
        case 'coffin':
            return CoffinBlockView.assets();
        case 'cube':
            return [...CubeBlockView.assets(props, effects), ...(effects ? ComboGlowAnimation.assets() : [])];
        case 'dog':
            return DogBlockView.assets();
        case 'door':
            return DoorBlockView.assets(props);
        case 'firebox':
            return FireboxBlockView.assets(effects);
        case 'fugu':
            return FuguBlockView.assets();
        case 'hat':
            return HatBlockView.assets(props);
        case 'ice':
            return FullIceBlockView.assets();
        case 'hazard':
            return HazardBlockView.assets(effects);
        case 'kappa':
            return KappaBlockView.assets();
        case 'kettle':
            return KettleBlockView.assets();
        case 'lock':
            return LockBlockView.assets();
        case 'miniFugu':
            return MiniFuguBlockView.assets();
        case 'mystery':
            return MysteryBlockView.assets(props);
        case 'panther':
            return PantherBlockView.assets(props);
        case 'pipe':
            return PipeBlockView.assets();
        case 'prison':
            return PrisonBlockView.assets();
        case 'rocket':
            return [...RocketBlockView.assets(effects), ...(effects ? ComboGlowAnimation.assets() : [])];
        case 'siren':
            return SirenBlockView.assets();
        case 'skull':
            return SkullBlockView.assets();
        case 'slime':
            return SlimeBlockView.assets();
        case 'spider':
            return SpiderBlockView.assets();
        case 'statue':
            return StatueBlockView.assets();
        case 'thrower':
            return ThrowerBlockView.assets();
        case 'virus':
            return VirusBlockView.assets();
        case 'wall':
            return WallBlockView.assets(props);
        case 'zombie':
            return ZombieBlockView.assets();
        case 'randomSpawn':
            return [];
        default:
            throw Error();
    }
}

// options
//-----------------------------------------------------------------------------
//TODO: refactor, this way too funky
export function blockOptionFromWall(count: number, width: number, height: number): number {
    return count * 100 + width * 10 + height;
}
export function blockOptionToWall(option = 0): {
    count: number;
    size: SizeType;
} {
    const { count, size } = config.blocks.wall;
    return {
        count: numberClamp(Math.floor(option / 100), count.min, count.max),
        size: {
            width: numberClamp(Math.floor(option / 10) % 10, size.min, size.max),
            height: numberClamp(option % 10, size.min, size.max),
        },
    };
}

// iteration
//-----------------------------------------------------------------------------
export function blockIterateAll(scene: GameScene, handler: BlockIterator): boolean {
    const map = scene.sessionEntity.c.map;

    // for each cell in map
    for (let column = 0; column < map.columns; ++column) {
        for (let row = 0; row < map.rows; ++row) {
            const cell = map.grid[column][row];

            // get base block entity
            const entity = cell.base?.entity;

            // if exists, handle it. end if handler succeeds.
            if (entity && handler(entity, cell.overlay?.entity)) return true;
        }
    }

    return false;
}

export function blockIterateNeighbors(map: MapComponent, startEntity: BlockEntity, handler: BlockIterator) {
    const visited: Cell[] = [];

    // recurse on given block entity
    _blockIterateNeighborsR(map, handler, visited, startEntity.c.position.mapPosition);
}
function _blockIterateNeighborsR(map: MapComponent, handler: BlockIterator, visited: Cell[], position: Vector2) {
    // get cell at this position
    const cell = map.getCellAt(position);

    // skip if no base entity, if already visited, or if handling fails
    if (!cell?.base || visited.includes(cell) || !handler(cell.base.entity, cell.overlay?.entity)) {
        return;
    }

    // add to visited
    visited.push(cell);

    // recurse left
    _blockIterateNeighborsR(map, handler, visited, new Vector2(position.x - 1, position.y));
    // recurse right
    _blockIterateNeighborsR(map, handler, visited, new Vector2(position.x + 1, position.y));
    // recurse up
    _blockIterateNeighborsR(map, handler, visited, new Vector2(position.x, position.y - 1));
    // recurse down
    _blockIterateNeighborsR(map, handler, visited, new Vector2(position.x, position.y + 1));
}

export function blockIterateNeighborsToGraph<T extends BlockEntity>(
    mapComponent: MapComponent,
    startEntity: T,
    predicate: BlockIterator,
): Graph<T> {
    const graph: Graph<T> = new Graph<T>((entity) => entity.name);

    // recurse on given block entity
    _blockIterateNeighborsToGraphR(mapComponent, predicate, startEntity.c.position.mapPosition, graph);

    return graph;
}
function _blockIterateNeighborsToGraphR(
    mapComponent: MapComponent,
    predicate: BlockIterator,
    position: Vector2,
    graph: Graph<BlockEntity>,
    lastEntity: BlockEntity = null,
) {
    // get block entity at this position
    const entity = mapComponent.getCellAt(position)?.base?.entity;

    // skip if no block entity or if predicate returns falsy
    if (!entity || !predicate(entity)) {
        return;
    }

    if (graph.hasNodeForObject(entity)) {
        // no need to iterate if already visited, but make sure nodes are connected
        if (lastEntity) {
            graph.addEdge(lastEntity, entity);
        }

        return;
    }

    // add to visited
    if (lastEntity) {
        graph.addEdge(lastEntity, entity);
    } else {
        graph.addVertex(entity);
    }

    // recurse left
    _blockIterateNeighborsToGraphR(mapComponent, predicate, new Vector2(position.x - 1, position.y), graph, entity);
    // recurse right
    _blockIterateNeighborsToGraphR(mapComponent, predicate, new Vector2(position.x + 1, position.y), graph, entity);
    // recurse up
    _blockIterateNeighborsToGraphR(mapComponent, predicate, new Vector2(position.x, position.y - 1), graph, entity);
    // recurse down
    _blockIterateNeighborsToGraphR(mapComponent, predicate, new Vector2(position.x, position.y + 1), graph, entity);
}

/**
 * Return just the 4 surrounding nearest neighbours, in the order top, right, bottom, left
 * If the handler predicate returns false, then that array element is undefined
 */
export function blockNearestNeighbors(
    mapComponent: MapComponent,
    startEntity: BlockEntity,
    handler: BlockIterator,
): BlockEntity[] {
    const position = startEntity.c.position.mapPosition;

    const topEntity = mapComponent.getCellAt(new Vector2(position.x, position.y - 1))?.base?.entity;
    const rightEntity = mapComponent.getCellAt(new Vector2(position.x + 1, position.y))?.base?.entity;
    const bottomEntity = mapComponent.getCellAt(new Vector2(position.x, position.y + 1))?.base?.entity;
    const leftEntity = mapComponent.getCellAt(new Vector2(position.x - 1, position.y))?.base?.entity;

    // Don't change the order!
    return [topEntity, rightEntity, bottomEntity, leftEntity].map((entity) => {
        if (entity && handler(entity)) {
            return entity;
        }
        return undefined;
    });
}
