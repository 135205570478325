import type { Component, Entity2D } from '@play-co/odie';

import { PipeBlockProps } from '../defs/block';
import { PipeBlockView } from '../views/blocks/PipeBlockView';

// types
//-----------------------------------------------------------------------------
export type PipeBlockComponentOptions = {
    props: PipeBlockProps;
};

/*
    pipe block component
*/
export class PipeBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockPipe';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: PipeBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: PipeBlockComponentOptions): void {
        // create pipe block view
        this.view = new PipeBlockView(options.props);
    }
}
