import { BlockId, blockPropsMap } from '../../../main/match2-odie/defs/block';
import { MapDef } from '../../../main/match2-odie/defs/map';
import { MysteryItemRewardId, mysteryItemRewardPropsMap } from '../../../main/match2-odie/defs/reward';
import { mapCellFormat, mapFormat, mapGridFormat } from './defs';

/*
    puzzle map exporter into a compressed format meant for backend storage
*/
export class PuzzleMapExporter {
    // fields
    //-------------------------------------------------------------------------
    private readonly _mapDef: MapDef;

    // init
    //-------------------------------------------------------------------------
    constructor(mapDef: MapDef) {
        // set fields
        this._mapDef = mapDef;
    }

    // api
    //-------------------------------------------------------------------------
    public export(): string {
        // join encoded groups
        return [
            // moves
            this._encodeMoves(),
            // goals
            this._encodeGoals(),
            // rewards
            this._encodeRewards(),
            // spawns
            this._encodeSpawns(),
            // grid
            this._encodeGrid(),
        ].join('');
    }

    // private: encode
    //-----------------------------------------------------------------------------
    private _encodeMoves(): string {
        // return moves block
        return this._encodeIdNumber(mapFormat.moves, this._mapDef.moves);
    }

    private _encodeGoals(): string {
        // encode goals
        const goals = Object.entries(this._mapDef.goals)
            .map(([id, count]) => this._encodeIdNumber(blockPropsMap[id as BlockId].id, count))
            .join('');

        // return goals block
        return this._encodeIdGroup(mapFormat.goals, goals);
    }

    private _encodeRewards(): string {
        // encode rewards
        const rewards = Object.entries(this._mapDef.rewards)
            .map(([id, def]) =>
                this._encodeIdNumber(
                    mysteryItemRewardPropsMap[id as MysteryItemRewardId].id,
                    Math.round(def.odds * 100),
                ),
            )
            .join('');

        // return rewards block
        return this._encodeIdGroup(mapFormat.rewards, rewards);
    }

    private _encodeSpawns(): string {
        // encode spawns
        const spawns = this._mapDef.spawns.map((id) => blockPropsMap[id].id).join(',');

        // return spawns block
        return this._encodeIdGroup(mapFormat.spawns, spawns);
    }

    private _encodeGrid(): string {
        const gridDef = this._mapDef.grid;

        // encode grid
        const grid = [
            // columns
            this._encodeIdNumber(mapGridFormat.columns, gridDef.columns),
            // rows
            this._encodeIdNumber(mapGridFormat.rows, gridDef.rows),
            // cells
            this._encodeGridCells(),
        ].join('');

        // return grid block
        return this._encodeIdGroup(mapFormat.grid, grid);
    }

    private _encodeGridCells(): string {
        const cellsDef = this._mapDef.grid.cells;

        // encode cells
        const cells = cellsDef
            .map((columns) =>
                columns
                    .map((cell) => {
                        // if cell has blocks
                        if (cell.blocks?.length > 0) {
                            // for each block in the cell
                            return cell.blocks
                                .map((block) => {
                                    const id = blockPropsMap[block.id].id;

                                    return block.option !== undefined ? this._encodeIdNumber(id, block.option) : id;
                                })
                                .join('|');
                        }

                        // else return tile or empty cell
                        return cell.enabled ? mapCellFormat.tile : '';
                    })
                    .join(','),
            )
            .join(',');

        // return cells block
        return this._encodeIdGroup(mapGridFormat.cells, cells);
    }

    // private: encode util
    //-----------------------------------------------------------------------------
    private _encodeIdNumber(id: string, n: number): string {
        return id + n;
    }

    private _encodeIdGroup(id: string, group: string): string {
        return `${id}[${group}]`;
    }
}
