import app from '../../../index.entry';
import { IFlow } from '../../../lib/pattern/IFlow';
import NakedPromise from '../../../lib/pattern/NakedPromise';
import bakery from '../../../replicant/defs/bakery';
import { trackUnlockRecipe } from '../../lib/analytics/bakery';
import { HomeScreen } from '../../main/home/HomeScreen';
import { CustomerFlow } from './CustomerFlow';

export class CustomerEnterFlow extends CustomerFlow implements IFlow {
    // init
    //-------------------------------------------------------------------------
    constructor(opts: { screen: HomeScreen }) {
        super(opts);
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._enterAction();

        return this._complete;
    }

    private async _enterAction() {
        const entryId = app.server.state.bakery.newCustomers[0];
        this._preloadCustomer(entryId);

        await this._screen.toggleScripted({ scripted: true });

        this._screen.playPlayerAnimation('happy', true);
        // welcome/init message
        const welcomePromise = new NakedPromise();
        await this._screen.spawnBubbleTap({ speech: `[dialogWelcomeChef${entryId}]` }, welcomePromise);
        await welcomePromise;
        await this._screen.spawnDoorAnimation();

        await this._loadPromise;

        const extraCallBacks = {
            2: () => this._screen.playPlayerAnimation('excited', true),
        };

        const customerData = bakery.customers[entryId];
        const { cakeId, enteringDialogs } = customerData;

        await this._speechSequence({ entryId, dialogs: enteringDialogs, extraCallBacks });
        await app.server.invoke.consumeCustomer();

        await this._screen.toggleScripted({ scripted: false });

        trackUnlockRecipe(cakeId);
        this._actionComplete(true);
    }
}
