import { Container, Graphics, Sprite, Texture, Ticker } from 'pixi.js';

import { PositionType } from '../../../../lib/defs/types';
import { Vector2 } from '../../../../lib/math/Vector2';
import { Gesture, GestureInputComponent } from '../../../../lib/pixi/components/GestureInputComponent';
import { TouchInputComponent } from '../../../../lib/pixi/components/TouchInputComponent';
import { pixiEmitTree, pixiGetDt, pixiPointToVector } from '../../../../lib/pixi/pixiTools';
import { uiCreateMask } from '../../../../lib/pixi/uiTools';
import { tween } from '../../../../lib/util/tweens';
import { numberClamp } from '../../../../replicant/util/mathTools';

// settings
//-----------------------------------------------------------------------------
const panDrag = 3; // pan drag
const panSpeedMin = 140; // min pan speed to activate/deactivate pan state
const panSpeedMax = 90; // max pan speed

// types
//-----------------------------------------------------------------------------
export type ScrollDirection = 'any' | 'down' | 'right';
export type ScrollBoxOptions = {
    width: number;
    height: number;
    direction: ScrollDirection;
};

/*
    ui: scroll box
*/
export class ScrollBox extends Container {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _direction: ScrollDirection;
    // scene
    private _content: Container;
    private _mask0: Graphics;
    // state
    private _panning = false;
    private _velocity = new Vector2(0, 0);
    private _input = true;
    // handlers
    private _stepHandler = this._step.bind(this);
    // components
    private _inputComponent: TouchInputComponent;
    private _gestureComponent: GestureInputComponent;

    // properties
    //-------------------------------------------------------------------------
    public get content(): Container {
        return this._content;
    }

    // if input is enabled
    public get input(): boolean {
        return this._input;
    }

    public set input(value: boolean) {
        this._input = value;
    }

    public get direction(): ScrollDirection {
        return this._direction;
    }

    public get boxWidth(): number {
        return this._mask0.width;
    }

    public get boxHeight(): number {
        return this._mask0.height;
    }

    public get panWidth(): number {
        return this._direction !== 'down' ? Math.max(this._content.width - this.boxWidth, 0) : 0;
    }

    public get panHeight(): number {
        return this._direction !== 'right' ? Math.max(this._content.height - this.boxHeight, 0) : 0;
    }

    public override get width(): number {
        return this.boxWidth;
    }

    public override get height(): number {
        return this.boxHeight;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: ScrollBoxOptions) {
        super();

        // set fields
        this._direction = options.direction;

        // spawn mask
        const mask = (this._mask0 = uiCreateMask(options.width, options.height));
        this.addChild(mask);

        // spawn dummy to make input work
        this.addChild(
            Sprite.from(Texture.WHITE).props({
                width: this.width,
                height: this.height,
                alpha: 0,
            }),
        );

        // spawn content container
        const content = (this._content = new Container());
        content.mask = mask;
        this.addChild(content);

        // initialize components
        this._inputComponent = new TouchInputComponent(this);
        this._gestureComponent = new GestureInputComponent(this._inputComponent);

        // register events
        this._gestureComponent.onGesture = this._onGesture.bind(this);
        Ticker.system.add(this._stepHandler);
    }

    // api
    //-------------------------------------------------------------------------
    public async scroll(position: PositionType, duration = 0, contain = false) {
        const content = this._content;
        const scale = content.scale.x;

        // reset any active panning
        this._panReset();

        // solve new position
        let newPosition = Vector2.from(position).multiplyScalar(scale).negate();

        // if changing
        if (!newPosition.equals(Vector2.from(content.position))) {
            // contain position
            if (contain) newPosition = this._clampPosition(newPosition);

            // animate to requested view position
            await this.animate().add(content.position, newPosition, duration, tween.pow2Out);
        }
    }

    public async zoom(scale: number, duration = 0, contain = false) {
        const content = this._content;
        const oldScale = content.scale.x;

        // solve half size (center of view)
        const hsize = new Vector2(this.boxWidth, this.boxHeight).divideScalar(2).multiplyScalar(1 - scale / oldScale);

        // solve final position
        let position = Vector2.from(content.position)
            .multiplyScalar(scale / oldScale)
            .add(hsize)
            .round();

        // contain position
        if (contain) position = this._clampPosition(position);

        // reset any active panning
        this._panReset();

        // animate to requested view position
        return content
            .animate()
            .add(content.scale, { x: scale, y: scale }, duration, tween.pow3InOut)
            .and(content.position, position, tween.pow3InOut);
    }

    // protected: overidables
    //-------------------------------------------------------------------------
    protected onStep(position: PositionType) {}

    // private: init
    //-------------------------------------------------------------------------
    private _panReset() {
        // if panning
        if (this._panning) {
            // reset panning
            this._panning = false;

            // reset velocity
            this._velocity.set(0);
        }
    }

    // private: step
    //-------------------------------------------------------------------------
    private _step() {
        // if in scene
        if (this.inScene()) {
            // if panning, do pan step
            if (this._panning) this._stepPan();

            // handle
            this.onStep(this._content.position);
        } else {
            // unregister stepper
            Ticker.system.remove(this._stepHandler);
        }
    }

    private _stepPan() {
        const dt = pixiGetDt();
        const position = pixiPointToVector(this._content.position);

        // solve frame velocity
        const velocity = this._velocity.clone().multiplyScalar(dt);

        // cap speed
        const speed = velocity.length();
        if (speed >= panSpeedMax) {
            this._velocity.multiplyScalar(panSpeedMax / speed);
        }

        // increment position
        position.add(this._velocity.clone().multiplyScalar(dt));

        // clamp position
        const panPosition = this._clampPosition(position);

        // update content position
        this._content.position = panPosition;

        // apply drag to velocity
        this._velocity.multiplyScalar(1 - panDrag * dt);

        // update panning state
        this._panningUpdate();
    }

    // private: updaters
    //-------------------------------------------------------------------------
    private _panningUpdate() {
        // get current pan speed
        const speed = this._velocity.length();

        // if panning
        if (this._panning) {
            // if speed below threshold, reset panning
            if (speed < panSpeedMin) this._panReset();
        }
        // else if speed over threshold
        else if (speed >= panSpeedMin) {
            // enable panning
            this._panning = true;
        }
    }

    // private: events
    //-------------------------------------------------------------------------
    private _onGesture(gesture: Gesture) {
        // if input allowed
        if (this._input) {
            // get velocity from gesture vector
            this._velocity = gesture.motion.clone().multiplyScalar(Ticker.system.FPS);

            // update panning state
            this._panningUpdate();

            // disable input from children while gesturing and panning
            this._content.interactiveChildren = !(gesture.down && this._panning);

            // on gesture up emulate the otherwise suppressed pointer up event
            // also send gesture.last as global so TouchInputComponent can correctly handle it
            if (!gesture.down) pixiEmitTree(this._content, 'pointerup', { global: gesture.last });
        }
    }

    // private: support
    //-------------------------------------------------------------------------
    private _clampPosition(position: PositionType): Vector2 {
        return new Vector2(numberClamp(position.x, -this.panWidth, 0), numberClamp(position.y, -this.panHeight, 0));
    }
}
