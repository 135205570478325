import { Application, Plugin, PluginOptions } from '@play-co/astro';

import { arrayReverseIterate } from '../../../replicant/util/jsTools';
import { pixiGetDt } from '../pixiTools';
import { ParticleEmitter, ParticleEmitterOptions } from './ParticleEmitter';

// types
//-----------------------------------------------------------------------------
// public
export type ParticlesPluginOptions = PluginOptions;

// private
type EmitterEntry = {
    emitter: ParticleEmitter;
    keepAlive?: boolean;
};

/*
    particle emitter
*/
export class ParticlesPlugin extends Plugin<ParticlesPluginOptions> {
    // fields
    //-------------------------------------------------------------------------
    // state
    private readonly _emitters: EmitterEntry[] = [];

    // init
    //-------------------------------------------------------------------------
    constructor(app: Application, options?: ParticlesPluginOptions) {
        super(app, options);
    }

    // impl
    //-------------------------------------------------------------------------
    public update(): void {
        // get dt
        const dt = pixiGetDt();

        // step emitters
        this._stepEmitters(dt);

        // log emitter leaks
        //console.log('ParticlesPlugin.emitters', this._emitters.length);
    }

    // api
    //-------------------------------------------------------------------------
    public create(options: ParticleEmitterOptions, keepAlive?: boolean): ParticleEmitter {
        // create
        const emitter = new ParticleEmitter(options);

        // add emitter
        this._emitters.push({ emitter, keepAlive });

        return emitter;
    }

    public destroy(emitter: ParticleEmitter) {
        // get index
        const index = this._emitters.findIndex((entry) => emitter === entry.emitter);
        if (index >= 0) this._destroyEntry(index);
    }

    // private: stppers
    //-------------------------------------------------------------------------
    private _stepEmitters(dt: number) {
        // step emitters, reverse iterate to allow safe removal
        arrayReverseIterate(this._emitters, (entry, i) => {
            const emitter = entry.emitter;
            // if completed
            if (emitter.completed) {
                // destroy if not keeping alive
                if (!entry.keepAlive) {
                    this._destroyEntry(i);
                }
                // else step
            } else {
                emitter.step(dt);
            }
        });
    }

    // private: support
    //-------------------------------------------------------------------------
    public _destroyEntry(index: number) {
        // complete emitter
        this._emitters[index].emitter.complete();

        // remove from list
        this._emitters.splice(index, 1);
    }
}
