export type IdGetter<T> = (object: T) => string;

export class GraphNode<T> {
    private readonly _id: string;
    private readonly _object: T;
    private _adjacents: GraphNode<T>[] = [];

    constructor(id: string, object: T) {
        this._id = id;
        this._object = object;
    }

    public addAdjacent(node: GraphNode<T>) {
        if (this.isAdjacent(node)) {
            return;
        }
        this._adjacents.push(node);
    }

    public removeAdjacent(node: GraphNode<T>): GraphNode<T> {
        const index = this._adjacents.indexOf(node);
        if (index > -1) {
            this._adjacents.splice(index, 1);
            return node;
        }
        return undefined;
    }

    public get id() {
        return this._id;
    }

    public get object() {
        return this._object;
    }

    public get adjacents() {
        return this._adjacents;
    }

    public isAdjacent(node: GraphNode<T>) {
        return this.adjacents.includes(node);
    }
}

export class Graph<T> {
    private _nodes: Map<string, GraphNode<T>> = new Map();
    private readonly _idGetter: IdGetter<T>;

    constructor(getValue: IdGetter<T>) {
        this._idGetter = getValue;
    }

    hasNodeForObject(object: T): boolean {
        return this._nodes.has(this._idGetter(object));
    }

    getNodeForObject(object: T): GraphNode<T> {
        return this._nodes.get(this._idGetter(object));
    }

    addVertex(object: T): GraphNode<T> {
        const key = this._idGetter(object);
        let node;

        if (this._nodes.has(key)) {
            node = this._nodes.get(key);
        } else {
            node = new GraphNode<T>(key, object);
            this._nodes.set(key, node);
        }

        return node;
    }

    removeVertex(object: T) {
        const key = this._idGetter(object);

        const current = this._nodes.get(key);
        if (current) {
            for (const node of this._nodes.values()) {
                node.removeAdjacent(current);
            }
        }
        return this._nodes.delete(key);
    }

    addEdge(source: T, destination: T): [GraphNode<T>, GraphNode<T>] {
        const sourceNode = this.addVertex(source);
        const destinationNode = this.addVertex(destination);

        sourceNode.addAdjacent(destinationNode);
        destinationNode.addAdjacent(sourceNode);

        return [sourceNode, destinationNode];
    }

    removeEdge(source: T, destination: T): [GraphNode<T>, GraphNode<T>] {
        const sourceNode = this._nodes.get(this._idGetter(source));
        const destinationNode = this._nodes.get(this._idGetter(destination));

        if (sourceNode && destinationNode) {
            sourceNode.removeAdjacent(destinationNode);
            destinationNode.removeAdjacent(sourceNode);
        }

        return [sourceNode, destinationNode];
    }

    toArray(): T[] {
        return Array.from(this._nodes.values(), (node) => node.object);
    }
}
