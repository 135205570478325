import { Sprite, Texture } from 'pixi.js';

import { RocketEffect } from '../../actions/effects/RocketEffect';
import { RocketBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    horizontal: 'block.rocket.horizontal.png',
    vertical: 'block.rocket.vertical.png',
};

/*
    rocket block view
*/
export class RocketBlockView extends Sprite {
    // init
    //-------------------------------------------------------------------------
    constructor(props: RocketBlockProps) {
        // create base view
        super(Texture.from(props.id === 'rh' ? manifest.horizontal : manifest.vertical));
        blockPositionView(this, props);
    }

    static assets(effects: boolean): string[] {
        return [...Object.values(manifest), ...(effects ? RocketEffect.assets() : [])];
    }
}
