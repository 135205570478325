import { analytics } from '@play-co/gcinstant';

import app from '../../../index.entry';
import { CakeItemId } from '../../../replicant/defs/items';

export function trackPlayerTap() {
    app.analytics.sessionTaps++;
    analytics.pushEvent('TapCharacter', { sessionTaps: app.analytics.sessionTaps });
}

export function trackUnlockRecipe(cakeName: CakeItemId) {
    analytics.pushEvent('UnlockRecipe', { cakeName });
}
export function trackCompleteRecipe(cakeName: CakeItemId) {
    analytics.pushEvent('CompleteRecipe', { cakeName });
}

export function trackRecipeStepStart(opts: { cakeName: CakeItemId; step: number; type: 'instant' | 'time_consuming' }) {
    analytics.pushEvent('RecipeStepStart', opts);
}

export function trackRecipeStepFinish(opts: {
    cakeName: CakeItemId;
    step: number;
    type: 'instant' | 'time_consuming';
}) {
    analytics.pushEvent('RecipeStepFinish', opts);
}
