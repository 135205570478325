// boosters

//import { ResourceId } from './resources';

//-----------------------------------------------------------------------------
export const boosterIds = ['dart', 'bullet', 'drill', 'roulette'] as const;
export type BoosterId = (typeof boosterIds)[number];
export type BoosterEntry = {
    count: number;
};
export type BoosterCollection = { [key in BoosterId]?: BoosterEntry };

// maps
//-----------------------------------------------------------------------------
export const boosterMap: {
    [key in BoosterId]: {
        text: string;
        asset: string;
        tip: string;
        cost: number; // in gold
    };
} = {
    dart: {
        text: '[boosterDart]',
        tip: '[tipBoosterDart]',
        asset: 'icon.dart.png',
        cost: 40,
    },
    bullet: {
        text: '[boosterBullet]',
        tip: '[tipBoosterBullet]',
        asset: 'icon.bullet.png',
        cost: 80,
    },
    drill: {
        text: '[boosterDrill]',
        tip: '[tipBoosterDrill]',
        asset: 'icon.drill.png',
        cost: 80,
    },
    roulette: {
        text: '[boosterRoulette]',
        tip: '[tipBoosterRoulette]',
        asset: 'icon.roulette.png',
        cost: 20,
    },
};

// helpers
//-----------------------------------------------------------------------------
// export function isResourceBooster(resourceId: ResourceId): boolean {
//     return !!boosterMap[resourceId as BoosterId];
// }
