import app from '../../index.entry';
import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import bakery from '../../replicant/defs/bakery';

export class MakeCakeFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();

    // init
    //-------------------------------------------------------------------------
    constructor() {}

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._makeCakeAction();

        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    private async _makeCakeAction() {
        const isCakePress = new NakedPromise<boolean>();
        app.nav.open('cakeListPopup', {
            cakes: bakery.defaultCakePopupList,
            onCakePress: () => isCakePress.resolve(true),
            onClose: () => isCakePress.resolve(false),
        });
        const isCake = await isCakePress;
        app.nav.close('cakeListPopup');

        if (isCake) {
            const infoId = 'infoPopup';
            const tapPromise = new NakedPromise();
            app.nav.open(infoId, { height: 380, infoText: '[popupCakeListInfo]', onOk: () => tapPromise.resolve() });
            await tapPromise;
            app.nav.close(infoId);
        }

        this._actionComplete(true);
    }
}
