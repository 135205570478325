import { Sprite } from 'pixi.js';

import app from '../../../../index.entry';
import { uiAlignCenter } from '../../../../lib/pixi/uiTools';
import { LevelPopup, LevelPopupOptions } from './LevelPopup';

// types
//-----------------------------------------------------------------------------
export type LevelSuccessMaxPopupOptions = {} & LevelPopupOptions;

const manifest = {
    header: 'panel.level.header.png',
    character: 'icon.character.png',
    pattern2: 'icon.pattern2.png',
    wellDoneEN: 'label.well.done.en.png',
    wellDoneJA: 'label.well.done.ja.png',
};

/*
    Success popup for max level (no star reward)
*/
export class LevelSuccessMaxPopup extends LevelPopup {
    public override preload() {
        return [...super.preload(), ...app.resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: LevelSuccessMaxPopupOptions) {
        super.spawning({
            ...options,
            okButton: '[buttonContinue]',
        });

        const asset = app.settings.language === 'ja' ? manifest.wellDoneJA : manifest.wellDoneEN;
        const wellDone = Sprite.from(asset);
        wellDone.scale.set(0.33);
        const character = Sprite.from(manifest.character);
        const pattern = Sprite.from(manifest.pattern2);
        character.addChild(wellDone);
        pattern.addChild(character);

        uiAlignCenter(pattern, character, 0, -50);
        uiAlignCenter(character, wellDone, 0, 115);

        // spawn
        this.main.addContent({
            combined: {
                content: pattern,
                styles: {
                    position: 'centerTop',
                    marginTop: 300,
                },
            },
        });
    }

    public override async spawned(): Promise<void> {
        await super.spawned();
    }

    public override async despawned() {
        super.despawned();
    }
}
