import { Sprite } from 'pixi.js';

import app from '../../../../index.entry';
import { BasicAsyncHandler } from '../../../../lib/defs/types';
import { uiAlignCenter } from '../../../../lib/pixi/uiTools';
import { CakeItemId, cakeItemPropsMap } from '../../../../replicant/defs/items';
import { BasicText } from '../text/BasicText';
import { BasicPopup } from './BasicPopup';

const HEIGHT = 570;

// types
//-----------------------------------------------------------------------------
export type CakePopupOptions = {
    onOk: BasicAsyncHandler;
    cakeId: CakeItemId;
};

const manifest = {
    pattern: 'icon.pattern.png',
    button: 'button.red.small.png',
};

/*
    General cake done popup
*/
export class CakePopup extends BasicPopup {
    // TODO only load main cake asset
    public override preload() {
        return [
            ...super.preload(),
            ...app.resource.loadAssets([
                ...Object.values(manifest),
                ...Object.keys(cakeItemPropsMap).map((key: CakeItemId) => cakeItemPropsMap[key].icon),
            ]),
        ];
    }

    // @ts-ignore
    public override async spawning(options: CakePopupOptions) {
        super.spawning({
            width: 690,
            height: HEIGHT,
            underlay: 0.7,
            ...options,
        });

        // const doneText = new BasicText({
        //     text: '[complete]',
        //     style: {
        //         align: 'center',
        //         fill: '#28202A',
        //         fontSize: 52,
        //         fontWeight: 'bolder',
        //         lineJoin: 'round',
        //         wordWrap: true,
        //     },
        // });

        const cakeName = new BasicText({
            text: cakeItemPropsMap[options.cakeId].name,
            style: {
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowDistance: 5,
                dropShadowColor: 0x0c7c7c7,
                fill: 0x00,
                fontSize: 38,
                fontWeight: 'bold',
                lineJoin: 'round',
                stroke: 0xdf886e,
                strokeThickness: 4,
            },
        });

        const pattern = Sprite.from(manifest.pattern);
        const cake = Sprite.from(cakeItemPropsMap[options.cakeId].icon);

        pattern.addChild(cake);
        uiAlignCenter(pattern, cake, 0, -30);

        // spawn
        this.main.addContent({
            main: {
                content: pattern,
                styles: {
                    position: 'topCenter',
                    marginTop: 120,
                },
            },
            cake: {
                content: cakeName,
                styles: {
                    position: 'topCenter',
                    marginTop: 360,
                },
            },
        });
    }

    public override async spawned(): Promise<void> {
        await super.spawned();
    }

    public override async despawned() {
        super.despawned();
    }
}
