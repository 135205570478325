import { Assets } from 'pixi.js';

import { BulletAnimation } from '../../animations/BulletAnimation';
import { despawnOverlayEntity, spawnOverlayEntity } from '../../entities/OverlayEntity';
import { GameScene } from '../../GameScene';
import { CollisionTracker } from '../../util/CollisionTracker';
import { IEffect } from './IEffect';

// types
//-----------------------------------------------------------------------------
export type BulletEffectOptions = {
    row: number;
};

/*
    bullet booster effect
*/
export class BulletEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _row: number;
    // state
    private readonly _collisionTracker = new CollisionTracker();

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: BulletEffectOptions) {
        this._scene = scene;
        this._row = options.row;
    }

    static assets(): string[] {
        return BulletAnimation.assets();
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // load assets
        await Assets.load(BulletEffect.assets());

        // run bullet
        await this._runBullet();
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _runBullet() {
        // choose start position at given map row and above the map
        const position = { x: 0, y: this._row };

        // create animation
        const animation = new BulletAnimation({ position });

        // register events
        animation.onMove = (row) => this._onBulletCollide(row);

        // spawn animation as overlay entity
        const entity = spawnOverlayEntity(this._scene, position, { width: 1, height: 1 }, () => animation);

        // run animation
        await animation.start();

        // despawn entity
        despawnOverlayEntity(this._scene, entity);
    }

    // private: events
    //-------------------------------------------------------------------------
    private _onBulletCollide(column: number) {
        // attack any blocks at this position as a booster
        this._collisionTracker.collideAt(this._scene.sessionEntity.c.map, { x: column, y: this._row }, 'attack', {
            type: 'booster',
        });
    }
}
