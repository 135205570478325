import type { QueriesObject, QueryResults, System } from '@play-co/odie';

import { PrisonBlockComponent } from '../components/PrisonBlockComponent';
import { GameEvent } from '../defs/event';
import { PrisonBlockEntity } from '../entities/BlockEntity';
import type { GameScene } from '../GameScene';

/*
    handles hive block specifics
*/
export class HiveBlockSystem implements System {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'blockHive';
    public static Queries: QueriesObject = {
        hive: {
            components: [PrisonBlockComponent],
        },
    };

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    public queries!: QueryResults;
    // handlers
    private _gameEventHandler = (event: GameEvent) => this._onGameEvent(event);

    // impl
    //-------------------------------------------------------------------------
    public awake() {
        // notify
        this.scene.events.subscribe(this._gameEventHandler);
    }

    public reset() {
        // notify
        this.scene.events.unsubscribe(this._gameEventHandler);
    }

    // private: events
    //-------------------------------------------------------------------------
    private _onGameEvent(event: GameEvent) {
        // on prison goal complete, complete collide all hives
        if (event.id === 'goal' && event.goalId === 'prison' && event.count === 0) {
            this.queries.hive.forEach((entity: PrisonBlockEntity) => entity.c.block.collide('complete'));
        }
    }
}
