import type { Component, Entity2D, Vector2 } from '@play-co/odie';
import { Container, Sprite } from 'pixi.js';

import { arrayCreate2 } from '../../../../replicant/util/jsTools';
import { config } from '../defs/config';
import { MapDef } from '../defs/map';

// types
//-----------------------------------------------------------------------------
export type TileComponentOptions = {
    mapDef: MapDef;
    position: Vector2;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    center: 'tile.center.png',
    corner: 'tile.corner.png',
    edge: 'tile.edge.png',
    tip: 'tile.tip.png',
};

/*
    tile component
*/
export class TileComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'tile';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public container!: Container;

    // impl
    //-------------------------------------------------------------------------
    init(options: TileComponentOptions): void {
        // create container
        const container = (this.container = new Container());

        // get map from current position
        const map = this._createMap(options.mapDef, options.position);

        // if has center, only need center tile
        if (map[1][1]) {
            container.addChild(Sprite.from(manifest.center));
            // else spawn corners
        } else {
            // top left
            this._spawnCorner(map[0][1], map[0][0], map[1][0], 0);
            // top right
            this._spawnCorner(map[1][0], map[2][0], map[2][1], Math.PI / 2);
            // bottom right
            this._spawnCorner(map[2][1], map[2][2], map[1][2], Math.PI);
            // bottom left
            this._spawnCorner(map[1][2], map[0][2], map[0][1], (Math.PI * 3) / 2);
            this.container.sortChildren();
        }
    }

    static assets(): string[] {
        return Object.values(manifest);
    }

    // private: spawn
    //-------------------------------------------------------------------------
    private _spawnCorner(left: boolean, tip: boolean, right: boolean, angle: number) {
        // solve corner
        const corner = this._solveCornerImage(left, tip, right, angle);

        // if solved, spawn corner image
        if (corner) {
            this._spawnCornerImage(corner.image, corner.angle, corner.z);
        }
    }

    private _spawnCornerImage(image: string, angle: number, z: number) {
        // create sprite
        const sprite = Sprite.from(image);

        // anchor, position, and rotate
        sprite.anchor.set(0.5);
        sprite.position.set(config.tile.size / 2);
        sprite.rotation = angle;
        sprite.zIndex = z;

        // spawn sprite
        this.container.addChild(sprite);
    }

    // private: support
    //-------------------------------------------------------------------------
    private _createMap(mapDef: MapDef, position: Vector2): boolean[][] {
        const cells = mapDef.grid.cells;

        return arrayCreate2<boolean>(
            3,
            3,
            (column, row) => !!cells[position.x + column - 1]?.[position.y + row - 1]?.enabled,
        );
    }

    private _solveCornerImage(
        left: boolean,
        tip: boolean,
        right: boolean,
        angle: number,
    ): { image: string; angle: number; z: number } | undefined {
        // if both left and right, use combined corner image
        if (left && right) {
            return { image: manifest.corner, angle, z: 1 };
            // else if left or right
        } else if (left || right) {
            // if left, rotate left
            if (left) {
                angle -= Math.PI / 2;
            }
            return { image: manifest.edge, angle, z: -1 };
            // else if tip use tip
        } else if (tip) {
            return { image: manifest.tip, angle, z: 0 };
        }

        return undefined;
    }
}
