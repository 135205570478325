import app from '../../index.entry';
import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { livesGet } from '../../replicant/components/lives';
import gameConfig from '../../replicant/defs/gameConfig';
import { integerRandom } from '../../replicant/util/mathTools';
import { trackPuzzleStart } from '../lib/analytics/puzzle';
import { NoLivesFlow } from './NoLivesFlow';

/*
    puzzle play command
*/
export class PuzzlePlayFlow implements IFlow {
    private readonly _complete = new NakedPromise<{ forceScene?: 'homeScreen' }>();
    // impl
    //-------------------------------------------------------------------------
    async execute() {
        this._actionPlay();
        return this._complete;
    }

    private async _actionComplete(opts: { forceScene?: 'homeScreen' }) {
        this._complete.resolve(opts);
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _actionPlay() {
        const livesAvailable = livesGet(app.server.state, app.server.now());
        const level = app.server.state.puzzle.level;
        console.log(`Puzzle.Start: Lvl=${level} Lives=${livesAvailable}`);

        if (livesAvailable > 0) {
            const lastLevel = app.server.state.puzzle.lastLevel;
            let levelOverride;
            // if at last completed level is max, select random level
            // TODO discuss and adjust the flow and generate everything on backend if different puzzles beecomes valuable
            if (lastLevel >= gameConfig.puzzle.levels.max) {
                levelOverride = integerRandom(1, gameConfig.puzzle.levels.max, Math.random);
            }

            const playPromise = new NakedPromise<boolean>();
            const goalPopupId = 'levelGoalPopup';
            app.nav.open(goalPopupId, {
                level: levelOverride ? levelOverride : lastLevel + 1,
                isMaxLevel: !!levelOverride,
                underlay: 0.7,
                onOk: () => playPromise.resolve(true),
                onClose: () => playPromise.resolve(false),
            });

            const play = await playPromise;
            app.nav.close(goalPopupId);

            if (!play) {
                // let caller to open home screen if needed
                this._actionComplete({ forceScene: 'homeScreen' });
                return;
            }

            this._actionPuzzle(levelOverride);
        } else {
            await new NoLivesFlow().execute();
        }
        this._actionComplete({});
    }

    private async _actionPuzzle(levelOverride?: number) {
        // server begin puzzle
        await app.server.invoke.puzzleBegin({ levelOverride });

        trackPuzzleStart();

        // navigate to puzzle
        await app.nav.open('puzzleScreen');
    }
}
