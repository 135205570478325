import { Container } from 'pixi.js';

import { PuzzleGoalsPanel } from './PuzzleGoalsPanel';
import { PuzzleMovesPanel } from './PuzzleMovesPanel';

/*
    puzzle screen header
*/
export class PuzzleHeader extends Container {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _goals: PuzzleGoalsPanel;
    private _moves: PuzzleMovesPanel;

    // properties
    //-------------------------------------------------------------------------
    public get goals(): PuzzleGoalsPanel {
        return this._goals;
    }

    public get moves(): PuzzleMovesPanel {
        return this._moves;
    }

    // init
    //-------------------------------------------------------------------------
    constructor() {
        super();

        // spawn goals panel
        this._spawnGoals();

        // spawn moves panel
        this._spawnMoves();
    }

    static assets(): string[] {
        return [...PuzzleGoalsPanel.assets(), ...PuzzleMovesPanel.assets()];
    }

    // api
    //-------------------------------------------------------------------------
    public init() {
        // init goals
        this._goals.init();

        // sort z
        this.sortChildren();
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawnGoals() {
        this._goals = this.addChild(new PuzzleGoalsPanel());
        this._goals.y = 24;
        this._goals.zIndex = 1;
    }

    private _spawnMoves() {
        this._moves = this.addChild(new PuzzleMovesPanel());
        this._moves.y = 24;
        this._moves.x = 486;
        this._moves.zIndex = 1;
    }
}
