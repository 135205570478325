import { objectCreate } from '../../../../../replicant/util/jsTools';
import { BlockId, PowerBlockType, powerBlockTypes } from '../../defs/block';
import { BlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { blockIsFrozen, blockIterateNeighbors } from '../../util/blockTools';
import { BombBombComboEffect } from './BombBombComboEffect';
import { BombRocketComboEffect } from './BombRocketComboEffect';
import { CubeCubeComboEffect } from './CubeCubeComboEffect';
import { CubeReplaceComboEffect } from './CubeReplaceComboEffect';
import { IEffect } from './IEffect';
import { RocketRocketComboEffect } from './RocketRocketComboEffect';

// types
//-----------------------------------------------------------------------------
// public
export const PowerBlockBit: Record<PowerBlockType, number> = {
    bomb: 0x01,
    rocket: 0x02,
    cube: 0x04,
};
export enum ComboId {
    bombBomb = PowerBlockBit.bomb,
    bombRocket = PowerBlockBit.bomb | PowerBlockBit.rocket,
    bombCube = PowerBlockBit.bomb | PowerBlockBit.cube,
    rocketRocket = PowerBlockBit.rocket,
    rocketCube = PowerBlockBit.rocket | PowerBlockBit.cube,
    cubeCube = PowerBlockBit.cube,
}

// private
type ComboEffectOptions = {
    scene: GameScene;
    subject: BlockEntity;
    pair: BlockEntity[];
    all: BlockEntity[];
};
type ComboEffectFactory = (options: ComboEffectOptions) => IEffect;

// constants
//-----------------------------------------------------------------------------
const comboEffectMap: Record<ComboId, ComboEffectFactory> = {
    [ComboId.bombBomb]: (options) => new BombBombComboEffect(options.scene, { ...options }),
    [ComboId.bombRocket]: (options) => new BombRocketComboEffect(options.scene, { ...options }),
    [ComboId.bombCube]: (options) => new CubeReplaceComboEffect(options.scene, { ...options, replacer: _bombReplacer }),
    [ComboId.rocketRocket]: (options) => new RocketRocketComboEffect(options.scene, { ...options }),
    [ComboId.rocketCube]: (options) =>
        new CubeReplaceComboEffect(options.scene, { ...options, replacer: _rocketReplacer }),
    [ComboId.cubeCube]: (options) => new CubeCubeComboEffect(options.scene, { ...options }),
};

// block replacers
//-----------------------------------------------------------------------------
function _rocketReplacer(): BlockId {
    return Math.random() < 0.5 ? 'rocketHorizontal' : 'rocketVertical';
}

function _bombReplacer(): BlockId {
    return 'bomb';
}

/*
    combo factory effect. fails if no combo found from the subject.
*/
export class PowerComboEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _scene: GameScene;
    private _subject: BlockEntity;

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, subject: BlockEntity) {
        this._scene = scene;
        this._subject = subject;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // build effect
        const result = this._comboEffectBuilder();

        // if exists
        if (result) {
            // notify combo event
            this._scene.events.publish({ id: 'combo', comboId: result.id });

            // execute effect
            await result.effect.execute();

            return true;
        }

        return false;
    }

    // private: factory
    //-------------------------------------------------------------------------
    private _comboEffectBuilder():
        | {
              id: ComboId;
              effect: IEffect;
          }
        | undefined {
        // require subject is power block
        if (!powerBlockTypes.includes(this._subject.c.block.props.type as PowerBlockType)) {
            return undefined;
        }

        // find power block neighbors
        const all = this._findNeighbors();

        // find combo effect
        const result = this._findComboEffect(all);
        if (!result) {
            return undefined;
        }

        // build effect
        const effect = result.factory({
            scene: this._scene,
            subject: this._subject,
            pair: result.pair,
            all,
        });

        return { id: result.id, effect };
    }

    // private: builder support
    //-----------------------------------------------------------------------------
    private _findNeighbors(): BlockEntity[] {
        const all: BlockEntity[] = [];

        // iterate immediate neighbor base blocks starting at given subject
        blockIterateNeighbors(this._scene.sessionEntity.c.map, this._subject, (base) => {
            // if not frozen
            if (!blockIsFrozen(this._scene, base)) {
                // if power block, add to found, and continue search
                const type = base.c.block.props.type as PowerBlockType;
                if (powerBlockTypes.includes(type)) {
                    all.push(base);
                    return true;
                }
            }
            return false;
        });

        return all;
    }

    private _findComboEffect(powerBlocks: BlockEntity[]):
        | {
              id: ComboId;
              factory: ComboEffectFactory;
              pair: BlockEntity[];
          }
        | undefined {
        const map = objectCreate(powerBlockTypes, () => []);
        const pair: BlockEntity[] = [];

        // group blocks by type
        for (const block of powerBlocks) {
            const type = block.c.block.props.type as PowerBlockType;
            map[type].push(block);
        }

        // for each power block type in order of strongest first
        for (const type of powerBlockTypes) {
            // for each collected block of this type
            for (const block of map[type]) {
                // add to pair
                pair.push(block);

                // if we have a pair, then return combo results
                if (pair.length === 2) {
                    const type1 = pair[0].c.block.props.type as PowerBlockType;
                    const type2 = pair[1].c.block.props.type as PowerBlockType;
                    const id = PowerBlockBit[type1] | PowerBlockBit[type2];
                    return {
                        id,
                        factory: comboEffectMap[id],
                        pair,
                    };
                }
            }
        }

        return undefined;
    }
}
