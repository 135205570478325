import type { Component, Entity2D } from '@play-co/odie';
import { Container } from 'pixi.js';

import { SizeType } from '../../../../lib/defs/types';
import { blockPositionView2 } from '../util/blockTools';

// types
//-----------------------------------------------------------------------------
export type OverlayComponentOptions = {
    spawn: () => Container;
    size: SizeType;
};

/*
    generic overlay view component
*/
export class OverlayComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'overlay';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: Container;

    // impl
    //-------------------------------------------------------------------------
    init(options: OverlayComponentOptions): void {
        // create view
        this.view = options.spawn();

        // position view
        blockPositionView2(this.view, options.size);
    }
}
