import { BasicHandler } from '../defs/types';

/*
    more usable version of setTimeout. uses seconds not milliseconds.
*/
export default class Timer {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _handler: BasicHandler;
    // state
    private _timer: ReturnType<typeof setTimeout>;

    // properties
    //-------------------------------------------------------------------------
    public get active(): boolean {
        return this._timer !== undefined;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(handler: BasicHandler) {
        this._handler = () => {
            this._timer = undefined;
            handler();
        };
    }

    // api
    //-------------------------------------------------------------------------
    // starts timer
    public start(delaySeconds: number) {
        // create new timer
        this._startTimer(delaySeconds);
    }

    // refreshes else starts timer
    public refresh(delaySeconds: number) {
        // restart timer
        this._startTimer(delaySeconds);
    }

    // stops timer
    public stop() {
        // stop timer
        clearTimeout(this._timer);

        // reset state
        this._timer = undefined;
    }

    // private
    //-------------------------------------------------------------------------
    private _startTimer(delaySeconds: number) {
        clearTimeout(this._timer);
        this._timer = setTimeout(this._handler, delaySeconds * 1000);
    }
}
