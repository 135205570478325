import app from '../../index.entry';
import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { livesTimeToNext } from '../../replicant/components/lives';

export class NoLivesFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();

    // init
    //-------------------------------------------------------------------------
    constructor() {}

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._noLivesAction();

        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    private async _noLivesAction() {
        const okPromise = new NakedPromise();
        app.nav.open('outOfLivesPopup', {
            lifeTimeLeft: () => livesTimeToNext(app.server.state, app.server.now()),
            onOk: () => {
                app.nav.close('outOfLivesPopup');
                return okPromise.resolve();
            },
        });

        await okPromise;
        this._actionComplete(true);
    }
}
