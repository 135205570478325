import type { Component, Entity2D } from '@play-co/odie';

import { SpiderBlockProps } from '../defs/block';
import { SpiderBlockView } from '../views/blocks/SpiderBlockView';

// types
//-----------------------------------------------------------------------------
export type SpiderBlockComponentOptions = {
    props: SpiderBlockProps;
};

/*
    chain block component
*/
export class SpiderBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockSpider';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: SpiderBlockView;

    // properties
    //-------------------------------------------------------------------------

    // impl
    //-------------------------------------------------------------------------
    public init(options: SpiderBlockComponentOptions): void {
        this.view = new SpiderBlockView(options.props);
    }
}
