import { Application, Plugin, PluginOptions } from '@play-co/astro';
import { Assets } from 'pixi.js';

// types
//-----------------------------------------------------------------------------
export type ResourceExPluginOptions = PluginOptions;

/*
    resource plugin extension
*/
export class ResourceExPlugin extends Plugin<ResourceExPluginOptions> {
    // init
    //-------------------------------------------------------------------------
    constructor(app: Application, options?: ResourceExPluginOptions) {
        super(app, options);
    }

    // impl
    //-------------------------------------------------------------------------
    async init() {}

    // api
    //-------------------------------------------------------------------------
    public async loadAsset(url: string) {
        return Assets.load(url);
    }

    public loadAssets(urls: string[]): Promise<any>[] {
        // filter nulls and dupes
        urls = urls.filter((url, i) => url && urls.indexOf(url) === i);

        // map urls to asset loading promises. upon each completed bump loaded and notify
        const promises = urls.map((url) => Assets.load(url));

        // return all promises
        return promises;
    }
}
