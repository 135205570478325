import type { Component, Entity2D } from '@play-co/odie';

import { DirectionBlockOptionDef, DogBlockProps } from '../defs/block';
import { DogBlockView } from '../views/blocks/DogBlockView';

// types
//-----------------------------------------------------------------------------
export type DogBlockComponentOptions = {
    props: DogBlockProps;
    option?: number;
};

/*
    dog block component
*/
export class DogBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockDog';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: DogBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: DogBlockComponentOptions): void {
        // get option def
        const optionDef = options.props.options[options.option || 0] as DirectionBlockOptionDef;

        // create view
        this.view = new DogBlockView(options.props);

        // set initial direction
        this.view.direction = optionDef.direction;
    }
}
