import { Vector2 } from '@play-co/odie';

import { PositionType } from '../../../../lib/defs/types';
import { arrayIterate2, objectAccess } from '../../../../replicant/util/jsTools';
import { PuzzleScreen } from '../../puzzle/PuzzleScreen';
import { Cell, MapComponent } from '../components/MapComponent';
import { TileComponent } from '../components/TileComponent';
import { BlockId } from '../defs/block';
import { config } from '../defs/config';
import { MapDef } from '../defs/map';
import { BlockEntity } from '../entities/BlockEntity';
import { GameScene } from '../GameScene';
import { blockIsFrozen, blockViewAssets } from './blockTools';

// types
//-----------------------------------------------------------------------------
export type MapValidationError = 'moves' | 'goals' | 'rewards' | 'rows' | 'columns' | 'cells';
export type MapStats = {
    [key in BlockId]?: {
        count: number;
    };
};
export type CellIterator = (cell: Cell, position: Vector2) => void;

// constants
//-----------------------------------------------------------------------------
// determines relative vertical position of grid
const alignY = 0.45;

// def
//-----------------------------------------------------------------------------
export function mapValidateDef(mapDef: MapDef): boolean | MapValidationError {
    const grid = mapDef.grid;

    // require moves
    if (mapDef.moves <= 0) return 'moves';

    // require goals
    if (Object.values(mapDef.goals).reduce((total, count) => total + count, 0) <= 0) return 'goals';

    // require rewards

    // require valid columns
    if (grid.columns <= 0) return 'columns';

    // require valid rows
    if (grid.rows <= 0) return 'rows';

    // require valid cells
    if (grid.cells.length !== grid.columns) return 'cells';
    for (const rows of grid.cells) {
        if (rows.length !== grid.rows) return 'cells';
    }

    return true;
}

export function mapGetAssets(mapDef: MapDef, effects: boolean): string[] {
    // collect block assets
    const blockAssets: string[] = [];
    arrayIterate2(
        mapDef.grid.cells,
        (cell) => cell.blocks?.forEach((block) => blockAssets.push(...blockViewAssets(block.id, effects))),
    );

    return [
        'symbols.json',
        ...TileComponent.assets(),
        ...blockAssets,
        ...mapDef.spawns.map((id) => blockViewAssets(id, effects)).flat(),
    ];
}

export function mapGetStats(mapDef: MapDef): MapStats {
    const stats: MapStats = {};
    arrayIterate2(
        mapDef.grid.cells,
        (cell) => cell.blocks?.forEach((block) => ++objectAccess(block.id, stats, () => ({ count: 0 })).count),
    );
    return stats;
}

export function mapGetPan(position: PositionType) {
    return (position.x + 0.5 - config.map.columns / 2) / config.map.columns;
}

// position
//-----------------------------------------------------------------------------
export function mapToViewPosition(position: PositionType, centered?: boolean): Vector2 {
    const map = PuzzleScreen.instance.scene.sessionEntity.c.map;
    const tileSize = config.tile.size;
    const center = centered ? tileSize / 2 : 0;

    // convert map to view position
    return new Vector2(
        position.x * tileSize - (tileSize * (config.map.columns + map.columnOffset)) / 2 + center,
        position.y * tileSize - tileSize * config.map.rows * alignY + center,
    );
}

export function mapFromViewPosition(position: PositionType): Vector2 {
    const map = PuzzleScreen.instance.scene.sessionEntity.c.map;
    const tileSize = config.tile.size;

    // convert view to map position
    return new Vector2(
        Math.floor((position.x + (tileSize * (config.map.columns + map.columnOffset) + tileSize) / 2) / tileSize),
        Math.floor((position.y + (tileSize * config.map.rows + tileSize) * alignY) / tileSize),
    );
}

export function mapFromGlobalPosition(position: PositionType): PositionType {
    const map = PuzzleScreen.instance.scene.view2d.layers.default;
    const local = map.toLocal(position);
    const offset = config.tile.size / 2;
    return mapFromViewPosition({
        x: local.x - offset,
        y: local.y - offset,
    });
}

export function mapIsPositionNextTo(position1: Vector2, position2: Vector2): boolean {
    const dx = Math.abs(position1.x - position2.x);
    const dy = Math.abs(position1.y - position2.y);

    return dx <= 1 && dy <= 1 && dx !== dy;
}

// cell
//-----------------------------------------------------------------------------
export function mapCellBaseOpen(cell: Cell): boolean {
    return !cell.base && !cell.overlay?.entity.c.block.props.freeze;
}

export function isCellBarrier(cell: Cell) {
    // barrier if base block or overlay is a barrier
    return cell.base || cell.overlay?.entity.c.block.props.barrier;
}

// fall
//-----------------------------------------------------------------------------
export function isDroppableCell(scene: GameScene, cell: Cell): boolean {
    if (!cell?.base || cell.base.parent) {
        return false;
    }

    const entity = cell.base.entity;
    const block = entity.c.block;

    return !block.props.immobile && !blockIsFrozen(scene, entity);
}

export function findDropRow(map: MapComponent, entity: BlockEntity): number | undefined {
    const position = entity.c.position.mapPosition;
    let minDropRow = 1000;

    // for each column of the block component
    for (let blockColumn = 0; blockColumn < entity.c.block.width; ++blockColumn) {
        const dropRow = findColumnDropRow(map, entity, position.x + blockColumn, position.y + 1);
        if (dropRow === undefined) return undefined;
        minDropRow = Math.min(dropRow, minDropRow);
    }

    return minDropRow;
}

export function findColumnDropRow(
    map: MapComponent,
    entity: BlockEntity,
    column: number,
    row: number,
): number | undefined {
    const columns = map.grid[column];
    const height = entity.c.block.height;
    let dropRow;
    let gapRows = 0;

    // for each row in the cell column from the given row
    for (; row < columns.length; ++row) {
        const cell = columns[row];

        // if cell is enabled and does not have an entity, update drop row
        if (cell.enabled) {
            // stop at first barrier that isnt given entity
            if (isCellBarrier(cell) && entity !== cell.base?.entity) {
                break;
            }

            // if enough gap rows, update drop row
            if (++gapRows >= height) {
                dropRow = row - height + 1;
            }
        } else {
            gapRows = 0;
        }
    }

    return dropRow;
}

export function hasBlockBelow(map: MapComponent, entity: BlockEntity): boolean {
    const block = entity.c.block;
    const height = block.props.height || 1;

    const position = entity.c.position.mapPosition;
    const fromColumn = position.x;
    const fromRow = position.y + height;

    // for each column of block's width
    for (let column = 0; column < block.width; ++column) {
        const columns = map.grid[column + fromColumn];

        // for each row below this block
        for (let row = fromRow; row < columns.length; ++row) {
            const cell = columns[row];
            if (cell?.enabled && cell.base) {
                return true;
            }
        }
    }

    return false;
}

// iteration
//-----------------------------------------------------------------------------
export function mapIterateCells(scene: GameScene, handler: CellIterator) {
    const map = scene.sessionEntity.c.map;

    // for each cell in map
    for (let column = 0; column < map.columns; ++column) {
        for (let row = 0; row < map.rows; ++row) {
            const cell = map.grid[column][row];
            if (cell.enabled) handler(cell, new Vector2(column, row));
        }
    }
}
