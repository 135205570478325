import { Assets } from 'pixi.js';

import { BlockId } from '../../defs/block';
import { BlockEntity, spawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { blockViewAssets } from '../../util/blockTools';
import { IEffect } from './IEffect';

// types
//-----------------------------------------------------------------------------
export type FuguEffectOptions = {
    subject: BlockEntity;
};

// constants
//-----------------------------------------------------------------------------
const spawnId: BlockId = 'miniFugu';

/*
    fugu generates a movable mini fugu block
*/
export class FuguEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _options: FuguEffectOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: FuguEffectOptions) {
        this._scene = scene;
        this._options = options;
    }

    static assets(): string[] {
        return blockViewAssets(spawnId, true);
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._spawnMiniFuguBlock();
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _spawnMiniFuguBlock() {
        // load assets
        await Assets.load(blockViewAssets(spawnId, true));

        // spawn mini fugu block
        spawnBlockEntity(this._scene, { id: spawnId }, this._options.subject.c.position.mapPosition);
    }
}
