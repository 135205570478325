/*
    math tools/helpers
*/
import { teaHash } from '@play-co/replicant';

import { arrayShuffle } from './jsTools';

// types
//-----------------------------------------------------------------------------
export type RandomFunction = () => number;

// constants
//-----------------------------------------------------------------------------
const PI2 = Math.PI * 2;

// integer
//-----------------------------------------------------------------------------
export function integerRandom(min: number, max: number, fRandom: RandomFunction = Math.random): number {
    return min + Math.floor(fRandom() * (max - min + 1));
}

export function integerTea(min: number, max: number, v0: string | number, v1?: number): number {
    return min + Math.floor(teaHash(v0.toString(), v1) * (max - min + 1));
}

/*
    takes a list of weights (odds) and returns a random index based on weight
    example:
        weights: [10,0,70,20]
        return: 2 (usually)
*/
export function randomOdds(weights: number[], fRandom: RandomFunction = Math.random): number {
    const total = weights.reduce((t, weight) => t + weight, 0);
    const roll = fRandom() * total;

    // for each odds index
    for (let i = 0, acc = 0; i < weights.length; ++i) {
        acc += weights[i];
        if (roll < acc) return i;
    }

    // rare edge case
    return 0;
}

/*
    returns list of <count> random standard deviations in range <-stdv, stdv> with total average of 0
*/
export function randomDeviations(count: number, stdv: number, fRandom: RandomFunction = Math.random): number[] {
    let avg = 0;
    const values = new Array(count);

    for (let i = 1; i < count; ++i) {
        let v = floatRandom(-stdv, stdv, fRandom);
        if (Math.abs(v + avg) > stdv) v = -v;
        avg += v;
        values[i] = v;
    }
    values[0] = -avg;

    return arrayShuffle(values);
}

// float
//-----------------------------------------------------------------------------
export function floatRandom(min: number, max: number, fRandom: RandomFunction = Math.random): number {
    return min + fRandom() * (max - min);
}

// numeric
//-----------------------------------------------------------------------------
export function numberInRange(value: number, min: number, max: number): boolean {
    return value >= min && value <= max;
}

export function numberClamp(value: number, min: number, max: number): number {
    return value < min ? min : value > max ? max : value;
}

export function numberRound(value: number, factor: number): number {
    return Math.round(value / factor) * factor;
}

export function numberFloor(value: number, factor: number): number {
    return Math.floor(value / factor) * factor;
}

export function numberContain(value: number, boundary: number): number {
    if (value > boundary) return value - boundary;
    if (value < 0) return value + boundary;
    return value;
}

// geometry
//-----------------------------------------------------------------------------
export function angleContain(radians: number): number {
    return numberContain(radians, PI2);
}
