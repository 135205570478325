import { Container, Graphics, NineSlicePlane, Point, Sprite, Texture } from 'pixi.js';

import app from '../../../index.entry';
import { Animation } from '../../../lib/animator/Animation';
import { SizeType } from '../../../lib/defs/types';
import NakedPromise from '../../../lib/pattern/NakedPromise';
import { pixiGetScene, pixiSetInterval } from '../../../lib/pixi/pixiTools';
import {
    uiAlignBottom,
    uiAlignCenter,
    uiAlignCenterX,
    uiCreateMask,
    uiCreateQuad,
    uiSizeToFit,
    uiSizeToWidth,
} from '../../../lib/pixi/uiTools';
import { tween } from '../../../lib/util/tweens';
import { getStepFinishTime } from '../../../replicant/components/bakery';
import bakery, { CakeItem, CustomerId, ProduceEntry, TimedStepType } from '../../../replicant/defs/bakery';
import { CakeItemId, cakeItemPropsMap } from '../../../replicant/defs/items';
import { arrayShuffle, sleep } from '../../../replicant/util/jsTools';
import { timeFormatCountdown, timeFromComponents, timeToComponents } from '../../../replicant/util/timeTools';
import { Girl } from '../../concept/Girl';
import { Grandma } from '../../concept/Grandma';
import { Lady } from '../../concept/Lady';
import { CharacterClipId, MainCharacter } from '../../concept/MainCharacter';
import { OldMan } from '../../concept/OldMan';
import { Woman } from '../../concept/Woman';
import { pixiConfig } from '../../defs/config';
import { MakeCakeFlow } from '../../flows/MakeCakeFlow';
import { PuzzlePlayFlow } from '../../flows/PuzzlePlayFlow';
import { RecipeStepFlow } from '../../flows/RecipeStepFlow';
import { trackPlayerTap } from '../../lib/analytics/bakery';
import { ImageButton } from '../../lib/ui/buttons/ImageButton';
import { Pointer } from '../../lib/ui/Pointer';
import { RecipePopup } from '../../lib/ui/popups/RecipePopup';
import { BasicText } from '../../lib/ui/text/BasicText';
import { MAIN_UI_Z, SpeechScreen } from './SpeechScreen';
import StarComponent from './StarComponent';

const DEFAULT_Y_OFF = 738;
const POINTER_SCALE = 0.45;

const DEFAULT_DIALOGS = [
    '[tapDialog0]',
    '[tapDialog1]',
    '[tapDialog2]',
    '[tapDialog3]',
    '[tapDialog4]',
    '[tapDialog5]',
    '[tapDialog6]',
];

// types
//-----------------------------------------------------------------------------
export type HomeScreenOptions = {
    cakes?: CakeItem[];
    forcedStarAmount?: number;
    tableItem?: string;
    shopName?: string;
    scripted?: boolean;
    showPointer?: boolean;
    animationOverride: CharacterClipId;
};

// manifest
//-----------------------------------------------------------------------------
const manifest = {
    // cafe
    default: 'bg.store.png',
    headerFrame: 'panel.title.png',
    cakeFrame: 'frame.cake.png',
    playButton: 'button.play.png',
    // puzzleIcon: 'icon.puzzle.small.png',
    empty: 'icon.empty.png',
    customerDoor: 'icon.customer.door.png',
    timer: 'icon.timer.png',
    timerFrame: 'frame.inset.light.png',

    // checkmark: 'icon.check.green.png',
    glow: 'vfx.glow2.png',
    // preTableItem: 'bakery.table.brownsugaroperapre.png',

    // star: 'icon.star.main.png',
    // locked: 'icon.locked.png',

    iconCook: 'icon.cook.png',
    iconFastForward: 'icon.fast.forward.png',

    buttonSquare: 'button.red.square.png',
};

// todo load if used or move to manifest
const lazyManifest = {
    iconActive: 'icon.active.png',

    oven: 'bakery.oven.base.png',
    tray: 'bakery.oven.tray.png',
    fridge: 'bakery.fridge.base.png',
    fridgeTray: 'bakery.fridge.tray.png',

    stove: 'bakery.stove.base.png',
    stoveArm: 'bakery.stove.arm.png',
    stovePan: 'bakery.stove.pan.png',
    stovePot: 'bakery.stove.pot.png',
};

export class HomeScreen extends SpeechScreen {
    // events
    //-------------------------------------------------------------------------
    // scene
    private _nameFrame: Sprite;
    private _bgMask: Graphics;
    private _menuContainer: Container;
    private _cakeFrame: NineSlicePlane;
    private _playerView: Container;
    private _player: MainCharacter;
    private _name: BasicText;
    private _notificationText: BasicText;
    private _notificationAnimation: Animation;
    private _tableItem: Sprite;
    private _cakesButton: ImageButton;
    private _cakesButtonGlow: Sprite;
    private _playerButton: ImageButton;
    private _scripted: boolean; // scripted state, if true the scene has limited interaction and no puzzle button
    private _fastForwardButton?: ImageButton;

    private _cakeMap: Record<CakeItemId, { button: ImageButton; amountText: BasicText }>;

    private _starView: StarComponent;

    private _newRecipeGlowMap: Record<CakeItemId, Sprite>;

    public get fastForwardButton() {
        return this._fastForwardButton;
    }

    public get nameView() {
        return this._name;
    }

    public get makeCakeButton() {
        return this._cakesButton;
    }

    public get starView() {
        return this._starView;
    }

    public preloadGrandma() {
        return Promise.all(app.resource.loadAssets([...Grandma.assets()]));
    }

    public async preloadLady() {
        return Promise.all(app.resource.loadAssets([...Lady.assets()]));
    }

    public preloadWoman() {
        return Promise.all(app.resource.loadAssets([...Woman.assets()]));
    }

    public preloadOldman() {
        return Promise.all(app.resource.loadAssets([...OldMan.assets()]));
    }

    public preloadGirl() {
        return Promise.all(app.resource.loadAssets([...Girl.assets()]));
    }

    // impl
    //-------------------------------------------------------------------------
    public override preload(options: HomeScreenOptions) {
        return [
            ...super.preload(options),
            ...app.resource.loadAssets([
                ...Object.values(manifest),
                ...MainCharacter.assets(),
                ...Object.keys(cakeItemPropsMap).map((key: CakeItemId) => cakeItemPropsMap[key].icon),
                ...(options?.showPointer ? Pointer.assets() : []),
            ]),
        ];
    }

    public async spawning(options: HomeScreenOptions) {
        this.addOrientationListener();
        // play music
        app.music.play('chill_lofi.ogg');

        this._newRecipeGlowMap = {} as Record<CakeItemId, Sprite>;
        this._isInteracting = false;
        this._scripted = !!options.scripted;
        this._cakeMap = {} as {} as Record<CakeItemId, { button: ImageButton; amountText: BasicText }>;

        // reset dialogs
        this._tapDialogs = arrayShuffle([...DEFAULT_DIALOGS]);

        // spawn scene
        this._spawn(options);

        // masking
        this._bgMask = uiCreateMask(this._bg.width, this._bg.height);
        this._bg.mask = this._bgMask;
        this._bg.addChild(this._bgMask);

        // preload
        sleep(0.5).then(() => app.nav.preload('recipePopup'));
    }

    public despawned() {
        this.empty();
    }

    public override resized(size: SizeType): void {
        super.resized(size);
        this._bg.height = size.height;
        this._bgMask.height = size.height;

        if (this._menuContainer) {
            uiAlignBottom(this._bg, this._menuContainer, -40);
        }

        if (this._cakeFrame) {
            this._alignCakeFrame();
        }
    }

    public playPlayerAnimation(animation: CharacterClipId, loop = false): Promise<void> {
        this._bg.sortableChildren = true;
        return this._player.start({ clipId: animation, loop });
    }

    public async spawnDoorAnimation() {
        this._skipSpeechCount = 0;

        app.sound.play('door_bell.ogg', { dupes: 1, volume: 0.7 });
        const banner = Sprite.from(manifest.customerDoor);

        const bellText = new BasicText({
            text: '[doorBell]',
            style: {
                fill: '#000',
                fontSize: 40,
                lineJoin: 'round',
                fontStyle: 'italic',
                wordWrap: true,
                align: 'center',
                stroke: 0xffffff,
                strokeThickness: 12,
                lineHeight: 38,
            },
        });

        bellText.rotation = -0.35;

        banner.addChild(bellText);
        uiAlignCenter(banner, bellText, -250, 20);

        const bellY = bellText.y;

        this._bg.addChild(banner);
        uiAlignCenter(this._bg, banner);
        banner.y -= 80;
        banner.x -= 50;
        banner.zIndex = MAIN_UI_Z;

        const target = banner.x + 50;
        banner.alpha = 0;

        bellText
            .animate()
            .wait(0.54)
            .add(bellText.position, { y: bellY + 5 }, 0.1, tween.backIn(0.2))
            .add(bellText.position, { y: bellY }, 0.1, tween.backOut(0.2))
            .add(bellText.position, { y: bellY + 5 }, 0.1, tween.backIn(0.2))
            .add(bellText.position, { y: bellY }, 0.1, tween.backOut(0.2))
            .add(bellText.position, { y: bellY + 3 }, 0.1, tween.backIn(0.2))
            .add(bellText.position, { y: bellY }, 0.1, tween.backOut(0.2))
            .add(bellText.position, { y: bellY + 3 }, 0.1, tween.backIn(0.2))
            .add(bellText.position, { y: bellY }, 0.1, tween.backOut(0.2))
            .add(bellText.position, { y: bellY + 2 }, 0.1, tween.backIn(0.2))
            .add(bellText.position, { y: bellY }, 0.1, tween.backOut(0.2))
            .add(bellText.position, { y: bellY + 1 }, 0.1, tween.backIn(0.2))
            .add(bellText.position, { y: bellY }, 0.1, tween.backOut(0.2));

        const fadeTime = 0.3;
        const waitTime = 2.1;

        const fadeOverlay = uiCreateQuad(0x0, 0.7, this._bg.width, this._bg.height);
        this._bg.addChild(fadeOverlay);
        fadeOverlay.zIndex = 12;
        uiAlignCenter(this._bg, fadeOverlay);
        fadeOverlay.alpha = 0;
        fadeOverlay
            .animate()
            .add(fadeOverlay, { alpha: 1 }, fadeTime, tween.pow2In)
            .wait(waitTime)
            .add(fadeOverlay, { alpha: 0 }, fadeTime, tween.pow2In);

        banner.animate().add(banner.position, { x: target }, 2.7, tween.pow2InOut);
        await banner
            .animate()
            .add(banner, { alpha: 1 }, fadeTime, tween.pow2In)
            .wait(waitTime)
            .add(banner, { alpha: 0 }, fadeTime, tween.pow2In)
            .promise();

        banner.removeSelf();
        fadeOverlay.removeSelf();
    }

    public async spawnTimedStepAnimation(opts: { timedStep: TimedStepType; finish?: boolean; icon?: string }) {
        const { timedStep, finish, icon } = opts;
        const animationMap: Record<TimedStepType, () => Promise<void>> = {
            oven: this._spawnTrayAnimation.bind(this, { type: timedStep, finish: !!finish, icon }),
            fridge: this._spawnTrayAnimation.bind(this, { type: timedStep, finish: !!finish, icon }),
            saucepot: this._spawnStovePotAnimation.bind(this, { finish: !!finish, icon }),
        };

        await animationMap[timedStep]();
    }

    public async spawnCustomerAnimation(customerId: CustomerId, waitPromise?: NakedPromise) {
        // call customer helper(s) before triggering the animation
        const customerMap: Record<CustomerId, { offsetY: number; createSpine: () => any }> = {
            grandma: { offsetY: 578, createSpine: () => new Grandma() },
            lady: { offsetY: 578, createSpine: () => new Lady() },
            woman: { offsetY: 578, createSpine: () => new Woman() },
            oldMan: { offsetY: 800, createSpine: () => new OldMan() },
            girl: { offsetY: 610, createSpine: () => new Girl() },
        };

        const customer = customerMap[customerId].createSpine();
        customer.start({ clipId: 'idle', loop: true });

        this._bg.addChild(customer);
        uiAlignBottom(this._bg, customer, customerMap[customerId].offsetY);
        customer.x = 610;
        customer.zIndex = 11;

        customer.alpha = 0;
        if (waitPromise) {
            await customer.animate().add(customer, { alpha: 1 }, 0.3, tween.pow2In).promise();
            await waitPromise;
            await customer.animate().add(customer, { alpha: 0 }, 0.3, tween.pow2In).promise();
        } else {
            await customer
                .animate()
                .add(customer, { alpha: 1 }, 0.3, tween.pow2In)
                .wait(2.4)
                .add(customer, { alpha: 0 }, 0.3, tween.pow2In)
                .promise();
        }

        customer.removeSelf();
    }

    public async setSetName(name: string): Promise<void> {
        if (!this._nameFrame) {
            const header = this._createHeader(name);
            this._bg.addChild(header);
            header.alpha = 0;
            header.animate().add(header, { alpha: 1 }, 0.3, tween.pow2In);
        }

        this._name.text = name;
        this._alignName();
    }

    public async spawnTableItem(itemIcon: string, animated = false) {
        this._tableItem = Sprite.from(itemIcon);
        this._tableItem.scale.set(0.9);
        this._bg.addChild(this._tableItem);
        uiAlignCenterX(this._bg, this._tableItem);
        this._tableItem.y = this._bg.height - 630;
        this._tableItem.zIndex = 11;

        if (animated) {
            this._tableItem.alpha = 0;
            await this._tableItem.animate().add(this._tableItem, { alpha: 1 }, 0.3, tween.pow2In).promise();
        }
    }

    public async spawnTableItemFromShowCase(opts: {
        cakeId: CakeItemId;
        hideShowcase?: boolean;
        forcedAmount?: number;
    }) {
        const { cakeId, hideShowcase, forcedAmount } = opts;
        const icon = cakeItemPropsMap[cakeId].icon;
        this._tableItem = Sprite.from(icon);
        // this._tableItem.pivot.set(this._tableItem.width * 0.5, this._tableItem.height * 0.5);
        const flyingCake = Sprite.from(icon);

        const showCaseCake = this._cakeMap[cakeId].button;

        const tableScale = 0.9 * this.root.scale.x;
        this._tableItem.scale.set(0.9); // table scale
        this._bg.addChild(this._tableItem);
        uiAlignCenterX(this._bg, this._tableItem);
        this._tableItem.y = this._bg.height - 630;
        this._tableItem.zIndex = 11;

        this._tableItem.alpha = 0;

        const from = showCaseCake.toGlobal({ x: 0, y: 0 });
        // const target = this._tableItem.toGlobal({ x: this._tableItem.width * 0.5, y: this._tableItem.height * 0.5 });
        const target = this._tableItem.toGlobal({ x: 0, y: 0 });
        flyingCake.scale.set(showCaseCake.scale.x * this.root.scale.x);
        // flyingCake.pivot.set(flyingCake.width * 0.5, flyingCake.height * 0.5);
        flyingCake.position.set(from.x, from.y);

        const duration = 1.5;
        const root = pixiGetScene();
        root.addChild(flyingCake);
        const targetX = target.x;
        const targetY = target.y;

        if (hideShowcase) {
            // this._cakeMap[cakeId].amountText.alpha = 0;
            this._cakeMap[cakeId].button.alpha = 1;
            await this._cakeMap[cakeId].button
                .animate()
                .add(this._cakeMap[cakeId].button, { alpha: 0 }, 0.3, tween.pow2Out)
                .promise();
        }

        if (forcedAmount !== undefined) {
            this._cakeMap[cakeId].amountText.text = `x${forcedAmount}`;
        }

        flyingCake.animate().add(flyingCake.scale, { x: tableScale, y: tableScale }, duration, tween.pow2InOut);
        await flyingCake.animate().add(flyingCake, { y: targetY, x: targetX }, duration, tween.pow2InOut).promise();
        this._tableItem.alpha = 1;
        flyingCake.destroy();
    }

    public async spawnTableItemToShowCase(opts: { cakeId: CakeItemId }) {
        const { cakeId } = opts;
        const icon = cakeItemPropsMap[cakeId].icon;
        this._tableItem = Sprite.from(icon);
        const flyingCake = Sprite.from(icon);
        const showCaseCake = this._cakeMap[cakeId].button;

        const finalScale = showCaseCake.scale.x * this.root.scale.x;

        // spawn reguala table item for reference point
        this._tableItem.scale.set(0.9); // table scale
        this._bg.addChild(this._tableItem);
        uiAlignCenterX(this._bg, this._tableItem);
        this._tableItem.y = this._bg.height - 630;
        this._tableItem.zIndex = 11;

        const from = this._tableItem.toGlobal({ x: 0, y: 0 });

        const target = showCaseCake.toGlobal({ x: 0, y: 0 });
        flyingCake.scale.set(this._tableItem.scale.x * this.root.scale.x);
        flyingCake.position.set(from.x, from.y);

        const duration = 1.5;
        const root = pixiGetScene();
        root.addChild(flyingCake);
        const targetX = target.x;
        const targetY = target.y;

        // remove and use global flying cake starting from its position
        this._tableItem.destroy();
        this._tableItem = null;

        flyingCake.animate().add(flyingCake.scale, { x: finalScale, y: finalScale }, duration, tween.pow2InOut);
        await flyingCake.animate().add(flyingCake, { y: targetY, x: targetX }, duration, tween.pow2InOut).promise();
        // show real cake and destroy temp flying cake
        this._cakeMap[cakeId].amountText.alpha = 0;
        this._cakeMap[cakeId].button.alpha = 1;
        flyingCake.destroy();
        await this._cakeMap[cakeId].amountText
            .animate()
            .add(this._cakeMap[cakeId].amountText, { alpha: 1 }, 0.3, tween.pow2In)
            .promise();
    }

    public async despawnTableItem() {
        if (this._tableItem) {
            await this._tableItem.animate().add(this._tableItem, { alpha: 0 }, 0.3, tween.pow2Out).promise();
            this._tableItem.removeSelf();
            this._tableItem = null;
        }
    }

    public async spawnCakes(opts: {
        cakes: readonly CakeItem[];
        animated?: boolean;
        skipUnlockLevels?: boolean;
        finishedGlow?: CakeItemId;
        interactive?: boolean;
        hiddenCake?: CakeItemId;
    }): Promise<{
        cakeButtons: ImageButton[];
        newCakeViews?: Sprite[]; // glow and checkmark for cleanup
    }> {
        const { cakes, animated, skipUnlockLevels, finishedGlow, interactive, hiddenCake } = opts;
        const frame = new NineSlicePlane(Texture.from(manifest.cakeFrame), 100, 0, 100, 0);
        frame.width = 730;
        frame.height = 316;

        const cakeButtons = [];
        const defaultX = 52;
        let x = defaultX;
        let y = 10;

        let newCakeViews: Sprite[];

        const lockLevelRendered = false;
        for (let i = 0; i < bakery.tableLimit; i++) {
            const cakeStatus = cakes[i];
            let cakeSlot: ImageButton;
            if (cakeStatus) {
                // we have a cake for the table slot, add cake to table
                cakeSlot = new ImageButton({ image: cakeItemPropsMap[cakeStatus.cakeId].icon });
                cakeSlot.interactive = !!interactive;
                cakeSlot.scale.set(0.56);
                cakeButtons.push(cakeSlot);

                if (hiddenCake && cakeStatus.cakeId === hiddenCake) cakeSlot.alpha = 0;

                const amount = new BasicText({
                    text: `x${cakeStatus.amount}`,
                    style: {
                        fill: '#FFF',
                        fontSize: 62,
                        strokeThickness: 10,
                        dropShadow: true,
                        fontWeight: 'bold',
                        lineJoin: 'round',
                        align: 'center',
                    },
                });

                this._cakeMap[cakeStatus.cakeId] = { button: cakeSlot, amountText: amount };

                cakeSlot.addChild(amount);
                amount.position.set(180, 180);
            } else {
                cakeSlot = new ImageButton({ image: manifest.empty });
                cakeSlot.animated = false;
                cakeSlot.y = 70;
            }

            cakeSlot.x = cakeStatus ? x : x + 6;
            cakeSlot.y += y;

            x += 160;
            if (i === 3) {
                x = defaultX;
                y += 125;
            }

            frame.addChild(cakeSlot);
        }

        this._bg.addChild(frame);
        this._cakeFrame = frame;
        this._alignCakeFrame();

        if (animated) {
            this._cakeFrame.alpha = 0;
            await this._cakeFrame.animate().add(this._cakeFrame, { alpha: 1 }, 0.3, tween.pow2In).promise();
        }
        return { cakeButtons, newCakeViews };
    }

    public async despawnCakes(opts: { animated: boolean }) {
        const { animated } = opts;
        if (animated) {
            await this._cakeFrame.animate().add(this._cakeFrame, { alpha: 0 }, 0.3, tween.pow2Out).promise();
        }
        this._cakeFrame?.removeSelf();
        this._cakeFrame = null;
        this._cakeMap = {} as Record<CakeItemId, { button: ImageButton; amountText: BasicText }>;
    }

    public async starDecreaseAnimation(target: Point) {
        const duration = 0.9;
        // const star = Sprite.from(manifest.star);
        // TODO
        const star = null as Sprite;
        const scale = 1;
        star.anchor.set(0.5, 0.5);
        const from = this._starView.icon.toGlobal({ x: star.width * 0.5, y: star.height * 0.5 });
        star.scale.set(0);
        // scale down for animation after getting width and height
        star.position.set(from.x, from.y);
        // get root to make star to be on top of everything
        const root = pixiGetScene();
        root.addChild(star);

        const targetX = target.x + star.width * 0.5 + 155;
        const targetY = target.y + star.height * 0.5 + 36;

        star.animate()
            .set(star.scale, { x: 0, y: 0 })
            .add(star.scale, { x: scale, y: scale }, 0.22, tween.backOut(2.2));
        star.animate()
            .add(star, { x: targetX }, duration * 0.333, tween.pow2InOut)
            .then(async () => {
                await star.animate().add(star, { x: targetX - 125 }, duration * 0.333, tween.pow2InOut);
                star.animate().add(star, { x: targetX }, duration * 0.333, tween.pow2InOut);
            });
        await star.animate().add(star, { y: targetY }, duration, tween.pow2InOut).promise();
        await star.animate().add(star.scale, { x: 0, y: 0 }, 0.12, tween.backIn(2.2)).promise();
        star.destroy();
    }

    public async starIncreaseAnimation(): Promise<void> {
        const duration = 0.84;
        // const star = Sprite.from(manifest.star);
        // TODO
        const star = null as Sprite;
        const scale = 1;
        star.anchor.set(0.5, 0.5);

        const from = this._player.toGlobal({ x: -12, y: 0 });
        const target = this._starView.icon.toGlobal({ x: star.width * 0.5, y: star.height * 0.5 });
        star.scale.set(0);
        // scale down for animation after getting width and height
        // star.position.set(from.x + 80, from.y + 240);
        star.position.set(from.x, from.y);
        // get root to make star to be on top of everything
        const root = pixiGetScene();
        root.addChild(star);
        const targetX = target.x + star.width * 0.5;
        const targetY = target.y + star.height * 0.5 + 30;

        star.animate()
            .set(star.scale, { x: 0, y: 0 })
            .add(star.scale, { x: scale, y: scale }, 0.25, tween.backOut(2.2));
        star.animate().add(star, { x: targetX }, duration * 0.333, tween.pow2InOut);

        this._starView.icon
            .animate()
            .wait(duration - 0.15)
            .add(star.scale, { x: 0.6, y: 0.6 }, 0.15, tween.linear)
            .promise();

        this._starView.icon
            .animate()
            .wait(duration - 0.23) // start a little bit earlier for better timing
            .add(this._starView.icon.scale, { x: 1.08, y: 1.08 }, 0.21, tween.backIn())
            .add(this._starView.icon.scale, { x: 1, y: 1 }, 0.21, tween.backOut());
        await star.animate().add(star, { y: targetY, x: targetX }, duration, tween.backIn(1.2)).promise();

        star.destroy();
    }

    public async showPlayPuzzle() {
        await this._showNotification('[playPuzzle]');
    }

    // used for toggling scripted flows outside tutorial
    public async toggleScripted(opts: { scripted: boolean; cakesButtonGlow?: boolean }): Promise<void> {
        const { scripted, cakesButtonGlow } = opts;
        let buttonPromise;
        let cakePromise;
        if (scripted) {
            if (this._scripted) return;
            this._playerButton.onPress = null;
            // despawn everything, remove taps
            if (this._cakesButtonGlow) {
                this._cakesButtonGlow.removeSelf();
                this._cakesButtonGlow = null;
            }
            buttonPromise = this._cakesButton
                .animate()
                .add(this._cakesButton, { alpha: 0 }, 0.3, tween.pow2Out)
                .promise()
                .finally(() => {
                    this._cakesButton?.removeSelf();
                    this._cakesButton = null;
                });
            cakePromise = this.despawnCakes({ animated: true });
        } else {
            if (!this._scripted) return;
            this._playerButton.onPress = this.onPlayerTap.bind(this);
            cakePromise = this.spawnCakes({ animated: true, interactive: true, cakes: bakery.defaultCakeState });
            buttonPromise = this.spawnCakesButton({ animated: true, glow: !!cakesButtonGlow });
            this.playPlayerAnimation('idle', true);
        }

        this._scripted = scripted;
        await Promise.all([buttonPromise, cakePromise]);
    }

    public spawnPlayerBubble(speech: string) {
        this.onPlayerTap(speech);
    }

    public async spawnFastForwardView(opts: { animated?: boolean; visibleButton?: boolean }): Promise<Container> {
        const { animated, visibleButton } = opts;
        const container = new Container();
        const icon = Sprite.from(manifest.iconFastForward);

        const button = (this._fastForwardButton = new ImageButton({
            image: manifest.buttonSquare,
            slice: {
                width: 344,
                height: 121,
                left: 55,
                right: 55,
                top: 55,
                bottom: 55,
            },
        }));

        button.alpha = visibleButton ? 1 : 0;

        const label = new BasicText({
            text: '[buttonFinish]',
            style: {
                fill: '#FFF',
                fontSize: 34,
                fontWeight: 'bold',
                align: 'center',
            },
        });

        button.button.addChild(icon, label);

        uiSizeToWidth(label, 196);
        uiAlignCenter(button, icon, -100, 0);
        uiAlignCenter(button, label, 40, -3);

        const timer = this._createTimer();

        timer.y = 0;
        button.y = 200;
        container.addChild(button, timer);

        uiAlignCenterX(container, timer);
        uiAlignCenterX(container, button);

        this._bg.addChild(container);

        container.zIndex = 11;
        uiAlignCenterX(this._bg, container);
        uiAlignBottom(this._bg, container, -195);

        if (animated) {
            container.alpha = 0;
            await container.animate().add(container, { alpha: 1 }, 0.3, tween.pow2In).promise();
        }

        return container;
    }

    public async fadeInFastForwardButton() {
        await this._fastForwardButton.animate().add(this._fastForwardButton, { alpha: 1 }, 0.3, tween.pow2In).promise();
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn(options: HomeScreenOptions) {
        this._bg = new NineSlicePlane(Texture.from(manifest.default), 0, 0, 0, 1338);
        this._bg.sortableChildren = true;
        this._bg.width = 900;
        this._bg.height =
            app.stage.canvas.height < pixiConfig.size.height ? pixiConfig.size.height : app.stage.canvas.height;

        this.base.addContent({
            bg: {
                content: this._bg,
                styles: {
                    position: 'bottomCenter',
                },
            },
        });

        const header = this._createHeader(app.game.player.name);
        this._bg.addChild(header);

        // cake override, scripted or not
        if (options.cakes) {
            this.spawnCakes({ cakes: options.cakes });
        } else if (!options.scripted) {
            // real game should use persistent cake state
            this.spawnCakes({ cakes: bakery.defaultCakeState, interactive: true });
        }

        this._spawnPlayer(!!options.scripted);

        this.playPlayerAnimation(options?.animationOverride ?? 'idle', true);

        if (options.tableItem) {
            this.spawnTableItem(options.tableItem);
        }

        if (!options.scripted) {
            this.spawnCakesButton({});
        }

        if (options.showPointer) {
            this._spawnPointer();
        }
    }

    private _spawnPlayer(scripted: boolean) {
        this._player = new MainCharacter();
        this.root.sortableChildren = true;
        const button = (this._playerButton = new ImageButton({
            // uncomment for debug
            // image: manifest.cakeFrame,
            sound: 'tap-pet.ogg',
        }));
        button.animated = false;
        // uncomment for debug
        button.alpha = 0.6;

        button.width = 340;
        button.height = 460;
        this._player.zIndex = 4;
        button.zIndex = 10;
        const container = new Container();
        container.sortableChildren = true;
        container.addChild(button, this._player);

        this._bg.addChild(container);
        container.pivot.set(container.width * 0.5, container.height * 0.5);
        container.position.set(this._bg.width * 0.5, this._bg.height - DEFAULT_Y_OFF);

        uiAlignCenter(container, button);
        uiAlignCenter(container, this._player, button.width * 0.5, button.height * 0.5 + 110);

        if (!scripted) {
            button.onPress = this.onPlayerTap.bind(this);
        }

        this._playerView = container;
        this._playerView.zIndex = 10;
    }

    private _createHeader(shopName: string): Container {
        const container = new Container();
        this._nameFrame = Sprite.from(manifest.headerFrame);
        this._name = new BasicText({
            text: shopName,
            style: {
                fill: '#FFF',
                fontSize: 34,
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowDistance: 6,
                dropShadowColor: 0x0,
                dropShadowAlpha: 0.25,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'left',
            },
        });

        this._nameFrame.addChild(this._name);
        this._alignName();

        uiAlignCenterX(this._bg, this._nameFrame);
        this._name.zIndex = MAIN_UI_Z;
        container.addChild(this._nameFrame);

        container.y = 6;
        return container;
    }

    private async onPlayerTap(forcedDialog?: string) {
        if (this._underlayInput) this._underlayInput.enabled = false;
        if (this._isInteracting) {
            this._skipSpeechCount++;
            return;
        }

        this._isInteracting = true;
        trackPlayerTap();

        this._skipSpeechCount = 0;

        const bubble = await this.spawnPlayerTapBubble(forcedDialog);

        if (this._skipSpeechCount < 2) {
            // if tapped 3+ times skip sleep after bubble.
            for (let i = 0; i < 10; i++) {
                if (this._skipSpeechCount > 1) break; // can be mutated from another tap callback, break if needed.
                await sleep(0.2); // sleep 2s total
            }
        }

        this._isInteracting = false;

        await bubble.animate().add(bubble, { alpha: 0 }, 0.3, tween.pow2Out);
        bubble.removeSelf();
        this._skipSpeechCount = 0;
        this._underlayInput.enabled = false;
    }

    private async onMakeCakes() {
        this._despawnPointer();
        await new MakeCakeFlow().execute();
    }

    private async _showNotification(text: string): Promise<void> {
        this._notificationAnimation?.cancel();
        this._notificationText?.destroy();
        this._notificationText = null;

        this._notificationText = new BasicText({
            text,
            style: {
                fill: '#FFF',
                fontSize: 46,
                lineJoin: 'round',
                fontWeight: 'bold',
                stroke: 0x0,
                strokeThickness: 4,
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.6,
                dropShadowDistance: 2,
                align: 'center',
            },
        });

        this._notificationText.pivot.set(this._notificationText.width * 0.5, this._notificationText.height * 0.5);
        this._notificationText.zIndex = 9000;
        this._bg.addChild(this._notificationText);
        uiAlignCenter(this._bg, this._notificationText, 0, -60);
        this._notificationText.alpha = 0;
        this._notificationAnimation = this._notificationText
            .animate()
            .add(this._notificationText, { alpha: 1 }, 0.2, tween.pow2In)
            .wait(3.75)
            .add(this._notificationText, { alpha: 0 }, 0.2, tween.pow2Out)
            .then(() => {
                this._notificationText?.destroy();
                this._notificationText = null;
            });
    }

    public async spawnCakesButton(opts: { animated?: boolean; glow?: boolean }): Promise<void> {
        const { animated, glow } = opts;

        let iconGlow;
        if (glow) {
            iconGlow = Sprite.from(manifest.glow);
            iconGlow.pivot.set(iconGlow.width * 0.5, iconGlow.height * 0.5);

            iconGlow.scale.set(1.45);
            iconGlow
                .animate()
                .add(iconGlow, { alpha: 0.6 }, 0.75, tween.pow2In)
                .add(iconGlow, { alpha: 1 }, 0.75, tween.pow2Out)
                .loop();
            iconGlow
                .animate()
                .set(iconGlow, { rotation: 0 })
                .add(iconGlow, { rotation: Math.PI * 2 }, 7, tween.linear)
                .loop();

            this._bg.addChild(iconGlow);

            uiAlignBottom(this._bg, iconGlow, 325);
            uiAlignCenterX(this._bg, iconGlow);

            this._cakesButtonGlow = iconGlow;
        }

        const cakesButton = (this._cakesButton = new ImageButton({
            image: manifest.playButton,
            slice: {
                width: 338,
                height: 108,
                left: 70,
                right: 70,
                top: 50,
                bottom: 50,
            },
        }));

        const label = new BasicText({
            text: '[buttonMakeCakes]',
            style: {
                fill: '#915735',
                fontSize: 32,
                fontWeight: 'bolder',
                lineJoin: 'round',
                align: 'right',
            },
        });

        const icon = Sprite.from(manifest.iconCook);

        cakesButton.button.addChild(icon, label);
        uiAlignCenter(cakesButton.button, icon, -105, -5);
        uiAlignCenter(cakesButton.button, label, 25, -5);
        cakesButton.onPress = this.onMakeCakes.bind(this);

        this._bg.addChild(cakesButton);
        uiAlignBottom(this._bg, cakesButton, -6);
        uiAlignCenterX(this._bg, cakesButton);

        if (animated) {
            if (iconGlow) {
                iconGlow.alpha = 0;
                iconGlow.animate().add(iconGlow, { alpha: 1 }, 0.3, tween.pow2In);
            }

            cakesButton.alpha = 0;
            await cakesButton.animate().add(cakesButton, { alpha: 1 }, 0.3, tween.pow2In).promise();
        }
    }

    public async despawnCakesButton(opts?: { animated: boolean }) {
        const { animated } = opts;
        if (animated) {
            await this._cakesButton.animate().add(this._cakeFrame, { alpha: 0 }, 0.3, tween.pow2Out).promise();
        }
        this._cakesButton?.removeSelf();
        this._cakesButton = null;
    }

    private async _spawnPointer() {
        this._pointer = new Pointer({ type: 'hand' });
        this._pointer.zIndex = 5;
        this._player.addChild(this._pointer);
        uiAlignCenterX(this._player, this._pointer, -60);
        const x = this._pointer.x;
        const y = this._pointer.y - 130;

        this._pointer.position.set(x, y);
        this._pointer.scale.set(0);

        setTimeout(() => {
            // pointer already despawned due to tap before timeout finished
            if (!this._pointer) return;
            this._pointerAnimation = this._pointer
                .animate()
                .add(
                    this._pointer.scale,
                    { x: this._playerView.scale.x * POINTER_SCALE, y: POINTER_SCALE },
                    0.35,
                    tween.backOut(1.2),
                )
                .add(this._pointer.position, { y: y + 8 }, 0.8, tween.powNInOut(1.1))
                .add(this._pointer.position, { y: y - 8 }, 0.8, tween.powNInOut(1.1))
                .loop();
        }, 4000);
    }

    private _alignCakeFrame() {
        uiAlignCenterX(this._bg, this._cakeFrame);
        uiAlignBottom(this._bg, this._cakeFrame, -114);
    }

    private _alignName() {
        uiSizeToFit(this._name, 272, 100);
        uiAlignCenter(this._nameFrame, this._name, 2, -2);
    }

    private async _onRecipeStepPress(opts: { cakeId: CakeItemId; popup: RecipePopup }) {
        const { cakeId, popup } = opts;
        const { goToPuzzle } = await new RecipeStepFlow({ screen: this, popup, cakeId }).execute();
        // not enough stars and player decided to abort and play for more stars
        if (goToPuzzle) {
            await new PuzzlePlayFlow().execute();
        }
    }

    // timer or bake icon depending state
    private _spawnCakeIcon(cake: ImageButton, cakeId: CakeItemId, cakeStatus: ProduceEntry) {
        const scaleAnim = async (view: Container, defaultScale = 1) => {
            view.alpha = 0;
            view.animate().add(view, { alpha: 1 }, 0.5, tween.linear).promise();

            view.animate()
                .add(view.scale, { x: defaultScale + 0.34, y: defaultScale + 0.34 }, 0.7, tween.pow2InOut)
                .add(view.scale, { x: defaultScale + 0.28, y: defaultScale + 0.28 }, 0.7, tween.pow2InOut)
                .loop();
        };

        const spawnActive = () => {
            // ready for action, indidate using action step icon
            icon = Sprite.from(lazyManifest.iconActive);
            icon.pivot.set(icon.width * 0.5, icon.height * 0.5);
            cake.button.addChild(icon);
            icon.x = 180;
            icon.y = 60;
            scaleAnim(icon);
        };

        const finishTime = getStepFinishTime(cakeId, cakeStatus);
        let activeTimer = false;
        let icon: Sprite;
        if (app.server.now() < finishTime) {
            activeTimer = true;
            icon = Sprite.from(manifest.timer);
            const scale = 1.25;
            icon.scale.set(scale);
            icon.pivot.set(icon.width * 0.5, icon.height * 0.5);
            cake.button.addChild(icon);
            icon.x = 180;
            icon.y = 60;
            scaleAnim(icon, scale);
        } else {
            spawnActive();
        }

        const update = () => {
            const timeLeft = finishTime - app.server.now();
            if (timeLeft <= 0) {
                if (activeTimer) {
                    activeTimer = false; // toggle to avoid re-rendering multople times
                    // remove and re-render
                    icon.destroy();
                    spawnActive();
                }
            }
        };

        // start timer
        update();
        pixiSetInterval(cake, () => update(), 0.9);
    }

    private async _spawnTrayAnimation(opts: { type: 'oven' | 'fridge'; finish: boolean; icon: string }) {
        const iconPromise = app.resource.loadAsset(opts.icon);
        const { type, finish, icon } = opts;

        const assetMap = {
            oven: {
                base: lazyManifest.oven,
                tray: lazyManifest.tray,
            },
            fridge: {
                base: lazyManifest.fridge,
                tray: lazyManifest.fridgeTray,
            },
        };

        const base = Sprite.from(assetMap[type].base);
        const tray = Sprite.from(assetMap[type].tray);

        const fadeOverlay = uiCreateQuad(0x0, 0.7, this._bg.width, this._bg.height);
        const mask = uiCreateMask(900, 422);
        base.mask = mask;
        base.addChild(mask);
        base.zIndex = MAIN_UI_Z;
        fadeOverlay.zIndex = MAIN_UI_Z;

        await iconPromise;
        const cake = Sprite.from(icon);
        cake.scale.set(0.84);

        if (finish) {
            const trayScale = 0.8;
            tray.scale.set(trayScale);
            tray.pivot.set(tray.width * 0.5, tray.height * 0.5);
            tray.addChild(cake);
            uiAlignCenter(tray, cake, 45, -82);

            base.addChild(tray);
            uiAlignCenterX(base, tray);
            tray.y = 330;
            this.base.addChild(fadeOverlay, base);

            uiAlignCenter(this.base, fadeOverlay);
            uiAlignCenter(this.base, base);

            tray.animate().add(tray.position, { y: tray.y + 70 }, 2.8, tween.pow2InOut);
            tray.animate().add(tray.scale, { x: 1, y: 1 }, 2.8, tween.pow2InOut);

            const fadeTime = 0.3;
            base.alpha = 0;
            fadeOverlay.alpha = 0;
            fadeOverlay.animate().add(fadeOverlay, { alpha: 0.7 }, fadeTime * 0.5, tween.pow2In);

            await base
                .animate()
                .add(base, { alpha: 1 }, fadeTime, tween.pow2In)
                .wait(2.5)
                .add(base, { alpha: 0 }, fadeTime, tween.pow2In)
                .promise();
        } else {
            tray.pivot.set(444 * 0.5, 278 * 0.5);
            tray.addChild(cake);
            uiAlignCenter(tray, cake, 0, -110);

            base.addChild(tray);
            uiAlignCenterX(base, tray);
            tray.y = 400;
            this.base.addChild(fadeOverlay, base);

            uiAlignCenter(this.base, fadeOverlay);
            uiAlignCenter(this.base, base);

            base.zIndex = MAIN_UI_Z;
            fadeOverlay.zIndex = MAIN_UI_Z;

            tray.animate().add(tray.position, { y: tray.y - 70 }, 2.8, tween.pow2InOut);
            tray.animate().add(tray.scale, { x: 0.8, y: 0.8 }, 2.8, tween.pow2InOut);

            const fadeTime = 0.3;
            base.alpha = 0;
            fadeOverlay.alpha = 0;
            fadeOverlay.animate().add(fadeOverlay, { alpha: 0.7 }, fadeTime * 0.5, tween.pow2In);

            await base
                .animate()
                .add(base, { alpha: 1 }, fadeTime, tween.pow2In)
                .wait(2.5)
                .add(base, { alpha: 0 }, fadeTime, tween.pow2In)
                .promise();
        }

        base.removeSelf();
        fadeOverlay.removeSelf();
    }

    private async _spawnStovePotAnimation(opts: { finish: boolean; icon?: string }) {
        const { finish, icon } = opts;
        const stove = Sprite.from(lazyManifest.stove);
        const fadeOverlay = uiCreateQuad(0x0, 0.7, this._bg.width, this._bg.height);
        const mask = uiCreateMask(900, 422);
        stove.mask = mask;
        stove.addChild(mask);
        this.base.addChild(fadeOverlay, stove);
        uiAlignCenter(this.base, fadeOverlay);
        uiAlignCenter(this.base, stove);
        stove.zIndex = MAIN_UI_Z;
        fadeOverlay.zIndex = MAIN_UI_Z;
        const fadeTime = 0.3;
        stove.alpha = 0;
        fadeOverlay.alpha = 0;
        fadeOverlay.animate().add(fadeOverlay, { alpha: 0.7 }, fadeTime * 0.5, tween.pow2In);

        if (finish) {
            // static with custom icon and with glow
            if (icon) {
                const iconPromise = app.resource.loadAsset(opts.icon);
                const iconGlow = Sprite.from(manifest.glow);
                iconGlow.pivot.set(iconGlow.width * 0.5, iconGlow.height * 0.5);
                iconGlow.scale.set(1.8);

                iconGlow
                    .animate()
                    .add(iconGlow, { alpha: 0.6 }, 0.75, tween.pow2In)
                    .add(iconGlow, { alpha: 1 }, 0.75, tween.pow2Out)
                    .loop();
                iconGlow
                    .animate()
                    .set(iconGlow, { rotation: 0 })
                    .add(iconGlow, { rotation: Math.PI * 2 }, 7, tween.linear)
                    .loop();

                stove.addChild(iconGlow);
                uiAlignCenter(stove, iconGlow, -18, 5);
                await iconPromise;
                // pot with item
                const icon = Sprite.from(opts.icon);
                uiAlignCenter(stove, icon, 28, -36);
                stove.addChild(icon);
            }
        } else {
            const arm = Sprite.from(lazyManifest.stoveArm);
            const pot = Sprite.from(lazyManifest.stovePot);
            uiAlignCenter(stove, pot, 28, 17);
            stove.addChild(arm, pot);
            const defaultY = -70;
            const defaultX = 400;
            arm.y = defaultY;
            arm.x = defaultX;

            arm.animate()
                .add(arm.position, { y: -50 }, 3.5, tween.pow2InOut)
                .add(arm.position, { y: defaultY }, 3.5, tween.pow2InOut)
                .loop();
            arm.animate()
                .add(arm.position, { x: 320 }, 1.5, tween.pow2InOut)
                .add(arm.position, { x: defaultX }, 1.5, tween.pow2InOut)
                .loop();
        }

        await stove
            .animate()
            .add(stove, { alpha: 1 }, fadeTime, tween.pow2In)
            .wait(3)
            .add(stove, { alpha: 0 }, fadeTime, tween.pow2In)
            .promise();

        stove.removeSelf();
        fadeOverlay.removeSelf();
    }

    private async _spawnStovePanAnimation() {
        const stove = Sprite.from(lazyManifest.stove);
        const arm = Sprite.from(lazyManifest.stoveArm);
        const pan = Sprite.from(lazyManifest.stovePan);
        uiAlignCenter(stove, pan, 40, 60);

        const fadeOverlay = uiCreateQuad(0x0, 0.7, this._bg.width, this._bg.height);

        const mask = uiCreateMask(900, 422);
        stove.mask = mask;
        stove.addChild(mask);

        stove.addChild(arm, pan);
        const defaultY = -20;
        const defaultX = 450;
        arm.y = defaultY;
        arm.x = defaultX;
        this.base.addChild(fadeOverlay, stove);

        uiAlignCenter(this.base, fadeOverlay);
        uiAlignCenter(this.base, stove);

        stove.zIndex = MAIN_UI_Z;
        fadeOverlay.zIndex = MAIN_UI_Z;

        arm.animate()
            .add(arm.position, { y: 0 }, 3.5, tween.pow2InOut)
            .add(arm.position, { y: defaultY }, 3.5, tween.pow2InOut)
            .loop();

        arm.animate()
            .add(arm.position, { x: 300 }, 1.5, tween.pow2InOut)
            .add(arm.position, { x: defaultX }, 1.5, tween.pow2InOut)
            .loop();

        const fadeTime = 0.3;
        stove.alpha = 0;
        fadeOverlay.alpha = 0;
        fadeOverlay.animate().add(fadeOverlay, { alpha: 0.7 }, fadeTime * 0.5, tween.pow2In);

        await stove
            .animate()
            .add(stove, { alpha: 1 }, fadeTime, tween.pow2In)
            .wait(3)
            .add(stove, { alpha: 0 }, fadeTime, tween.pow2In)
            .promise();

        stove.removeSelf();
        fadeOverlay.removeSelf();
    }

    private async _cakeTapHandler(cakeId: CakeItemId) {
        if (app.server.state.bakery.produce[cakeId].tapped) return;
        await app.server.invoke.updateTapped({ cakeId });
        this._newRecipeGlowMap[cakeId]?.removeSelf();
        this._newRecipeGlowMap[cakeId] = null;
    }

    private _createTimer() {
        const timerFrame = new NineSlicePlane(Texture.from(manifest.timerFrame), 30, 30, 30, 30);
        timerFrame.width = 240;
        timerFrame.height = 66;
        const timerIcon = Sprite.from(manifest.timer);

        timerFrame.addChild(timerIcon);
        uiAlignCenter(timerFrame, timerIcon);
        timerIcon.x -= 86;

        const time = new BasicText({
            text: '',
            style: {
                fill: 0x00,
                fontSize: 30,
                fontWeight: 'bold',
                lineJoin: 'round',
                stroke: 0xdf886e,
                strokeThickness: 4,
                align: 'center',
            },
        });

        timerFrame.addChild(time);

        const finishTime = app.server.now() + timeFromComponents({ minutes: 15 });
        const update = () => {
            const timeLeft = finishTime - app.server.now();
            if (timeLeft > 0) {
                time.text = timeFormatCountdown(timeToComponents(finishTime - app.server.now()));
            } else {
                time.text = '[buttonDone]';
            }

            uiAlignCenter(timerFrame, time, 18);
        };

        // start timer
        update();
        pixiSetInterval(this.base, () => update(), 1);

        return timerFrame;
    }
}
