import { Vector2 } from '@play-co/odie';
import { Assets } from 'pixi.js';

import { BigBombAnimation } from '../../animations/BigBombAnimation';
import { BombAnimation } from '../../animations/BombAnimation';
import { despawnOverlayEntity, spawnOverlayEntity } from '../../entities/OverlayEntity';
import { GameScene } from '../../GameScene';
import { CollisionTracker } from '../../util/CollisionTracker';
import { IEffect } from './IEffect';

// types
//-----------------------------------------------------------------------------
export type BombEffectOptions = {
    position: Vector2;
    radius: number;
    big: boolean;
};

/*
    bomb explosion effect
*/
export class BombEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _options: BombEffectOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: BombEffectOptions) {
        this._scene = scene;
        this._options = options;
    }

    static assets(options: Partial<BombEffectOptions>): string[] {
        return options.big ? BigBombAnimation.assets() : BombAnimation.assets();
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // animate
        await this._animate();

        // load assets
        await Assets.load(BombEffect.assets(this._options));

        // attack neighbors
        this._attackNeighbors();
    }

    // private: actions
    //-------------------------------------------------------------------------
    private _attackNeighbors() {
        const { radius, position } = this._options;
        const from = position.clone().subScalar(radius);
        const to = from.clone().addScalar(radius * 2 + 1);
        const map = this._scene.sessionEntity.c.map;
        const collisionTracker = new CollisionTracker();
        const area = new Vector2();

        // for each cell in affected area
        for (area.x = from.x; area.x < to.x; ++area.x) {
            for (area.y = from.y; area.y < to.y; ++area.y) {
                collisionTracker.collideAt(map, area, 'attack', { type: 'bomb' });
            }
        }
    }

    // private: animation
    //-------------------------------------------------------------------------
    private async _animate() {
        const { big, position } = this._options;

        // select animation
        const animation = big ? new BigBombAnimation({ position }) : new BombAnimation({ position });

        // spawn animation as overlay entity
        const entity = spawnOverlayEntity(this._scene, position, { width: 1, height: 1 }, () => animation);

        // run animation
        await animation.start();

        // despawn entity
        despawnOverlayEntity(this._scene, entity);
    }
}
