import { Container } from 'pixi.js';

import app from '../../../index.entry';
import { IFlow } from '../../../lib/pattern/IFlow';
import NakedPromise from '../../../lib/pattern/NakedPromise';
import { tween } from '../../../lib/util/tweens';
import bakery from '../../../replicant/defs/bakery';
import player from '../../../replicant/defs/player';
import { sleep } from '../../../replicant/util/jsTools';
import { NavLayer } from '../../defs/nav';
import {
    trackTutorialFinish,
    trackTutorialStart,
    trackTutorialStepFinish,
    trackTutorialStepStart,
} from '../../lib/analytics/tutorial';
import { CakeListPopup } from '../../lib/ui/popups/CakeListPopup';
import { HomeScreen } from '../../main/home/HomeScreen';
import { IntroScreen } from '../../main/home/IntroScreen';
import { NamePopup } from '../../main/home/NamePopup';

// types
//-----------------------------------------------------------------------------
const id = 'bakery';

export class TutorialFlow implements IFlow {
    // fields
    //-------------------------------------------------------------------------
    private _screen: HomeScreen;
    private _ladyPromise: Promise<any[]>;
    private _girlPromise: Promise<any[]>;

    // state
    private _tutorialOffset: number[];
    // step map
    private _steps = [
        { name: 'intro', handler: this._intro, substeps: 2 },
        { name: 'customer1', handler: this._customer1, substeps: 5 },
        { name: 'name', handler: this._name, substeps: 3 },
        { name: 'makeCake', handler: this._makeCake, substeps: 5 },
        { name: 'customer2', handler: this._customer2, substeps: 4 },
        { name: 'break', handler: this._break, substeps: 1 },
    ];

    // props
    //-------------------------------------------------------------------------
    // step
    private get step(): number {
        return app.server.state.tutorial.step;
    }

    private set step(step: number) {
        app.server.invoke.tutorialSetStep({ step });
    }

    // init
    //-------------------------------------------------------------------------
    constructor() {
        this._tutorialOffset = this._steps.reduce(
            (acc, cur) => {
                acc.push(acc[acc.length - 1] + cur.substeps);
                return acc;
            },
            [0],
        );
    }

    // impl
    //-------------------------------------------------------------------------
    async execute(): Promise<void> {
        // this.step = 2;

        // run tutorial
        await this._run();

        // complete
        await this._complete();
    }

    // private: control
    //-------------------------------------------------------------------------
    private async _run() {
        // track overall tutorial start
        trackTutorialStart({ stepIndex: 0, stepName: id });

        // for each tutorial step
        for (; this.step < this._steps.length; this.step++) {
            const entry = this._steps[this.step];
            const stepName = `${id}.${entry.name}`;

            // execute tutorial
            await entry.handler.call(this, {
                stepName,
            });
        }
    }

    private async _complete() {
        // complete tutorial
        await app.server.invoke.tutorialComplete();

        trackTutorialFinish({ stepIndex: this._tutorialOffset[this.step], stepName: id });

        // close any popups
        await app.nav.closeLayer(NavLayer.popup);
    }

    // private: steps
    //-------------------------------------------------------------------------
    private async _intro() {
        let stepIndex = this._tutorialOffset[this.step];
        trackTutorialStepStart({ stepIndex, stepName: `intro1` });
        const introScreen = (await app.nav.open('introScreen')) as IntroScreen;
        // preload next scene while we wait for tap
        app.nav.preload('homeScreen');

        // allow for animation to start before spawning bubble+tap
        await sleep(1.5);
        // bubble with tap block
        const introPromise = new NakedPromise();
        await introScreen.spawnBubbleTap({ speech: '[tutorialDialog0]', narrator: true }, introPromise);
        await introPromise;

        // deep copy to void mutating the config
        const mockState = bakery.defaultCakeState.map((cake) => ({ ...cake }));
        mockState[mockState.length - 1].amount = 5;
        if (!this._screen) {
            this._screen = (await app.nav.open('homeScreen', {
                scripted: true,
                animationOverride: 'excited',
                cakes: mockState,
            })) as HomeScreen;
        }

        trackTutorialStepFinish({ stepIndex, stepName: `intro1` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `intro2` });

        // bubble with tap block
        const tapPromise = new NakedPromise();
        await this._screen.spawnBubbleTap({ speech: '[tutorialDialog1]' }, tapPromise);
        await tapPromise;

        this._screen.despawnCakes({ animated: true });
        trackTutorialStepFinish({ stepIndex, stepName: `intro2` });
    }

    private async _customer1() {
        let stepIndex = this._tutorialOffset[this.step];
        const mockState = bakery.defaultCakeState.map((cake) => ({ ...cake }));
        mockState[mockState.length - 1].amount = 5;
        if (!this._screen) {
            this._screen = (await app.nav.open('homeScreen', {
                scripted: true,
                animationOverride: 'excited',
            })) as HomeScreen;
        }

        this._ladyPromise = this._screen.preloadGrandma();
        trackTutorialStepStart({ stepIndex, stepName: `door_grandma` });
        await this._screen.spawnDoorAnimation();

        this._screen.spawnCakes({
            cakes: mockState,
            skipUnlockLevels: true,
            animated: true,
        });

        this._screen.playPlayerAnimation('happy', true);

        const tapPromise = new NakedPromise();
        await this._screen.spawnBubbleTap({ speech: '[tutorialDialog2]' }, tapPromise);
        await tapPromise;
        trackTutorialStepFinish({ stepIndex, stepName: `door_grandma` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `grandma_dialog1` });

        this._screen.despawnCakes({ animated: true });

        this._screen.playPlayerAnimation('idle', true);
        // ----- CUSTOMER DIALOG 1
        const customerPromise1 = new NakedPromise();
        await this._ladyPromise;
        this._screen.spawnCustomerAnimation('grandma', customerPromise1);
        await this._screen.spawnBubbleTap({ speech: '[tutorialDialog3]', customer: true }, customerPromise1);
        await customerPromise1;
        // ------------------------
        trackTutorialStepFinish({ stepIndex, stepName: `grandma_dialog1` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `tap_apple_tart` });

        this._screen.playPlayerAnimation('happy', true);
        const bubble0 = await this._screen.spawnBubbleScripted({ speech: '[tutorialDialog4]' });
        const { cakeButtons } = await this._screen.spawnCakes({
            cakes: mockState,
            skipUnlockLevels: true,
            animated: true,
        });

        const cakePromise = new NakedPromise();
        const cakeButton = cakeButtons[cakeButtons.length - 1];
        // re-enable button all disabled by default if spawned from flow
        cakeButton.interactive = true;

        cakeButton.onPress = async () => cakePromise.resolve();
        app.nav.open('tipScreen', {
            type: 'hand',
            motion: 'tap',
            allowInput: true,
            targets: [cakeButton.getBounds()],
        });
        await cakePromise;

        trackTutorialStepFinish({ stepIndex, stepName: `tap_apple_tart` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `grandma_dialog2` });

        this._screen.playPlayerAnimation('idle', true);
        this._despawnView(bubble0);
        app.nav.close('tipScreen');
        // this._screen.spawnTableItem(cakeItemPropsMap.appleTart.icon, true);
        await this._screen.spawnTableItemFromShowCase({ cakeId: 'appleTart', forcedAmount: 4 });
        await sleep(0.2); // small pause before customer

        await this._screen.despawnCakes({ animated: true });

        const customerPromise2 = new NakedPromise();
        this._screen.spawnCustomerAnimation('grandma', customerPromise2);
        await this._screen.spawnBubbleTap({ speech: '[tutorialDialog5]', customer: true }, customerPromise2);
        await customerPromise2;

        trackTutorialStepFinish({ stepIndex, stepName: `grandma_dialog2` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `chef_nevrous` });

        this._screen.despawnTableItem();

        this._screen.playPlayerAnimation('happy', true);

        this._screen.spawnCakes({
            cakes: bakery.defaultCakeState,
            skipUnlockLevels: true,
            animated: true,
        });

        const finalPromise = new NakedPromise();
        await this._screen.spawnBubbleTap({ speech: '[tutorialDialog6]' }, finalPromise);
        await finalPromise;
        this._screen.despawnCakes({ animated: true });
        trackTutorialStepFinish({ stepIndex, stepName: `chef_nevrous` });
    }

    private async _name() {
        let stepIndex = this._tutorialOffset[this.step];
        if (!this._screen) {
            this._screen = (await app.nav.open('homeScreen', {
                scripted: true,
            })) as HomeScreen;
        }

        this._screen.playPlayerAnimation('idle', true);

        trackTutorialStepStart({ stepIndex, stepName: `name1` });
        const bubble = await this._screen.spawnBubbleScripted({ speech: '[tutorialDialog7]' });

        const namePopupId = 'namePopup';
        const namePromise = new NakedPromise<string>();

        const isEn = app.settings.language === 'en';
        const preFilledName = isEn ? player.defaultNameEN : player.defaultNameJA;

        (await app.nav.open(namePopupId, { onConfirm: namePromise, preFilledName })) as NamePopup;
        const name = await namePromise;
        this._despawnView(bubble);
        // render new name
        this._screen.setSetName(name);

        this._screen.playPlayerAnimation('excited', true);

        const popupPromise = app.nav.close(namePopupId);

        //make sure keyboard and popup has time to close and then force a resize to fix layout edge case on android devices
        popupPromise.then(() => sleep(0.4)).then(() => this._screen.forcedResize());

        trackTutorialStepFinish({ stepIndex, stepName: `name1` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `name2` });

        const happyPromise = new NakedPromise();
        await this._screen.spawnBubbleTap({ speech: `[tutorialDialog8|${name}]` }, happyPromise);
        // store name before exiting the step
        await Promise.all([happyPromise, app.server.invoke.playerSetName({ name })]);

        this._screen.playPlayerAnimation('happy', true);

        this._screen.spawnCakes({
            cakes: bakery.defaultCakeState,
            skipUnlockLevels: true,
            animated: true,
        });

        trackTutorialStepFinish({ stepIndex, stepName: `name2` });
    }

    private async _makeCake() {
        let stepIndex = this._tutorialOffset[this.step];
        if (!this._screen) {
            this._screen = (await app.nav.open('homeScreen', {
                scripted: true,
                cakes: bakery.defaultCakeState,
            })) as HomeScreen;
        }
        trackTutorialStepStart({ stepIndex, stepName: `tap_make_cakes` });

        const cakeButtonPromise = new NakedPromise();

        const bubble0 = await this._screen.spawnBubbleScripted({ speech: '[tutorialDialog9]' });

        this._screen.playPlayerAnimation('idle', true);

        await this._screen.spawnCakesButton({ animated: true });

        const button = this._screen.makeCakeButton;
        button.onPress = async () => cakeButtonPromise.resolve();
        app.nav.open('tipScreen', {
            type: 'hand',
            motion: 'tap',
            allowInput: true,
            targets: [button.getBounds()],
        });
        await cakeButtonPromise;

        trackTutorialStepFinish({ stepIndex, stepName: `tap_make_cakes` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `cake_list` });

        this._despawnView(bubble0);
        await app.nav.close('tipScreen');

        const mangoIndex = 4;
        const cakePopup = (await app.nav.open('cakeListPopup', {
            cakes: bakery.defaultCakePopupList,
            scrollIndex: mangoIndex,
            normalButtonIndex: mangoIndex,
            lockScroll: true,
        })) as CakeListPopup;

        const popupPromise = new NakedPromise();
        cakePopup.itemButtons.forEach((button, index) => {
            if (index === mangoIndex) {
                button.onPress = async () => popupPromise.resolve();
            } else {
                button.onPress = null;
            }
        });

        app.nav.open('tipScreen', {
            type: 'hand',
            motion: 'tap',
            allowInput: true,
            targets: [cakePopup.itemButtons[mangoIndex].getBounds()],
        });
        await popupPromise;
        app.nav.close('cakeListPopup');
        app.nav.close('tipScreen');
        this._screen.despawnCakesButton({ animated: true });

        trackTutorialStepFinish({ stepIndex, stepName: `cake_list` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `baking` });

        const fastForwardView = await this._screen.spawnFastForwardView({ animated: true });
        this._screen.playPlayerAnimation('cooking', true);
        const tapPromise = new NakedPromise();
        await this._screen.spawnBubbleTap({ speech: '[tutorialDialog10]' }, tapPromise);
        await tapPromise;

        trackTutorialStepFinish({ stepIndex, stepName: `baking` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `finish_baking` });

        const bubble1 = await this._screen.spawnBubbleScripted({ speech: '[tutorialDialog11]' });

        const finishPromise = new NakedPromise();
        await this._screen.fadeInFastForwardButton();
        const finishButton = this._screen.fastForwardButton;
        finishButton.onPress = async () => finishPromise.resolve();
        app.nav.open('tipScreen', {
            type: 'hand',
            motion: 'tap',
            allowInput: true,
            targets: [finishButton.getBounds()],
        });
        await finishPromise;
        this._screen.playPlayerAnimation('happy', true);
        this._despawnView(bubble1);
        this._despawnView(fastForwardView);
        app.nav.close('tipScreen');

        const mangoMockState = bakery.defaultCakeState.map((cake) => ({ ...cake }));
        mangoMockState.push({ cakeId: 'mangoMousseCake', amount: 1 });
        const { cakeButtons } = await this._screen.spawnCakes({
            cakes: mangoMockState,
            skipUnlockLevels: true,
            animated: true,
            hiddenCake: 'mangoMousseCake',
        });

        await this._screen.spawnTableItemToShowCase({ cakeId: 'mangoMousseCake' });

        trackTutorialStepFinish({ stepIndex, stepName: `finish_baking` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `yay_mango` });

        const mangoPromise = new NakedPromise();
        await this._screen.spawnBubbleTap({ speech: '[tutorialDialog12]' }, mangoPromise);
        await mangoPromise;

        this._screen.despawnTableItem();

        trackTutorialStepFinish({ stepIndex, stepName: `yay_mango` });
    }

    private async _customer2() {
        let stepIndex = this._tutorialOffset[this.step];
        const mangoMockState = bakery.defaultCakeState.map((cake) => ({ ...cake }));
        mangoMockState.push({ cakeId: 'mangoMousseCake', amount: 1 });
        if (!this._screen) {
            this._screen = (await app.nav.open('homeScreen', {
                scripted: true,
                cakes: mangoMockState,
            })) as HomeScreen;
        }
        trackTutorialStepStart({ stepIndex, stepName: `door_girl` });

        this._screen.despawnCakes({ animated: true });
        this._girlPromise = this._screen.preloadGirl();
        await this._screen.spawnDoorAnimation();

        this._screen.spawnCakes({
            cakes: mangoMockState,
            skipUnlockLevels: true,
            animated: true,
        });

        this._screen.playPlayerAnimation('happy', true);

        const welcomePromise = new NakedPromise();
        await this._screen.spawnBubbleTap({ speech: '[tutorialDialog13]' }, welcomePromise);
        await welcomePromise;

        trackTutorialStepFinish({ stepIndex, stepName: `door_girl` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `girl_dialog1` });

        this._screen.playPlayerAnimation('idle', true);
        this._screen.despawnCakes({ animated: true });

        // ----- CUSTOMER DIALOG 1
        const customerPromise1 = new NakedPromise();

        await this._girlPromise;
        this._screen.spawnCustomerAnimation('girl', customerPromise1);
        await this._screen.spawnBubbleTap({ speech: '[tutorialDialog14]', customer: true }, customerPromise1);
        await customerPromise1;

        this._screen.playPlayerAnimation('happy', true);

        trackTutorialStepFinish({ stepIndex, stepName: `girl_dialog1` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `tap_mango_mousse` });

        const bubble0 = await this._screen.spawnBubbleScripted({ speech: '[tutorialDialog15]' });

        const { cakeButtons } = await this._screen.spawnCakes({
            cakes: mangoMockState,
            skipUnlockLevels: true,
            animated: true,
        });

        const mangoButton = cakeButtons[cakeButtons.length - 1];
        // re-enable button all disabled by default if spawned from flow
        mangoButton.interactive = true;
        const mangoPromise = new NakedPromise();
        mangoButton.onPress = async () => mangoPromise.resolve();
        app.nav.open('tipScreen', {
            type: 'hand',
            motion: 'tap',
            allowInput: true,
            targets: [mangoButton.getBounds()],
        });
        await mangoPromise;
        trackTutorialStepFinish({ stepIndex, stepName: `tap_mango_mousse` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `girl_dialog2` });

        this._screen.playPlayerAnimation('idle', true);
        app.nav.close('tipScreen');
        this._despawnView(bubble0);

        // this._screen.spawnTableItem(cakeItemPropsMap.appleTart.icon, true);

        // allow for spawnTableItemFromShowCase to grab show case position and start the animation
        // we re-render the table with a empty slot in place of the mango cake
        sleep(0.28).then(async () => {
            // -- re-render table without mango to add empty slot where the mango cake is flying from
            await this._screen.despawnCakes({ animated: false });
            await this._screen.spawnCakes({
                // const { cakeButtons } = await this._screen.spawnCakes({
                cakes: bakery.defaultCakeState,
                skipUnlockLevels: true,
                animated: false,
            });
        });
        await this._screen.spawnTableItemFromShowCase({ cakeId: 'mangoMousseCake', hideShowcase: true });
        await sleep(0.2); // small pause before customer
        await this._screen.despawnCakes({ animated: true });

        const tapPromise = new NakedPromise();
        this._screen.spawnCustomerAnimation('girl', tapPromise);
        await this._screen.spawnBubbleTap({ speech: `[tutorialDialog16]`, customer: true }, tapPromise);
        await tapPromise;

        this._screen.despawnTableItem();

        this._screen.playPlayerAnimation('happy', true);

        this._screen.spawnCakes({
            cakes: bakery.defaultCakeState,
            skipUnlockLevels: true,
            animated: true,
        });

        trackTutorialStepFinish({ stepIndex, stepName: `girl_dialog2` });
    }

    private async _break() {
        if (!this._screen) {
            this._screen = (await app.nav.open('homeScreen', {
                scripted: true,
                animationOverride: 'happy',
                cakes: bakery.defaultCakeState,
            })) as HomeScreen;
        }

        const stepIndex = this._tutorialOffset[this.step];
        trackTutorialStepStart({ stepIndex, stepName: `breaktime` });
        // bubble with tap block
        const endPromise = new NakedPromise();
        await this._screen.spawnBubbleTap({ speech: '[tutorialDialog17]' }, endPromise);
        await endPromise;

        trackTutorialStepFinish({ stepIndex, stepName: `breaktime` });

        // ADD OLD CAKE/PRODUCE STATE TO MAKE IT EASY TO REVERT IF NEEDED
        await app.server.invoke.tutorialOven({ ovenTimestamp: app.server.now() });
    }

    // helper
    private async _despawnView(view: Container) {
        await view.animate().add(view, { alpha: 0 }, 0.3, tween.pow2Out);
        view.removeSelf();
    }
}
