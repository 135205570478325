import { UISchema } from '@play-co/debug-panel';

import app from '../../index.entry';
import {
    FirstSessionAssetKey,
    firstSessionOA,
    recipeOA,
    RecipeStepAssetKey,
} from '../../replicant/chatbot/messageTemplates';
import gameConfig from '../../replicant/defs/gameConfig';
import player from '../../replicant/defs/player';
import { LanguageId } from '../../replicant/defs/settings';
import { sleep } from '../../replicant/util/jsTools';
import { CustomerEnterFlow } from '../flows/customer/CustomerEnterFlow';
import { windowReload } from '../lib/util/device';
import { HomeScreen } from '../main/home/HomeScreen';
import { PuzzleScreen } from '../main/puzzle/PuzzleScreen';
import { NavLayer } from './nav';

export const cheatUi: UISchema = {
    Navigate: {
        Home: onHome,
        Reset: onReset,
        'Reset (skip tutorial)': onResetSkipTutorial,
    },
    Currencies: {
        'Reset Lives': onLivesReset,
        'Add Life': onLivesPlus,
        'Max Lives': onLivesMax,
        'Add 10 Stars': onStarsPlus,
        'Subtract 10 Stars': onStarsMinus,
    },
    Customers: {
        1: onCustomer1,
        2: onCustomer2,
        3: onCustomer3,
        4: onCustomer4,
    },
    Cakes: {
        'Finish Timed steps': onFinishTimedSteps,
    },
    Puzzle: {
        Open: onPuzzleOpen,
        Complete: onPuzzleComplete,
        Fail: onPuzzleFail,
        Level: {
            min: 1,
            max: gameConfig.puzzle.levels.max,
            step: 1,
            get: () => app.server.state.puzzle.level,
            set: async (value: number) => {
                console.log(`--- puzzle level: ${value}`);
                await app.server.invoke.puzzleSetLevel({ level: value });
            },
        },
    },
    Settings: {
        Language: {
            choices: ['en', 'ja'],
            get: () => app.settings.language,
            set: async (language: LanguageId) => {
                await app.settings.setLanguage(language);
                await windowReload();
            },
        },
    },
    Chatbot: {
        '1st Sess.': onSendFirstSessionOAs,
        RecipeSteps: onSendRecipeStepsOA,
    },
};

// events
//-----------------------------------------------------------------------------
async function onHome() {
    // navigate home
    await app.nav.open('homeScreen', {});
}

async function onReset() {
    // reset backend state
    await app.server.invoke.coreReset();

    // reload game
    await windowReload();
}

async function onSendFirstSessionOAs() {
    console.log(`-- onSendFirstSessionOAs`);
    for (const id of Object.keys(firstSessionOA) as FirstSessionAssetKey[]) {
        await app.server.invoke.sendFirstSessionOA({ id });
        console.log(`-- sent ${id}`);
        await sleep(0.1);
    }
}

async function onSendRecipeStepsOA() {
    console.log(`-- onSendRecipeStepsOA`);
    for (const id of Object.keys(recipeOA) as RecipeStepAssetKey[]) {
        await app.server.invoke.sendRecipeStepOA({ id });
        console.log(`-- sent ${id}`);
        await sleep(0.1);
    }
}

async function onResetSkipTutorial() {
    // reset backend state
    await app.server.invoke.coreReset();
    await app.server.invoke.cheatTutorialGrant(); // grant then complete
    await app.server.invoke.tutorialComplete();
    await app.server.invoke.playerSetName({ name: player.defaultNameEN }); // no tutorial so just add default
    // reload game
    await windowReload();
}

async function onEN() {
    await app.settings.setLanguage('en');
    await windowReload();
}

async function onJP() {
    await app.settings.setLanguage('ja');
    await windowReload();
}

async function onStarsPlus() {
    await app.server.invoke.cheatAddStars({ amount: 10 });
}

async function onStarsMinus() {
    await app.server.invoke.cheatAddStars({ amount: -10 });
}

async function onLivesReset() {
    await app.server.invoke.cheatLivesSet({ amount: 0 });
}

async function onLivesPlus() {
    await app.server.invoke.cheatLivesAdd({ amount: 1 });
}

async function onLivesMax() {
    await app.server.invoke.cheatLivesSet({ amount: gameConfig.lives.max });
}

async function onCustomer1() {
    console.log(`--- spawned customer 1`);
    await app.server.invoke.cheatAddCustomer({ customerEntry: 0 });
    const screen = (await app.nav.open('homeScreen', { scripted: true })) as HomeScreen;
    await new CustomerEnterFlow({ screen }).execute();
}

async function onCustomer2() {
    console.log(`--- spawned customer 2`);
    await app.server.invoke.cheatAddCustomer({ customerEntry: 1 });
    const screen = (await app.nav.open('homeScreen', { scripted: true })) as HomeScreen;
    await new CustomerEnterFlow({ screen }).execute();
}

async function onCustomer3() {
    console.log(`--- spawned customer 3`);
    await app.server.invoke.cheatAddCustomer({ customerEntry: 2 });
    const screen = (await app.nav.open('homeScreen', { scripted: true })) as HomeScreen;
    await new CustomerEnterFlow({ screen }).execute();
}

async function onCustomer4() {
    console.log(`--- spawned customer 4`);
    await app.server.invoke.cheatAddCustomer({ customerEntry: 3 });
    const screen = (await app.nav.open('homeScreen', { scripted: true })) as HomeScreen;
    await new CustomerEnterFlow({ screen }).execute();
}

async function onPuzzleOpen() {
    console.log(`--- puzzle open: ${app.server.state.puzzle.level}`);
    await app.nav.close('puzzleScreen');
    await app.nav.open('puzzleScreen');
}

async function onPuzzleComplete() {
    const puzzleController = (app.nav.screens.puzzleScreen?.instance as PuzzleScreen)?.controller;
    console.log(`--- puzzle complete`);
    if (puzzleController?.active) {
        puzzleController.forceComplete();
    }
}

async function onPuzzleFail() {
    const puzzleController = (app.nav.screens.puzzleScreen?.instance as PuzzleScreen)?.controller;
    console.log(`--- puzzle fail`);
    if (puzzleController?.active) {
        puzzleController.forceFail();
    }
}

async function onFinishTimedSteps() {
    console.log(`--- finish timed steps`);
    await app.server.invoke.cheatFinishedTimedSteps();

    void app.nav.closeLayer(NavLayer.popup);
    // refresh scene
    await app.nav.open('homeScreen');
}
