//TODO: _shapeSize no longer needed
import { Mesh, Texture } from 'pixi.js';

import { RegionType, SizeType } from '../../defs/types';
import { UberEffecs, UberMaterial } from '../materials/UberMaterial';
import { pixiSolveQuadVertices } from '../pixiTools';
import { NineSliceShape } from '../shapes/NineSliceShape';

/*
    pixi: performance NineSlicePlane
*/
export class MaterialNineSlicePlane extends Mesh<UberMaterial> {
    // fields
    //-------------------------------------------------------------------------
    private _shapeSize: SizeType;

    // properties
    //-------------------------------------------------------------------------
    public get shapeSize(): SizeType {
        return this._shapeSize;
    }

    public set shapeSize(shapeSize: SizeType) {
        this._shapeSize = shapeSize;
        this._update();
    }

    public override get width(): number {
        return this._shapeSize.width;
    }

    public override set width(width: number) {
        this._height = this._shapeSize.width = width;
        this._update();
    }

    public override get height(): number {
        return this._shapeSize.height;
    }

    public override set height(height: number) {
        this._width = this._shapeSize.height = height;
        this._update();
    }

    // init
    //-------------------------------------------------------------------------
    constructor(texture: Texture, slices: Partial<RegionType>, size: SizeType, effects: UberEffecs = {}) {
        const material = new UberMaterial(texture, effects);
        super(new NineSliceShape(slices, material.texture, size), material);
        this._shapeSize = size;
        material.size = size;
    }

    // impl
    //-----------------------------------------------------------------------------
    protected override _calculateBounds(): void {
        const vertices = pixiSolveQuadVertices(this, this._shapeSize.width, this._shapeSize.height);
        this._bounds.addQuad(vertices);
    }

    // private: updaters
    //-------------------------------------------------------------------------
    private _update(): void {
        // update nine slice shape region
        (this.geometry as NineSliceShape).size = this._shapeSize;

        // update material size
        this.material.size = this._shapeSize;
    }
}
