import * as lodash from 'lodash';

import { BlockId, blockPropsMap } from '../../../main/match2-odie/defs/block';
import { BlockDef, CellDef, defaultMapDef, MapDef } from '../../../main/match2-odie/defs/map';
import { MysteryItemRewardId, mysteryItemRewardPropsMap } from '../../../main/match2-odie/defs/reward';
import { mapValidateDef } from '../../../main/match2-odie/util/mapTools';
import { mapCellFormat } from './defs';

// types
//-----------------------------------------------------------------------------
type DecodeHandler = (value?: string) => void;

// constants
//-----------------------------------------------------------------------------
// patterns
const rxId = /^[a-z$]/i;
const rxValue = /^[0-9.]/;

/*
    puzzle map importer
*/
export class PuzzleMapImporter {
    // fields
    //-------------------------------------------------------------------------
    // state
    private readonly _encoding: string;
    private _position = 0;
    private _mapDef: MapDef = {
        moves: 0,
        goals: {},
        rewards: lodash.cloneDeep(defaultMapDef.rewards),
        spawns: [],
        grid: {
            columns: 0,
            rows: 0,
            cells: [],
        },
    };

    // maps
    private readonly _idBlockMap: Record<string, BlockId>;
    private readonly _idRewardMap: Record<string, MysteryItemRewardId>;
    private readonly _mapHandlers: Record<string, DecodeHandler> = {
        m: this._decodeMoves,
        o: this._decodeGoals,
        r: this._decodeRewards,
        s: this._decodeSpawns,
        g: this._decodeGrid,
    };

    private readonly _mapGridHandlers: Record<string, DecodeHandler> = {
        o: this._decodeGridColumns,
        r: this._decodeGridRows,
        c: this._decodeGridCells,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(encoding: string) {
        // set fields
        this._encoding = encoding;

        // create block id reverse lookup
        this._idBlockMap = Object.entries(blockPropsMap).reduce(
            (map, [id, props]) => {
                map[props.id] = id as BlockId;

                return map;
            },
            {} as Record<string, BlockId>,
        );

        // create reward id reverse lookup
        this._idRewardMap = Object.entries(mysteryItemRewardPropsMap).reduce(
            (map, [id, props]) => {
                map[props.id] = id as MysteryItemRewardId;

                return map;
            },
            {} as Record<string, MysteryItemRewardId>,
        );
    }

    // api
    //-------------------------------------------------------------------------
    public import(): MapDef | undefined {
        // decode at map level
        this._decodeMap();

        // require valid
        if (mapValidateDef(this._mapDef) !== true) return undefined;

        return this._mapDef;
    }

    // private: decode handlers
    //-----------------------------------------------------------------------------
    private _decodeMap() {
        // while blocks can be read, handle block
        for (let block; (block = this._readIdValue()); ) {
            this._mapHandlers[block.id]?.call(this, block.value);
        }
    }

    private _decodeMoves(value: string) {
        // parse moves into map def
        this._mapDef.moves = parseInt(value);
    }

    private _decodeGoals() {
        // while goal blocks can be read, fill goals def
        for (let block; (block = this._readIdValue()); ) {
            // lookup block id
            const id = this._idBlockMap[block.id];

            // add to goals
            if (id) {
                this._mapDef.goals[id] = parseInt(block.value);
            }

            // delimiter break
            if (block.delimiter) {
                break;
            }
        }
    }

    private _decodeRewards() {
        // while rewards can be read, fill rewards def
        for (let block; (block = this._readIdValue()); ) {
            // lookup reward id
            const id = this._idRewardMap[block.id];

            // add to rewards
            if (id) {
                this._mapDef.rewards[id] = {
                    limit: 1,
                    odds: parseInt(block.value) / 100,
                };
            }

            // delimiter break
            if (block.delimiter) {
                break;
            }
        }
    }

    private _decodeSpawns() {
        // while blocks can be read
        for (let block; (block = this._readIdValue()); ) {
            // lookup block id
            const id = this._idBlockMap[block.id]; // add to spawns

            if (id) {
                this._mapDef.spawns.push(id);
            }

            // delimiter break
            if (block.delimiter !== ',') {
                break;
            }
        }
    }

    private _decodeGrid() {
        // while blocks can be read
        for (let block; (block = this._readIdValue()); ) {
            // call grid handler
            this._mapGridHandlers[block.id]?.call(this, block.value);

            // delimiter break
            if (block.delimiter) break;
        }
    }

    private _decodeGridColumns(value: string) {
        // parse grid columns into map def
        this._mapDef.grid.columns = parseInt(value);
    }

    private _decodeGridRows(value: string) {
        // parse grid rows into map def
        this._mapDef.grid.rows = parseInt(value);
    }

    private _decodeGridCells() {
        const grid = this._mapDef.grid;
        let columnCells: CellDef[] = [];

        // while grid cells can be read
        while (this._hasMore()) {
            let enabled = false;
            const blocks: BlockDef[] = [];
            let block;

            // read grid cell blocks
            while ((block = this._readIdValue())) {
                // if tile then set enabled
                if (block.id === mapCellFormat.tile) enabled = true;
                // else parse blocks
                else {
                    const id = this._idBlockMap[block.id];

                    if (id) {
                        blocks.push({
                            id: this._idBlockMap[block.id],
                            option: parseInt(block.value) || undefined,
                        });
                    }
                }

                // delimiter break
                if (block.delimiter !== '|') break;
            }

            // add to column cells
            columnCells.push(
                blocks.length > 0
                    ? {
                          enabled: true,
                          blocks,
                      }
                    : { enabled },
            );

            // if column cells match grid column length, add to grid, and reset column cells
            if (columnCells.length === grid.columns) {
                grid.cells.push(columnCells);
                columnCells = [];
            }

            // delimiter break
            if (block.delimiter !== ',') break;
        }
    }

    // private: reader utils
    //-----------------------------------------------------------------------------
    private _readIdValue(): { id: string; value: string; delimiter?: string } | undefined {
        let value;

        // fail if no more
        if (!this._hasMore()) {
            return undefined;
        }

        // read id
        const id = this._readIdToken();

        if (id) {
            // read optional value
            value = this._readValueToken();
        }

        // read delimiter
        const delimiter = this._readDelimiter();

        return {
            id,
            value,
            delimiter,
        };
    }

    private _readDelimiter(): string | undefined {
        // fail if no more
        if (!this._hasMore()) return undefined;

        // fail if current character is id or value
        const c = this._getCurrentChar();

        if (rxId.test(c) || rxValue.test(c)) {
            return undefined;
        }

        // increment position
        ++this._position;

        return c;
    }

    private _readIdToken(): string {
        return this._readToken((c) => rxId.test(c));
    }

    private _readValueToken(): string {
        return this._readToken((c) => rxValue.test(c));
    }

    private _readToken(allow: (c: string) => boolean): string {
        let token = '';

        for (; this._hasMore(); ++this._position) {
            const c = this._getCurrentChar();

            if (!allow(c)) break;
            token += c;
        }

        return token;
    }

    // private: state
    //-----------------------------------------------------------------------------
    private _getCurrentChar(): string {
        return this._encoding.charAt(this._position);
    }

    private _hasMore(): boolean {
        return this._position < this._encoding.length;
    }
}
