import { Sprite, Texture } from 'pixi.js';

import { HazardEffect } from '../../actions/effects/HazardEffect';
import { HazardBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    image: 'block.hazard.png',
};

/*
    hazard block view
*/
export class HazardBlockView extends Sprite {
    // init
    //-------------------------------------------------------------------------
    constructor(props: HazardBlockProps) {
        // spawn
        super(Texture.from(manifest.image));

        // position
        blockPositionView(this, props);
    }

    static assets(effects: boolean): string[] {
        return [manifest.image, ...(effects ? HazardEffect.assets() : [])];
    }
}
