import { IFlow } from '../../../lib/pattern/IFlow';
import { HomeScreen } from '../../main/home/HomeScreen';
import { CustomerFlow } from './CustomerFlow';

export class CustomerExitFlow extends CustomerFlow implements IFlow {
    private _customerEntryId: number;

    // init
    //-------------------------------------------------------------------------
    constructor(opts: { screen: HomeScreen; customerEntryId: number }) {
        super(opts);
        this._customerEntryId = opts.customerEntryId;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // await this._exitAction();

        return this._complete;
    }

    // private async _exitAction() {
    //     this._preloadCustomer(this._customerEntryId);
    //     this._screen.playPlayerAnimation('excited', true);

    //     const customerData = bakery.customers[this._customerEntryId];
    //     const { cakeId, exitDialogs } = customerData;
    //     const step = app.server.state.bakery.produce[customerData.cakeId].step;
    //     const itemIcon = bakery.cakeRecipeMap[customerData.cakeId][step].tableIcon;

    //     this._screen.spawnTableItem(itemIcon, true);
    //     const cakes = parseCakes(app.server.state);
    //     const fakeCake = {
    //         step: -1,
    //         startTimestamp: -1,
    //         tapped: true,
    //     };

    //     // add mock cake to be finished into the parsed state, the cake will be added to the state once the flow is finished
    //     cakes[cakeId] = fakeCake;

    //     let cakeSlots: ImageButton[];
    //     let newCakeSprites: Sprite[];
    //     const extraCallBacks = {
    //         2: () => {
    //             this._screen.despawnTableItem();
    //             this._screen.playPlayerAnimation('happy', true);
    //         },
    //         3: () => {
    //             this._screen.playPlayerAnimation('excited', true);
    //             const { cakeButtons, newCakeViews } = this._screen.spawnCakes({
    //                 cakes,
    //                 animated: true,
    //                 finishedGlow: cakeId,
    //             });
    //             newCakeSprites = newCakeViews;
    //             cakeSlots = cakeButtons;
    //             cakeSlots.forEach((cake) => {
    //                 cake.onPress = null;
    //                 cake.animated = false;
    //             });
    //         },
    //     };

    //     await this._loadPromise;
    //     await this._speechSequence({ entryId: this._customerEntryId, dialogs: exitDialogs, extraCallBacks });

    //     newCakeSprites.forEach((sprite) => sprite.destroy());
    //     this._actionComplete(true);
    // }
}
