import { Application, StagePlugin, waitFor } from '@play-co/astro';
import { DisplayObject, Rectangle } from 'pixi.js';

// types
//-----------------------------------------------------------------------------
export type InputHandler = (text: string) => void;

export type TextInputOptions = {
    target: DisplayObject;
    value?: string;
    limit: number;
    size: number;
    focus?: boolean;
    onUpdate: InputHandler;
};

/*
    creates text input dom over the given target pixi object. this is a bit of
    a hack since no native canvas/webgl approach exists.
*/
export class TextInput {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _app: Application;
    private readonly _target: DisplayObject;
    private readonly _value: string;
    private readonly _limit: number;
    private readonly _size: number;
    private readonly _focus: boolean;
    private readonly _onUpdate: InputHandler;
    // scene
    private _input: HTMLInputElement;
    // state
    private readonly _resizeListener = () => this._onResized();

    // properties
    //-------------------------------------------------------------------------
    public get value(): string {
        return this._input?.value || '';
    }

    public set value(value: string) {
        this._input.value = value;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(app: Application, options: TextInputOptions) {
        // set fields
        this._app = app;
        this._target = options.target;
        this._value = options.value || '';
        this._limit = options.limit;
        this._size = options.size;
        this._focus = !!options.focus;
        this._onUpdate = options.onUpdate;
    }

    // api
    //-------------------------------------------------------------------------
    public start() {
        // spawn input
        this._spawnInput();

        // update input
        this._updateInput();
    }

    public stop() {
        // despawn input
        this._despawnInput();
    }

    // private: updaters
    //-------------------------------------------------------------------------
    private _updateInput() {
        const style = this._input.style;

        // get target bounds
        const bounds = this._getTargetBounds();

        // udpate input style to match bounds of target
        style.left = bounds.x.toString();
        style.top = bounds.y.toString();
        style.width = bounds.width.toString();
        style.height = bounds.height.toString();
    }

    // private: events
    //-------------------------------------------------------------------------
    private _onInput() {
        // notify updated input value
        this._onUpdate(this._input.value);
    }

    private async _onResized() {
        // it takes well over a few frames for everything to be happy
        await waitFor(100);

        // update
        this._updateInput();
    }

    // private: spawners
    //-------------------------------------------------------------------------
    private _spawnInput() {
        // create text input element
        const input = (this._input = document.createElement('input'));
        input.type = 'text';
        input.value = this._value;
        input.autofocus = this._focus;
        input.maxLength = this._limit;
        const style = input.style;

        // set style
        style.position = 'absolute';
        style.zIndex = '99';
        style.fontSize = `${this._size}vh`;
        style.color = '#000';
        style.backgroundColor = 'rgba(0,0,0,0)';
        style.borderWidth = '0px';
        style.outline = 'none';

        // register events
        input.addEventListener('input', () => this._onInput());
        window.addEventListener('resize', this._resizeListener);

        // add to dom root
        document.body.appendChild(input);
    }

    private _despawnInput() {
        // unregister events
        window.removeEventListener('resize', this._resizeListener);

        // remove from dom root
        document.body.removeChild(this._input);

        // cleanup
        this._input = undefined;
    }

    // private: support
    //-------------------------------------------------------------------------
    private _getTargetBounds(): Rectangle {
        // get screen to canvas coordinates ratio
        const ratio = window.innerHeight / this._app.get(StagePlugin).renderer.screen.height;

        // get target bounds
        const bounds = this._target.getBounds();

        // return bounds adjusted by coordinate ratio
        return new Rectangle(bounds.x * ratio, bounds.y * ratio, bounds.width * ratio, bounds.height * ratio);
    }
}
