import { PlaneGeometry } from 'pixi.js';

import { RegionType, regionZero, SizeType } from '../../defs/types';

/*
    performance nine slice shape
*/
export class NineSliceShape extends PlaneGeometry {
    // private
    //-------------------------------------------------------------------------
    private _slices: RegionType;
    private _textureSize: SizeType;
    private _shapeSize: SizeType;

    // properties
    //-------------------------------------------------------------------------
    public get size(): SizeType {
        return this._shapeSize;
    }

    public set size(size: SizeType) {
        this._shapeSize = size;
        this._update();
    }

    // init
    //-------------------------------------------------------------------------
    constructor(slices: Partial<RegionType>, textureSize: SizeType, shapeSize: SizeType) {
        super(1, 1, 4, 4);
        this._slices = { ...regionZero, ...slices };
        this._textureSize = textureSize;
        this.size = shapeSize;
    }

    // private: updaters
    //-------------------------------------------------------------------------
    private _update(): void {
        const slices = this._slices;
        const textureSize = this._textureSize;
        const shapeSize = this._shapeSize;
        const vertices = this.buffers[0].data;
        const uvs = this.buffers[1].data;

        // uvs
        uvs[0] = uvs[8] = uvs[16] = uvs[24] = 0;
        uvs[1] = uvs[3] = uvs[5] = uvs[7] = 0;
        uvs[6] = uvs[14] = uvs[22] = uvs[30] = 1;
        uvs[25] = uvs[27] = uvs[29] = uvs[31] = 1;
        uvs[2] = uvs[10] = uvs[18] = uvs[26] = slices.left / textureSize.width;
        uvs[4] = uvs[12] = uvs[20] = uvs[28] = 1 - slices.right / textureSize.width;
        uvs[9] = uvs[11] = uvs[13] = uvs[15] = slices.top / textureSize.height;
        uvs[17] = uvs[19] = uvs[21] = uvs[23] = 1 - slices.bottom / textureSize.height;

        // vertices
        vertices[6] = vertices[14] = vertices[22] = vertices[30] = 1;
        vertices[25] = vertices[27] = vertices[29] = vertices[31] = 1;
        vertices[2] = vertices[10] = vertices[18] = vertices[26] = slices.left / shapeSize.width;
        vertices[4] = vertices[12] = vertices[20] = vertices[28] = 1 - slices.right / shapeSize.width;
        vertices[9] = vertices[11] = vertices[13] = vertices[15] = slices.top / shapeSize.height;
        vertices[17] = vertices[19] = vertices[21] = vertices[23] = 1 - slices.bottom / shapeSize.height;

        // commit
        this.buffers[0].update();
        this.buffers[1].update();
    }
}
