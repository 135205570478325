import { analytics } from '@play-co/gcinstant';

type StepEvent = {
    stepIndex: number;
    stepName: string;
};

export function trackTutorialStepStart(props: StepEvent) {
    analytics.pushEvent('TutorialStepStart', { ...props });
}

export function trackTutorialStepFinish(props: StepEvent) {
    analytics.pushEvent('TutorialStepFinish', { ...props });
}

export function trackTutorialStart(props: StepEvent) {
    analytics.pushEvent('TutorialStart', { ...props });
}

export function trackTutorialFinish(props: StepEvent) {
    analytics.pushEvent('TutorialFinish', { ...props });
}

export function setFullTutorialFinished() {
    analytics.setUserProperties({ realtimeTutorialFinished: true });
}
