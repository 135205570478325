import { action } from '@play-co/replicant';

import { FirstSessionAssetKey, RecipeStepAssetKey } from '../chatbot/messageTemplates';
import bakery from '../defs/bakery';
import { CakeItemId } from '../defs/items';
import { MutableState, SyncActionAPI } from '../defs/replicant';
import { sendRecipeStepOA } from './bakery';
import { sendFirstSessionOA } from './firstSession';
import { livesAdd, livesSet } from './lives';

// Do not allow cheats in production
export const cheatActions = process.env.IS_DEVELOPMENT
    ? {
          sendFirstSessionOA: action((state: MutableState, args: { id: FirstSessionAssetKey }, api: SyncActionAPI) => {
              //   const token = await createLoginTokenMultiUse(api);
              sendFirstSessionOA(state, api, args.id);
          }),
          sendRecipeStepOA: action((state: MutableState, args: { id: RecipeStepAssetKey }, api: SyncActionAPI) => {
              sendRecipeStepOA(state, api, args.id);
          }),
          cheatSetClockOffset: action((state, args: { offset: number }, api: any) => {
              api.setClockOffset(args.offset);
          }),
          cheatGetClockOffset: action((state: MutableState, _, api: any) => api.getClockOffset()),
          cheatAddStars: action((state: MutableState, args: { amount: number }, api: SyncActionAPI) => {
              state.stars += args.amount;
              if (state.stars < 0) state.stars = 0;
          }),
          cheatAddCustomer: action((state: MutableState, args: { customerEntry: number }, api: SyncActionAPI) => {
              const { customerEntry } = args;
              if (customerEntry > bakery.customers.length - 1) return;
              state.bakery.newCustomers.push(customerEntry);
          }),
          cheatLivesAdd: action((state: MutableState, args: { amount: number }, api: SyncActionAPI) => {
              livesAdd(state, args.amount, api.date.now());
          }),
          cheatLivesSet: action((state: MutableState, args: { amount: number }, api: SyncActionAPI) => {
              livesSet(state, args.amount, api.date.now());
          }),
          cheatFinishedTimedSteps: action((state: MutableState, _, api: SyncActionAPI) => {
              const cakes = Object.keys(state.bakery.produce);
              for (const cake of cakes as CakeItemId[]) {
                  const cakeState = state.bakery.produce[cake];
                  const timedStep = bakery.cakeRecipeMap[cake][cakeState.step].timedStep;
                  if (timedStep) {
                      state.bakery.produce[cake].startTimestamp =
                          state.bakery.produce[cake].startTimestamp - timedStep.time;
                  }
              }
          }),
          cheatTutorialGrant: action((state: MutableState, _, api: SyncActionAPI) => {
              if (state.tutorial.complete) return;

              // Not granted initial free cake(s)
              if (Object.keys(state.bakery.cakes).length === 0) {
                  bakery.freeCakes.forEach((cakeId: CakeItemId) => {
                      state.bakery.cakes[cakeId] = {};
                  });

                  // add apple to production state
                  const steps = bakery.cakeRecipeMap[bakery.tutorialFinishCake];
                  state.bakery.produce[bakery.tutorialFinishCake] = {
                      customerEntryId: bakery.tutorialCustomerId, // grandma tutorial id
                      step: steps.length - 1,
                      startTimestamp: api.date.now(),
                      tapped: true,
                  };
              }
          }),
      }
    : null;
