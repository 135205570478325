import { PositionType } from '../../../../lib/defs/types';
import { BlockComponent } from '../components/BlockComponent';
import { MapComponent } from '../components/MapComponent';
import { CollideId, Collider } from '../defs/collide';

/*
    tracks unique collisions with target blocks
*/
export class CollisionTracker {
    // fields
    //-------------------------------------------------------------------------
    private _collided: BlockComponent[] = [];

    // api
    //-------------------------------------------------------------------------
    public reset() {
        // clear collided list
        this._collided = [];
    }

    public collide(block: BlockComponent, collisionId: CollideId, collider?: Collider): boolean {
        // if not already collided
        if (this._collided.includes(block)) return false;

        // add to collided list
        this._collided.push(block);

        // collide
        block.collide(collisionId, collider);

        return true;
    }

    public collideAt(map: MapComponent, position: PositionType, collisionId: CollideId, collider?: Collider): boolean {
        // get cell at this position
        const cell = map.getCellAt(position);
        if (!cell) return false;

        // collide each layer
        const baseCollided = cell.base && this.collide(cell.base.entity.c.block, collisionId, collider);
        const overlayCollided = cell.overlay && this.collide(cell.overlay.entity.c.block, collisionId, collider);

        // success if any layer collided
        return baseCollided || overlayCollided;
    }
}
