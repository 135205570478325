import { numberContain } from '../../replicant/util/mathTools';
import { lerp } from '../math/math';
import { Color } from './Color';

/*
    HSL: hue, saturation, luminance
*/
export class HSL {
    // fields
    //-------------------------------------------------------------------------
    private _hue: number;
    private _saturation: number;
    private _luminance: number;

    // properties
    //-------------------------------------------------------------------------
    // hue
    public get hue(): number {
        return this._hue;
    }

    public set hue(value: number) {
        this._hue = value;
    }

    // saturation
    public get saturation(): number {
        return this._saturation;
    }

    public set saturation(value: number) {
        this._saturation = value;
    }

    // luminance
    public get luminance(): number {
        return this._luminance;
    }

    public set luminance(value: number) {
        this._luminance = value;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(hue = 0, saturation = 1, luminance = 1) {
        this._hue = hue;
        this._saturation = saturation;
        this._luminance = luminance;
    }

    // api
    //-------------------------------------------------------------------------
    public lerp(other: HSL, amount: number): HSL {
        return new HSL(
            lerp(this.hue, other.hue, amount),
            lerp(this.saturation, other.saturation, amount),
            lerp(this.luminance, other.luminance, amount),
        );
    }

    public toRGB(): Color {
        if (this.saturation === 0) return new Color(this.luminance, this.luminance, this.luminance);

        const f2 =
            this.luminance < 0.5
                ? this.luminance * (1 + this.saturation)
                : this.luminance + this.saturation - this.saturation * this.luminance;
        const f1 = 2 * this.luminance - f2;

        return new Color(
            this._hueToColor(f1, f2, this.hue + 1 / 3),
            this._hueToColor(f1, f2, this.hue),
            this._hueToColor(f1, f2, this.hue - 1 / 3),
        );
    }

    // private: support
    //-------------------------------------------------------------------------
    private _hueToColor(f1: number, f2: number, hue: number) {
        let out;
        hue = numberContain(hue, 1);
        if (6 * hue < 1) out = f1 + (f2 - f1) * 6 * hue;
        else if (2 * hue < 1) out = f2;
        else if (3 * hue < 2) out = f1 + (f2 - f1) * (2 / 3 - hue) * 6;
        else out = f1;
        return out * 255;
    }
}
