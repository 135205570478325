import { BLEND_MODES, Graphics } from 'pixi.js';

import { BoundsType } from '../../../../lib/defs/types';
import { pixiToLocalBounds } from '../../../../lib/pixi/pixiTools';
import { SpotLight, SpotLightOptions } from './SpotLight';

// types
//-----------------------------------------------------------------------------
export type TargetSpotLightOptions = {
    targets: BoundsType[];
    radius?: number;
    pad?: number;
} & SpotLightOptions;

/*
    spotlight on targets
*/
export class TargetSpotLight extends SpotLight {
    // fields
    //-------------------------------------------------------------------------
    private _options: TargetSpotLightOptions;

    private _light: Graphics;

    // init
    //-------------------------------------------------------------------------
    constructor(options: TargetSpotLightOptions) {
        super(options);
        this._options = options;
    }

    // api
    //-------------------------------------------------------------------------
    public override start() {
        // spawn lights around targets. requires we are already spawned
        for (const target of this._options.targets) {
            const bounds = pixiToLocalBounds(this, target);

            // pad the bounds
            bounds.pad(this._options.pad || 0);

            const radius = Math.max(bounds.width, bounds.height) / 2;
            // spawn circular light
            this._light = new Graphics().beginFill().drawCircle(0, 0, radius).endFill();

            // rectangular, not sure if used at ll
            // spawn light
            // const light = new Graphics()
            //     .beginFill()
            //     .drawRoundedRect(bounds.x, bounds.y, bounds.width, bounds.height, this._options.radius || 0)
            //     .endFill();

            this._light.blendMode = BLEND_MODES.ERASE;
            this.addChild(this._light);

            this._light.position.set(bounds.x + bounds.width / 2, bounds.y + bounds.height / 2);
        }
    }
}
