import { Sprite, Texture } from 'pixi.js';

import { DynamicNumber, DynamicXy, importDynamicNumber, importDynamicXy } from '../../defs/types';
import { Vector2 } from '../../math/Vector2';
import { pixiPointToVector } from '../pixiTools';
import { ParticleEmitter } from './ParticleEmitter';

// types
//-----------------------------------------------------------------------------
export type ParticleOptions = {
    texture: Texture;
} & ParticleState;

export type ParticleState = {
    duration: DynamicNumber;
    velocity?: DynamicXy;
    scale?: DynamicXy;
    angularVelocity?: DynamicNumber;
    tint?: number;
    alpha?: number;
};

/*
    a particle instance
*/
export class Particle extends Sprite {
    // fields
    //-------------------------------------------------------------------------
    // public
    public velocity: Vector2;
    public angularVelocity: number;
    // input
    private _emitter: ParticleEmitter;
    private _options: ParticleOptions;
    // state
    private _duration: number;
    private _expire: number;

    // properties
    //-------------------------------------------------------------------------
    public get emitter(): ParticleEmitter {
        return this._emitter;
    }

    public get expire(): number {
        return this._expire;
    }

    public get duration(): number {
        return this._duration;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(emitter: ParticleEmitter, options: ParticleOptions) {
        super(options.texture);

        // set fields
        this._emitter = emitter;
        this._options = options;

        // set center anchor
        this.anchor.set(0.5);
    }

    // api
    //-------------------------------------------------------------------------
    public reset(time: number) {
        const options = this._options;

        // set initial state
        this._duration = importDynamicNumber(options.duration);
        this._expire = time + this._duration;
        this.position.set(0);
        this.velocity = options.velocity
            ? pixiPointToVector(importDynamicXy(options.velocity, true))
            : new Vector2(0, 0);
        this.angularVelocity = importDynamicNumber(options.angularVelocity || 0);
        if (options.tint) this.tint = options.tint;
        this.alpha = options.alpha === undefined ? 1 : options.alpha;

        // set scale
        if (options.scale) {
            const scale = importDynamicXy(options.scale, true);
            this.scale.set(scale.x, scale.y);
        } else this.scale.set(1);
    }

    public getTweenTime(): number {
        return 1 - (this._expire - this._emitter.time) / this._duration;
    }
}
