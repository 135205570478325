import { CollideEvent, CollideId } from '../../defs/collide';
import type { CageBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

/*
    cage block collision resolver
*/
export class CageCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _entity: CageBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: CageBlockEntity) {
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        // invalid effect
        await new InvalidEffect(this._entity).execute();
        return false;
    }
}
