import { Container, Sprite } from 'pixi.js';

import { AxisId, ChainBlockProps } from '../../defs/block';
import { config } from '../../defs/config';
import { blockPositionView } from '../../util/blockTools';

export const ChainBlockLayout = {
    Horizontal: 'HORIZONTAL',
    HorizontalAlternate: 'HORIZONTAL_ALTERNATE',
    Vertical: 'VERTICAL',
    VerticalAlternate: 'VERTICAL_ALTERNATE',
    CornerTopLeft: 'CORNER_TOP_LEFT',
    CornerTopRight: 'CORNER_TOP_RIGHT',
    CornerBottomLeft: 'CORNER_BOTTOM_LEFT',
    CornerBottomRight: 'CORNER_BOTTOM_RIGHT',
    TSectionTop: 'T_SECTION_TOP',
    TSectionRight: 'T_SECTION_RIGHT',
    TSectionBottom: 'T_SECTION_BOTTOM',
    TSectionLeft: 'T_SECTION_LEFT',
    Cross: 'CROSS',
};
export type ChainBlockLayoutType = (typeof ChainBlockLayout)[keyof typeof ChainBlockLayout];

type SectionType = 'top' | 'bottom' | 'post';

const manifest: Record<string, string> = {
    topDamage0: 'block.barbedwire.top.damage0.png',
    topDamage1: 'block.barbedwire.top.damage1.png',
    topDamage2: 'block.barbedwire.top.damage2.png',
    bottomDamage0: 'block.barbedwire.bottom.damage0.png',
    bottomDamage1: 'block.barbedwire.bottom.damage1.png',
    bottomDamage2: 'block.barbedwire.bottom.damage2.png',
    postDamage0: 'block.barbedwire.post.damage0.png',
    postDamage1: 'block.barbedwire.post.damage1.png',
    postDamage2: 'block.barbedwire.post.damage2.png',
};

const HALF_PI = Math.PI / 2;
const TILE_SIZE = config.tile.size;
const HALF_TILE_SIZE = TILE_SIZE / 2;
const Y_OFFSET = 4;

export const isLineLayout = (layout: ChainBlockLayoutType): boolean =>
    [
        ChainBlockLayout.Horizontal,
        ChainBlockLayout.Vertical,
        ChainBlockLayout.HorizontalAlternate,
        ChainBlockLayout.VerticalAlternate,
    ].includes(layout);

const isVerticalLineLayout = (layout: ChainBlockLayoutType): boolean =>
    [ChainBlockLayout.Vertical, ChainBlockLayout.VerticalAlternate].includes(layout);

const isAlternateLineLayout = (layout: ChainBlockLayoutType): boolean =>
    [ChainBlockLayout.VerticalAlternate, ChainBlockLayout.HorizontalAlternate].includes(layout);

// Although all of these layouts are represented by a post, it's useful to know what the
// block actually is eg. to ensure posts that aren't at the top have the correct z index
export const isPostLayout = (layout: ChainBlockLayoutType): boolean =>
    [
        ChainBlockLayout.CornerTopLeft,
        ChainBlockLayout.CornerTopRight,
        ChainBlockLayout.CornerBottomLeft,
        ChainBlockLayout.CornerBottomRight,
        ChainBlockLayout.TSectionTop,
        ChainBlockLayout.TSectionRight,
        ChainBlockLayout.TSectionBottom,
        ChainBlockLayout.TSectionLeft,
        ChainBlockLayout.Cross,
    ].includes(layout);

export const isPostLayoutWithHigherZ = (layout: ChainBlockLayoutType): boolean =>
    isPostLayout(layout) &&
    ![ChainBlockLayout.CornerTopLeft, ChainBlockLayout.CornerTopRight, ChainBlockLayout.TSectionTop].includes(layout);

/*
    chain block view
*/
export class ChainBlockView extends Container {
    private _damage = 0;
    private _layout: ChainBlockLayoutType = ChainBlockLayout.Horizontal;
    private _block: Sprite;

    static assets(): string[] {
        return Object.values(manifest);
    }

    constructor(props: ChainBlockProps) {
        super();
        this._updateLayout();

        blockPositionView(this, props);
    }

    public get damage(): number {
        return this._damage;
    }

    public set damage(value: number) {
        if (this._damage === value) {
            return;
        }

        this._damage = value;

        this._updateLayout();
    }

    public get layout(): ChainBlockLayoutType {
        return this._layout;
    }

    public set layout(chainLayout: ChainBlockLayoutType) {
        if (this._layout === chainLayout) {
            return;
        }

        this._layout = chainLayout;

        this._updateLayout();
    }

    private _updateLayout() {
        this._despawnBlock();
        this._spawnBlock();
    }

    private _getBlockSpriteWithDamage(sectionType: SectionType): Sprite {
        // A chain block can potentially briefly sustain more than 2 damage if the same
        // block is attacked 3 times in a row without other chain blocks being hit.
        const maxDamageIndex = config.blocks.chain.damage - 1;
        const damageIndex = Math.min(this._damage, maxDamageIndex);
        const manifestKey = `${sectionType}Damage${damageIndex}`;

        const layer = Sprite.from(manifest[manifestKey]);
        layer.pivot.set(layer.width / 2, layer.height / 2);

        return layer;
    }

    private _getLineBlock(opts: { axisId: AxisId; isAlternate: boolean }): Sprite {
        const { axisId, isAlternate = false } = opts;
        const lineBlock = this._getBlockSpriteWithDamage(isAlternate ? 'bottom' : 'top');
        lineBlock.position.set(HALF_TILE_SIZE, HALF_TILE_SIZE + Y_OFFSET);

        if (axisId === 'vertical') {
            lineBlock.rotation = HALF_PI;
        }

        return lineBlock;
    }

    private _getPost(): Sprite {
        const postBlock = this._getBlockSpriteWithDamage('post');
        postBlock.position.set(HALF_TILE_SIZE, HALF_TILE_SIZE + Y_OFFSET);

        return postBlock;
    }

    private _spawnBlock() {
        if (isLineLayout(this._layout)) {
            this._block = this._getLineBlock({
                axisId: isVerticalLineLayout(this._layout) ? 'vertical' : 'horizontal',
                isAlternate: isAlternateLineLayout(this._layout),
            });
        } else {
            this._block = this._getPost();
        }

        this.addChild(this._block);
    }

    private _despawnBlock() {
        this.removeChild(this._block);
        this._block = undefined;
    }
}
