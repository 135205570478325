import type { Component, Entity2D } from '@play-co/odie';

import { HatBlockProps } from '../defs/block';
import { HatBlockView } from '../views/blocks/HatBlockView';

// types
//-----------------------------------------------------------------------------
export type HatBlockComponentOptions = {
    props: HatBlockProps;
};

/*
    top hat block component
*/
export class HatBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockHat';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: HatBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: HatBlockComponentOptions): void {
        // create hat block view
        this.view = new HatBlockView(options.props);
    }
}
