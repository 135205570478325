import { SB } from '@play-co/replicant';

// types
//-----------------------------------------------------------------------------
export type LanguageId = 'en' | 'ja';
export const defaultLanguageId: LanguageId = 'ja';

// state
//-----------------------------------------------------------------------------
export const settingsState = {
    // language
    language: SB.string().default(defaultLanguageId),
    bgm: SB.boolean().default(true),
    sfx: SB.boolean().default(true),
};
