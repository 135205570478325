import { LanguageId } from '../defs/settings';
import { timeFromComponents } from '../util/timeTools';

// constants
//-----------------------------------------------------------------------------
export default {
    lives: {
        max: 5,
        interval: timeFromComponents({ minutes: 30 }),
    },
    name: {
        max: 40,
    },
    npc: {
        // default npc count. will pad up to this many npcs to a player/friend list
        count: 10,
    },
    puzzle: {
        levels: {
            max: 200, // max user playable
            maxTest: 999, // max editable and testable
        },
        continue: {
            moves: 5,
            // incremental cost in gold based on attempt. further attempts use last value
            cost: [20, 40, 60, 80, 100],
        },
    },
    stars: {
        puzzleFinishGrant: 1,
        max: 999999,
    },
    support: {
        email: 'cafe-match@play.co',
    },
    settings: {
        language: 'ja' as LanguageId,
    },
    summon: {
        daily: {
            interval: timeFromComponents({ hours: 23 }),
            reminder: timeFromComponents({ hours: 24 }),
        },
    },
};
