import { GCInstant } from '@play-co/gcinstant';

import app from '../../index.entry';
import { livesGet } from '../../replicant/components/lives';
import { CooldownID, cooldowns } from '../../replicant/defs/cooldowns';
import { Player } from '../../replicant/objects/Player';

/*
    implementation of the active user player
*/
export class UserPlayer implements Player {
    // properties
    //-------------------------------------------------------------------------
    public get id(): string {
        return GCInstant.playerID;
    }

    public get image(): string {
        return '';
    }

    public get name(): string {
        return app.server.state.name;
    }

    public get lives(): number {
        return livesGet(app.server.state, app.server.now());
    }

    public get stars(): number {
        return app.server.state.stars;
    }

    public isCooldownFinished(cooldownId: CooldownID): boolean {
        const cooldownStartTimestamp = app.server.state.cooldowns[cooldownId]?.startTimestamp || 0;
        const cooldownDuration = cooldowns[cooldownId].duration;
        return cooldownStartTimestamp + cooldownDuration < app.server.now();
    }
}
