import NakedPromise from './NakedPromise';

/*
    js mutex
*/
export class Mutex {
    // fields
    //-------------------------------------------------------------------------
    private _locked = false;
    private _promise: NakedPromise;

    // properties
    //-------------------------------------------------------------------------
    public get locked(): boolean {
        return this._locked;
    }

    // api
    //-------------------------------------------------------------------------
    public async lock() {
        while (this._locked) await this._promise;
        this._locked = true;
        this._promise = new NakedPromise();
    }

    public unlock() {
        this._promise?.resolve();
        this._locked = false;
    }
}
