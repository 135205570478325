import { Container, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { BasicAsyncHandler, SizeType } from '../../../lib/defs/types';
import { uiAlignCenterX } from '../../../lib/pixi/uiTools';
import { ImageButton } from '../../lib/ui/buttons/ImageButton';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    close: 'button.close.png',
    boosters: 'puzzle.boosters.png',
    bg: 'puzzle.footer.bg.png',
    panel: 'puzzle.footer.panel.png',
};

/*
    app overlay footer
*/
export class PuzzleFooter extends NineSlicePlane {
    // fields
    //-------------------------------------------------------------------------
    private _container: Container;

    // init
    //-------------------------------------------------------------------------
    constructor(opts: { onClose: BasicAsyncHandler }) {
        super(Texture.from(manifest.bg), 1, 0, 1, 0);
        this.height = 127;
        this.width = 900;

        const container = (this._container = this.addChild(new Container()));
        const boosters = container.addChild(Sprite.from(manifest.boosters));
        boosters.y = -20;

        const panel = container.addChild(Sprite.from(manifest.panel));
        panel.x = 590;
        panel.y = -23;

        const close = panel.addChild(
            new ImageButton({
                image: manifest.close,
            }),
        );
        uiAlignCenterX(panel, close);
        close.y = 25;
        close.onPress = opts.onClose;

        uiAlignCenterX(this, this._container);
    }

    static assets(): string[] {
        return Object.values(manifest);
    }

    public resized(size: SizeType): void {}
}
