import type { Component, Entity2D } from '@play-co/odie';

import { HazardBlockProps } from '../defs/block';
import { HazardBlockView } from '../views/blocks/HazardBlockView';

// types
//-----------------------------------------------------------------------------
export type HazardBlockComponentOptions = {
    props: HazardBlockProps;
};

/*
    hazard block component
*/
export class HazardBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockHazard';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: HazardBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: HazardBlockComponentOptions): void {
        // create hazard block view
        this.view = new HazardBlockView(options.props);
    }
}
