import { Container } from 'pixi.js';

import { SpineAnimation } from '../../lib/pixi/animation/SpineAnimation';

// constants
//-----------------------------------------------------------------------------
const clipIds = ['excited', 'happy', 'hurrying', 'idle', 'cooking', 'cooking_clouds', 'cooking_noclouds'] as const;

export type CharacterClipId = (typeof clipIds)[number];

const manifest = {
    spine: 'spine.main_character.json',
};

/*
    main character
*/
export class MainCharacter extends Container {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _spine: SpineAnimation;

    // properties
    //-------------------------------------------------------------------------
    public get spine(): SpineAnimation {
        return this._spine;
    }

    // init
    //-------------------------------------------------------------------------
    static assets() {
        return [manifest.spine];
    }

    constructor() {
        super();

        // spawn
        this._spawn();
    }

    // api
    //-------------------------------------------------------------------------
    public async start(opts: { clipId: CharacterClipId; loop?: boolean }) {
        const { clipId, loop } = opts;
        this._spine.speed(0.95);
        await this._spine.start({ id: clipId, loop: !!loop, mix: 0.2 });
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn() {
        const spine = (this._spine = new SpineAnimation({ json: manifest.spine }));
        spine.scale.set(1);
        this.pivot.set(this.width / 2, this.height / 2);
        this.addChild(spine);
    }
}
