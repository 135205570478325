import { StateObserver } from '../../../../../lib/pattern/StateMachine';
import type { IPhase, PhaseSystem } from '../PhaseSystem';

/*
    handles game session player active phase
*/
export class ActivePhase implements StateObserver, IPhase {
    // fields
    //-------------------------------------------------------------------------
    private readonly _system: PhaseSystem;

    // init
    //-------------------------------------------------------------------------
    constructor(system: PhaseSystem) {
        this._system = system;
    }

    // impl
    //-------------------------------------------------------------------------
    public async enter() {
        // initial step
        this.step();
    }

    public async leave() {}

    public step() {
        // if no activations enter fall phase
        if (this._system.scene.sessionEntity.c.phase.activations === 0) {
            this._system.phase = 'fall';
        }
    }
}
