import type { Component, Entity2D } from '@play-co/odie';

import { ChainBlockProps } from '../defs/block';
import { ChainBlockLayoutType, ChainBlockView } from '../views/blocks/ChainBlockView';

// types
//-----------------------------------------------------------------------------
export type ChainBlockComponentOptions = {
    props: ChainBlockProps;
    option?: number;
};

/*
    chain block component
*/
export class ChainBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockChain';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: ChainBlockView;

    private _destructionInProgress: boolean;

    // properties
    //-------------------------------------------------------------------------
    public get damage(): number {
        return this.view.damage;
    }

    public set damage(value: number) {
        this.view.damage = value;
    }

    public set layout(layout: ChainBlockLayoutType) {
        this.view.layout = layout;
    }

    public get layout(): ChainBlockLayoutType {
        return this.view.layout;
    }

    public set destructionInProgress(value: boolean) {
        this._destructionInProgress = value;
    }

    public get destructionInProgress(): boolean {
        return this._destructionInProgress;
    }

    // impl
    //-------------------------------------------------------------------------
    public init(options: ChainBlockComponentOptions): void {
        this.view = new ChainBlockView(options.props);
        this.view.damage = options.option || 0;

        this._destructionInProgress = false;
    }
}
