import { Container, Texture } from 'pixi.js';

import { arrayRandom, arraySwap } from '../../../replicant/util/jsTools';
import NakedPromise from '../../pattern/NakedPromise';
import { BehaviorOptions } from './behaviors/defs';
import { createBehavior } from './behaviors/factory';
import { IBehavior } from './behaviors/IBehavior';
import { Particle, ParticleState } from './Particle';

// types
//-----------------------------------------------------------------------------
// public
export type ParticleEmitterOptions = {
    // emitter
    rate: number;
    limit: number;
    emitterDuration?: number;
    // particle
    textures: string[];
    // behaviors
    behaviors: BehaviorOptions[];
} & ParticleState;

// private
type ParticleEntry = {
    particle: Particle;
    behaviors: IBehavior[];
};

/*
    particle emitter
*/
export class ParticleEmitter {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _options: ParticleEmitterOptions;
    private _duration: number;
    // components
    private _container: Container; //ParticleContainer;
    private _textures: Texture[];
    // state
    private _completed: boolean;
    private _completePromise: NakedPromise<void>;
    private _started = false;
    private _hasStarted = false;
    private _particleBuffer: ParticleEntry[] = [];
    private _particleCount = 0;
    private _respawnTime: number;
    private _time: number;

    // properties
    //-------------------------------------------------------------------------
    public get started(): boolean {
        return this._started;
    }

    public get completed(): boolean {
        return this._completed;
    }

    public get completePromise(): Promise<void> {
        return this._completePromise;
    }

    public get duration(): number {
        return this._duration;
    }

    public get time(): number {
        return this._time;
    }

    public get view(): Container {
        return this._container;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: ParticleEmitterOptions) {
        // set fields
        this._options = options;
        this._duration = options.emitterDuration !== undefined ? options.emitterDuration : Number.MAX_VALUE;

        // init textures
        this._textures = options.textures.map((texture) => Texture.from(texture));

        // init container
        this._container = new Container();
        /*
        // this is the recommended approach, yet seems alot slower. also need to call .destroy() or will leak/crash.
        this._container = new ParticleContainer(options.limit, {
            scale: true,
            rotation: true,
            tint: true,
        });
        */
    }

    // api
    //-------------------------------------------------------------------------
    public start(): Promise<void> {
        // set initial state
        this._started = true;
        this._hasStarted = true;
        this._time = 0;
        this._respawnTime = 0;
        this._completed = false;
        this._completePromise = new NakedPromise<void>();

        // return complete promise
        return this._completePromise;
    }

    public stop() {
        // reset started
        this._started = false;
    }

    public complete() {
        // despawn all particles
        this._despawnAllParticles();

        // set completed
        this._completed = true;

        // resolve complete
        this._completePromise?.resolve();
    }

    public step(dt: number) {
        // if not completed
        if (!this._completed) {
            // if started, step emitter
            if (this._started) {
                // stop if expired
                if (this._time > this._duration) {
                    this.stop();
                    // else step emitter
                } else {
                    this._stepEmitter(dt);
                }
                // else complete if particles empty after a previous start
            } else if (this._hasStarted && this._particleCount === 0) {
                this.complete();
            }

            // step particles. this may continue even if stopped
            this._stepParticles(dt);

            // update run time
            this._time += dt;
        }
    }

    // prvate: steppers
    //-------------------------------------------------------------------------
    // emitter
    private _stepEmitter(dt: number) {
        const options = this._options;

        // increment spawn time
        this._respawnTime += dt;

        // spawn particles based on rate and remaining spawn time
        while (this._respawnTime >= options.rate) {
            // if within limit
            if (this._particleCount < options.limit) {
                // spawn particle
                this._spawnParticle();

                // decrement spawn time by rate
                this._respawnTime -= options.rate;
                // else stop spawning and reset respawn time
            } else {
                this._respawnTime = 0;
                break;
            }
        }
    }

    // particles
    private _stepParticles(dt: number) {
        // step expiring
        this._stepParticleExpiring();

        // step behaviors
        this._stepParticleBehaviors(dt);
    }

    private _stepParticleExpiring() {
        // for each active particle
        for (let i = 0; i < this._particleCount; ) {
            const entry = this._particleBuffer[i];

            // if expired despawn
            if (this._time > entry.particle.expire) this._despawnParticle(i);
            // else increment
            else ++i;
        }
    }

    private _stepParticleBehaviors(dt: number) {
        // for each behavior index
        for (let i = 0; i < this._options.behaviors.length; ++i) {
            // step each particle for this behavior
            for (let j = 0; j < this._particleCount; ++j) {
                this._particleBuffer[j].behaviors[i].step?.(dt);
            }
        }
    }

    // private: particle management
    //-------------------------------------------------------------------------
    private _spawnParticle() {
        const options = this._options;
        let entry: ParticleEntry;

        // choose random texture
        const texture = arrayRandom(this._textures);

        // if have available particles in buffer
        if (this._particleCount < this._particleBuffer.length) {
            // reuse existing particle
            entry = this._particleBuffer[this._particleCount];

            // update texture
            entry.particle.texture = texture;
            // else allocate new particle
        } else {
            // create new particle
            const particle = new Particle(this, {
                texture,
                duration: options.duration,
                velocity: options.velocity,
                scale: options.scale,
                angularVelocity: options.angularVelocity,
                tint: options.tint,
            });

            // create new behaviors
            const behaviors = options.behaviors.map((options) => createBehavior(particle, options));

            // add particle entry
            entry = { particle, behaviors };
            this._particleBuffer.push(entry);
        }

        // increment particle count
        ++this._particleCount;

        // reset particle
        entry.particle.reset(this._time);

        // behavior start
        for (const behavior of entry.behaviors) {
            behavior.start?.();
        }

        // add particle to scene
        this._container.addChild(entry.particle);
    }

    private _despawnParticle(index: number) {
        const entry = this._particleBuffer[index];

        // remove from container
        this._container.removeChild(entry.particle);

        // decrement particle count and swap positions with last valid entry
        arraySwap(this._particleBuffer, --this._particleCount, index);
    }

    private _despawnAllParticles() {
        // despawn each particle until empty
        while (this._particleCount > 0) {
            this._despawnParticle(0);
        }
    }
}
