/*
    NOTES:
        padding: use to solve clipping bugs
*/
import type { I18nEntry } from '@play-co/astro';

import { LanguageId } from '../../replicant/defs/settings';

// types
//-----------------------------------------------------------------------------
export type TextLanguageId = LanguageId | 'default';

// explicit and fallback system font family map
export const systemFontMap: Record<TextLanguageId, string> = {
    default: 'sans-serif',
    en: 'sans-serif',
    ja: '"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
};

export const languageFontMap: Record<TextLanguageId, Partial<I18nEntry>> = {
    default: { fontName: `poppins, ${systemFontMap.default}` },
    en: { fontName: `poppins, ${systemFontMap.en}` },
    ja: { fontName: `poppins, noto, ${systemFontMap.ja}` },
};
