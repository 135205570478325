import { CollideEvent, CollideId } from '../../defs/collide';
import type { BlockEntity } from '../../entities/BlockEntity';
import { despawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { BombEffect } from '../effects/BombEffect';
import { PowerComboEffect } from '../effects/PowerComboEffect';
import { CollideHandler, ICollision } from './ICollision';

/*
    bomb block collision resolver
*/
export class BombCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: BlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        attack: this._resolveAttack,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: BlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        // attempt potential combo else explode bomb
        const combo = new PowerComboEffect(this._scene, this._entity);
        if (!(await combo.execute())) await this._explode();

        return true;
    }

    private async _resolveAttack(event: CollideEvent) {
        // explode bomb
        await this._explode();
        return true;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _explode() {
        // despawn block
        despawnBlockEntity(this._scene, this._entity);

        // notify
        this._scene.events.publish({ id: 'power', type: 'bomb' });

        // execute bomb effect
        return new BombEffect(this._scene, {
            position: this._entity.c.position.mapPosition,
            radius: 1,
            big: false,
        }).execute();
    }
}
