import type { Component, Entity2D } from '@play-co/odie';

import type { SlimeBlockProps } from '../defs/block';
import { SlimeBlockView } from '../views/blocks/SlimeBlockView';

// types
//-----------------------------------------------------------------------------
export type SlimeBlockComponentOptions = {
    props: SlimeBlockProps;
};

/*
    slime block component
*/
export class SlimeBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockSlime';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: SlimeBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: SlimeBlockComponentOptions): void {
        // create slime block view
        this.view = new SlimeBlockView(options.props);
    }
}
