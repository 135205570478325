import { Sprite } from 'pixi.js';

import app from '../../../../index.entry';
import { uiAlignCenter } from '../../../../lib/pixi/uiTools';
import { tween } from '../../../../lib/util/tweens';
import { LevelPopup, LevelPopupOptions } from './LevelPopup';

// types
//-----------------------------------------------------------------------------
export type LevelSuccessPopupOptions = {} & LevelPopupOptions;

const manifest = {
    header: 'panel.level.header.png',
    ribbon: 'ribbon.star.png',
    wellDoneEN: 'label.well.done.en.png',
    wellDoneJA: 'label.well.done.ja.png',
    starFrame: 'icon.star.frame.png',
    star: 'icon.star.main.large.png',
    glow: 'vfx.glow2.png',
};

/*
    Success popup
*/
export class LevelSuccessPopup extends LevelPopup {
    private _starFrame: Sprite;

    public override preload() {
        return [...super.preload(), ...app.resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: LevelSuccessPopupOptions) {
        super.spawning({
            ...options,
            okButton: '[buttonContinue]',
        });

        const asset = app.settings.language === 'ja' ? manifest.wellDoneJA : manifest.wellDoneEN;
        const wellDone = Sprite.from(asset);
        wellDone.scale.set(0.6);
        const ribbon = Sprite.from(manifest.ribbon);
        this._starFrame = Sprite.from(manifest.starFrame);

        const iconGlow = Sprite.from(manifest.glow);
        iconGlow.pivot.set(iconGlow.width * 0.5, iconGlow.height * 0.5);
        iconGlow.scale.set(1.1);

        iconGlow
            .animate()
            .add(iconGlow, { alpha: 0.7 }, 0.75, tween.pow2In)
            .add(iconGlow, { alpha: 1 }, 0.75, tween.pow2Out)
            .loop();
        iconGlow
            .animate()
            .set(iconGlow, { rotation: 0 })
            .add(iconGlow, { rotation: Math.PI * 2 }, 7, tween.linear)
            .loop();

        ribbon.addChild(iconGlow, this._starFrame);
        const offsetY = -35;
        uiAlignCenter(ribbon, iconGlow, 0, offsetY);
        uiAlignCenter(ribbon, this._starFrame, 0, offsetY);

        // spawn
        this.main.addContent({
            ribbon: {
                content: ribbon,
                styles: {
                    position: 'centerTop',
                    marginTop: 310,
                },
            },
            wellDone: {
                content: wellDone,
                styles: {
                    position: 'centerTop',
                    marginTop: 150,
                },
            },
        });

        setTimeout(() => {
            this._starAnimation();
        }, 700);
    }

    public override async spawned(): Promise<void> {
        await super.spawned();
    }

    public override async despawned() {
        super.despawned();
    }

    private _starAnimation() {
        const star = Sprite.from(manifest.star);
        star.pivot.set(star.width * 0.5, star.height * 0.5);
        star.scale.set(0);
        this._starFrame.addChild(star);

        uiAlignCenter(this._starFrame, star);
        const defaultY = star.y;
        star.y -= 22;

        star.animate().add(star.scale, { x: 1.1, y: 1.1 }, 0.3, tween.backOut(2.5));

        star.animate()
            // .add(star, { y: defaultY }, 1.4, tween.pow2Out)
            .add(star, { y: defaultY }, 1.1, tween.backOut(2))
            .add(star.scale, { x: 1, y: 1 }, 0.4, tween.backIn(2));
    }
}
