import { PowerBlockType, powerBlockTypes } from '../defs/block';
import { config } from '../defs/config';
import { BlockEntity } from '../entities/BlockEntity';
import { GameScene } from '../GameScene';
import { blockIsFrozen, blockIterateAll, blockIterateNeighbors } from './blockTools';

// types
//-----------------------------------------------------------------------------
type Match = {
    base: BlockEntity;
    overlay?: BlockEntity;
};

export type MatchGroup = {
    matches: Match[];
    // neighbors to above results
    neighbors: BlockEntity[];
};

// api
//-----------------------------------------------------------------------------
export function matchCheckTappable(scene: GameScene): boolean {
    const searched: BlockEntity[] = [];

    // iterate blocks
    return blockIterateAll(scene, (base, overlay) => {
        // if not overlayed
        if (!overlay || overlay.c.block.props.tappable) {
            // if a power block is tappable
            if (_isPowerBlock(base)) return true;

            // if a basic group is tappable
            if (matchFindGroup(scene, base, searched)) return true;
        }

        return false;
    });
}

export function matchFindGroups(scene: GameScene): MatchGroup[] {
    const groups: MatchGroup[] = [];
    const searched: BlockEntity[] = [];

    // iterate blocks
    blockIterateAll(scene, (base, overlay) => {
        // if not overlayed, add basic group if matchable
        if (!overlay || overlay.c.block.props.tappable) {
            const group = matchFindGroup(scene, base, searched);
            if (group) groups.push(group);
        }
    });

    return groups;
}

export function matchFindGroup(
    scene: GameScene,
    entity: BlockEntity,
    searched: BlockEntity[] = [],
): MatchGroup | undefined {
    const block = entity.c.block;

    // require basic initial block
    if (block.props.type === 'basic') {
        const group: MatchGroup = {
            matches: [],
            neighbors: [],
        };

        // iterate neighbor blocks starting at given entity
        blockIterateNeighbors(scene.sessionEntity.c.map, entity, (base, overlay) => {
            // end if does not match our id or is frozen. track as neighbor instead.
            if (block.blockId !== base.c.block.blockId || blockIsFrozen(scene, base)) {
                group.neighbors.push(base);
                return false;
            }

            // fail if already searched
            if (searched.includes(base)) {
                group.matches = [];
                return false;
            }

            // add to matches
            group.matches.push({
                base,
                overlay,
            });

            // add to searched
            searched.push(base);

            return true;
        });

        // success if a match group length
        if (group.matches.length >= config.blocks.basic.match) return group;
    }

    return undefined;
}

// private: support
//-------------------------------------------------------------------------
function _isPowerBlock(entity: BlockEntity): boolean {
    return powerBlockTypes.includes(entity.c.block.props.type as PowerBlockType);
}
