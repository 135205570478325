import { Component, Entity2D } from '@play-co/odie';

import type { IceBlockProps } from '../defs/block';
import { FullIceBlockView } from '../views/blocks/FullIceBlockView';

// types
//-----------------------------------------------------------------------------
export type IceBlockComponentOptions = {
    props: IceBlockProps;
};

/*
    ice block component
*/
export class IceBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'blockIce';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: FullIceBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: IceBlockComponentOptions): void {
        // create ice block view (reusing full ice block's)
        this.view = new FullIceBlockView(options.props);
    }
}
