import { DisplayObject, Ticker } from 'pixi.js';

import { Animation } from '../../../lib/animator/Animation';
import { pixiGetDt } from '../pixiTools';

/*
    scene: tween animation component. self destructs upon completion or
    leaving scene.
*/
export class AnimationComponent extends Animation {
    // fields
    //-------------------------------------------------------------------------
    private _object: DisplayObject;
    private _stepper = this._step.bind(this);

    // init
    //-------------------------------------------------------------------------
    constructor(object: DisplayObject) {
        super();

        // set fields
        this._object = object;

        // register frame stepper
        Ticker.system.add(this._stepper);
    }

    // private: handlers
    //-------------------------------------------------------------------------
    private _step() {
        // if active and in scene, continue stepping
        if (this.active && this._object.inScene()) {
            this.$step(pixiGetDt());
        }
        // else cancel and unregister frame stepper
        else {
            this.cancel();
            Ticker.system.remove(this._stepper);
        }
    }
}
