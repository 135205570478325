import { Sprite, Texture } from 'pixi.js';

import { FuguBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-------------------------------------------------------------------------
const manifest = {
    image: 'block.fugu.1.png',
};

/*
    fugu block view
*/
export class FuguBlockView extends Sprite {
    // init
    //-------------------------------------------------------------------------
    constructor(props: FuguBlockProps) {
        // create base view
        super(Texture.from(manifest.image));
        blockPositionView(this, props);
    }

    static assets(): string[] {
        return Object.values(manifest);
    }
}
