import { LoaderDefs } from '../../plugins/nav/LoaderManager';
import { ScreenDefs } from '../../plugins/nav/NavPlugin';
import { AlphaTransition } from '../../plugins/nav/transitions/AlphaTransition';
import { AlertPopup } from '../lib/ui/popups/AlertPopup';
import { CakeListPopup } from '../lib/ui/popups/CakeListPopup';
import { CakePopup } from '../lib/ui/popups/CakePopup';
import { ConfirmPopup } from '../lib/ui/popups/ConfirmPopup';
import { InfoPopup } from '../lib/ui/popups/InfoPopup';
import { LevelFailedPopup } from '../lib/ui/popups/LevelFailedPopup';
import { LevelGoalPopup } from '../lib/ui/popups/LevelGoalPopup';
import { LevelSuccessMaxPopup } from '../lib/ui/popups/LevelSuccessMaxPopup';
import { LevelSuccessPopup } from '../lib/ui/popups/LevelSuccessPopup';
import { NotEnoughStarsPopup } from '../lib/ui/popups/NotEnoughStarsPopup';
import { OutOfLivesPopup } from '../lib/ui/popups/OutOfLivesPopup';
import { PromptPopup } from '../lib/ui/popups/PromptPopup';
import { PuzzlePopup } from '../lib/ui/popups/PuzzlePopup';
import { RecipePopup } from '../lib/ui/popups/RecipePopup';
import { WaitStepPopup } from '../lib/ui/popups/WaitStepPopup';
import { HomeScreen } from '../main/home/HomeScreen';
import { IntroScreen } from '../main/home/IntroScreen';
import { NamePopup } from '../main/home/NamePopup';
import { MajorLoader } from '../main/loaders/MajorLoader';
import { SpinnerLoader } from '../main/loaders/SpinnerLoader';
import { SplashLoader } from '../main/loaders/SplashLoader';
import { PuzzleScreen } from '../main/puzzle/PuzzleScreen';
import { TipScreen } from '../main/tip/TipScreen';
import { TipScreen2 } from '../main/tip/TipScreen2';

// config
//-----------------------------------------------------------------------------
export enum NavLayer {
    main,
    menu,
    popup,
    tip,
    cheat,
    cheatPopup,
    count,
}

export const navLoaders: LoaderDefs = {
    splash: SplashLoader,
    boot: MajorLoader,
    default: SpinnerLoader,
};

// screens
//-----------------------------------------------------------------------------
export const navScreens: ScreenDefs = {
    alertPopup: {
        screen: AlertPopup,
        layer: NavLayer.popup,
    },
    cakePopup: {
        screen: CakePopup,
        layer: NavLayer.popup,
    },
    cakeListPopup: {
        screen: CakeListPopup,
        layer: NavLayer.popup,
    },
    confirmPopup: {
        screen: ConfirmPopup,
        layer: NavLayer.popup,
    },
    infoPopup: {
        screen: InfoPopup,
        layer: NavLayer.popup,
    },
    puzzlePopup: {
        screen: PuzzlePopup,
        layer: NavLayer.popup,
    },
    homeScreen: {
        screen: HomeScreen,
        transition: AlphaTransition,
        layer: NavLayer.main,
    },
    introScreen: {
        screen: IntroScreen,
        transition: AlphaTransition,
        layer: NavLayer.main,
    },
    promptPopup: {
        screen: PromptPopup,
        layer: NavLayer.popup,
    },
    puzzleScreen: {
        screen: PuzzleScreen,
        transition: AlphaTransition,
        layer: NavLayer.main,
    },
    recipePopup: {
        screen: RecipePopup,
        layer: NavLayer.popup,
    },
    tipScreen: {
        screen: TipScreen,
        layer: NavLayer.tip,
    },
    tipScreen2: {
        screen: TipScreen2,
        layer: NavLayer.tip,
    },
    levelFailedPopup: {
        screen: LevelFailedPopup,
        layer: NavLayer.popup,
    },
    levelGoalPopup: {
        screen: LevelGoalPopup,
        layer: NavLayer.popup,
    },
    levelSuccessPopup: {
        screen: LevelSuccessPopup,
        layer: NavLayer.popup,
    },
    levelSuccessMaxPopup: {
        screen: LevelSuccessMaxPopup,
        layer: NavLayer.popup,
    },
    namePopup: {
        screen: NamePopup,
        transition: AlphaTransition,
        layer: NavLayer.popup,
    },
    outOfLivesPopup: {
        screen: OutOfLivesPopup,
        layer: NavLayer.popup,
    },
    notEnoughStarsPopup: {
        screen: NotEnoughStarsPopup,
        layer: NavLayer.popup,
    },
    waitStepPopup: {
        screen: WaitStepPopup,
        layer: NavLayer.popup,
    },
};
