import { Container, Graphics, NineSlicePlane, Sprite } from 'pixi.js';

import app from '../../../index.entry';
import { Animation } from '../../../lib/animator/Animation';
import NakedPromise from '../../../lib/pattern/NakedPromise';
import { TouchInputComponent } from '../../../lib/pixi/components/TouchInputComponent';
import { uiAlignBottom, uiAlignCenter, uiAlignCenterX, uiCreateQuad, uiSizeToFit } from '../../../lib/pixi/uiTools';
import { textLocaleFormat } from '../../../lib/util/textTools';
import { tween } from '../../../lib/util/tweens';
import { CakeItem } from '../../../replicant/defs/bakery';
import { arrayShuffle, sleep } from '../../../replicant/util/jsTools';
import { LayoutScreen2 } from '../../lib/screens/LayoutScreen2';
import { Pointer } from '../../lib/ui/Pointer';
import { BasicText } from '../../lib/ui/text/BasicText';

const SPEECH_SPEED = 0.06;

export const MAIN_UI_Z = 10000;

const DEFAULT_DIALOGS = [
    '[tapDialog0]',
    '[tapDialog1]',
    '[tapDialog2]',
    '[tapDialog3]',
    '[tapDialog4]',
    '[tapDialog5]',
    '[tapDialog6]',
];

// types
//-----------------------------------------------------------------------------
export type HomeScreenOptions = {
    cakes?: CakeItem[];
    forcedStarAmount?: number;
    tableItem?: string;
    shopName?: string;
    scripted?: boolean;
    showPointer?: boolean;
};

// manifest
//-----------------------------------------------------------------------------
const manifest = {
    bubbleSimple: 'bubble.simple.png',
    bubbleTop: 'bubble.top.png',
    bubbleBottom: 'bubble.bot.png',
};

export abstract class SpeechScreen extends LayoutScreen2 {
    // events
    //-------------------------------------------------------------------------
    // scene
    protected _bg: NineSlicePlane | Graphics;
    protected _isInteracting = false;
    protected _pointerAnimation: Animation;
    protected _tapDialogs: string[];
    protected _skipSpeechCount = 0; // larger than 0 means skip speech, larger than 1 speech and bubble delay
    protected _underlayInput: TouchInputComponent;
    // if pointer used, load in subclass ...Pointer.assets()
    protected _pointer?: Pointer;
    protected _pointerText?: BasicText;

    // impl
    //-------------------------------------------------------------------------
    public preload(options?: any) {
        return app.resource.loadAssets(Object.values(manifest));
    }

    public async spawnBubbleTap(
        opts: { speech: string; customer?: boolean; narrator?: boolean },
        promise?: NakedPromise,
    ) {
        this._isInteracting = true;

        const views: Container[] = [];
        this._underlayInput = this._underlayInput ?? new TouchInputComponent(this.root);
        this._underlayInput.enabled = true;
        let finishedSpeech = false;
        const onTap = async () => {
            if (!finishedSpeech) {
                this._skipSpeechCount++;
                return;
            }

            for (const view of views) {
                view.animate()
                    .add(view, { alpha: 0 }, 0.3, tween.pow2Out)
                    .then(() => view.removeSelf());

                promise.resolve();
                this._isInteracting = false;
            }
        };

        // bubble spawn and tap
        const bubble = await this.spawnBubbleScripted({ ...opts, onTapOverride: onTap });
        // this step requires tap to continue,
        // re-enable it even though it got disabled in the end of the shared bubble animation above
        this._underlayInput.enabled = true;
        finishedSpeech = true;
        views.push(bubble);
        this._spawnTapContainer(views);
    }

    public async spawnBubbleScripted(opts: {
        speech: string;
        customer?: boolean;
        narrator?: boolean;
        onTapOverride?: () => Promise<any>;
    }) {
        return this._speechBubble(opts);
    }

    public async spawnPlayerTapBubble(forcedDialog?: string): Promise<Sprite> {
        if (!forcedDialog) {
            if (this._tapDialogs.length === 0) {
                this._tapDialogs = arrayShuffle([...DEFAULT_DIALOGS]);
            }
        }
        const speechDialog = forcedDialog ?? this._tapDialogs.shift();
        return this._speechBubble({ speech: speechDialog });
    }

    protected async _speechBubble(opts: {
        speech: string;
        customer?: boolean;
        narrator?: boolean;
        onTapOverride?: () => Promise<any>;
    }) {
        const { speech, customer, narrator, onTapOverride } = opts;
        this._skipSpeechCount = 0;

        this._despawnPointer();

        const bubble = Sprite.from(
            narrator ? manifest.bubbleSimple : customer ? manifest.bubbleBottom : manifest.bubbleTop,
        );

        const maxLabelWidth = bubble.width - 80;
        const label = new BasicText({
            style: {
                fill: '#28202A',
                fontSize: 30,
                lineJoin: 'round',
                wordWrap: true,
                fontWeight: 'bold',
                wordWrapWidth: maxLabelWidth,
                align: 'center',
            },
        });

        bubble.addChild(label);
        const fullText = textLocaleFormat(speech);
        this._bg.addChild(bubble);

        uiAlignCenterX(this._bg, bubble);

        if (narrator) {
            bubble.y = 80;
        } else {
            if (customer) {
                bubble.y = this._bg.height - 400;
                bubble.x += 20;
            } else {
                bubble.y = this._bg.height - 1200;
            }
        }

        bubble.zIndex = MAIN_UI_Z;

        this._underlayInput = this._underlayInput ?? new TouchInputComponent(this.root);

        if (onTapOverride) {
            this._underlayInput.onTap = () => onTapOverride();
        } else {
            this._underlayInput.onTap = async () => this._skipSpeechCount++;
        }

        this._underlayInput.enabled = true;
        bubble.alpha = 0;
        bubble.animate().add(bubble, { alpha: 1 }, this._skipSpeechCount > 0 ? 0 : 0.3, tween.pow2In);
        await sleep(this._skipSpeechCount > 0 ? 0 : 0.5);

        for (let i = 0; i < fullText.length; i++) {
            label.text = fullText.substring(0, i + 1);
            uiSizeToFit(label, maxLabelWidth, bubble.height - 40);

            const offsetY = narrator ? 0 : customer ? 40 : -40;
            uiAlignCenter(bubble, label, 2, offsetY);
            if (i % 2 === 0) this._playSpeechSound();

            await sleep(this._skipSpeechCount > 0 ? 0 : SPEECH_SPEED);
        }

        return bubble;
    }

    protected _despawnPointer() {
        if (this?._pointer) {
            this._pointerAnimation?.cancel();
            this._pointer
                .animate()
                .add(this._pointer.scale, { x: 0, y: 0 }, 0.2, tween.backIn(1.2))
                .then(() => {
                    this._pointer.destroy();
                    this._pointer = null;
                });
        }
    }

    private _playSpeechSound() {
        if (this._skipSpeechCount > 0) return;
        app.sound.play(`speak${Math.round(Math.random() * 2)}.ogg`, { volume: 0.25, dupes: 2 });
    }

    private _spawnTapContainer(views: Container[]) {
        const tapFrame = uiCreateQuad(0x0, 0.4, this._bg.width, 60);

        const tapLabel = new BasicText({
            text: `[tapToContinue]`,
            style: {
                fill: '#FFF',
                fontSize: 30,
                lineJoin: 'round',
                fontWeight: 'bold',
                stroke: 0x0,
                strokeThickness: 3,
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.6,
                dropShadowDistance: 2,
            },
        });
        tapFrame.zIndex = MAIN_UI_Z + 1;
        tapLabel.zIndex = MAIN_UI_Z + 1;

        views.push(tapFrame, tapLabel);

        this._bg.addChild(tapFrame, tapLabel);
        uiAlignBottom(this._bg, tapFrame);
        uiAlignBottom(this._bg, tapLabel, -8);
        uiAlignCenterX(this._bg, tapLabel);
    }
}
