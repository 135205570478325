import { ChainBlockComponent } from '../../components/ChainBlockComponent';
import { CollideEvent, CollideId } from '../../defs/collide';
import { config } from '../../defs/config';
import type { ChainBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

/*
    chain block collision resolver
*/
export class ChainCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _entity: ChainBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        block: this._resolveBlock,
        attack: this._resolveAttack,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: ChainBlockEntity) {
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent) {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveBlock(event: CollideEvent) {
        const collider = event.collider;
        const colliderType = collider?.type;

        if (colliderType === 'block') {
            const colliderPropsType = collider?.entity.c.block.props.type;

            if (colliderPropsType === 'basic') {
                await this._attack();
                return true;
            }
        }
        return false;
    }

    private async _resolveAttack() {
        await this._attack();
        return true;
    }

    private async _attack() {
        const damage = ++this._entity.c.blockChain.damage;
        const modifiedData = damage >= config.blocks.chain.damage ? { destroyChain: true } : { damageChainLink: true };

        // let ChainBlockSystem handle block damage / chain destruction
        this._entity.setModified(ChainBlockComponent, modifiedData);
    }
}
