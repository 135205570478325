import { Assets, Container } from 'pixi.js';

import app from '../../../../index.entry';
import { BasicHandler, PositionType } from '../../../../lib/defs/types';
import { IAnimation } from '../../../../lib/pattern/IAnimation';
import { SpritesheetAnimation } from '../../../../lib/pixi/animation/SpritesheetAnimation';
import { mapGetPan } from '../util/mapTools';

// types
//-----------------------------------------------------------------------------
export type DartAnimationOptions = {
    position: PositionType;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    clip: 'clip.dart.json',
};

/*
    dart booster animation
*/
export class DartAnimation extends Container implements IAnimation {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onImpact: BasicHandler;
    // input
    private _options: DartAnimationOptions;
    // state
    private _sounded: boolean;
    private _impacted: boolean;

    // init
    //-------------------------------------------------------------------------
    constructor(options: DartAnimationOptions) {
        super();
        this._options = options;
    }

    // init
    //-------------------------------------------------------------------------
    static assets(): string[] {
        return Object.values(manifest);
    }

    // impl
    //-------------------------------------------------------------------------
    public async start(): Promise<void> {
        // reset state
        this._sounded = false;
        this._impacted = false;

        // load assets
        await Assets.load(DartAnimation.assets());

        // create clip
        const clip = this._createClip();

        // spawn clip
        this.addChild(clip);

        // animate clip
        await clip.start();

        // despawn clip
        this.removeChild(clip);
    }

    public stop() {}

    // private: events
    //-------------------------------------------------------------------------
    private _onClipFrame(frame: number) {
        // if sound frame, play sound
        if (frame >= 20 && !this._sounded) {
            this._sounded = true;
            app.sound.play('puzzle-dart.mp3', { pan: mapGetPan(this._options.position) });
        }

        // if impact frame, notify
        if (frame >= 70 && !this._impacted) {
            this._impacted = true;
            this.onImpact?.();
        }
    }

    // private: factory
    //-------------------------------------------------------------------------
    private _createClip(): SpritesheetAnimation {
        // create animation
        const clip = new SpritesheetAnimation(manifest.clip);
        //clip.animationSpeed = 0.1;

        // position clip
        clip.position.set(-54, -178);

        // handle frame update
        clip.onFrameChange = (frame: number) => this._onClipFrame(frame);

        return clip;
    }
}
