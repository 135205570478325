import { StateObserver } from '../../../../../lib/pattern/StateMachine';
import { AttackAllEffect } from '../../actions/effects/AttackAllEffect';
import { ShuffleEffect } from '../../actions/effects/ShuffleEffect';
import { matchCheckTappable } from '../../util/matchTools';
import { IPhase, PhaseSystem } from '../PhaseSystem';

/*
    handles game session shuffle phase
*/
export class ShufflePhase implements StateObserver, IPhase {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _system: PhaseSystem;

    // init
    //-------------------------------------------------------------------------
    constructor(system: PhaseSystem) {
        this._system = system;
    }

    // impl
    //-------------------------------------------------------------------------
    public async enter() {
        const system = this._system;

        // if tappable or no moves left
        if (matchCheckTappable(system.scene) || system.component.moves <= 0) {
            // enter input phase
            system.phase = 'input';
        } else {
            // execute swap effect
            const shuffled = await new ShuffleEffect(system.scene).execute();

            // if successfully suffed
            if (shuffled) {
                // enter input phase
                system.phase = 'input';
            } else {
                // execute attack all effect
                await new AttackAllEffect(system.scene).execute();

                // trigger move
                this._system.scene.sessionEntity.c.phase.triggerMove('auto');

                // enter active phase
                system.phase = 'active';
            }
        }
    }

    public async leave() {}

    public step() {}
}
