import { arrayRemove } from '../../replicant/util/jsTools';
import NakedPromise from './NakedPromise';

// types
//-----------------------------------------------------------------------------
type Listener<DATA> = (data?: DATA) => boolean | void;

/*
    simple observer
*/
export default class Observer<DATA = void> {
    // fields
    //-------------------------------------------------------------------------
    private _listeners: Listener<DATA>[] = [];

    // api
    //-------------------------------------------------------------------------
    publish(data: DATA): boolean {
        let handled = false;

        // notify listeners in reverse (allows mid loop removal)
        for (let i = this._listeners.length; i--; ) {
            if (this._listeners[i](data)) {
                handled = true;
            }
        }

        return handled;
    }

    subscribe(listener: Listener<DATA>) {
        // add listener (unique)
        if (!this._listeners.includes(listener)) this._listeners.push(listener);
    }

    unsubscribe(listener: Listener<DATA>) {
        // remove listener
        arrayRemove(this._listeners, listener);
    }

    unsubscribeAll() {
        this._listeners = [];
    }

    async waitFor(condition = (data: DATA) => true) {
        const promise = new NakedPromise<DATA>();

        // setup listener
        const listener = (data?: DATA) => {
            if (condition(data)) {
                promise.resolve(data);
                return true;
            }
            return false;
        };

        // subscribe
        this.subscribe(listener);

        // wait for message
        const data = await promise;

        // unsubscribe
        this.unsubscribe(listener);

        return data;
    }
}
