import NakedPromise from '../pattern/NakedPromise';

// state
//-----------------------------------------------------------------------------
export const firstTapPromise = new NakedPromise();

// initial input event. useful for playing audio.
//-----------------------------------------------------------------------------
const firstTapListener = () => {
    firstTapPromise.resolve();
    window.removeEventListener('pointerdown', firstTapListener);
};

window.addEventListener('pointerdown', firstTapListener);
