import { Layout } from '@pixi/layout';
import { Container, NineSlicePlane, Texture } from 'pixi.js';

import app from '../../../../index.entry';
import { BasicAsyncHandler } from '../../../../lib/defs/types';
import { arrayFindBest } from '../../../../replicant/util/jsTools';
import { pixiConfig } from '../../../defs/config';
import { PopupScreen } from '../../screens/PopupScreen';
import { ImageButton } from '../buttons/ImageButton';
import { TextImageButton } from '../buttons/TextImageButton';
import { BasicText } from '../text/BasicText';

// types
//-----------------------------------------------------------------------------
export type BaseSimplePopupOptions = {
    header: string;
    content: Container;
    buttons?: ButtonSpec[];
    onClose?: BasicAsyncHandler;
};

type ButtonSpec = {
    label: string;
    handler: BasicAsyncHandler;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    bg: 'frame.popup.default.png',
    button: 'button.red.large.png',
    close: 'button.close.png',
};

/*
    base simple popup. includes:
        - text header
        - provided content
        - close button
        - footer with given buttons
    should be extended, not directly used.
*/
export abstract class BaseSimplePopup extends PopupScreen {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private main: Layout;

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        return app.resource.loadAssets(Object.values(manifest));
    }

    // @ts-ignore
    public override async spawning(options: BaseSimplePopupOptions) {
        // create header
        const header = this._createHeader(options.header);

        // create footer
        const footer = options.buttons ? this._createFooter(options.buttons) : undefined;

        // determine size
        const width = Math.min(
            arrayFindBest([options.content.width, header.width, footer?.width || 0], (a, b) => a > b) + 160,
            pixiConfig.size.width - 20,
        );
        const height = header.height + options.content.height + (footer?.height || -30) + 200;

        // spawn base
        super.spawning({
            ...options,
            width,
            height,
            underlay: 0.5,
        });

        // scene
        this.base.addContent({
            bg: {
                content: new NineSlicePlane(Texture.from(manifest.bg), 70, 94, 70, 85).props({
                    width,
                    height,
                }),
                styles: {
                    width,
                    height,
                },
            },
            header: {
                content: header,
                styles: {
                    marginTop: 34,
                    position: 'centerTop',
                },
            },
            footer: footer
                ? {
                      content: footer,
                      styles: {
                          marginBottom: 48,
                          position: 'centerBottom',
                      },
                  }
                : undefined,
            main: {
                content: options.content,
                styles: {
                    width: options.content.width,
                    height: options.content.height,
                    marginTop: header.height + 100,
                    position: 'centerTop',
                },
            },
        });

        // spawn close butotn
        if (this.onClose) this._spawnClose();
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawnClose() {
        const button = new ImageButton({
            image: manifest.close,
        });
        button.props({
            x: 68,
            y: 24,
            onPress: async () => this.onClose?.(),
        });
        this.base.addContent({
            // close button
            close: {
                content: button,
                styles: {
                    marginTop: 20,
                    marginRight: 20,
                    position: 'topRight',
                },
            },
        });
    }

    // private: factory
    //-------------------------------------------------------------------------
    private _createHeader(text: string) {
        return new BasicText({
            text,
            style: {
                fill: 'fff4e6',
                strokeThickness: 4,
                fontSize: 32,
                fontWeight: 'bold',
                lineJoin: 'round',
            },
        });
    }

    private _createFooter(specs: ButtonSpec[]): Container {
        const container = new Container();

        // create buttons
        let x = 0;
        for (const spec of specs) {
            const button = container.addChild(this._createButton(spec)).props({ x });
            x += button.width + 10;
        }

        return container;
    }

    private _createButton(spec: ButtonSpec): TextImageButton {
        // spawn
        return new TextImageButton({
            text: spec.label,
            image: manifest.button,
            y: -4,
            slice: {
                width: 300,
                height: 92,
                left: 55,
                top: 0,
                right: 55,
                bottom: 0,
            },
            style: {
                fill: 'FFF',
                fontSize: 36,
                fontWeight: 'bold',
                lineJoin: 'round',
                fontStyle: 'normal',
            },
        }).props({
            onPress: spec.handler,
        });
    }
}
