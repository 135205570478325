import { CollideEvent, CollideId } from '../../defs/collide';
import type { CoffinBlockEntity } from '../../entities/BlockEntity';
import { despawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { hasBlockBelow } from '../../util/mapTools';
import { BreakEffect } from '../effects/BreakEffect';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

export class ZombieCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: CoffinBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        fall: this._resolveFall,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: CoffinBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveFall(event: CollideEvent) {
        if (!this._findBlockBelow()) {
            await this._actionDestroy();
            return true;
        }
        return false;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _actionDestroy() {
        const position = this._entity.c.position.mapPosition;

        despawnBlockEntity(this._scene, this._entity);

        return new BreakEffect(this._scene, {
            position,
            size: this._entity.c.block.props,
            color: 0xa4d41c,
        }).execute();
    }

    // private: support
    //-------------------------------------------------------------------------
    private _findBlockBelow(): boolean {
        return hasBlockBelow(this._scene.sessionEntity.c.map, this._entity);
    }
}
