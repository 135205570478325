import type { QueriesObject, QueryResults, System } from '@play-co/odie';

import { createCollision } from '../actions/collisions/collisionFactory';
import { BlockComponent } from '../components/BlockComponent';
import { CollideEvent } from '../defs/collide';
import type { BlockEntity } from '../entities/BlockEntity';
import type { GameScene } from '../GameScene';
import { blockIsFrozen } from '../util/blockTools';

/*
    handles block collisions
*/
export class CollisionSystem implements System {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'collision';
    public static Queries: QueriesObject = {
        block: {
            components: [BlockComponent],
            modified: true,
        },
    };

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    public queries!: QueryResults;

    // impl
    //-------------------------------------------------------------------------
    public modifiedQuery(entity: BlockEntity, component: BlockComponent, properties: any) {
        // handle collide event
        if (properties.collide) {
            this._handleCollide(entity, properties.collide as CollideEvent);
        }
    }

    // private
    //-------------------------------------------------------------------------
    private async _handleCollide(entity: BlockEntity, event: CollideEvent) {
        // cannot collide frozen block
        if (!blockIsFrozen(this.scene, entity)) {
            // create collision
            const collision = createCollision(this.scene, entity);
            if (collision) {
                const phase = this.scene.sessionEntity.c.phase;

                // push activations
                phase.activePush();

                // resolve collision
                const resolved = await collision.resolve(event);

                // pop activations
                await phase.activePop();

                // if resolved on tap, then trigger moved state
                if (resolved && event.id === 'tap') phase.triggerMove('tap');
            }
        }
    }
}
