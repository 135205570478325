import type { Component, Entity2D } from '@play-co/odie';

import { PositionType } from '../../../../lib/defs/types';
import { WallBlockProps } from '../defs/block';
import { WallBlockView } from '../views/blocks/WallBlockView';

// types
//-----------------------------------------------------------------------------
export type WallBlockComponentOptions = {
    props: WallBlockProps;
    count: number;
    positionHint?: PositionType;
};

/*
    wall block component
*/
export class WallBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockWall';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: WallBlockView;

    // properties
    //-------------------------------------------------------------------------
    // count
    public get count(): number {
        return this.view.count;
    }

    public set count(value: number) {
        this.view.count = value;
    }

    // impl
    //-------------------------------------------------------------------------
    public init(options: WallBlockComponentOptions): void {
        // create wall block view
        this.view = new WallBlockView(options.props, options.positionHint);

        // set initial count
        this.view.count = options.count;
    }
}
