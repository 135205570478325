import { AtlasAttachmentLoader, SkeletonJson } from '@pixi-spine/all-4.1';
import { Assets, ExtensionType, LoadAsset, Loader, LoaderParser } from 'pixi.js';

export const loadSpine = {
    extension: ExtensionType.LoadParser,
    name: 'loadSpine',
    async testParse(asset: any, options: LoadAsset): Promise<boolean> {
        return options.src.includes('.json') && !!asset.bones;
    },
    async parse(url: string, loadAsset?: LoadAsset, loader?: Loader): Promise<any> {
        const src = loadAsset.alias?.[0] || loadAsset.src;
        const atlas = await Assets.load(src.replace('.json', '.atlas'));

        const attachmentLoader = new AtlasAttachmentLoader(atlas);
        const spineParser = new SkeletonJson(attachmentLoader);
        const skeletonData = spineParser.readSkeletonData(url);

        return skeletonData;
    },
} as LoaderParser;
