import type { Entity, QueriesObject, QueryResults, System } from '@play-co/odie';

import { BlockComponent } from '../components/BlockComponent';
import { ChameleonBlockComponent } from '../components/ChameleonBlockComponent';
import { PhaseComponent } from '../components/PhaseComponent';
import { ChameleonBlockEntity } from '../entities/BlockEntity';
import type { GameScene } from '../GameScene';

/*
    handles chameleon block specifics
*/
export class ChameleonBlockSystem implements System {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'blockChameleon';
    public static Queries: QueriesObject = {
        block: {
            components: [BlockComponent, ChameleonBlockComponent],
        },
        phase: {
            components: [PhaseComponent],
            modified: true,
        },
    };

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    public queries!: QueryResults;

    // impl
    //-------------------------------------------------------------------------
    public modifiedQuery(entity: Entity, component: PhaseComponent, properties: any) {
        // if move input phase step chameleons
        if (properties.phase && component.phase === 'input' && component.moved) {
            this._stepChameleon();
        }
    }

    // private: actions
    //-------------------------------------------------------------------------
    private _stepChameleon() {
        // rotate chameleon colors
        this.queries.block.forEach((entity: ChameleonBlockEntity) => entity.c.blockChameleon.rotateColor());
    }
}
