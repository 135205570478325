import { BasicAsyncHandler, BasicHandler } from '../defs/types';
import NakedPromise from './NakedPromise';

// types
//-----------------------------------------------------------------------------
type TaskEntry = {
    complete: NakedPromise<void>;
    task: BasicAsyncHandler;
    result?: any;
};

/*
    sequences async calls
*/
export default class TaskQueue {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onBegin?: BasicHandler;
    public onEnd?: BasicHandler;
    // state
    private _tasks: TaskEntry[] = [];

    // properties
    //-------------------------------------------------------------------------
    public get pending(): number {
        return this._tasks.length;
    }

    // api
    //-------------------------------------------------------------------------
    public reset() {
        // clear command queue
        this._tasks = [];
    }

    public async add<T extends BasicAsyncHandler>(task: T): Promise<ReturnType<T>> {
        // create task entry
        const entry = {
            complete: new NakedPromise<void>(),
            task,
        } as TaskEntry;

        // enqueue task entry
        this._tasks.push(entry);

        // if this task is the only one active
        if (this._tasks.length === 1) {
            // start a new execute loop
            this._executeLoop();
        }

        // wait on complete
        await entry.complete;

        // return result
        return entry.result;
    }

    // private
    //-------------------------------------------------------------------------
    public async _executeLoop() {
        // notify begin
        this.onBegin?.();

        // while tasks exist
        while (this._tasks.length > 0) {
            // use first task entry
            const entry = this._tasks[0];

            // execute task and get result
            entry.result = await entry.task();

            // resolve complete
            entry.complete.resolve();

            // remove completed task
            this._tasks.shift();
        }

        // notify end
        this.onEnd?.();
    }
}
