import app from '../../../index.entry';
import { ITableStore, TableFields, TableRecord } from './ITableStore';

/*
    local store from json assets
*/
export class LocalTableStore implements ITableStore {
    // impl
    //-------------------------------------------------------------------------
    public async init() {}

    public async queryRows(tableId: string, fieldIds: string[]): Promise<TableRecord[]> {
        const assetId = `${tableId}.json`;

        // preload json asset
        const jsonTable = await app.resource.loadAsset(assetId);

        // get json table
        //const jsonTable = ResourceManager.getJson(assetId);

        // map from json table to expected format
        return jsonTable.map((row: any, index: number) => ({
            id: index.toString(),
            values: fieldIds.map((key) => row[key]),
        }));
    }

    public async updateRow(table: string, id: string, fields: TableFields): Promise<void> {}

    public async addRow(table: string, fields: TableFields): Promise<string> {
        return '';
    }
}
