import { NineSlicePlane, Texture } from 'pixi.js';

import { RegionType } from '../../../../lib/defs/types';
import { tween } from '../../../../lib/util/tweens';
import { numberClamp } from '../../../../replicant/util/mathTools';

// types
//-----------------------------------------------------------------------------
export type BasicProgressBarOptions = {
    width: number;
    image: string;
} & Partial<RegionType>;

/*
    basic scaling progress bar
*/
export class BasicProgressBar extends NineSlicePlane {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _baseWidth: number;
    // state
    private _progress: number;

    // properties
    //-------------------------------------------------------------------------
    public get progress(): number {
        return this._progress;
    }

    public set progress(progress: number) {
        this.setProgress(progress, true);
    }

    public set image(image: string) {
        this.texture = Texture.from(image);
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: BasicProgressBarOptions) {
        super(
            Texture.from(options.image),
            options.left || 1,
            options.top || 0,
            options.right || 1,
            options.bottom || 0,
        );

        // set fields
        this._baseWidth = options.width;
        this.width = 0;
    }

    // api
    //-------------------------------------------------------------------------
    public async setProgress(progress: number, animate?: boolean, duration = 0.38): Promise<void> {
        const visualMinimum = 2;

        // normalize progress
        this._progress = numberClamp(progress, 0, 1);

        // determine width, including a visual minimum
        let width = Math.ceil(this._baseWidth * this._progress);
        if (width > 0 && width < visualMinimum) width = visualMinimum;

        // update
        if (animate) {
            this.animate().add(this, { width }, duration, tween.pow2InOut);
        } else {
            this.width = width;
        }
    }
}
