import { waitFor } from '@play-co/astro';
import type { Component, Entity, QueriesObject, QueryResults, System } from '@play-co/odie';
import { clamp } from '@play-co/odie';

import { Graph, GraphNode } from '../../../../lib/pattern/Graph';
import { BreakEffect } from '../actions/effects/BreakEffect';
import { ChainBlockComponent } from '../components/ChainBlockComponent';
import { config } from '../defs/config';
import { ChainBlockEntity, despawnBlockEntity } from '../entities/BlockEntity';
import type { GameScene } from '../GameScene';
import { blockIterateNeighborsToGraph, blockNearestNeighbors } from '../util/blockTools';
import { ChainBlockLayout, ChainBlockLayoutType, isLineLayout } from '../views/blocks/ChainBlockView';

/*
    handles chain block specifics
*/
export class ChainBlockSystem implements System {
    public static readonly NAME = 'blockChain';
    public static Queries: QueriesObject = {
        blockChain: {
            components: [ChainBlockComponent],
            modified: true,
        },
    };

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    public queries!: QueryResults;

    public start() {
        this._layoutChain();
    }

    public modifiedQuery(entity: Entity, component: Component, properties: any) {
        if (properties.damageChainLink) {
            this._entityBreakEffect(entity as ChainBlockEntity);
        }

        if (properties.destroyChain) {
            const chainEntity = entity as ChainBlockEntity;

            // If a chain destruction is in progress don't try to start another one.
            if (chainEntity.c.blockChain.destructionInProgress) {
                return;
            }

            const chainGraph = this._getChainGraph(chainEntity);

            // Mark all chain entities as destruction in progress
            chainGraph.toArray().forEach((e) => (e.c.blockChain.destructionInProgress = true));

            // Then do iterative async staggered destruction
            this._destroyChainFromEntity(chainEntity, chainGraph);
        }
    }

    private _layoutChain() {
        this.queries.blockChain.forEach((entity: ChainBlockEntity) => {
            const chainNeighbors = this._getChainNearestNeighbours(entity);
            const numNeighbors = chainNeighbors.reduce((acc, entity) => acc + (entity ? 1 : 0), 0);
            const [top, right, bottom, left] = chainNeighbors;

            let layout: ChainBlockLayoutType;

            if (numNeighbors === 1) {
                // Puzzle design shouldn't allow it, throw Error instead?
                if (top || bottom) {
                    layout = ChainBlockLayout.Vertical;
                } else if (left || right) {
                    layout = ChainBlockLayout.Horizontal;
                }
            } else if (numNeighbors === 2) {
                // Horizontal, Vertical or Corner block
                if (top && bottom) {
                    layout = ChainBlockLayout.Vertical;
                } else if (left && right) {
                    layout = ChainBlockLayout.Horizontal;
                } else if (left && top) {
                    layout = ChainBlockLayout.CornerBottomRight;
                } else if (top && right) {
                    layout = ChainBlockLayout.CornerBottomLeft;
                } else if (right && bottom) {
                    layout = ChainBlockLayout.CornerTopLeft;
                } else if (bottom && left) {
                    layout = ChainBlockLayout.CornerTopRight;
                }
            } else if (numNeighbors === 3) {
                // T Section block
                if (left && top && right) {
                    layout = ChainBlockLayout.TSectionBottom;
                } else if (top && right && bottom) {
                    layout = ChainBlockLayout.TSectionLeft;
                } else if (right && bottom && left) {
                    layout = ChainBlockLayout.TSectionTop;
                } else if (bottom && left && top) {
                    layout = ChainBlockLayout.TSectionRight;
                }
            } else if (numNeighbors === 4) {
                // Cross block
                layout = ChainBlockLayout.Cross;
            }

            if (isLineLayout(layout)) {
                const isHorizontal = layout === ChainBlockLayout.Horizontal;
                const positionProperty = isHorizontal ? 'x' : 'y';
                const gridIndex = entity.c.position.mapPosition[positionProperty];

                // switch for alternate layouts on cells with odd map positions
                if (gridIndex % 2 !== 0) {
                    layout = isHorizontal ? ChainBlockLayout.HorizontalAlternate : ChainBlockLayout.VerticalAlternate;
                }
            }

            entity.c.blockChain.layout = layout;
        });
    }

    private _getChainNearestNeighbours(entity: ChainBlockEntity): ChainBlockEntity[] {
        return blockNearestNeighbors(
            this.scene.sessionEntity.c.map,
            entity,
            (neighbor) => entity.c.block.blockId === neighbor.c.block.blockId,
        ) as ChainBlockEntity[];
    }

    private _getChainGraph(entity: ChainBlockEntity): Graph<ChainBlockEntity> {
        return blockIterateNeighborsToGraph(
            this.scene.sessionEntity.c.map,
            entity,
            (neighbor) => entity.c.block.blockId === neighbor.c.block.blockId,
        );
    }

    private async _destroyChainFromEntity(entity: ChainBlockEntity, chainGraph: Graph<ChainBlockEntity>) {
        const phase = this.scene.sessionEntity.c.phase;

        phase.activePush();

        const node = chainGraph.getNodeForObject(entity);
        const visited: Record<string, boolean> = {};
        visited[node.id] = true;

        const blockDelay = (config.tile.size / config.sim.chain.speed) * 1000;

        const iterateChainGraph = async (levelNodes: GraphNode<ChainBlockEntity>[]) => {
            // destroy this level...
            levelNodes.forEach((n) => {
                despawnBlockEntity(this.scene, n.object);
                this._entityBreakEffect(n.object);
            });

            // stagger animation so chain appears to break from last destroyed link
            await waitFor(blockDelay);

            // then search next level and iterate...
            const nextLevelNodes = levelNodes.reduce((levelNodeAcc, levelNode) => {
                levelNode.adjacents.reduce((adjacentAcc, adjacentNode) => {
                    if (!visited[adjacentNode.id]) {
                        visited[adjacentNode.id] = true;
                        adjacentAcc.push(adjacentNode);
                    }

                    return adjacentAcc;
                }, levelNodeAcc);

                return levelNodeAcc;
            }, [] as GraphNode<ChainBlockEntity>[]);

            if (nextLevelNodes.length) {
                await iterateChainGraph(nextLevelNodes);
            }
        };

        await iterateChainGraph([node]);

        await phase.activePop();
    }

    private _entityBreakEffect(entity: ChainBlockEntity) {
        const damageColors = [0x71bdef, 0xcec3c6, 0xee906c];
        const damageIndex = clamp(entity.c.blockChain.damage - 1, 0, damageColors.length - 1);

        new BreakEffect(this.scene, {
            position: entity.c.position.mapPosition,
            size: entity.c.block.props,
            color: damageColors[damageIndex],
        }).execute();
    }
}
