import type { QueriesObject, QueryResults, System } from '@play-co/odie';

import app from '../../../../index.entry';
import { BreakEffect } from '../actions/effects/BreakEffect';
import { BasicBlockComponent } from '../components/BasicBlockComponent';
import { BlockComponent } from '../components/BlockComponent';
import type { BasicBlockEntity } from '../entities/BlockEntity';
import { despawnBlockEntity } from '../entities/BlockEntity';
import type { GameScene } from '../GameScene';
import { mapGetPan } from '../util/mapTools';

/*
    handles cage blocks
*/
export class CageBlockSystem implements System {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'blockCage';
    public static Queries: QueriesObject = {
        block: {
            components: [BlockComponent, BasicBlockComponent],
            removed: true,
        },
    };

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    public queries!: QueryResults;

    // impl
    //-------------------------------------------------------------------------
    public removedFromQuery(entity: BasicBlockEntity) {
        // handle basic block cleared
        this._onBasicBlockCleared(entity);
    }

    // private: events
    //-------------------------------------------------------------------------
    private _onBasicBlockCleared(entity: BasicBlockEntity) {
        // clear any cages over basic block
        this._clearCageOverBlock(entity);
    }

    // private: actions
    //-------------------------------------------------------------------------
    //TODO: handle via collider
    private _clearCageOverBlock(entity: BasicBlockEntity) {
        const mapComponent = this.scene.sessionEntity.c.map;
        const position = entity.c.position.mapPosition;

        // get overlay block entity (if any) at given block's position
        const overlayBlock = mapComponent.getCellAt(position)?.overlay?.entity;

        // if cage overlay on block
        if (overlayBlock?.c.block.blockId === 'cage') {
            // play sound
            app.sound.play('puzzle-cage-destroy.mp3', { pan: mapGetPan(position) });

            // despawn cage block
            despawnBlockEntity(this.scene, overlayBlock);

            // execute break effect
            new BreakEffect(this.scene, {
                position,
                size: overlayBlock.c.block.props,
                color: 0x4466ff,
            }).execute();
        }
    }
}
