import { Plugin, PluginOptions } from '@play-co/astro';
import * as lodash from 'lodash';

import app from '../../index.entry';
import type { App } from '../App';
import { PuzzleMapExporter } from '../lib/porters/mapPorter/PuzzleMapExporter';
import { PuzzleMapImporter } from '../lib/porters/mapPorter/PuzzleMapImporter';
import { defaultMapDef, MapDef } from '../main/match2-odie/defs/map';
import { ITableStore, TableRecord } from './tableStore/ITableStore';

// types
//-----------------------------------------------------------------------------
export type PuzzleMapServiceOptions = PluginOptions;

type MapEntry = {
    storeId: string;
    encoded: string;
    mapDef?: MapDef;
};

// constants
//-----------------------------------------------------------------------------
const tableId = 'puzzleMaps';
const levelColumn = 'level';
const dataColumn = 'data';

/*
    puzzle map management service
*/
export class PuzzleMapService extends Plugin {
    // fields
    //-------------------------------------------------------------------------
    // components
    private _tableStore: ITableStore;
    // state
    private _editorMap?: MapDef;
    private _editorLevel = 1;
    private _maps: MapEntry[] = [];
    private _loadingPromise: Promise<TableRecord[]>;

    // properties
    //-------------------------------------------------------------------------
    public get editorMap(): MapDef | undefined {
        return this._editorMap;
    }

    public set editorMap(mapDef: MapDef) {
        this._editorMap = mapDef;
    }

    public get editorLevel(): number {
        return this._editorLevel;
    }

    public set editorLevel(level: number) {
        this._editorLevel = level;
    }

    public get highestLevel(): number {
        return this._maps.length - 1;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(app: App, options: Partial<PuzzleMapServiceOptions>) {
        super(app, options);
    }

    //-------------------------------------------------------------------------
    public async init() {
        // get table store
        this._tableStore = app.tableStore.store;

        // load maps
        this._loadMaps();
    }

    // api
    //-------------------------------------------------------------------------
    public async getMap(level: number): Promise<MapDef> {
        // ensure loaded
        await this._loadingPromise;

        // get map entry
        const entry = this._maps[level];
        if (!entry) {
            return undefined;
        }

        // if not decoded
        if (!entry.mapDef) {
            // import map
            entry.mapDef = new PuzzleMapImporter(entry.encoded).import();

            // if failed, clone a default map
            if (!entry.mapDef) {
                entry.mapDef = lodash.cloneDeep(defaultMapDef);
            }
        }

        return entry.mapDef;
    }

    public async setMap(level: number, mapDef: MapDef) {
        // ensure loaded
        await this._loadingPromise;

        // export map
        const encoded = new PuzzleMapExporter(mapDef).export();

        // get map entry
        const entry = this._maps[level];

        try {
            // if exists with valid store id
            if (entry?.storeId) {
                // sync to backend
                await this._updateMap(entry.storeId, level, encoded);

                // update map entry
                entry.encoded = encoded;
                entry.mapDef = mapDef;
                // else add new entry
            } else {
                // add to backend
                const storeId = await this._addMap(level, encoded);

                // add new map entry
                this._maps[level] = {
                    storeId,
                    encoded,
                    mapDef,
                };
            }

            // show saved alert
            await app.showAlert('Saved! :)');
        } catch (error) {
            // show error alert
            await app.showAlert(error, 'Airtable Error');
        }
    }

    // private: backend
    //-------------------------------------------------------------------------
    private async _loadMaps() {
        // query rows and store promise
        this._loadingPromise = this._tableStore.queryRows(tableId, [levelColumn, dataColumn]);

        // collect results
        this._loadingPromise.then(async (rows) => {
            // reset map table
            this._maps = [];

            // for each row
            for (const row of rows) {
                const [level, data] = row.values;

                this._maps[level as number] = {
                    storeId: row.id,
                    encoded: data as string,
                };
            }
        });
    }

    private async _addMap(level: number, encoded: string): Promise<string> {
        // add new map
        return this._tableStore.addRow(tableId, {
            [levelColumn]: level,
            [dataColumn]: encoded,
        });
    }

    private async _updateMap(storeId: string, level: number, encoded: string) {
        // update map
        return this._tableStore.updateRow(tableId, storeId, {
            [levelColumn]: level,
            [dataColumn]: encoded,
        });
    }
}
