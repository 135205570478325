import { CollideEvent, CollideId } from '../../defs/collide';
import type { CoffinBlockEntity } from '../../entities/BlockEntity';
import { despawnBlockEntity, spawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { BreakEffect } from '../effects/BreakEffect';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

export class CoffinCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: CoffinBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        attack: this._resolveAttack,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: CoffinBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveAttack(event: CollideEvent) {
        const collider = event.collider;

        if (collider?.type === 'bomb' || collider?.type === 'rocket') {
            await this._actionAttack();
            return true;
        }

        return false;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _actionAttack() {
        const {
            position: { mapPosition },
        } = this._entity.c;

        // destroy the coffin and replace with a zombie
        despawnBlockEntity(this._scene, this._entity);
        spawnBlockEntity(this._scene, { id: 'zombie' }, mapPosition);

        const position = this._entity.c.position.mapPosition;

        return new BreakEffect(this._scene, {
            position,
            size: this._entity.c.block.props,
            color: 0xcb9949,
        }).execute();
    }
}
