import type { Component, Entity, QueriesObject, Query, QueryResults, System } from '@play-co/odie';

import { StateComponent } from '../components/StateComponent';
import type { SessionEntity } from '../entities/SessionEntity';
import type { GameScene } from '../GameScene';

/*
    monitors game events worthy of reporting externally
*/
export class MonitorSystem implements System {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'monitor';
    public static Queries: QueriesObject = {
        state: {
            components: [StateComponent],
            modified: true,
        },
    };

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    public queries!: QueryResults;
    // maps
    private readonly _modifyHandlers = {
        state: this._onStateModified,
    };

    // impl
    //-------------------------------------------------------------------------
    public modifiedQuery(entity: Entity, component: Component, properties: any, query: Query) {
        // call modify handler
        (this._modifyHandlers as any)[query.name].call(this, entity, properties);
    }

    // private: events
    //-------------------------------------------------------------------------
    private _onStateModified(entity: SessionEntity, properties: any) {
        // if reward given
        if (properties.rewards) {
            this.scene.events.publish({ id: 'reward', rewardId: properties.id, block: properties.block });
        }
    }
}
