import { SB } from '@play-co/replicant';

import { RecipeStepAssetKey } from '../chatbot/messageTemplates';
import { timeFromComponents } from '../util/timeTools';
import { CakeItemId } from './items';

export type TimedStepType = 'oven' | 'fridge' | 'saucepot';
// TODO REMOVE OPTIONAL from icons?
// TODO REMOVE OPTIONAL from icons?
export type TimedStep = {
    time: number;
    type: TimedStepType;
    description: string;
    icons?: { start?: string; finish?: string };
    chatbotIds?: { first: RecipeStepAssetKey; second: RecipeStepAssetKey; third: RecipeStepAssetKey };
};
export type CustomerId = 'girl' | 'grandma' | 'lady' | 'oldMan' | 'woman';

export type ProduceEntry = SB.ExtractType<typeof produceSchema>;
export type BakeryTableItem = 'brownsugaroperapre' | 'brownsugaropera0' | 'appletart0' | 'appletart1' | 'appletart2';

export type StepData = {
    starCost: number;
    key: string;
    icon: string;
    tableIcon: string;
    chefDialogs?: { a: string[]; b?: string[] }; // Only for non-final steps, the final step is dependent on customer entry id and not recipe.
    timedStep?: TimedStep;
};

const produceSchema = SB.object({
    customerEntryId: SB.int().optional(),
    step: SB.int(),
    startTimestamp: SB.int(),
    tapped: SB.boolean(),
});

// state
//-----------------------------------------------------------------------------
export const bakeryState = {
    bakery: SB.object({
        newCustomers: SB.array(SB.int()), // new customer(s) to be served, element value (id) index of customers in bakery.customers
        // cakeId as key for the maps cakes and produce
        cakes: SB.map(SB.object({})), // map in case we want to add properties to the cake, for example amount, expieration date, etc.
        // customerEntryId optional in case we want to allow the chef to bake an customer
        produce: SB.map(produceSchema),
        lastActionRecipe: SB.object({ cakeId: SB.string() }), // used for table rendering when multiple cakes being worked on
    }),
};
//-----------------------------------------------------------------------------

// customers used outside tutorial (+final tutorial cake customer), index is customerEntryId: 0 - length -1
// NOTE: Keep adding new customers at the bottom of the array
const customers: {
    customerId: CustomerId;
    cakeId: CakeItemId;
    enteringDialogs: { customer: boolean; speech: string }[];
    exitDialogs: { customer: boolean; speech: string }[];
}[] = [
    {
        customerId: 'woman',
        cakeId: 'mangoMousseCake',
        enteringDialogs: [
            { customer: true, speech: '[dialogEnter0Customer0]' },
            { customer: false, speech: '[dialogEnter0Chef0]' },
            { customer: false, speech: '[dialogEnter1Chef0]' },
        ],
        exitDialogs: [
            { customer: false, speech: '[dialogExit0Chef0]' },
            { customer: true, speech: '[dialogExit0Customer0]' },
            { customer: false, speech: '[dialogExit1Chef0]' },
            { customer: false, speech: '[dialogExit2Chef0]' },
        ],
    },
    {
        customerId: 'oldMan',
        cakeId: 'frenchFlan',
        enteringDialogs: [
            { customer: true, speech: '[dialogEnter0Customer1]' },
            { customer: false, speech: '[dialogEnter0Chef1]' },
            { customer: false, speech: '[dialogEnter1Chef1]' },
        ],
        exitDialogs: [
            { customer: false, speech: '[dialogExit0Chef1]' },
            { customer: true, speech: '[dialogExit0Customer1]' },
            { customer: false, speech: '[dialogExit1Chef1]' },
            { customer: false, speech: '[dialogExit2Chef1]' },
        ],
    },
    {
        customerId: 'girl',
        cakeId: 'kouignAmann',
        enteringDialogs: [
            { customer: true, speech: '[dialogEnter0Customer2]' },
            { customer: false, speech: '[dialogEnter0Chef2]' },
            { customer: false, speech: '[dialogEnter1Chef2]' },
        ],
        exitDialogs: [
            { customer: false, speech: '[dialogExit0Chef2]' },
            { customer: true, speech: '[dialogExit0Customer2]' },
            { customer: false, speech: '[dialogExit1Chef2]' },
            { customer: false, speech: '[dialogExit2Chef2]' },
        ],
    },
    {
        customerId: 'grandma',
        cakeId: 'chocoGlazed',
        enteringDialogs: [
            { customer: true, speech: '[dialogEnter0Customer3]' },
            { customer: false, speech: '[dialogEnter0Chef3]' },
            { customer: false, speech: '[dialogEnter1Chef3]' },
        ],
        exitDialogs: [
            { customer: false, speech: '[dialogExit0Chef3]' },
            { customer: true, speech: '[dialogExit0Customer3]' },
            { customer: false, speech: '[dialogExit1Chef3]' },
            { customer: false, speech: '[dialogExit2Chef3]' },
        ],
    },
    {
        // INDEX 4 TUTORIAL GRANDMA
        customerId: 'grandma',
        cakeId: 'appleTart',
        enteringDialogs: [],
        exitDialogs: [
            // uniquq dialog format, only for the tutorial cake
            { customer: false, speech: '[dialogExitTutorial0]' },
            { customer: true, speech: '[dialogExitTutorial1]' },
            { customer: false, speech: '[dialogExitTutorial2]' },
            { customer: false, speech: '[dialogExitTutorial3]' },
        ],
    },
];

// ----- key completed puzzle level, value customerEntryId
const puzzleCustomerUnlocks: Record<number, number> = {
    2: 0,
    5: 1,
    16: 2,
    36: 3,
};
// -----

// Keep the ordering of customers in the array, the index is used as customerEntryId
const tutorialCustomerId = 4;

const freeCakes: CakeItemId[] = ['brownSugarOpera', 'rareCheese', 'strawberryShort']; // apple tart is granted once tutorial is finished
const tutorialFinishCake: CakeItemId = 'appleTart';

const cakeRecipeMap: Record<CakeItemId, StepData[]> = {
    // anniversarie: [],
    appleTart: [
        {
            starCost: 1,
            key: '[recipeAppletart0]',
            icon: 'icon.cup.measure.png',
            tableIcon: 'bakery.table.appletart0.png',
        },
        {
            starCost: 1,
            key: '[recipeAppletart1]',
            icon: 'icon.dough.knead.png',
            tableIcon: 'bakery.table.appletart1.png',
        },
        {
            starCost: 1,
            key: '[recipeAppletart2]',
            icon: 'icon.bake.oven.png',
            tableIcon: 'bakery.table.appletart2.png',
            timedStep: {
                icons: { start: 'bakery.oven.appletart.png', finish: 'bakery.oven.appletart.png' },
                time: timeFromComponents({ minutes: 15 }),
                type: 'oven',
                description: '[recipeAppletartDesc0]',
            },
        },
    ],
    brownSugarOpera: [
        // { key: '[recipeBrownsugaropera0]', icon: 'icon.cup.measure.png' },
        // { key: '[recipeBrownsugaropera1]', icon: 'icon.cup.measure.png' },
        // { key: '[recipeBrownsugaropera2]', icon: 'icon.whip.mix.png' },
        // { key: '[recipeBrownsugaropera3]', icon: 'icon.pot.cook.png' },
        {
            starCost: 1,
            key: '[recipeBrownsugaropera4]',
            icon: 'icon.cake.whole.png',
            tableIcon: 'bakery.table.brownsugaropera0.png',
        },
    ],
    chocoGlazed: [
        {
            starCost: 1,
            key: '[recipeChocoGlazed0]',
            icon: 'icon.cup.measure.png',
            tableIcon: 'bakery.table.chocoglazed0.png',
            chefDialogs: { a: ['[recipeChocoGlazedChef0]', '[recipeChocoGlazedChef1]'] },
        },
        {
            starCost: 2,
            key: '[recipeChocoGlazed1]',
            icon: 'icon.dough.knead.png',
            tableIcon: 'bakery.table.chocoglazed1.png',
            chefDialogs: { a: ['[recipeChocoGlazedChef2]', '[recipeChocoGlazedChef3]'] },
        },
        {
            starCost: 5,
            key: '[recipeChocoGlazed2]',
            icon: 'icon.bake.oven.png',
            chefDialogs: {
                a: ['[recipeChocoGlazedChef4]'],
                b: ['[recipeChocoGlazedChef5]', '[recipeChocoGlazedChef6]'],
            },
            tableIcon: 'bakery.table.chocoglazed2.png',
            timedStep: {
                // reuse tableIcon for start and finish asset
                icons: { start: 'bakery.table.chocoglazed2.png', finish: 'bakery.table.chocoglazed2.png' },
                chatbotIds: { first: 'chocoGlazedOven0', second: 'chocoGlazedOven1', third: 'chocoGlazedOven2' },
                time: timeFromComponents({ minutes: 360 }),
                type: 'oven',
                description: '[recipeChocoGlazedDesc0]',
            },
        },
        {
            starCost: 5,
            key: '[recipeChocoGlazed3]',
            icon: 'icon.sauce.pot.png',
            chefDialogs: {
                a: ['[recipeChocoGlazedChef7]'],
                b: ['[recipeChocoGlazedChef8]', '[recipeChocoGlazedChef9]'],
            },
            tableIcon: 'bakery.table.chocoglazed3.png',
            timedStep: {
                icons: { finish: 'bakery.stove.complete.choco.png' },
                chatbotIds: { first: 'chocoGlazedPot0', second: 'chocoGlazedPot1', third: 'chocoGlazedPot2' },
                time: timeFromComponents({ minutes: 360 }),
                type: 'saucepot',
                description: '[recipeChocoGlazedDesc1]',
            },
        },
        {
            starCost: 5,
            key: '[recipeChocoGlazed4]',
            icon: 'icon.cake.whole.png',
            tableIcon: 'bakery.table.chocoglazed4.png',
            chefDialogs: { a: ['[recipeChocoGlazedChef10], [recipeChocoGlazedChef11]'] },
        },
        {
            starCost: 10,
            key: '[recipeChocoGlazed5]',
            icon: 'icon.fridge.png',
            chefDialogs: { a: ['[recipeChocoGlazedChef12]'] },
            tableIcon: 'bakery.table.chocoglazed5.png',
            timedStep: {
                // reuse tableIcon from previous step
                icons: { start: 'bakery.table.chocoglazed4.png', finish: 'bakery.table.chocoglazed4.png' },
                chatbotIds: { first: 'chocoGlazedFridge0', second: 'chocoGlazedFridge1', third: 'chocoGlazedFridge2' },
                time: timeFromComponents({ minutes: 720 }),
                type: 'fridge',
                description: '[recipeChocoGlazedDesc2]',
            },
        },
    ],
    rareCheese: [],
    strawberryShort: [],
    mangoMousseCake: [
        {
            starCost: 1,
            key: '[recipeMangoMousse0]',
            icon: 'icon.cup.measure.png',
            tableIcon: 'bakery.table.mangomousse0.png',
            chefDialogs: { a: ['[recipeMangoMousseChef0]', '[recipeMangoMousseChef1]'] },
        },
        {
            starCost: 1,
            key: '[recipeMangoMousse1]',
            icon: 'icon.bake.oven.png',
            chefDialogs: {
                a: ['[recipeMangoMousseChef2]'],
                b: ['[recipeMangoMousseChef3]', '[recipeMangoMousseChef4]'],
            },
            tableIcon: 'bakery.table.mangomousse1.png',
            timedStep: {
                icons: { start: 'bakery.oven.mangomousse.start.png', finish: 'bakery.table.mangomousse1.png' },
                chatbotIds: { first: 'mangoMousseOven0', second: 'mangoMousseOven1', third: 'mangoMousseOven2' },
                time: timeFromComponents({ minutes: 15 }),
                type: 'oven',
                description: '[recipeMangoMousseDesc0]',
            },
        },
        {
            starCost: 2,
            key: '[recipeMangoMousse2]',
            icon: 'icon.whip.mix.png',
            tableIcon: 'bakery.table.mangomousse2.png',
            chefDialogs: { a: ['[recipeMangoMousseChef5]', '[recipeMangoMousseChef6]'] },
        },
        {
            starCost: 2,
            key: '[recipeMangoMousse3]',
            icon: 'icon.fridge.png',
            chefDialogs: {
                a: ['[recipeMangoMousseChef7]'],
                b: ['[recipeMangoMousseChef8]', '[recipeMangoMousseChef9]'],
            },
            tableIcon: 'bakery.table.mangomousse3.png',
            timedStep: {
                // reuse current tableIcon
                icons: { start: 'bakery.table.mangomousse3.png', finish: 'bakery.table.mangomousse3.png' },
                chatbotIds: { first: 'mangoMousseFridge0', second: 'mangoMousseFridge1', third: 'mangoMousseFridge2' },
                time: timeFromComponents({ hours: 1 }),
                type: 'fridge',
                description: '[recipeMangoMousseDesc1]',
            },
        },
        {
            starCost: 2,
            key: '[recipeMangoMousse4]',
            icon: 'icon.cake.whole.png',
            tableIcon: 'bakery.table.mangomousse4.png',
            // chefDialogs: { a: ['[recipeMangoMousseChef10]', '[recipeMangoMousseChef11]'] },
        },
    ],
    frenchFlan: [
        {
            starCost: 1,
            key: '[recipeFrenchFlan0]',
            icon: 'icon.cup.measure.png',
            tableIcon: 'bakery.table.frenchflan0.png',
            chefDialogs: { a: ['[recipeFrenchFlanChef0]', '[recipeFrenchFlanChef1]'] },
        },
        {
            starCost: 1,
            key: '[recipeFrenchFlan1]',
            icon: 'icon.pan.cook.png',
            tableIcon: 'bakery.table.frenchflan1.png',
            chefDialogs: { a: ['[recipeFrenchFlanChef2]', '[recipeFrenchFlanChef3]'] },
        },
        {
            starCost: 2,
            key: '[recipeFrenchFlan2]',
            icon: 'icon.sauce.pot.png',
            chefDialogs: { a: ['[recipeFrenchFlanChef4]'], b: ['[recipeFrenchFlanChef5]', '[recipeFrenchFlanChef6]'] },
            tableIcon: 'bakery.table.frenchflan2.png',
            timedStep: {
                icons: { finish: 'bakery.stove.complete.custard.png' },
                chatbotIds: { first: 'frenchFlanPot0', second: 'frenchFlanPot1', third: 'frenchFlanPot2' },
                time: timeFromComponents({ minutes: 5 }),
                type: 'saucepot',
                description: '[recipeFrenchFlanDesc0]',
            },
        },
        {
            starCost: 3,
            key: '[recipeFrenchFlan3]',
            icon: 'icon.bake.oven.png',
            chefDialogs: { a: ['[recipeFrenchFlanChef7]'], b: ['[recipeFrenchFlanChef8]', '[recipeFrenchFlanChef9]'] },
            tableIcon: 'bakery.table.frenchflan3.png',
            timedStep: {
                icons: { start: 'bakery.oven.frenchflan.start.png', finish: 'bakery.table.frenchflan3.png' },
                chatbotIds: { first: 'frenchFlanOven0', second: 'frenchFlanOven1', third: 'frenchFlanOven2' },
                time: timeFromComponents({ minutes: 30 }),
                type: 'oven',
                description: '[recipeFrenchFlanDesc1]',
            },
        },
        {
            starCost: 3,
            key: '[recipeFrenchFlan4]',
            icon: 'icon.fridge.png',
            chefDialogs: {
                a: ['[recipeFrenchFlanChef10]'],
            },
            tableIcon: 'bakery.table.frenchflan4.png',
            timedStep: {
                // same asset as oven asset in the previous step
                icons: { start: 'bakery.table.frenchflan3.png', finish: 'bakery.table.frenchflan3.png' },
                chatbotIds: { first: 'frenchFlanFridge0', second: 'frenchFlanFridge1', third: 'frenchFlanFridge2' },
                time: timeFromComponents({ minutes: 90 }),
                type: 'fridge',
                description: '[recipeFrenchFlanDesc2]',
            },
        },
    ],
    kouignAmann: [
        {
            starCost: 1,
            key: '[recipeKouignAmann0]',
            icon: 'icon.dough.knead.png',
            tableIcon: 'bakery.table.kouignamann0.png',
            chefDialogs: { a: ['[recipeKouignAmannChef0]', '[recipeKouignAmannChef1]'] },
        },
        {
            starCost: 2,
            key: '[recipeKouignAmann1]',
            icon: 'icon.fridge.png',
            chefDialogs: {
                a: ['[recipeKouignAmannChef2]'],
                b: ['[recipeKouignAmannChef3]', '[recipeKouignAmannChef4]'],
            },
            tableIcon: 'bakery.table.kouignamann1.png',
            timedStep: {
                // same as table icon
                icons: { start: 'bakery.table.kouignamann1.png', finish: 'bakery.table.kouignamann1.png' },
                chatbotIds: { first: 'kouignAmannFridge0', second: 'kouignAmannFridge1', third: 'kouignAmannFridge2' },
                time: timeFromComponents({ minutes: 60 }),
                type: 'fridge',
                description: '[recipeKouignAmannDesc0]',
            },
        },
        {
            starCost: 3,
            key: '[recipeKouignAmann2]',
            icon: 'icon.dough.knead.png',
            tableIcon: 'bakery.table.kouignamann2.png',
            chefDialogs: { a: ['[recipeKouignAmannChef5]', '[recipeKouignAmannChef6]'] },
        },
        {
            starCost: 4,
            key: '[recipeKouignAmann3]',
            icon: 'icon.layer.stack.png',
            tableIcon: 'bakery.table.kouignamann3.png',
            chefDialogs: { a: ['[recipeKouignAmannChef7]', '[recipeKouignAmannChef8]'] },
        },
        {
            starCost: 5,
            key: '[recipeKouignAmann4]',
            icon: 'icon.bake.oven.png',
            chefDialogs: {
                a: ['[recipeKouignAmannChef9]'],
                b: ['[recipeKouignAmannChef10]', '[recipeKouignAmannChef11]'],
            },
            tableIcon: 'bakery.table.kouignamann4.png',
            timedStep: {
                // custom start asset and reuse tableIcon for finished asset
                icons: { start: 'bakery.oven.kouignamann.start.png', finish: 'bakery.table.kouignamann4.png' },
                chatbotIds: { first: 'kouignAmannOven0', second: 'kouignAmannOven1', third: 'kouignAmannOven2' },
                time: timeFromComponents({ minutes: 90 }),
                type: 'oven',
                description: '[recipeKouignAmannDesc1]',
            },
        },
        {
            starCost: 6,
            key: '[recipeKouignAmann5]',
            icon: 'icon.cool.freeze.png',
            tableIcon: 'bakery.table.kouignamann5.png',
        },
    ],
};

const tutorialCakes: CakeItemId[] = ['brownSugarOpera', 'appleTart'];

export type CakeItem = { cakeId: CakeItemId; amount: number };
const defaultCakeState: readonly CakeItem[] = [
    { cakeId: 'rareCheese', amount: 5 },
    { cakeId: 'strawberryShort', amount: 5 },
    { cakeId: 'brownSugarOpera', amount: 5 },
    { cakeId: 'appleTart', amount: 4 },
];

const defaultCakePopupList: CakeItemId[] = [
    'rareCheese',
    'strawberryShort',
    'brownSugarOpera',
    'appleTart',
    'mangoMousseCake',
];

const placements: CakeItemId[] = [
    'rareCheese',
    'strawberryShort',
    'brownSugarOpera',
    'appleTart',
    'mangoMousseCake',
    'frenchFlan',
    'kouignAmann',
    'chocoGlazed',
];

export default {
    cakeRecipeMap,
    tutorialCakes,
    customers,
    placements,
    freeCakes,
    puzzleCustomerUnlocks,
    tableLimit: 8, // 8 cakes per table
    tutorialFinishCake,
    tutorialCustomerId,
    defaultCakeState,
    defaultCakePopupList,
};
