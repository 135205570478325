export function toB64(code: number) {
    if (code === 43) {
        return 0;
    }
    if (code === 47) {
        return 1;
    }

    if (code >= 48 && code <= 57) {
        return code - 48 + 2;
    }

    if (code >= 65 && code <= 90) {
        return code - 65 + 12;
    }

    if (code >= 97 && code <= 122) {
        return code - 97 + 38;
    }

    throw Error(`unknown character code ${code}`);
}

export function fromB64(b64: number) {
    if (b64 === 0) {
        return 43;
    }
    if (b64 === 1) {
        return 47;
    }
    if (b64 >= 2 && b64 <= 11) {
        return b64 - 2 + 48;
    }
    if (b64 >= 12 && b64 < 38) {
        return b64 - 12 + 65;
    }
    if (b64 >= 38 && b64 < 64) {
        return b64 - 38 + 97;
    }
    throw Error(`unknown b64 code ${b64}`);
}

/**
 * NOTE: code copied over from https://github.com/dankogai/js-base64/blob/main/base64.ts#L43
 */
const b64ch = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
const b64chs = Array.prototype.slice.call(b64ch);

const btoaPolyfill = (bin: string) => {
    // console.log('polyfilled');
    let u32: number;
    let c0: number;
    let c1: number;
    let c2: number;
    let asc = '';
    const pad = bin.length % 3;
    for (let i = 0; i < bin.length; ) {
        if ((c0 = bin.charCodeAt(i++)) > 255 || (c1 = bin.charCodeAt(i++)) > 255 || (c2 = bin.charCodeAt(i++)) > 255) {
            throw new TypeError('invalid character found');
        }
        u32 = (c0 << 16) | (c1 << 8) | c2;
        asc += b64chs[(u32 >> 18) & 63] + b64chs[(u32 >> 12) & 63] + b64chs[(u32 >> 6) & 63] + b64chs[u32 & 63];
    }
    return pad ? asc.slice(0, pad - 3) + '==='.substring(pad) : asc;
};

/**
 * This function is symmetric (property ofXOR)
 * i.e. xorCipher(xorCipher('my-custom-str')) == 'my-custom-str'
 */
export function xorCipher(token: string) {
    return token
        .split('')
        .map((x) => x.charCodeAt(0))
        .map((x) => toB64(x))
        .map((x) => x ^ 0x1c)
        .map((x) => fromB64(x))
        .map((x) => String.fromCharCode(x))
        .join('');
}

export function encodePacket(packet: string): string {
    // This is done explicitly since this method may either be called in a nodejs
    // environment or within a browser based environment
    return xorCipher(btoaPolyfill(packet).replace(/[=]/g, ''));
}

export function hexDecode(encodedString: string): string {
    return encodedString
        .match(/.{1,2}/g)
        .map((byte) => String.fromCharCode(parseInt(byte, 16)))
        .join('');
}

export function hexEncode(originalString: string): string {
    return [...originalString].map((char) => char.charCodeAt(0).toString(16)).join('');
}
