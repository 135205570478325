import app from '../../../../../index.entry';
import { CollideEvent, CollideId } from '../../defs/collide';
import { despawnBlockEntity, IceBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { CollisionTracker } from '../../util/CollisionTracker';
import { mapGetPan } from '../../util/mapTools';
import { BreakEffect } from '../effects/BreakEffect';
import { IceDustEffect } from '../effects/IceDustEffect';
import { CollideHandler, ICollision } from './ICollision';

const sounds = ['puzzle-ice-tap.mp3', 'puzzle-door-poke-chain.mp3'];

/*
    ice block collision resolver
*/
export class IceCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: IceBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        block: this._resolveBlock,
        attack: this._resolveAttack,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: IceBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event) ?? false;
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        const position = this._entity.c.position.mapPosition;

        app.sound.play(sounds[0], {
            volume: 3,
            pan: mapGetPan(position),
        });

        await new IceDustEffect(this._scene, {
            position,
            amount: 3,
            tint: 0xbbe3ee,
        }).execute();

        return false;
    }

    private async _resolveBlock(event: CollideEvent) {
        const collider = event.collider;

        // if block collider
        if (collider?.type === 'block') {
            this._actionAttack();
        }

        return false;
    }

    private async _resolveAttack(event: CollideEvent) {
        await this._actionAttack();
        return true;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _actionAttack() {
        // destroy the ice overlay
        despawnBlockEntity(this._scene, this._entity);

        const position = this._entity.c.position.mapPosition;

        app.sound.play(sounds[1], { pan: mapGetPan(position) });

        await Promise.all([
            new BreakEffect(this._scene, {
                position,
                size: this._entity.c.block.props,
                color: 0xbbe3ee,
                alpha: 0.3,
            }).execute(),
            new IceDustEffect(this._scene, {
                position,
                tint: 0xbbe3ee,
            }).execute(),
        ]);

        // Trigger a fall collision at this position, since now blocks can fall.
        const collisionTracker = new CollisionTracker();
        collisionTracker.collideAt(this._scene.sessionEntity.c.map, position, 'fall');
    }
}
