//TODO: use TextImageButton instead
import { uiAlignCenter, uiScaleToFit } from '../../../../lib/pixi/uiTools';
import { BasicText, BasicTextOptions } from '../text/BasicText';
import { UpDownButton, UpDownButtonOptions } from './UpDownButton';

// types
//-----------------------------------------------------------------------------
type TextButtonOptions = { offsetX?: number; offsetY?: number; paddingX?: number; paddingY?: number };
export type TextUpDownButtonOptions = BasicTextOptions & UpDownButtonOptions & TextButtonOptions;
/*
    updown button with text in middle
*/
export class TextUpDownButton extends UpDownButton {
    // init
    //-------------------------------------------------------------------------
    constructor(options: TextUpDownButtonOptions) {
        super(options);

        // add text
        const text = this.addChild(new BasicText(options));

        const padX = options.paddingX ?? 0;
        const padY = options.paddingY ?? 0;
        uiScaleToFit(text, this.width - padX * 2, this.height + padY * 2);
        // center it
        uiAlignCenter(this, text);

        // add optional offset to the center pos
        text.x = text.x + (options.offsetX ?? 0);
        text.y = text.y + (options.offsetY ?? 0);
    }
}
