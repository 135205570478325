// constants
//-----------------------------------------------------------------------------

import { IController } from '../../../../lib/pattern/IController';
import { CharacterClipId } from '../../../concept/MainCharacter';
import { blockPropsMap, BlockType } from '../../match2-odie/defs/block';
import {
    BoosterEvent,
    ComboEvent,
    EventId,
    GameEvent,
    GoalEvent,
    GroupEvent,
    PowerEvent,
    RewardEvent,
} from '../../match2-odie/defs/event';
import { PuzzleScreen } from '../PuzzleScreen';

// blocks that take many hits. excludes mystery boxes which are handled as rewards
const sturdyBlocks = new Set<BlockType>([
    'android',
    'candle',
    'door',
    'firebox',
    'lock',
    'siren',
    'skull',
    'statue',
    'wall',
    'virus',
]);

/*
    puzzle character reaction controller

    spec
        1. 2 - 4 cubes - 1
        2. Breaking any goal obstacles that requires 1 hit (hat, smoke, bees etc.) -1
        3. Breaking any goal obstacles that requires 2 hits or more (sirens, candles, etc.) - 2
        4. Achieve one of the puzzle targets - 3
        5. Creating a special block (rockets, bombs, rubiks) - 1
        6. Breaking a single special block (rockets, bombs, rubiks) - 2
        7. Breaking a special blocks combo (rockets/bombs/rubiks) - 3
        8. Breaking question mark cubes - 3
        9. Boosters - 3
*/
export class PuzzleReactionController implements IController {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _screen: PuzzleScreen;
    // handlers
    private _gameEventHandler = (event: GameEvent) => this._gameEventHandlers[event.id]?.call(this, event);
    // maps
    private readonly _gameEventHandlers: { [key in EventId]?: (event: GameEvent) => void } = {
        booster: this._onBoosterEvent,
        combo: this._onComboEvent,
        goal: this._onGoalEvent,
        group: this._onGroupEvent,
        power: this._onPowerEvent,
        reward: this._onRewardEvent,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(screen: PuzzleScreen) {
        this._screen = screen;
    }

    // impl
    //-------------------------------------------------------------------------
    public async start() {
        // register events
        this._screen.scene.events.subscribe(this._gameEventHandler);
    }

    public async stop() {
        // unregister events
        this._screen.scene.events.unsubscribe(this._gameEventHandler);
    }

    // private: events
    //-------------------------------------------------------------------------
    private _onBoosterEvent(event: BoosterEvent) {
        // spec: 9
        this._reactHappy(3, event);
    }

    private _onComboEvent(event: ComboEvent) {
        // spec: 7
        this._reactHappy(3, event);
    }

    private _onGoalEvent(event: GoalEvent) {
        // if final goal
        if (event.count === 0) {
            // spec: 4
            this._reactHappy(3, event);
        } else {
            const isSturdy = sturdyBlocks.has(blockPropsMap[event.goalId].type as BlockType);
            // spec: 2,3
            this._reactHappy(isSturdy ? 2 : 1, event);
        }
    }

    private _onGroupEvent(event: GroupEvent) {
        if (event.cause === 'basic') {
            // spec: 1,5
            this._reactHappy(1, event);
        }
    }

    private _onPowerEvent(event: PowerEvent) {
        // spec: 6
        this._reactHappy(2, event);
    }

    private _onRewardEvent(event: RewardEvent) {
        // spec: 8
        this._reactHappy(3, event);
    }

    // private: support
    //-------------------------------------------------------------------------
    private _reactHappy(level: number, event: GameEvent) {
        const index = level - 1;
        const reactions: CharacterClipId[] = ['happy', 'hurrying', 'excited'];
        const activeIndex = reactions.indexOf(this._screen.activeReaction);
        const reaction = reactions[index];
        const play = index > activeIndex;

        // only interrupt higher levels
        if (play) {
            this._screen.playCharacterReaction(reaction);
        }

        // log it
        console.log(`Character.React: event=${event.id} reaction=${`${level}:${reaction}`} played=${play}`);
    }
}
