import { Sprite, Texture } from 'pixi.js';

import { CoffinBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

const manifest = {
    coffin: 'block.coffin.png',
};

export class CoffinBlockView extends Sprite {
    constructor(props: CoffinBlockProps) {
        super(Texture.from(manifest.coffin));
        blockPositionView(this, props);

        this.y -= 3;
    }

    static assets(): string[] {
        return [manifest.coffin];
    }
}
