import { Container, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import app from '../../../../index.entry';
import { uiAlignCenter, uiSizeToFit } from '../../../../lib/pixi/uiTools';
import { GoalId, goalMap } from '../../../main/match2-odie/defs/goal';
import { GoalsDef, MapDef } from '../../../main/match2-odie/defs/map';
import { BasicText } from '../text/BasicText';
import { LevelPopup, LevelPopupOptions } from './LevelPopup';

// types
//-----------------------------------------------------------------------------
export type LevelGoalPopupOptions = { isMaxLevel?: boolean } & LevelPopupOptions;

const manifest = {
    header: 'panel.level.header.png',
    frame: 'frame.inset.light.png',
};

/*
    Puzzle goals popup
*/
export class LevelGoalPopup extends LevelPopup {
    // will be resolved by the time the popup is spawning
    private _mapDef: MapDef;

    public override preload(opts: { level: number }) {
        const mapGoalPromise = app.puzzleMap.getMap(opts.level).then((mapDef: MapDef) => {
            this._mapDef = mapDef;
            return Promise.all(app.resource.loadAssets(this._goalAssets(mapDef.goals)));
        });
        return [...super.preload(), ...app.resource.loadAssets([...Object.values(manifest)]), mapGoalPromise];
    }

    // @ts-ignore
    public override async spawning(options: LevelGoalPopupOptions) {
        super.spawning({
            ...options,
            height: options.isMaxLevel ? 680 : LevelPopup.defaultHeight,
            okButton: '[buttonPlay]',
        });

        let maxLabel;
        if (options.isMaxLevel) {
            maxLabel = new BasicText({
                text: '[popupLevelObjectivesMax]',
                style: {
                    fill: 0x0d03555,
                    fontSize: 32,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                    align: 'center',
                    wordWrapWidth: 640,
                    wordWrap: true,
                },
            });
        }

        const goals = new BasicText({
            text: '[popupLevelObjectives]',
            style: {
                fill: 0x592929,
                fontSize: 32,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'center',
            },
        });

        const frame = new NineSlicePlane(Texture.from(manifest.frame), 20, 20, 20, 20);
        frame.width = 612;
        frame.height = 154;

        // spawn
        this.main.addContent({
            maxLevel: options.isMaxLevel
                ? {
                      content: maxLabel,
                      styles: { position: 'centerTop', marginTop: 150 },
                  }
                : null,
            goals: {
                content: goals,
                styles: {
                    position: 'centerTop',
                    marginTop: options.isMaxLevel ? 280 : 200,
                },
            },
            frame: {
                content: frame,
                styles: {
                    position: 'centerTop',
                    marginTop: options.isMaxLevel ? 340 : 260,
                },
            },
        });

        let x = 0;
        const container = new Container();
        Object.keys(this._mapDef.goals).forEach((id: GoalId) => {
            const count = new BasicText({
                text: `${this._mapDef.goals[id]}`,
                style: {
                    fill: 0xffffff,
                    fontSize: 40,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                    strokeThickness: 10,
                    stroke: 0x5a2d26,
                    align: 'center',
                },
            });

            count.x = x + 30;
            count.y = 48;

            const goalIcon = Sprite.from(goalMap[id]);
            goalIcon.scale.set(1.5); // make sure the small icons are big enough
            uiSizeToFit(goalIcon, 86, 86);
            container.addChild(goalIcon, count);
            goalIcon.x = x;
            goalIcon.y = frame.height * 0.33 - goalIcon.height * 0.5;
            x += 116;
        });

        frame.addChild(container);
        uiAlignCenter(frame, container, 0, 0);
    }

    private _goalAssets(goalDef: GoalsDef): string[] {
        return Object.keys(goalDef).map((id: GoalId) => goalMap[id]);
    }

    public override async spawned(): Promise<void> {
        await super.spawned();
    }

    public override async despawned() {
        super.despawned();
    }
}
