// types
//-----------------------------------------------------------------------------
export interface StateObserver {
    enter?: () => Promise<void>;
    leave?: () => Promise<void>;
}

export type ObserverMap<STATE extends string> = { [key in STATE]: StateObserver };

/*
  a state machine manager
*/
export default class StateMachine<State extends string> {
    // fields
    //---------------------------------------------------------------------------
    private _state: State = null;
    private readonly _observers: ObserverMap<State>;
    private readonly _nullObserver: StateObserver = {};

    // properties
    //---------------------------------------------------------------------------
    public get state(): State {
        return this._state;
    }

    public get observer(): StateObserver {
        return this._observers[this._state] || this._nullObserver;
    }

    // init
    //---------------------------------------------------------------------------
    constructor(transitions: ObserverMap<State>) {
        // set fields
        this._observers = transitions;
    }

    // api
    //---------------------------------------------------------------------------
    public reset() {
        this._state = null;
    }

    public async transition(state: State) {
        // if change state
        if (state !== this._state) {
            const oldObserver = this._observers[this._state];
            const newObserver = this._observers[state];

            // call leave
            await oldObserver?.leave?.();

            // update state
            this._state = state;

            // call enter
            await newObserver?.enter?.();
        }
    }
}
