//TODO: use TextImageButton instead
import { uiAlignCenter, uiSizeToFit } from '../../../../lib/pixi/uiTools';
import { BasicText, BasicTextOptions } from '../text/BasicText';
import { SliceButton, SliceButtonOptions } from './SliceButton';

// types
//-----------------------------------------------------------------------------
type SliceTextOptions = { offsetX?: number; offsetY?: number; paddingX?: number; paddingY?: number };
export type TextSliceButtonOptions = BasicTextOptions & SliceButtonOptions & SliceTextOptions;
/*
    slice button with text in middle
*/
export class TextSliceButton extends SliceButton {
    // init
    //-------------------------------------------------------------------------
    constructor(options: TextSliceButtonOptions) {
        super(options);

        // add text
        const text = this.addChild(new BasicText(options));

        const padX = options.paddingX ?? 0;
        const padY = options.paddingY ?? 0;
        uiSizeToFit(text, this.width - padX * 2, this.height + padY * 2);
        // center it
        uiAlignCenter(this, text);

        // add optional offset to the center pos
        text.x = text.x + (options.offsetX ?? 0);
        text.y = text.y + (options.offsetY ?? 0);
    }
}
