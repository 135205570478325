// common tween functions
//-----------------------------------------------------------------------------
export namespace tween {
    // linear
    export function linear(t: number): number {
        return t;
    }

    // power2
    export function pow2In(t: number): number {
        return t * t;
    }
    export function pow2Out(t: number): number {
        return 1 - pow2In(t - 1);
    }
    export function pow2InOut(t: number): number {
        return t < 0.5 ? 2 * pow2In(t) : 1 - Math.pow(-2 * t + 2, 2) / 2;
    }

    // power3
    export function pow3In(t: number): number {
        return t * t * t;
    }
    export function pow3Out(t: number): number {
        return 1 - pow3In(1 - t);
    }
    export function pow3InOut(t: number): number {
        return t < 0.5 ? 4 * pow3In(t) : 1 - Math.pow(-2 * t + 2, 3) / 2;
    }

    // power4
    export function pow4In(t: number): number {
        return t * t * t * t;
    }
    export function pow4Out(t: number): number {
        return 1 - pow4In(1 - t);
    }
    export function pow4InOut(t: number): number {
        return t < 0.5 ? 8 * pow4In(t) : 1 - Math.pow(-2 * t + 2, 4) / 2;
    }

    // powerN
    export function powNIn(n: number) {
        return (t: number) => Math.pow(t, n);
    }
    export function powNOut(n: number) {
        return (t: number) => 1 - Math.pow(1 - t, n);
    }
    export function powNInOut(n: number) {
        return (t: number) => (t < 0.5 ? Math.pow(2, n - 1) * Math.pow(t, n) : 1 - Math.pow(-2 * t + 2, n) / 2);
    }

    // back(height)
    export function backIn(height = 2) {
        return (t: number) => {
            const c3 = height + 1;
            return c3 * pow3In(t) - height * pow2In(t);
        };
    }
    export function backOut(height = 2) {
        return (t: number) => {
            const c3 = height + 1;
            return 1 + c3 * pow3In(t - 1) + height * pow2In(t - 1);
        };
    }
}

/*
TODO: make this work with single param
    static buildTrajectory(height: number, time: number, gravity = 9.8): TweenFunction {
        // calculate initial y velocity
        const v0y = (0.5 * gravity * time * time + height) / time;
        // create trajectory solver
        return (ratio: number, begin: number, end: number) => {
            // get current time
            const t = time * ratio;

            // solve y at current time
            return begin + (v0y * t - 0.5 * gravity * t * t);
        };
    }
*/
