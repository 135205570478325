import {
    Application,
    gameResize,
    I18nPlugin,
    ResizePlugin,
    ResourcePlugin,
    SoundPlugin,
    StagePlugin,
    VisibilityPlugin,
} from '@play-co/astro';
import { configureDebugPanel } from '@play-co/debug-panel';

import app from '../index.entry';
import { PerformanceAnalytics } from '../lib/performance/PerformanceAnalytics';
import { ParticlesPlugin } from '../lib/pixi/particles/ParticlesPlugin';
import { pixiSetScene } from '../lib/pixi/pixiTools';
// @ts-ignore manifest.json does not exist before client build
import manifest from '../manifest.json';
import { InstantGame } from '../plugins/instantGames/InstantGame';
import { InstantGamePlugin } from '../plugins/instantGames/InstantGamesPlugin';
import { NavPlugin } from '../plugins/nav/NavPlugin';
import { ResourceExPlugin } from '../plugins/resourceEx/ResourceExPlugin';
import { isValidLineId } from '../replicant/util';
import { BusyComponent } from './components/BusyComponent';
import { cheatUi } from './defs/cheats';
import { pixiConfig } from './defs/config';
import { NavLayer, navLoaders, navScreens } from './defs/nav';
import { languageFontMap } from './defs/text';
import { tipMap } from './defs/tips';
import { StartFlow } from './flows/StartFlow';
import { TutorialFlow } from './flows/tutorials/TutorialFlow';
import { getForcedLanguage, getUserPreferredLanguage } from './lib/util/device';
import { AnalyticsService } from './services/AnalyticsService';
import { CoreService } from './services/CoreService';
import { GameService } from './services/GameService';
import { InputService } from './services/InputService';
import { MusicService } from './services/MusicService';
import { PlatformService } from './services/PlatformService';
import { PuzzleMapService } from './services/PuzzleMapService';
import { SettingsService } from './services/SettingsService';
import { SoundService } from './services/SoundService';
import { TableStoreService } from './services/TableStoreService';
import { TipService } from './services/TipService';

/*
    application core
*/
export class App extends Application {
    // fields
    //-------------------------------------------------------------------------
    // plugins
    public i18nPlugin!: I18nPlugin;
    public instantGamePlugin!: InstantGamePlugin;
    public nav!: NavPlugin;
    public resizePlugin!: ResizePlugin;
    public resourcePlugin!: ResourcePlugin;
    public resource!: ResourceExPlugin;
    public soundPlugin!: SoundPlugin;
    public stage!: StagePlugin;
    public particles!: ParticlesPlugin;
    public visibilityPlugin!: VisibilityPlugin;
    // services (app specific plugins)
    public core!: CoreService;
    public input!: InputService;
    public platform!: PlatformService;
    public game!: GameService;
    public analytics!: AnalyticsService;
    public music!: MusicService;
    public settings!: SettingsService;
    public sound!: SoundService;
    public tableStore!: TableStoreService;
    public tip!: TipService;
    public puzzleMap!: PuzzleMapService;
    // components
    public busy: BusyComponent;
    // state
    public server: typeof InstantGame.replicant;

    // init
    //-------------------------------------------------------------------------
    constructor() {
        super(pixiConfig);
    }

    // impl
    //-------------------------------------------------------------------------
    public async run(): Promise<void> {
        // init plugins
        this._initPlugins();

        // init services
        this._initServices();

        // init application (await inits all plugins then services in order)
        await this.init();

        // create busy component
        this.busy = new BusyComponent();

        if (!process.env.REPLICANT_OFFLINE && !isValidLineId(app.server.state.id)) {
            this.showAlert('Unknown error.');
            return;
        }

        // Must trigger EntryFinal after PreLaunchCommand
        await InstantGame.sendEntryFinal();

        // post init start
        this._start();
    }

    // api
    //-------------------------------------------------------------------------
    //TODO: move next 3 into UiService
    // show an alert popup with an optional title
    public async showAlert(message: string, title = '[popupErrorGeneralTitle]') {
        // open error popup and wait on close
        await new Promise((resolve) =>
            this.nav.open('alertPopup', { title, message, onOk: resolve, onClose: resolve }),
        );

        // close
        await this.nav.close('alertPopup');
    }

    // private: start
    //-------------------------------------------------------------------------
    private async _start() {
        // open debug menu
        if (process.env.IS_DEVELOPMENT) {
            configureDebugPanel({
                replicant: this.server,
                ui: cheatUi,
            });
        }

        // await app.nav.open('introScreen');
        // return;

        // app.nav.open('cakeListPopup', { cakes: bakery.placements, onClose: () => app.nav.close('cakeListPopup') });
        // await new TutorialFlow().execute();
        // return;

        // Initialize performance analytics
        PerformanceAnalytics.init();

        if (app.server.state.tutorial.complete) {
            await app.nav.open('homeScreen', {});
            await new StartFlow().execute();
        } else {
            await new TutorialFlow().execute();
            // show optional pointer if we come from tutorial
            app.nav.open('homeScreen', { showPointer: true });
        }
    }

    // private: init
    //-------------------------------------------------------------------------
    private _initPlugins(): void {
        // install stage plugin and register the root scene
        this.stage = this.add(StagePlugin, { name: 'stage' });
        pixiSetScene(this.stage.stage);

        // install resize plugin
        this.resizePlugin = this.add(ResizePlugin, {
            resizeFunction: gameResize(pixiConfig.size.width, pixiConfig.size.height),
        });

        // install resource plugins
        this.resourcePlugin = this.add(ResourcePlugin, {
            name: 'resource',
            manifest,
        });
        this.resource = this.add(ResourceExPlugin, {
            name: 'resourceEx',
        });

        // install particle plugin
        this.particles = this.add(ParticlesPlugin);

        // install instant games plugin
        this.instantGamePlugin = this.add(InstantGamePlugin);

        // add navigation plugin
        this.nav = this.add(NavPlugin, {
            layers: NavLayer.count,
            screens: navScreens,
            loaders: navLoaders,
            preload: this._getLaunchScene.bind(this),
        });

        // install sound plugin
        this.soundPlugin = this.add(SoundPlugin, {});

        // install visibility plugin
        this.visibilityPlugin = this.add(VisibilityPlugin);

        // install i18n (localization) plugin. needs to be down here or errors
        this.i18nPlugin = this.add(I18nPlugin, {
            //generatedFontLanguages: ['en','ja'], // this should match the `fontLanguages` in `fido-config.json`
            entryDefaults: languageFontMap,
            //language: 'ja',
            defaultManifestID: 'assets/i18n',
        });
    }

    private _initServices(): void {
        // add core service
        this.core = this.add(CoreService, {});

        // add input service
        this.input = this.add(InputService, {});

        // add analytics service
        this.analytics = this.add(AnalyticsService, {});

        // add tip service
        this.tip = this.add(TipService, {
            tips: tipMap,
        });

        // add settings service
        const forcedLanguage = getForcedLanguage();
        const preferredLanguage = getUserPreferredLanguage();
        this.settings = this.add(SettingsService, { forcedLanguage, preferredLanguage });

        // add table store service
        this.tableStore = this.add(TableStoreService, {});

        // add music service
        this.music = this.add(MusicService, {});

        // add sound service
        this.sound = this.add(SoundService, {
            channels: 16,
        });

        // add platform service
        this.platform = this.add(PlatformService, {});

        // add puzzle map service
        this.puzzleMap = this.add(PuzzleMapService, {});

        // add game service
        this.game = this.add(GameService, {});
    }

    // private: support
    //-------------------------------------------------------------------------
    private _getLaunchScene(): string {
        return 'homeScreen';
    }
}
