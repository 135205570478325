import { NineSlicePlane, Sprite, Texture } from 'pixi.js';

import app from '../../../../index.entry';
import { uiAlignCenter } from '../../../../lib/pixi/uiTools';
import { BasicText } from '../text/BasicText';
import { BasicPopup, BasicPopupOptions } from './BasicPopup';

const HEIGHT = 600;

// types
//-----------------------------------------------------------------------------
export type NotEnoughStarsPopupOptions = {} & BasicPopupOptions;

const manifest = {
    arrow: 'icon.arrow.png',
    frame: 'frame.inset.light.png',
    button: 'button.red.small.png',
    star: 'icon.star.main.large.png',
    starFrame: 'icon.star.frame.png',
    board: 'icon.game.board.png',
};

/*
    Not enough stars popup
*/
export class NotEnoughStarsPopup extends BasicPopup {
    public override preload() {
        return [...super.preload(), ...app.resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: NotEnoughStarsPopupOptions) {
        super.spawning({
            width: 690,
            height: HEIGHT,
            underlay: 0.7,
            okButton: '[buttonContinue]',
            ...options,
        });

        const title = new BasicText({
            text: '[popopNoStarTite]',
            style: {
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowDistance: 5,
                dropShadowColor: 0x0c7c7c7,
                fill: 0x00,
                fontSize: 50,
                fontWeight: 'bold',
                lineJoin: 'round',
                stroke: 0xdf886e,
                strokeThickness: 6,
            },
        });
        const description = new BasicText({
            text: '[popopNoStarDesc]',
            style: {
                fill: 0x592929,
                fontSize: 34,
                fontWeight: 'bold',
                lineJoin: 'round',
            },
        });

        const frame = this._spawnMainFrame();

        // spawn
        this.main.addContent({
            title: {
                content: title,
                styles: {
                    position: 'topCenter',
                    marginTop: 90,
                },
            },
            main: {
                content: frame,
                styles: {
                    position: 'topCenter',
                    marginTop: 180,
                },
            },
            description: {
                content: description,
                styles: {
                    position: 'topCenter',
                    marginTop: 410,
                },
            },
        });
    }

    public override async spawned(): Promise<void> {
        await super.spawned();
    }

    public override async despawned() {
        super.despawned();
    }

    private _spawnMainFrame() {
        const frame = new NineSlicePlane(Texture.from(manifest.frame), 20, 20, 20, 20);
        frame.width = 612;
        frame.height = 200;
        const board = Sprite.from(manifest.board);
        const arrow = Sprite.from(manifest.arrow);
        const star = Sprite.from(manifest.star);
        const starFrame = Sprite.from(manifest.starFrame);

        starFrame.addChild(star);
        uiAlignCenter(starFrame, star);

        const offset = 170;
        uiAlignCenter(frame, board, -offset);
        uiAlignCenter(frame, arrow);
        uiAlignCenter(frame, starFrame, offset);

        frame.addChild(board, arrow, starFrame);

        return frame;
    }
}
