// types

import { Graphics, Sprite } from 'pixi.js';

import app from '../../../index.entry';
import { uiCreateMask, uiCreateQuad } from '../../../lib/pixi/uiTools';
import { tween } from '../../../lib/util/tweens';
import { sleep } from '../../../replicant/util/jsTools';
import { pixiConfig } from '../../defs/config';
import { SpeechScreen } from './SpeechScreen';

//-----------------------------------------------------------------------------
export type IntroScreenOptions = { fadeTime?: number; dual?: boolean };

// manifest
//-----------------------------------------------------------------------------
const manifest = {
    introbg: 'bg.store.outside.png',
};

export class IntroScreen extends SpeechScreen {
    // events
    //-------------------------------------------------------------------------
    // scene
    private _bgMask: Graphics;
    private _bgScroll: Sprite;

    // impl
    //-------------------------------------------------------------------------
    public override preload() {
        return [...super.preload(), ...app.resource.loadAssets(Object.values(manifest))];
    }

    public async spawning(options: IntroScreenOptions) {
        this.addOrientationListener();

        // play music
        app.music.play('chill_lofi.ogg');

        // spawn scene
        this._spawn(options);

        this._bgMask = uiCreateMask(this._bg.width, this._bg.height);
        this._bg.mask = this._bgMask;
        this._bg.addChild(this._bgMask);

        sleep(0.75).then(() => this.panDown());
    }

    public despawned() {
        this.empty();
    }

    // private: scene
    //-------------------------------------------------------------------------
    private async _spawn(options: IntroScreenOptions) {
        this.root.sortableChildren = true;
        // spawn scene
        this._bgScroll = Sprite.from(manifest.introbg);

        const containerHeight =
            app.stage.canvas.height < pixiConfig.size.height ? pixiConfig.size.height : app.stage.canvas.height;
        this._bg = uiCreateQuad(0x0, 0.0001, this._bgScroll.width, containerHeight);

        this.base.addContent({
            bgScroll: {
                content: this._bgScroll,
                styles: {
                    position: 'bottomCenter',
                    marginBottom: -680,
                },
            },
            bg: {
                content: this._bg,
                styles: {
                    position: 'bottomCenter',
                },
            },
        });
    }

    public panDown() {
        return this._bgScroll
            .animate()
            .add(this._bgScroll.position, { y: this._bgScroll.y - 674 }, 4.3, tween.pow2InOut);
    }
}
