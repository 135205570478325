import { Container, Sprite } from 'pixi.js';

import { CandleBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    base: 'block.candles.png',
    candle: 'scrap.candle.png',
};

const offset = [9, 10];
const positions: { x: number; y: number; z: number }[] = [
    {
        x: 8,
        y: 89,
        z: 1,
    },
    {
        x: 41,
        y: 89,
        z: 1,
    },
    {
        x: 74,
        y: 89,
        z: 1,
    },
    {
        x: 107,
        y: 89,
        z: 1,
    },
    {
        x: 24,
        y: 36,
        z: 0,
    },
    {
        x: 57,
        y: 36,
        z: 0,
    },
    {
        x: 90,
        y: 36,
        z: 0,
    },
];

/*
    candle block view
*/
export class CandleBlockView extends Container {
    // fields
    //-------------------------------------------------------------------------
    // constants
    public static LIMIT = positions.length;
    // scene
    private _candles: Sprite[] = [];
    // state
    private _count = 0;

    // properties
    //-------------------------------------------------------------------------
    public get count(): number {
        return this._count;
    }

    public set count(value: number) {
        // if adding
        if (value > this._count) {
            // spawn candles
            for (let i = this._count; i < value; ++i) {
                this._spawnCandle(i);
            }
            // else removing
        } else {
            // despawn candles
            for (let i = this._count - 1; i >= value; --i) {
                this._despawnCandle(i);
            }
        }
        this.sortChildren();

        // update field
        this._count = value;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(props: CandleBlockProps) {
        super();

        // create border
        const border = Sprite.from(manifest.base);

        this.addChild(border);

        // complete scene
        blockPositionView(this, props);
        border.x += offset[0];
        border.y += offset[1];
    }

    // private: spawn
    //-------------------------------------------------------------------------
    private _spawnCandle(index: number) {
        const position = positions[index];

        // create candle sprite
        const candle = Sprite.from(manifest.candle);
        candle.x = position.x + offset[0];
        candle.y = position.y + offset[1];
        candle.zIndex = position.z;

        // add to table
        this._candles[index] = candle;

        // spawn
        this.addChild(candle);
    }

    private _despawnCandle(index: number) {
        const sprite = this._candles[index];

        // despawn
        this.removeChild(sprite);

        // remove from table
        this._candles[index] = undefined;
    }

    static assets(): string[] {
        return [manifest.base, manifest.candle];
    }
}
