import { createChatbotConfig, createChatbotEvents, LineWebhookEvent } from '@play-co/replicant';

import { messages } from '../messages';
import { scheduledActions } from '../scheduledActions';
import { stateSchema } from '../state';
import { timeFromComponents } from '../util/timeTools';
import { chatbotMessageTemplates } from './messageTemplates';

// Also, define how to respond to webhook events (onGameEnd)
const events = createChatbotEvents(stateSchema, { messages, scheduledActions })({
    enabledWebhookEvents: ['follow', 'message'],
    onWebhook: async (state, messageEvent: LineWebhookEvent, api) => {
        // if (messageEvent.type === 'follow') {
        //     // forced japanese because we dont know the language yet
        //     await sendLoginMessage(state, api as any, 'greetingJA');
        // }
        // https://developers.line.biz/en/reference/messaging-api/#message-event
        // if (messageEvent.type === 'message') {
        //     const message = messageEvent.message as any;
        //     if (message?.type === 'text' && !!message?.text) {
        //         const parsed = message.text.trim().toLowerCase();
        //         if (parsed === 'repair') {
        //             // send a new login message
        //             // todo repair analytics??
        //             await sendRepairMessage(state, api as any);
        //         }
        //     }
        // }
    },
});

// make sure to add the chatbot configuration the our config.ts
const chatbot = createChatbotConfig(chatbotMessageTemplates, events);

export default chatbot;

/**
 * Generate a login token for the chatbot message payload
 */
export async function createLoginTokenOLD(api: any) {
    const token = await api.loginLinks.createLoginToken({
        expiresInMs: timeFromComponents({ days: 30 }),
    });

    return token;
}

/**
 * Generate a login token for the chatbot message payload with no max uses or expiry
 */
export async function createLoginTokenMultiUse(api: any) {
    const token = await api.loginLinks.createWebPlayerToken();

    return token;
}
