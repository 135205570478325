import { action, asyncAction, SB } from '@play-co/replicant';

import gameConfig from '../defs/gameConfig';
import { AsyncActionAPI, MutableState, State, SyncActionAPI } from '../defs/replicant';
import { numberClamp } from '../util/mathTools';
import { getCustomerUnlocks } from './bakery';
import { livesGet, livesSet } from './lives';

// types
//-----------------------------------------------------------------------------
export type PuzzleCompleteResults = {
    stars: number;
    completedLevel: number;
    //kcoins: number;
    //medals: number;
    //grantedItem?: CollectionItemId;
    // was the puzzle side of the mission completed
    missionCompleted?: boolean;
};

// state
//-----------------------------------------------------------------------------
export const puzzleState = {
    // puzzle
    puzzle: SB.object({
        // current level
        level: SB.int().default(1),
        // highest completed level
        lastLevel: SB.int().default(0),
        // start timestamp
        time: SB.int(),
        // continue counter
        continues: SB.int(),
    }),
};

// actions
//-----------------------------------------------------------------------------
//TODO: cheaters!
export const puzzleActions = {
    // begin puzzle level
    puzzleBegin: action((state: MutableState, options: { levelOverride?: number }, api: SyncActionAPI) => {
        const now = api.date.now();

        const { levelOverride } = options;

        // require has lives
        const availableLives = livesGet(state, now);
        if (availableLives > 0) {
            // consume life and fail stat (will refund on complete)
            livesSet(state, availableLives - 1, now);
            //++state.collectionStats.puzzleFails;
            const puzzle = state.puzzle;

            if (state.puzzle.lastLevel < gameConfig.puzzle.levels.max && levelOverride) {
                throw new Error(`Not allowed to play override level at ${state.puzzle.lastLevel}`);
            }

            if (levelOverride) {
                if (levelOverride < 1 || levelOverride > gameConfig.puzzle.levels.max) {
                    throw new Error(`Invalid level override ${levelOverride}`);
                }
                puzzle.level = levelOverride;
            }
            // set start timestamp
            puzzle.time = now;

            // reset continue count
            puzzle.continues = 0;
        }
    }),
    // complete puzzle level
    //TODO: cheaters!
    puzzleComplete: asyncAction(
        async (state: MutableState, options: { playerMoves: number; mapMoves: number }, api: AsyncActionAPI) => {
            const puzzle = state.puzzle;
            const now = api.date.now();
            const completedLevel = state.puzzle.level;
            const out = {} as PuzzleCompleteResults;
            out.completedLevel = completedLevel;

            // require has start time
            //if (puzzle.time === 0)                return out;

            // reset start time
            puzzle.time = 0;

            // refund life and fail stat
            livesSet(state, livesGet(state, now) + 1, now);
            //--state.collectionStats.puzzleFails;

            // update last level
            if (puzzle.level > puzzle.lastLevel) {
                puzzle.lastLevel = puzzle.level;
                state.stars += gameConfig.stars.puzzleFinishGrant;
                out.stars = gameConfig.stars.puzzleFinishGrant;
                // do undefined check to also detect the 0 id

                const unlocks = getCustomerUnlocks(state);
                if (unlocks[puzzle.level] !== undefined) {
                    // make sure there's no duplicate from the Enabled 0000_FreeMangoMousse test
                    if (
                        unlocks[puzzle.level] !== 0 ||
                        (!state.bakery.produce['mangoMousseCake'] && !state.bakery.cakes['mangoMousseCake'])
                    ) {
                        // push customerEntryId to newCustomers
                        state.bakery.newCustomers.push(unlocks[puzzle.level]);
                    }
                }
            }

            puzzleLevelSetPlayer(state, completedLevel + 1);

            // update stats
            //++state.collectionStats.puzzleWins;

            return out;
        },
    ),

    // set puzzle level (dev only)
    puzzleSetLevel: action((state: MutableState, options: { level: number }) => {
        // if dev env
        if (process.env.IS_DEVELOPMENT) {
            // set dev puzzle level
            puzzleLevelSetDev(state, options.level);
        }
    }),
};

// api
//-----------------------------------------------------------------------------
export function puzzleIsMaxLevel(state: State): boolean {
    return state.puzzle.level >= gameConfig.puzzle.levels.max;
}

export function puzzleLevelSetPlayer(state: MutableState, level: number) {
    state.puzzle.level = Math.min(level, gameConfig.puzzle.levels.max);
}

export function puzzleLevelSetDev(state: MutableState, level: number) {
    state.puzzle.level = numberClamp(level, 1, gameConfig.puzzle.levels.max);
    state.puzzle.lastLevel = state.puzzle.level - 1;
}
