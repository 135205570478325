import { Sprite, Texture } from 'pixi.js';

import { IceDustAnimation } from '../../animations/IceDustAnimation';
import { IceBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    image: 'block.ice.full.png',
};

/*
    full ice block view
*/
export class FullIceBlockView extends Sprite {
    // init
    //-------------------------------------------------------------------------
    constructor(props: IceBlockProps) {
        // create base view
        super(Texture.from(manifest.image));
        blockPositionView(this, props);
    }

    public static assets(): string[] {
        return [manifest.image, ...IceDustAnimation.assets()];
    }
}
