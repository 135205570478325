import { Sprite, Texture } from 'pixi.js';

import { BombEffect } from '../../actions/effects/BombEffect';
import { BombBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    image: 'block.bomb.png',
};

/*
    bomb block view
*/
export class BombBlockView extends Sprite {
    // init
    //-------------------------------------------------------------------------
    constructor(props: BombBlockProps) {
        // create base view
        super(Texture.from(manifest.image));
        blockPositionView(this, props);
    }

    static assets(effects: boolean): string[] {
        return [
            ...Object.values(manifest),
            ...(effects ? BombEffect.assets({ big: true }) : []),
            ...(effects ? BombEffect.assets({ big: false }) : []),
        ];
    }
}
