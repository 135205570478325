import { gsap } from 'gsap';
import { Container } from 'pixi.js';

import { arrayRandom } from '../../../../../replicant/util/jsTools';
import { BasicBlockEntity, BlockEntity, SlimeBlockEntity, spawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { blockIterateNeighbors } from '../../util/blockTools';
import { IEffect } from './IEffect';

// types
//-----------------------------------------------------------------------------
// public
export type SlimeEffectOptions = {
    slimes: SlimeBlockEntity[];
};

// private
type SlimeBlockPair = {
    slime: SlimeBlockEntity;
    basic: BasicBlockEntity;
};

/*
    spawns one new slime from the position of a random existing slime on top of
    a random neighboring basic block (if any exist)
*/
export class SlimeEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _options: SlimeEffectOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: SlimeEffectOptions) {
        this._scene = scene;
        this._options = options;
    }

    static assets(): string[] {
        return [];
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // find slime+basic pairs
        const pairs = this._findPairs();
        if (pairs.length > 0) {
            const phase = this._scene.sessionEntity.c.phase;

            // pick a random one
            const pair = arrayRandom(pairs);

            // push activations
            phase.activePush();

            // do slime action from the slime block to its neighbor block
            await this._slimeTarget(pair.slime, pair.basic);

            // pop activations
            await phase.activePop();
        }
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _slimeTarget(from: BlockEntity, target: BlockEntity) {
        const duration = 0.4;
        const fromPosition = from.c.view2d.view.position;

        // spawn slime block at slime position
        const newSlime = spawnBlockEntity(this._scene, { id: 'slime' }, target.c.position.mapPosition);

        // animate to requested view position
        const view = newSlime.c.view2d.view;
        const scaleView = view.getChildAt(0) as Container;
        await gsap.timeline().from(scaleView.scale, { x: 0.2, y: 0.2, duration, ease: 'back.out' }).from(
            view,
            {
                x: fromPosition.x,
                y: fromPosition.y,
                duration,
                ease: 'power2.inOut',
            },
            0,
        );

        // trigger ungoal
        this._triggerUngoal(newSlime);
    }

    // private: support
    //-------------------------------------------------------------------------
    private _triggerUngoal(source: BlockEntity) {
        const id = 'slime';
        const map = this._scene.sessionEntity.c.map;
        let count = map.goals[id];

        // if slime is a goal
        if (count > 0) {
            // increment goal count
            map.goals[id] = ++count;

            // notify ungoal
            this._scene.events.publish({ id: 'ungoal', goalId: 'slime', source });
        }
    }

    private _findPairs(): SlimeBlockPair[] {
        // from each given slime create list of pairs of that slime and a neighboring basic block
        return this._options.slimes.reduce((pairs, slime) => {
            // iterate slime's immediate neighbors
            blockIterateNeighbors(this._scene.sessionEntity.c.map, slime, (neighbor, overlay) => {
                // skip subject
                if (overlay === slime) return true;
                // if neighbor is un-overlayed basic block create pair and end iteration
                if (neighbor.c.block.props.type === 'basic' && !overlay) {
                    pairs.push({
                        slime,
                        basic: neighbor as BasicBlockEntity,
                    });
                }
                return false;
            });
            return pairs;
        }, [] as SlimeBlockPair[]);
    }
}
