import { i18n } from '@play-co/astro';

import { LanguageId } from '../../replicant/defs/settings';
import { stringFormat } from '../../replicant/util/jsTools';

/*
    format text to include locale translation based on following format:

    normal text [textId] normal text [textIdWithArgs|arg0|arg1] normal text
*/
export function textLocaleFormat(format: string, language?: LanguageId): string {
    // translate/replace text ids
    return format.replace(/\[([^\]]+)\]/g, (match, value) => {
        try {
            // break down text id into components (id + arguments)
            const args: string[] = value.split('|');
            const textId = args.shift();

            // get locale text or format from text id
            const format = i18n.getEntry(textId, language).text;

            // return with arguments included
            return stringFormat(format, args);
        } catch {}

        return `[${value}]`;
    });
}

// returns a proper locale formatted currency string given a currency id (ex: USD, JPY)
export function textCurrencyFormat(amount: string, currency: string): string {
    return new Intl.NumberFormat(i18n.language || 'en', { style: 'currency', currency }).format(parseFloat(amount));
}
