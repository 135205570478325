import { Container, Sprite } from 'pixi.js';

import { ColorId, SirenBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// types
//-----------------------------------------------------------------------------
export type SirenMap = { [key in ColorId]?: Sprite };

// constants
//-----------------------------------------------------------------------------
const manifest = {
    base: 'block.siren.png',
};

const offset = [8, 10];
const sirenScraps: { [key in ColorId]?: { asset: string; x: number; y: number; z: number } } = {
    blue: {
        asset: 'scrap.siren.blue.png',
        x: 17,
        y: 8,
        z: 0,
    },
    red: {
        asset: 'scrap.siren.red.png',
        x: 76,
        y: 8,
        z: 0,
    },
    yellow: {
        asset: 'scrap.siren.yellow.png',
        x: 17,
        y: 62,
        z: 1,
    },
    green: {
        asset: 'scrap.siren.green.png',
        x: 76,
        y: 62,
        z: 1,
    },
};

/*
    siren block view
*/
export class SirenBlockView extends Container {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _sirens: SirenMap = {};

    // properties
    //-------------------------------------------------------------------------
    public get sirens(): SirenMap {
        return this._sirens;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(props: SirenBlockProps) {
        super();

        // create border
        const border = Sprite.from(manifest.base);

        this.addChild(border);

        // complete scene
        blockPositionView(this, props);
        border.x += offset[0];
        border.y += offset[1];
    }

    // api
    //-------------------------------------------------------------------------
    public isEmpty(): boolean {
        return Object.keys(this._sirens).length === 0;
    }

    public enable(color: ColorId) {
        // spawn siren sprite
        this._spawnSiren(color);
    }

    public disable(color: ColorId) {
        // despawn siren sprite
        this._despawnSiren(color);
    }

    // private: spawn
    //-------------------------------------------------------------------------
    private _spawnSiren(color: ColorId) {
        const props = sirenScraps[color];

        // create siren sprite
        const siren = Sprite.from(props.asset);

        siren.scale.set(0.78);
        siren.x = props.x + offset[0];
        siren.y = props.y + offset[1] + 20;
        siren.zIndex = props.z;

        // add to table
        this._sirens[color] = siren;

        // spawn
        this.addChild(siren);
        this.sortChildren();
    }

    private _despawnSiren(color: ColorId) {
        // get siren sprite
        const siren = this._sirens[color];

        if (siren) {
            // remove from scene
            this.removeChild(siren);

            // remove from table
            delete this._sirens[color];
        }
    }

    static assets(): string[] {
        return [manifest.base, ...Object.values(sirenScraps).map((object) => object.asset)];
    }
}
