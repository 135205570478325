import { Sprite, Texture } from 'pixi.js';

import { uiAlignBottom, uiAlignRight } from '../../../../../lib/pixi/uiTools';
import { BasicText } from '../../../../lib/ui/text/BasicText';
import { MysteryBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    immobile: 'block.mystery.png',
    mobile: 'block.mystery.round.png',
};

/*
    mystery block view
*/
export class MysteryBlockView extends Sprite {
    // fields
    //-------------------------------------------------------------------------
    // scene
    public _countView: BasicText;
    // state
    private _count = 0;

    // properties
    //-------------------------------------------------------------------------
    public get count(): number {
        return this._count;
    }

    public set count(value: number) {
        // update field
        this._count = value;

        // update count view
        this._countView.text = value.toString();
    }

    // init
    //-------------------------------------------------------------------------
    constructor(props: MysteryBlockProps) {
        // create base view
        super(Texture.from(props.immobile ? manifest.immobile : manifest.mobile));
        blockPositionView(this, props);

        // create count view
        const countView = (this._countView = new BasicText({
            style: {
                fill: '#fff',
                fontSize: 32,
                strokeThickness: 8,
                lineJoin: 'round',
            },
        }));
        countView.view.anchor.x = 1;
        this.addChild(countView);

        // align
        uiAlignRight(this, countView, 18);
        uiAlignBottom(this, countView, 10);
    }

    static assets(props: MysteryBlockProps): string[] {
        return [props.immobile ? manifest.immobile : manifest.mobile];
    }
}
