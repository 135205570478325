import { GameScene } from '../../GameScene';
import { blockIterateAll } from '../../util/blockTools';
import { CollisionTracker } from '../../util/CollisionTracker';
import { IEffect } from './IEffect';

/*
    attack all blocks effect
*/
export class AttackAllEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene) {
        this._scene = scene;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        const collisionTracker = new CollisionTracker();

        // iterate blocks
        blockIterateAll(this._scene, (base) => {
            // attack block
            collisionTracker.collide(base.c.block, 'attack');
        });
    }
}
