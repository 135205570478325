import { Sprite, Texture } from 'pixi.js';

import { flex } from '../../../../../lib/ui/mods/flexMod';
import { BreakEffect } from '../../actions/effects/BreakEffect';
import { ComboGlowAnimation } from '../../animations/ComboGlowAnimation';
import { BasicBlockProps, ColorId } from '../../defs/block';
import { SymbolId, symbolMap } from '../../defs/symbol';
import { blockPositionView } from '../../util/blockTools';
import { BombBlockView } from './BombBlockView';
import { CubeBlockView } from './CubeBlockView';
import { RocketBlockView } from './RocketBlockView';

// constants
//-------------------------------------------------------------------------
const colorMap: { [key in ColorId]?: string } = {
    blue: 'block.basic.blue.png',
    green: 'block.basic.green.png',
    purple: 'block.basic.purple.png',
    red: 'block.basic.red.png',
    yellow: 'block.basic.yellow.png',
};

/*
    basic block view
*/
export class BasicBlockView extends Sprite {
    // fields
    //-------------------------------------------------------------------------
    // state
    private _symbolId!: SymbolId;
    // scene
    private _symbolSprite: Sprite;

    // properties
    //-------------------------------------------------------------------------
    public get symbolId(): SymbolId {
        return this._symbolId;
    }

    public set symbolId(symbolId: SymbolId) {
        // update field
        this._symbolId = symbolId;

        // update texture
        this._symbolSprite.texture = Texture.from(symbolMap[symbolId].asset);
    }

    // init
    //-------------------------------------------------------------------------
    constructor(props: BasicBlockProps) {
        // create base view
        super(Texture.from(colorMap[props.color]));
        flex(this, {});
        blockPositionView(this, props);

        // create symbol sprite
        const sprite = (this._symbolSprite = new Sprite());
        //sprite.blendMode = BLEND_MODES.MULTIPLY;
        sprite.anchor.set(0.5, 0.5);
        flex(sprite, { centerX: 0, centerY: 10 });

        // spawn
        this.addChild(sprite);
    }

    static assets(props: BasicBlockProps, effects: boolean): string[] {
        return [
            colorMap[props.color],
            ...BombBlockView.assets(effects),
            ...RocketBlockView.assets(effects),
            ...CubeBlockView.assets({ color: props.color }, effects),
            ...(effects ? BreakEffect.assets() : []),
            ...(effects ? ComboGlowAnimation.assets() : []),
        ];
    }
}
