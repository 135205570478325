import { DynamicNumber, importDynamicNumber } from '../../../defs/types';
import { Particle } from '../Particle';
import { IBehavior } from './IBehavior';

// types
//-----------------------------------------------------------------------------
export type DragBehaviorOptions = {
    type: 'drag';
    drag: DynamicNumber;
};

/*
    drag aka viscosity aka fluid/air resistance
*/
export class DragBehavior implements IBehavior {
    // fields
    //-------------------------------------------------------------------------
    private _particle: Particle;
    private _drag: number;

    // init
    //-------------------------------------------------------------------------
    constructor(particle: Particle, options: DragBehaviorOptions) {
        this._particle = particle;
        this._drag = importDynamicNumber(options.drag);
    }

    // impl
    //-------------------------------------------------------------------------
    public step(dt: number) {
        const velocity = this._particle.velocity;

        // v1 = v0 - v0 * drag * dt
        velocity.subtract(velocity.clone().multiplyScalar(this._drag * dt));
    }
}
