import { NineSlicePlane, Texture } from 'pixi.js';

import { Color } from '../../../../lib/pixi/Color';
import { HslFilter } from '../../../../lib/pixi/filters/HSL/HslFilter';
import { BasicProgressBar } from '../../../lib/ui/progress/BasicProgressBar';

// types
//-----------------------------------------------------------------------------
export type LoadingProgessBarOptions = {
    width: number;
};

// constants
//-----------------------------------------------------------------------------
const settings = {
    from: 0xff00ff,
    to: 0x00ffff,
};
const manifest = {
    bg: 'progress.basic.bg.png',
    fill: 'progress.basic.fill.png',
};

/*
    ui: loading progress bar
*/
export class LoadingProgessBar extends NineSlicePlane {
    // init
    //-------------------------------------------------------------------------
    // scene
    private _bar: BasicProgressBar;
    // components
    private _hsl: HslFilter;

    // properties
    //-------------------------------------------------------------------------
    // progress
    public get progress(): number {
        return this._bar.progress;
    }

    public setProgress(progress: number, animate: boolean) {
        this._bar.setProgress(progress, animate);
        this._updateColor();
    }

    // init
    //-------------------------------------------------------------------------
    static assets(): string[] {
        return Object.values(manifest);
    }

    constructor(options: LoadingProgessBarOptions) {
        // spawn background
        const bg = Texture.from(manifest.bg);
        super(bg, 36, 33, 36, 31);
        this.width = options.width;
        this.height = bg.height;

        // spawn fill bar
        const bar = (this._bar = this.addChild(
            new BasicProgressBar({
                image: manifest.fill,
                width: options.width - 24,
                left: 18,
                right: 18,
            }),
        ).props({
            x: 12,
            y: 10,
            filters: [(this._hsl = new HslFilter())],
        }));
    }

    // private: updaters
    //-------------------------------------------------------------------------
    private _updateColor() {
        const from = Color.from(settings.from).toHsl();
        const to = Color.from(settings.to).toHsl();
        const hsl = from.lerp(to, this.progress);
        hsl.luminance = 0.8;
        this._hsl.hsl = hsl;
    }
}
