import { BLEND_MODES, Rectangle, RenderTexture, Sprite, Texture } from 'pixi.js';

import app from '../../../../../index.entry';
import { PositionType } from '../../../../../lib/defs/types';
import { MaskFilter } from '../../../../../lib/pixi/filters/Mask/MaskFilter';
import { TileSlicePlane } from '../../../../../lib/pixi/TileSlicePlane';
import { flex } from '../../../../../lib/ui/mods/flexMod';
import { arrayCreate } from '../../../../../replicant/util/jsTools';
import { ColorId, WallBlockProps } from '../../defs/block';
import { config } from '../../defs/config';
import { blockPositionView } from '../../util/blockTools';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    bricks: 'block.wall.bricks.png',
    blend: 'block.wall.blend.png',
};

const dynoMap: { [key in ColorId]?: string } = {
    all: 'block.wall.dyno.all.png',
    blue: 'block.wall.dyno.blue.png',
    green: 'block.wall.dyno.green.png',
    purple: 'block.wall.dyno.purple.png',
    red: 'block.wall.dyno.red.png',
    yellow: 'block.wall.dyno.yellow.png',
};

const digitOffsets = [69, 49];

/*
    wall block view
*/
export class WallBlockView extends TileSlicePlane {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _dynomite: Dynomite;

    // properties
    //-------------------------------------------------------------------------
    static assets(props: WallBlockProps): string[] {
        return [...Object.values(manifest), ...Dynomite.assets(props)];
    }

    public get count(): number {
        return this._dynomite.count;
    }

    public set count(value: number) {
        this._dynomite.count = value;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(props: WallBlockProps, positionHint?: PositionType) {
        const size = config.tile.size;
        const width = size * props.width;
        const height = size * props.height + 14;
        const texture = Texture.from(manifest.bricks);
        const position = positionHint || { x: 0, y: 0 };

        // create base view
        super({
            texture,
            width,
            height,
            left: 38,
            right: 38,
            top: 53,
            bottom: 37,
        });
        flex(this, {
            width,
            height,
        });

        //TODO: this can be removed and instead the blend texture can be applied directly via a new custom texture blending filter
        // create mask from current wall
        const maskTexture = RenderTexture.create({ width, height });
        app.stage.renderer.render(this, { renderTexture: maskTexture });
        const mask = new Sprite(maskTexture);
        mask.renderable = false;
        this.addChild(mask);

        // spawn masked blend over base view
        const blend = new Sprite(
            new Texture(
                Texture.from(manifest.blend).baseTexture,
                new Rectangle(position.x * size, position.y * size, width, height),
            ),
        );
        const filter = new MaskFilter(mask);
        filter.blendMode = BLEND_MODES.ADD;
        blend.filters = [filter];
        this.addChild(blend);

        // spawn dynomite
        this._dynomite = this.addChild(
            flex(new Dynomite(props), {
                centerX: 0,
                centerY: 0,
            }),
        );

        // position
        blockPositionView(this, props);
    }
}

/*
    dynomite view
*/
class Dynomite extends Sprite {
    // fields
    //-------------------------------------------------------------------------
    // state
    private _count = 0;

    // properties
    //-------------------------------------------------------------------------
    static assets(props: WallBlockProps): string[] {
        return [dynoMap[props.color], ...arrayCreate(10, (i) => `text.${i}.png`)];
    }

    public get count(): number {
        return this._count;
    }

    public set count(value: number) {
        // update field
        this._count = value;

        // update view
        this._updateCountView();
    }

    // init
    //-------------------------------------------------------------------------
    constructor(props: WallBlockProps) {
        // create base
        super(Texture.from(dynoMap[props.color]));
    }

    // private: updaters
    //-------------------------------------------------------------------------
    private _updateCountView() {
        // despawn existing
        this.removeChildren();

        // spawn digits
        let value = this._count;
        for (let i = 0; i < digitOffsets.length; ++i, value = Math.floor(value / 10)) {
            // get digit
            const digit = value % 10;

            // spawn digit sprite
            this.addChild(
                flex(Sprite.from(`text.${digit}.png`), {
                    top: 36,
                    left: digitOffsets[i],
                }),
            );
        }
    }
}
