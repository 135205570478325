import type { QueriesObject, QueryResults, System } from '@play-co/odie';

import TaskQueue from '../../../../lib/pattern/TaskQueue';
import { DogEffect } from '../actions/effects/DogEffect';
import { BlockComponent } from '../components/BlockComponent';
import { DogBlockComponent } from '../components/DogBlockComponent';
import { DogBlockEntity } from '../entities/BlockEntity';
import type { GameScene } from '../GameScene';

/*
    handles dog block specifics
*/
export class DogBlockSystem implements System {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'blockDog';
    public static Queries: QueriesObject = {
        block: {
            components: [BlockComponent, DogBlockComponent],
        },
        removed: {
            components: [BlockComponent],
            removed: true,
        },
    };

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    public queries!: QueryResults;
    // state
    private _activateQueue: TaskQueue = new TaskQueue();

    // impl
    //-------------------------------------------------------------------------
    public removedFromQuery() {
        // handle basic block cleared
        this.queries.block.forEach((entity: DogBlockEntity) => this._activateDog(entity));
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _activateDog(entity: DogBlockEntity) {
        // add to queue
        this._activateQueue.add(async () => {
            // play effect
            await new DogEffect(this.scene, { subject: entity }).execute();
        });
    }
}
