//TODO: support multitouch
import { Vector2 } from '../../math/Vector2';
import { InputEvent, TouchInputComponent } from './TouchInputComponent';

// types
//-----------------------------------------------------------------------------
export type Gesture = {
    // start position
    begin: Vector2;
    // last position
    last: Vector2;
    // change in movement in a single frame
    motion: Vector2;
    // if button is up or down
    down: boolean;
};
export type GestureListener = (gesture: Gesture) => void;

/*
    scene: gesture input component
*/
export class GestureInputComponent {
    // fields
    //-------------------------------------------------------------------------
    // input
    public onGesture?: GestureListener;
    // state
    private _begin: Vector2;
    private _last: Vector2;
    private _motion: Vector2;
    private _down: boolean;
    private _id: number;

    // init
    //-------------------------------------------------------------------------
    constructor(input: TouchInputComponent) {
        // register events
        input.onDown = this._onBegin.bind(this);
        input.onUp = this._onUp.bind(this);
        input.onMove = this._onMove.bind(this);
    }

    // private: event handlers
    //-------------------------------------------------------------------------
    private _onBegin(event: InputEvent): void {
        // set initial state
        this._last = this._begin = event.position;
        this._motion = new Vector2();
        this._down = true;
        this._id = event.id;
    }

    private _onUp(event: InputEvent): void {
        // require down
        if (event.id === this._id && this._down) {
            // reset down
            this._down = false;

            // notify
            this._notifyGesture();
        }
    }

    private _onMove(event: InputEvent): void {
        // require down
        if (event.id === this._id && this._down) {
            // get current position
            const position = event.position;

            // if has last position
            if (this._last) {
                // update swipe vector
                this._motion = position.clone().subtract(this._last);

                // notify
                this._notifyGesture();
            }

            // update last position and time
            this._last = position;
        }
    }

    // private: notifies
    //-------------------------------------------------------------------------
    private _notifyGesture(): void {
        this.onGesture?.({
            begin: this._begin.clone(),
            last: this._last.clone(),
            motion: this._motion,
            down: this._down,
        });
    }
}
