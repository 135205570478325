import { Container, DisplayObject, Sprite } from 'pixi.js';

import { prototypeHookAfter } from '../../../replicant/util/jsTools';
import { Vector2 } from '../../math/Vector2';

// types
//-----------------------------------------------------------------------------
enum AlignType {
    begin,
    center,
    end,
}

type ValueFormat = string | number;
class Value {
    format: ValueFormat = 0;
    pixel = 0;
}

export interface ILayout {
    // size
    width?: ValueFormat;
    maxWidth?: ValueFormat;
    scaleWidth?: ValueFormat;
    height?: ValueFormat;
    maxHeight?: ValueFormat;
    scaleHeight?: ValueFormat;
    // alignment
    left?: ValueFormat;
    right?: ValueFormat;
    hcenter?: ValueFormat;
    top?: ValueFormat;
    bottom?: ValueFormat;
    vcenter?: ValueFormat;
    // sync size to associated container
    sync?: boolean;
}

// extension
//-----------------------------------------------------------------------------
Object.defineProperty(Container.prototype, 'xlayout', {
    value(layout?: ILayout) {
        if (!getLayout(this)) new LayoutEngine(this, layout || {});
        getLayout(this).update(layout || {});
        return this;
    },
});

// hooks
//-----------------------------------------------------------------------------
prototypeHookAfter(Container, 'addChild', function (child: Container) {
    const layout = getLayout(child);
    if (layout) layout.$onParentUpdated();
});

/*
prototypeHookAfter(Container, '_calculateBounds', function () {
    const layout = getLayout(this);
    if (layout) layout.$onContainerSizeUpdated();
});
*/

// util
//-----------------------------------------------------------------------------
function getLayout(object: DisplayObject): LayoutEngine | undefined {
    return (object as any)?._layout as LayoutEngine;
}

/*
    layout engine
*/
class LayoutEngine implements ILayout {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _container: Container;
    // state: size
    private _width: Value;
    private _maxWidth: Value;
    private _scaleWidth: Value;
    private _height: Value;
    private _maxHeight: Value;
    private _scaleHeight: Value;
    // state: alignment
    private _hType = AlignType.begin;
    private _hValue = new Value();
    private _vType = AlignType.begin;
    private _vValue = new Value();
    // state: options
    private _sync = false;

    // properties
    //-------------------------------------------------------------------------
    // width
    public get width(): number {
        // get layout width
        if (this._width) return this._width.pixel;
        // else max width
        else if (this._maxWidth) return Math.min(this._maxWidth.pixel, getLayout(this._container.parent)?.width || 0);
        // else container width
        return this._container.width;
    }

    public set width(format: ValueFormat) {
        // create layout height if does not exist
        if (!this._width) this._width = new Value();
        // set value and if changed, notify size updated
        if (this._setValue(this._width, format)) this._onSizeUpdated();
    }

    public set maxWidth(format: ValueFormat) {
        // create max height if does not exist
        if (!this._maxWidth) this._maxWidth = new Value();
        // set value and if changed, notify size updated
        if (this._setValue(this._maxWidth, format)) this._onSizeUpdated();
    }

    public set scaleWidth(format: ValueFormat) {
        // access scale width
        const value = this._scaleWidth || (this._scaleWidth = new Value());
        // if changing, set format and update scale size and container size. setup height if doesnt exist
        if (value.format !== format) {
            value.format = format;
            if (!this._scaleHeight) this._scaleHeight = new Value();
            this._updateScaleSize();
            this._updateContainerSize();
        }
    }

    // height
    public get height(): number {
        // get layout height
        if (this._height) return this._height.pixel;
        // else max height
        else if (this._maxHeight) {
            return Math.min(this._maxHeight.pixel, getLayout(this._container.parent)?.height || 0);
        }
        // else container height
        return this._container.height;
    }

    public set height(format: ValueFormat) {
        // create layout height if does not exist
        if (!this._height) this._height = new Value();
        // set value and if changed, notify size updated
        if (this._setValue(this._height, format)) this._onSizeUpdated();
    }

    public set maxHeight(format: ValueFormat) {
        // create max height if does not exist
        if (!this._maxHeight) this._maxHeight = new Value();
        // set value and if changed, notify size updated
        if (this._setValue(this._maxHeight, format)) this._onSizeUpdated();
    }

    public set scaleHeight(format: ValueFormat) {
        // access scale height
        const value = this._scaleHeight || (this._scaleHeight = new Value());
        // if changing, set format and update scale size and container size
        if (value.format !== format) {
            value.format = format;
            if (!this._scaleWidth) this._scaleWidth = new Value();
            this._updateScaleSize();
            this._updateContainerSize();
        }
    }

    // alignment: left
    public get left(): number {
        return this._hValue.pixel;
    }

    public set left(format: ValueFormat) {
        this._setHAlign(AlignType.begin, format);
    }

    // alignment: right
    public get right(): number {
        return this._hValue.pixel;
    }

    public set right(format: ValueFormat) {
        this._setHAlign(AlignType.end, format);
    }

    // alignment: hcenter
    public get hcenter(): number {
        return this._hValue.pixel;
    }

    public set hcenter(format: ValueFormat) {
        this._setHAlign(AlignType.center, format);
    }

    // alignment: top
    public get top(): number {
        return this._vValue.pixel;
    }

    public set top(format: ValueFormat) {
        this._setVAlign(AlignType.begin, format);
    }

    // alignment: bottom
    public get bottom(): number {
        return this._vValue.pixel;
    }

    public set bottom(format: ValueFormat) {
        this._setVAlign(AlignType.end, format);
    }

    // alignment: vcenter
    public get vcenter(): number {
        return this._vValue.pixel;
    }

    public set vcenter(format: ValueFormat) {
        this._setVAlign(AlignType.center, format);
    }

    // options: sync
    public get sync(): boolean {
        return this._sync;
    }

    public set sync(enabled: boolean) {
        // if changing, set sync size and update
        if (this._sync !== enabled) {
            this._sync = enabled;
            this._updateContainerSize();
        }
    }

    // init
    //-------------------------------------------------------------------------
    constructor(container: Container, layout: ILayout) {
        (container as any)._layout = this;
        this._container = container;
    }

    // api
    //-------------------------------------------------------------------------
    public update(layout: ILayout) {
        // merge state
        Object.assign(this, layout);

        // handle updates
        this._onSizeUpdated();
    }

    // private: properties
    //-------------------------------------------------------------------------
    // alignable width
    private get alignableWidth(): number {
        const { anchor, pivot } = this._container as Sprite;

        // 0 if has pivot or anchor
        if (!!pivot.x || !!anchor?.x) return 0;

        // return scaled width
        if (this._scaleWidth) return this._scaleWidth.pixel;

        // else width
        return this.width;
    }

    // height
    public get alignableHeight(): number {
        const { anchor, pivot } = this._container as Sprite;

        // 0 if has pivot or anchor
        if (!!pivot.y || !!anchor?.y) return 0;

        // return scaled width
        if (this._scaleHeight) return this._scaleHeight.pixel;

        // else width
        return this.height;
    }

    // private: updaters
    //-------------------------------------------------------------------------
    private _updateSize() {
        // sync size values. always assume updated if not specified
        if (this._width) this._updateValue(this._width);
        if (this._height) this._updateValue(this._height);

        // sync max size values
        if (this._maxWidth) this._updateValue(this._maxWidth);
        if (this._maxHeight) this._updateValue(this._maxHeight);

        // sync scale size values
        this._updateScaleSize();
    }

    private _updateScaleSize() {
        const width = this._scaleWidth;
        const height = this._scaleHeight;

        // if specified
        if (width && height) {
            const container = this._container;
            const bounds = container.getLocalBounds();

            // sync scale size values
            width.format && this._updateValue(this._scaleWidth);
            height.format && this._updateValue(this._scaleHeight);

            // update unspecified to match aspect ratio
            if (!width.format) width.pixel = (bounds.width * height.pixel) / bounds.height;
            if (!height.format) height.pixel = (bounds.height * width.pixel) / bounds.width;
        }
    }

    private _updatePosition() {
        const own = this._container;
        const parent = getLayout(own.parent);

        // layout positioning requires a layout node parent
        if (parent) {
            const position = new Vector2();

            // update horizontal
            switch (this._hType) {
                case AlignType.begin:
                    position.x = this._hValue.pixel;
                    break;
                case AlignType.end:
                    position.x = parent.width - this.alignableWidth - this._hValue.pixel;
                    break;
                case AlignType.center:
                    position.x = (parent.width - this.alignableWidth) / 2 + this._hValue.pixel;
                    break;
                default:
                    position.x = own.x;
            }

            // update vertical
            switch (this._vType) {
                case AlignType.begin:
                    position.y = this._vValue.pixel;
                    break;
                case AlignType.end:
                    position.y = parent.height - this.alignableHeight - this._vValue.pixel;
                    break;
                case AlignType.center:
                    position.y = (parent.height - this.alignableHeight) / 2 + this._vValue.pixel;
                    break;
                default:
                    position.y = own.y;
            }

            // update position
            own.position = position;
        }
    }

    private _updateAlignment() {
        // update align values
        this._updateValue(this._hValue);
        this._updateValue(this._vValue);
    }

    private _updateContainerSize() {
        const container = this._container;

        // if scaling, update scale
        if (this._scaleWidth || this._scaleHeight) {
            const bounds = container.getLocalBounds();
            container.scale.set(this._scaleWidth.pixel / bounds.width, this._scaleHeight.pixel / bounds.height);
        }
        // else sync sizing
        else if (this._sync) {
            container.width = this.width;
            container.height = this.height;
        }
    }

    private _updateValue(value: Value): boolean {
        const current = value.pixel;
        const format = value.format;

        // parse string format
        if (typeof format === 'string') {
            const parent = getLayout(this._container.parent);
            const n = parseFloat(format);
            const id = format.charAt(format.length - 1);

            // handle by suffix character
            if (id === 'w') value.pixel = n * this.width;
            else if (id === 'h') value.pixel = n * this.height;
            else if (parent) {
                if (id === 'W') value.pixel = n * parent.width;
                else if (id === 'H') value.pixel = n * parent.height;
                else value.pixel = 0;
            } else value.pixel = 0;
            // else use absolute value
        } else value.pixel = format;

        // true if changed
        return current !== value.pixel;
    }

    // private: handlers
    //-------------------------------------------------------------------------
    public $onParentUpdated() {
        // assume new parent has new size and transform
        this._onParentSizeUpdated();
    }

    /*
    public $onContainerSizeUpdated() {
        // update alignment
        this._updateAlignment();

        // update positioning
        this._updatePosition();
        // sync pivot
        //if (this._updatePivot()) this.onPivotUpdated();

        // notify children
        for (const child of this._container.children) {
            //getLayout(child)?._onParentSizeUpdated();
        }
    }
    */

    private _onSizeUpdated() {
        // update container size
        this._updateContainerSize();

        // update alignment
        this._updateAlignment();

        // update positioning
        this._updatePosition();

        // notify children
        for (const child of this._container.children) {
            getLayout(child)?._onParentSizeUpdated();
        }
    }

    private _onAlignmentUpdated() {
        // update positioning
        this._updatePosition();
    }

    private _onParentSizeUpdated() {
        // update size
        this._updateSize();

        // handle size updated
        this._onSizeUpdated();
    }

    // private: sets
    //-------------------------------------------------------------------------
    private _setValue(value: Value, format: ValueFormat): boolean {
        value.format = format;
        const vu = this._updateValue(value);
        return vu || value.format !== format;
    }

    private _setHAlign(type: AlignType, format: ValueFormat) {
        const tu = this._hType !== type;
        const vu = this._setValue(this._hValue, format);
        if (tu || vu) {
            this._hType = type;
            this._onAlignmentUpdated();
        }
    }

    private _setVAlign(type: AlignType, format: ValueFormat) {
        const tu = this._vType !== type;
        const vu = this._setValue(this._vValue, format);
        if (tu || vu) {
            this._vType = type;
            this._onAlignmentUpdated();
        }
    }
}
