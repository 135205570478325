import type { Component, Entity2D, Vector2 } from '@play-co/odie';

import { mapToViewPosition } from '../util/mapTools';

// types
//-----------------------------------------------------------------------------
export type PositionComponentOptions = {
    position: Vector2;
};

/*
    declares map position. this can include coordiantes outside the map.
*/
export class PositionComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'position';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // state
    private _mapPosition: Vector2;

    // properties
    //-------------------------------------------------------------------------
    public get mapPosition(): Vector2 {
        return this._mapPosition;
    }

    public set mapPosition(position: Vector2) {
        // set map position
        this._mapPosition = position;

        // get view position
        const viewPosition = mapToViewPosition(position);

        // commit view position
        this.entity.view.position.set(viewPosition.x, viewPosition.y);

        // set modified
        this.entity.setModified(PositionComponent, { mapPosition: true });
    }

    // impl
    //-------------------------------------------------------------------------
    init(options: PositionComponentOptions): void {
        this.mapPosition = options.position;
    }
}
