import { Container } from 'pixi.js';

import { SpineAnimation } from '../../lib/pixi/animation/SpineAnimation';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    spine: 'spine.customer_oldman.json',
};

const clipIds = ['idle'] as const;
export type OldManClipId = (typeof clipIds)[number];

/*
    Old man customer character
*/
export class OldMan extends Container {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _spine: SpineAnimation;

    // init
    //-------------------------------------------------------------------------
    static assets() {
        return [manifest.spine];
    }

    constructor() {
        super();

        // spawn
        this._spawn();
    }

    // api
    //-------------------------------------------------------------------------
    public async start(opts: { clipId: string; loop?: boolean }) {
        const { clipId, loop } = opts;
        await this._spine.start({ id: clipId, loop: !!loop, mix: 0.2 });
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn() {
        const spine = (this._spine = new SpineAnimation({ json: manifest.spine }));
        spine.scale.set(0.6);
        this.pivot.set(this.width / 2, this.height / 2);
        this.addChild(spine);
    }
}
