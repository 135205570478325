import { TweenFunction } from '../defs/types';

// types
//-----------------------------------------------------------------------------
type Action = {
    object: any;
    begin?: any;
    end: any;
    tween: TweenFunction;
};

/*
  tween animation clip
*/
export default class Clip {
    // fields
    //---------------------------------------------------------------------------
    private _elapsed = 0;
    private _duration: number;
    private _actions: Action[] = [];

    // init
    //---------------------------------------------------------------------------
    constructor(duration: number) {
        this._duration = duration;
    }

    // api
    //---------------------------------------------------------------------------
    public reset() {
        this._elapsed = 0;
    }

    public step(dt: number): boolean {
        // if starting collect begin values
        if (this._elapsed === 0) this._collectBeginValues();

        // increment elapsed time
        this._elapsed += dt;

        // is final step
        const final = this._elapsed >= this._duration;

        // step clip actions
        this._stepActions(final);

        // true if final step
        return final;
    }

    public addAction(object: any, end: any, tween: TweenFunction) {
        this._actions.push({
            object,
            end,
            tween,
        });
    }

    // private
    //---------------------------------------------------------------------------
    private _collectBeginValues() {
        // for each action, collect begin state
        for (const action of this._actions) {
            action.begin = {};
            for (const key in action.end) action.begin[key] = action.object[key];
        }
    }

    private _stepActions(final: boolean) {
        // get time ratio
        const timeRatio = Math.min(this._elapsed / this._duration, 1);

        // for each action
        for (const action of this._actions) {
            // for each end state, update tweened object value
            for (const key in action.end) {
                const begin = action.begin[key];
                const end = action.end[key];

                // update object value with tweened value between begin and end values for given time ratio
                // if final step, force value to end value
                action.object[key] = final ? end : begin + (end - begin) * action.tween(timeRatio);
            }
        }
    }
}
