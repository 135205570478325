import { Sprite, Texture } from 'pixi.js';

import { ChameleonBlockProps, ColorId } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-----------------------------------------------------------------------------
export const chameleonColors: { color: ColorId; asset: string }[] = [
    { color: 'blue', asset: 'block.chameleon.blue.png' },
    { color: 'red', asset: 'block.chameleon.red.png' },
    { color: 'yellow', asset: 'block.chameleon.yellow.png' },
    { color: 'green', asset: 'block.chameleon.green.png' },
];

/*
    chameleon block view
*/
export class ChameleonBlockView extends Sprite {
    // fields
    //-------------------------------------------------------------------------
    private _colorIndex = 0;

    // properties
    //-------------------------------------------------------------------------
    public get colorIndex(): number {
        return this._colorIndex;
    }

    public set colorIndex(value: number) {
        // update field
        this._colorIndex = value;

        // update view texture based on count
        this.texture = Texture.from(chameleonColors[value].asset);
    }

    // init
    //-------------------------------------------------------------------------
    constructor(props: ChameleonBlockProps) {
        // create base view
        super(Texture.from(chameleonColors[0].asset));

        // position
        blockPositionView(this, props);
    }

    static assets(): string[] {
        return chameleonColors.map((entry) => entry.asset);
    }
}
