import { gsap } from 'gsap';
import { Assets, Sprite, Texture } from 'pixi.js';

import app from '../../../../index.entry';
import { IAnimation } from '../../../../lib/pattern/IAnimation';
import { ParticleEmitter } from '../../../../lib/pixi/particles/ParticleEmitter';
import { flex } from '../../../../lib/ui/mods/flexMod';
import { config } from '../defs/config';

/*
    fireworks animation
*/
export class RocketAnimation extends Sprite implements IAnimation {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _exhaust: Sprite;
    // animations
    private _smokeEmitter: ParticleEmitter;
    private _exhaustAnimation: gsap.core.Timeline;

    // init
    //-------------------------------------------------------------------------
    static assets(): string[] {
        return ['fx.rocket.json'];
    }

    // impl
    //-------------------------------------------------------------------------
    public async start(): Promise<void> {
        // load assets
        await Assets.load(RocketAnimation.assets());

        // iint base
        super.texture = Texture.from('fx.rocket.png');
        flex(this, { pivot: { width: 0.5, height: 0.5 } });

        // create smoke emitter
        this._smokeEmitter = this._createSmokeEmitter();

        // create exhaust
        this._exhaust = this._createExhaust();

        // create exhaust animation
        this._createExhaustAnimation();

        // spawn
        this.addChild(this._smokeEmitter.view);
        this.addChild(this._exhaust);

        // start animations
        this._smokeEmitter.start();
        this._exhaustAnimation.restart();
    }

    public stop() {
        // stop animations
        this._exhaustAnimation.kill();
        this._smokeEmitter.stop();

        // despawn
        this.removeChild(this._exhaust);
        this.removeChild(this._smokeEmitter.view);
    }

    // private: factory
    //-------------------------------------------------------------------------
    private _createExhaust(): Sprite {
        const exhaust = flex(Sprite.from('fx.rocket.exhaust.png'), {
            centerY: -1,
            pivot: { width: 1 },
            right: 3,
        });
        exhaust.scale.x = 1.5;
        return exhaust;
    }

    private _createSmokeEmitter(): ParticleEmitter {
        // create
        const emitter = app.particles.create({
            textures: ['fx.rocket.smoke1.png', 'fx.rocket.smoke2.png', 'fx.rocket.smoke3.png', 'fx.rocket.smoke4.png'],
            rate: 0.05,
            limit: 64,
            duration: 2,
            velocity: { x: -config.sim.rocket.speed * 0.7, y: 0 },
            scale: [
                { x: 0.5, y: 0.5 },
                { x: 1, y: 1 },
            ],
            tint: 0xff53d2,
            behaviors: [
                { type: 'explode', magnitude: [16, 32] },
                { type: 'scale', to: 0 },
                //{ type: 'tint', from: 0xff53d2, to: 0xffffff },
                { type: 'kinematic' },
            ],
        });
        flex(emitter.view, { left: -40, top: 32 });
        return emitter;
    }

    private _createExhaustAnimation() {
        const timeline = (this._exhaustAnimation = gsap.timeline());
        timeline.to(this._exhaust.scale, { x: 0.8, duration: 0.02, ease: 'none' });
        timeline.pause().repeat(-1).yoyo(true);
    }
}
