import { gsap } from 'gsap';

import app from '../../../../../index.entry';
import { PositionType } from '../../../../../lib/defs/types';
import { arrayRemoveMany, arrayShuffle } from '../../../../../replicant/util/jsTools';
import { BlockEntity, swapBlockEntities } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { blockIsFrozen, blockIterateAll } from '../../util/blockTools';
import { mapIsPositionNextTo } from '../../util/mapTools';
import { IEffect } from './IEffect';

// types
//-----------------------------------------------------------------------------
type BasicEntry = {
    entity: BlockEntity;
    overlayed: boolean;
};

type SwapPair = [BlockEntity, BlockEntity];

/*
    basic color block shuffle effect
*/
export class ShuffleEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene) {
        this._scene = scene;
    }

    // api
    //-------------------------------------------------------------------------
    public async execute() {
        // build swap pairs
        const pairs = this._build();
        if (!pairs) return false;

        // play sound
        app.sound.play('puzzle-roulette.mp3');

        // do swap action
        await this._swap(pairs);

        return true;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _swap(pairs: SwapPair[]) {
        const animations: Promise<void>[] = [];

        // for each pair
        for (const pair of pairs) {
            const [a, b] = pair;
            const [aPos, bPos] = [a.view.position.clone(), b.view.position.clone()];

            // do logical swap
            swapBlockEntities(this._scene, a, b);

            // do visual/animated swap
            animations.push(this._animateMove(a, aPos, bPos));
            animations.push(this._animateMove(b, bPos, aPos));
        }

        // wait for all to complete
        await Promise.all(animations);
    }

    // private: builders
    //-------------------------------------------------------------------------
    private _build(): SwapPair[] | undefined {
        const pairs: SwapPair[] = [];

        // build basic list
        const basicList = this._buildBasicList();

        // shuffle basic list
        arrayShuffle(basicList);

        // build swap pair (required)
        if (!this._buildTappablePair(pairs, basicList)) return undefined;

        // build remaining pairs shuffled
        this._buildShuffledPairs(pairs, basicList);

        return pairs;
    }

    private _buildBasicList(): BasicEntry[] {
        const basicList: BasicEntry[] = [];

        // iterate blocks
        blockIterateAll(this._scene, (base, overlay) => {
            // if not frozen, add to basic block to list
            if (!blockIsFrozen(this._scene, base) && base.c.block.props.type === 'basic') {
                basicList.push({ entity: base, overlayed: !!overlay });
            }
            return false;
        });

        return basicList;
    }

    private _buildTappablePair(pairs: SwapPair[], basicList: BasicEntry[]): boolean {
        // for each basic block
        for (let i = 0; i < basicList.length; ++i) {
            const entry = basicList[i];

            // if not overlayed
            if (!entry.overlayed) {
                // find a neighbor block
                const neighborIndex = basicList.findIndex(
                    (other) =>
                        other.entity !== entry.entity &&
                        mapIsPositionNextTo(entry.entity.c.position.mapPosition, other.entity.c.position.mapPosition),
                );

                // if found neighbor block
                if (neighborIndex >= 0) {
                    // find a matching block
                    const matchingIndex = basicList.findIndex(
                        (other) =>
                            other.entity !== entry.entity &&
                            other.entity.c.block.blockId === entry.entity.c.block.blockId,
                    );

                    // if found matching block
                    if (matchingIndex >= 0) {
                        // if not already a neighboring pair, add pair
                        if (matchingIndex !== neighborIndex) {
                            pairs.push([basicList[matchingIndex].entity, basicList[neighborIndex].entity]);
                        }

                        // remove involved entries
                        arrayRemoveMany(basicList, [i, neighborIndex, matchingIndex]);

                        return true;
                    }
                }
            }
        }

        return false;
    }

    private _buildShuffledPairs(pairs: SwapPair[], basicList: BasicEntry[]) {
        // shuffle basic list
        arrayShuffle(basicList);

        // for each basic block
        for (let i = 0; i < basicList.length - 1; i += 2) {
            // create pair
            pairs.push([basicList[i].entity, basicList[i + 1].entity]);
        }
    }

    // private: animators
    //-------------------------------------------------------------------------
    private async _animateMove(blockEntity: BlockEntity, from: PositionType, to: PositionType): Promise<void> {
        const view = blockEntity.c.view2d.view;

        // set from position
        view.position.set(from.x, from.y);

        // animate to requested position
        await gsap.to(view, { x: to.x, y: to.y, duration: 0.8, ease: 'power2.inOut' });
    }
}
