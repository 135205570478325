import { Sprite, Texture } from 'pixi.js';

import { AndroidBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-----------------------------------------------------------------------------
const styles = [
    {
        asset: 'block.android.0.png',
        x: 76,
        y: 73,
    },
    {
        asset: 'block.android.1.png',
        x: 76,
        y: 69,
    },
    {
        asset: 'block.android.2.png',
        x: 66,
        y: 69,
    },
];

/*
    android block view
*/
export class AndroidBlockView extends Sprite {
    // fields
    //-------------------------------------------------------------------------
    private _count = 0;

    // properties
    //-------------------------------------------------------------------------
    public get count(): number {
        return this._count;
    }

    public set count(value: number) {
        const style = styles[value];

        // update field
        this._count = value;

        // update view texture based on count
        this.texture = Texture.from(style.asset);

        // position view based on count
        this.x = style.x;
        this.y = style.y;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(props: AndroidBlockProps) {
        // create base view
        super(Texture.from(styles[0].asset));
        blockPositionView(this, props);
    }

    static assets(): string[] {
        return styles.map((style) => style.asset);
    }
}
