import type { Component, Entity2D } from '@play-co/odie';

import { ColorId, ColorsBlockOptionDef, SirenBlockProps } from '../defs/block';
import { SirenBlockView } from '../views/blocks/SirenBlockView';

// types
//-----------------------------------------------------------------------------
export type SirenBlockComponentOptions = {
    props: SirenBlockProps;
    option?: number;
};

/*
    siren block component
*/
export class SirenBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockSiren';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: SirenBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: SirenBlockComponentOptions): void {
        // get option def
        const optionDef = options.props.options[options.option || 0] as ColorsBlockOptionDef;

        // create container
        this.view = new SirenBlockView(options.props);

        // enable initial siren colors
        for (const color of optionDef.colors) {
            this.view.enable(color);
        }
    }

    // api
    //-------------------------------------------------------------------------
    public isEmpty(): boolean {
        return this.view.isEmpty();
    }

    public poke(color: ColorId) {
        // disable specified color
        this.view.disable(color);
    }
}
