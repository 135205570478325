import type { Component, Entity2D } from '@play-co/odie';

import { AnimalBlockProps } from '../defs/block';
import { AnimalBlockView } from '../views/blocks/AnimalBlockView';

// types
//-----------------------------------------------------------------------------
export type AnimalBlockComponentOptions = {
    props: AnimalBlockProps;
};

/*
    animal block component
*/
export class AnimalBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockAnimal';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: AnimalBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: AnimalBlockComponentOptions): void {
        // create animal block view
        this.view = new AnimalBlockView(options.props);
    }
}
