import { Application, Plugin, PluginOptions } from '@play-co/astro';

import app from '../../index.entry';
import { InstantGame } from './InstantGame';

export type InstantGamePluginOptions = PluginOptions;

export class InstantGamePlugin extends Plugin<InstantGamePluginOptions> {
    constructor(app: Application, options?: InstantGamePluginOptions) {
        super(app, options);
    }

    public async prepare(): Promise<void> {
        await InstantGame.init();
        //TODO: this is lib referencing app. refactor
        app.server = InstantGame.replicant;
        await InstantGame.start();
    }
}
