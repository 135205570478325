import type { System } from '@play-co/odie';

import StateMachine from '../../../../lib/pattern/StateMachine';
import { PhaseComponent } from '../components/PhaseComponent';
import type { PhaseId } from '../defs/types';
import type { GameScene } from '../GameScene';
import { ActivePhase } from './phases/ActivePhase';
import { FallPhase } from './phases/FallPhase';
import { InputPhase } from './phases/InputPhase';
import { ShufflePhase } from './phases/ShufflePhase';

// types
//-----------------------------------------------------------------------------
export interface IPhase {
    step?: () => void;
}

/*
    manages game phases (singleton)
    phase flow
        fall (start)
            if blocks can fall, fall blocks, then: active
            else: shuffle
        active
            when activations = 0: fall
        shuffle
            if user not stuck: input
            else if user stuck and after resolved: input
            else if not resolved, attack all: action
        input
            when activations > 0: active
*/
export class PhaseSystem implements System {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'phase';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    // state
    private _shufflePhase: ShufflePhase;
    private _inputPhase: InputPhase;
    private _fallPhase: FallPhase;
    private _activePhase: ActivePhase;
    private _stateMachine: StateMachine<PhaseId>;

    // properties
    //-------------------------------------------------------------------------
    public get phase(): PhaseId {
        return this._stateMachine.state;
    }

    public set phase(phaseId: PhaseId) {
        this._stateMachine.transition(phaseId);
        this.component.phase = phaseId;
    }

    public get component(): PhaseComponent {
        return this.scene.sessionEntity.c.phase;
    }

    // impl
    //-------------------------------------------------------------------------
    public init() {
        // create phases
        this._shufflePhase = new ShufflePhase(this);
        this._inputPhase = new InputPhase(this);
        this._fallPhase = new FallPhase(this);
        this._activePhase = new ActivePhase(this);

        // create state machine
        this._stateMachine = new StateMachine<PhaseId>({
            none: {},
            active: this._activePhase,
            fall: this._fallPhase,
            input: this._inputPhase,
            shuffle: this._shufflePhase,
        });
    }

    public awake() {
        // reset state
        this._stateMachine.reset();

        // enter fall state
        this._stateMachine.transition('fall');
    }

    public update(): void {
        // step current phase
        (this._stateMachine.observer as IPhase).step?.();
    }
}
