import type { Component, Entity2D } from '@play-co/odie';

import type { BasicBlockProps } from '../defs/block';
import { SymbolId } from '../defs/symbol';
import { BasicBlockView } from '../views/blocks/BasicBlockView';

// types
//-----------------------------------------------------------------------------
export type BasicBlockComponentOptions = {
    props: BasicBlockProps;
};

/*
  basic block component
*/
export class BasicBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockBasic';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: BasicBlockView;

    // properties
    //-------------------------------------------------------------------------
    public get symbolId(): SymbolId {
        return this.view.symbolId;
    }

    public set symbolId(symbolId: SymbolId) {
        // update view
        this.view.symbolId = symbolId;
    }

    // impl
    //-------------------------------------------------------------------------
    public init(options: BasicBlockComponentOptions): void {
        // create basic block view
        this.view = new BasicBlockView(options.props);
    }
}
