import type { Component, Entity2D } from '@play-co/odie';

import type { BombBlockProps } from '../defs/block';
import { BombBlockView } from '../views/blocks/BombBlockView';

// types
//-----------------------------------------------------------------------------
export type BombBlockComponentOptions = {
    props: BombBlockProps;
};

/*
    bomb block component
*/
export class BombBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockBomb';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: BombBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: BombBlockComponentOptions): void {
        // create bomb block view
        this.view = new BombBlockView(options.props);
    }
}
