import { Assets } from 'pixi.js';

import { PositionType } from '../../../../../lib/defs/types';
import { DartAnimation } from '../../animations/DartAnimation';
import { despawnOverlayEntity, spawnOverlayEntity } from '../../entities/OverlayEntity';
import { GameScene } from '../../GameScene';
import { CollisionTracker } from '../../util/CollisionTracker';
import { IEffect } from './IEffect';

// types
//-----------------------------------------------------------------------------
export type DartEffectOptions = {
    position: PositionType;
};

/*
    dart booster effect
*/
export class DartEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _position: PositionType;
    // state
    private readonly _collisionTracker = new CollisionTracker();

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: DartEffectOptions) {
        this._scene = scene;
        this._position = options.position;
    }

    static assets(): string[] {
        return DartAnimation.assets();
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // load assets
        await Assets.load(DartEffect.assets());

        // run dart
        await this._runDart();
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _runDart() {
        // create animation
        const animation = new DartAnimation({ position: this._position });

        // register events
        animation.onImpact = () => this._onDartImpact();

        // spawn animation as overlay entity
        const entity = spawnOverlayEntity(this._scene, this._position, { width: 1, height: 1 }, () => animation);

        // run animation
        await animation.start();

        // despawn entity
        despawnOverlayEntity(this._scene, entity);
    }

    // private: events
    //-------------------------------------------------------------------------
    private _onDartImpact() {
        // attack any blocks at this position as a booster
        this._collisionTracker.collideAt(this._scene.sessionEntity.c.map, this._position, 'attack', {
            type: 'booster',
        });
    }
}
