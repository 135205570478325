import { Container, Sprite } from 'pixi.js';

import { DirectionId, ThrowerBlockProps } from '../../defs/block';
import { config } from '../../defs/config';
import { blockPositionView } from '../../util/blockTools';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    image: 'block.thrower.png',
    arm: 'block.thrower.arm.png',
    cage: 'block.cage.front.png',
};

/*
    thrower block view
*/
export class ThrowerBlockView extends Container {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _direction: DirectionId;
    // scene
    public _cop: Sprite;
    public _arm: Sprite;
    public _cage: Sprite;
    // state
    private _enabled = true;

    // properties
    //-------------------------------------------------------------------------
    public get enabled(): boolean {
        return this._enabled;
    }

    public set enabled(value: boolean) {
        // update field
        this._enabled = value;

        // if 0 hide cage
        this._cage.visible = value;
    }

    public get direction(): DirectionId {
        return this._direction;
    }

    public set direction(direction: DirectionId) {
        this._direction = direction;

        // update x scale based on direction
        this._cop.scale.x = direction === 'right' ? 1 : -1;
    }

    public get arm(): Sprite {
        return this._arm;
    }

    public get cage(): Sprite {
        return this._cage;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(props: ThrowerBlockProps) {
        // spawn
        super();

        // spawn this way to let direction scale work right
        const cop = (this._cop = this.addChild(Sprite.from(manifest.image)));
        cop.anchor.set(0.5);
        cop.position.set(config.tile.size / 2);

        // spawn cage
        const cage = (this._cage = cop.addChild(Sprite.from(manifest.cage)));
        cage.position.set(0, -10);
        cage.scale.set(0.55);

        // spawn arm
        const arm = (this._arm = cop.addChild(Sprite.from(manifest.arm)));
        arm.position.set(-30, 28);
        arm.anchor.set(0.12, 0.7);

        // position
        blockPositionView(this, props);
    }

    static assets(): string[] {
        return Object.values(manifest);
    }
}
