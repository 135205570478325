import { blockColorMap, WallBlockProps } from '../../defs/block';
import { CollideEvent, CollideId } from '../../defs/collide';
import { despawnBlockEntity, WallBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { BreakEffect } from '../effects/BreakEffect';
import { CollideHandler, ICollision } from './ICollision';

/*
    wall block collision resolver
*/
export class WallCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: WallBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        goal: this._resolveGoal,
        tap: this._resolveTap,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: WallBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveGoal(event: CollideEvent) {
        const c = this._entity.c;
        const component = c.blockWall;
        const position = c.position.mapPosition;

        // if count is 0
        if (component.count <= 1) {
            // get wall break effect color
            const color = blockColorMap[(c.block.props as WallBlockProps).color].base;

            // despawn entity
            despawnBlockEntity(this._scene, this._entity);

            // execute break effect
            await new BreakEffect(this._scene, {
                position,
                size: this._entity.c.block.props,
                color,
            }).execute();
        }
        // else decrement count
        else --component.count;

        return true;
    }

    private async _resolveTap(event: CollideEvent) {
        //TODO: this looks wierd, create new effect
        // invalid effect
        //await new InvalidEffect(this._entity).execute();

        return false;
    }
}
