import { lerp } from '@play-co/odie';

import { DynamicNumber, importDynamicNumber } from '../../../defs/types';
import { Particle } from '../Particle';
import { IBehavior } from './IBehavior';
import { TweenFunction, tweenLinear } from './particle_tweens';

// types
//-----------------------------------------------------------------------------
export type ScaleBehaviorOptions = {
    type: 'scale';
    from?: DynamicNumber;
    to: DynamicNumber;
    tween?: TweenFunction;
};

/*
    tween scale
*/
export class ScaleBehavior implements IBehavior {
    // fields
    //-------------------------------------------------------------------------
    private _particle: Particle;
    private _options: ScaleBehaviorOptions;
    private _from: number;
    private _to: number;
    private _tween: TweenFunction;

    // init
    //-------------------------------------------------------------------------
    constructor(particle: Particle, options: ScaleBehaviorOptions) {
        this._particle = particle;
        this._options = options;
    }

    // impl
    //-------------------------------------------------------------------------
    public start() {
        const options = this._options;
        this._from = importDynamicNumber(options.from !== undefined ? options.from : this._particle.scale.x);
        this._to = importDynamicNumber(options.to);
        this._tween = options.tween || tweenLinear;
    }

    public step(dt: number) {
        // get tween value
        const tween = this._tween(this._particle.getTweenTime());

        // lerp to scale
        const scale = lerp(this._from, this._to, tween);

        // commit scale
        this._particle.scale.set(scale);
    }
}
