import type { EntityType, Vector2 } from '@play-co/odie';
import { createEntity, DefineEntity, Entity2D } from '@play-co/odie';
import { Container } from 'pixi.js';

import { PositionComponent } from '../components/PositionComponent';
import { RocketComponent } from '../components/RocketComponent';
import { DirectionId } from '../defs/block';
import type { GameScene } from '../GameScene';

// types
//-----------------------------------------------------------------------------
const RocketEntityDef = DefineEntity(Entity2D, RocketComponent, PositionComponent);

export type RocketEntity = EntityType<typeof RocketEntityDef>;

// api
//-----------------------------------------------------------------------------
export function spawnRocketEntity(scene: GameScene, position: Vector2, direction: DirectionId): RocketEntity {
    const container = new Container();

    // create entity
    const entity = createEntity(RocketEntityDef, {
        view2d: { view: container, layer: 'front' },
        rocket: { direction },
        position: { position },
    });

    // build scene
    container.addChild(entity.c.rocket.view);
    scene.addToScene(entity);

    // start component
    entity.c.rocket.start();

    return entity;
}

export function despawnRocketEntity(scene: GameScene, entity: RocketEntity) {
    // stop component
    entity.c.rocket.stop();

    // remove from scene
    scene.removeFromScene(entity);
}
