import type { Component, Entity2D } from '@play-co/odie';

import { RocketBlockProps } from '../defs/block';
import { RocketBlockView } from '../views/blocks/RocketBlockView';

// types
//-----------------------------------------------------------------------------
export type RocketBlockComponentOptions = {
    props: RocketBlockProps;
};

/*
    rocket block component
*/
export class RocketBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockRocket';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: RocketBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: RocketBlockComponentOptions): void {
        // create rocket block view
        this.view = new RocketBlockView(options.props);
    }
}
