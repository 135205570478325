import { Layout } from '@pixi/layout';

import { SizeType } from '../../../lib/defs/types';
import { IScreen } from '../../../plugins/nav/IScreen';

/*
    base layout screen (experimental)
*/
export class LayoutScreen implements IScreen {
    // fields
    //-------------------------------------------------------------------------
    private _root: Layout;
    private _size: SizeType = { width: 1, height: 1 };

    // properties
    //-------------------------------------------------------------------------
    public get root(): Layout {
        return this._root;
    }

    public get size(): SizeType {
        return this._size;
    }

    // init
    //-------------------------------------------------------------------------
    constructor() {
        // create scene
        this._root = new Layout({
            id: 'root',
            styles: {
                width: '100%',
                height: '100%',
            },
        });
    }

    // api
    //-------------------------------------------------------------------------
    protected empty() {
        // FIXME workaround to remove all children
        this.root.removeChildren();
        for (const id of this.root.content.children.keys()) {
            this.root.removeChildByID(id);
        }
    }

    // impl
    //-------------------------------------------------------------------------
    public resized(size: SizeType): void {
        // update last size
        this._size = size;

        // update root size
        this.root.resize(size.width, size.height);
    }
}
