import { Container, Sprite, Texture, TilingSprite } from 'pixi.js';

import { SpiderBlockProps } from '../../defs/block';
import { config } from '../../defs/config';
import { blockPositionView } from '../../util/blockTools';

// constants
//-------------------------------------------------------------------------
const manifest = {
    spider: 'block.spider.png',
    web: 'block.spider.web.png',
};

export class SpiderBlockView extends Container {
    private readonly _web: TilingSprite;
    private readonly _spider: Sprite;

    constructor(props: SpiderBlockProps) {
        super();

        const { size } = config.tile;
        const texture = Texture.from(manifest.web);

        // web image is pow2 128 x 128, so scale down to tile size
        this._web = new TilingSprite(texture, size, size);
        this._web.tileScale.set(size / 128);
        this.addChild(this._web);

        this._spider = Sprite.from(manifest.spider);
        this.addChild(this._spider);

        blockPositionView(this._spider, props);

        this._spider.y += 6;
    }

    static assets(): string[] {
        return Object.values(manifest);
    }

    public setWebHeight(height: number) {
        const centerTileY = this.y + config.tile.size / 2;
        this._web.y = centerTileY - height;
        this._web.height = height;
    }

    public breakWeb() {
        this._web.visible = false;
    }
}
