import {
    ColorMatrixFilter,
    Container,
    DisplayObject,
    DisplayObjectEvents,
    IPointData,
    Point,
    Rectangle,
    Ticker,
} from 'pixi.js';

import { BoundsType, DirectionType, PositionType, positionZero } from '../defs/types';
import { Vector2 } from '../math/Vector2';

// state
//-----------------------------------------------------------------------------
let _scene: Container;

// vector
//-----------------------------------------------------------------------------
export function pixiPointToVector(point: PositionType | DirectionType): Vector2 {
    return new Vector2(point.x, point.y);
}

// get unit vector from radians angle
export function pixiVectorFromAngle(angle: number): Vector2 {
    return new Vector2(Math.cos(angle), Math.sin(angle));
}

// ui
//-----------------------------------------------------------------------------
export function pixiEmitTree(from: Container, event: string, ...args: any[]) {
    from.emit(event as keyof DisplayObjectEvents, ...(args as any));
    for (const child of from.children) {
        if (child instanceof Container) pixiEmitTree(child, event, ...args);
    }
}

export function pixiGetRelativePosition(from: DisplayObject, to: Container): PositionType {
    return to.toLocal(positionZero as Point, from);
}

export function pixiGetRelativeBounds(from: DisplayObject, to: Container): Rectangle {
    const bounds = from.getLocalBounds();

    // get relative  position
    const position = pixiGetRelativePosition(from, to);

    return new Rectangle(position.x, position.y, bounds.width, bounds.height);
}

export function pixiGetGlobalCenter(object: DisplayObject): Vector2 {
    const bounds = object.getBounds();
    return new Vector2(bounds.x + bounds.width / 2, bounds.y + bounds.height / 2);
}

export function pixiToLocalBounds(object: DisplayObject, bounds: BoundsType): Rectangle {
    const position = object.toLocal(bounds as IPointData);
    return new Rectangle(position.x, position.y, bounds.width, bounds.height);
}

// like setting scale, but preserves current sign
export function pixiSignScale(object: DisplayObject, x: number, y?: number) {
    const xSign = Math.sign(object.scale.x);
    const ySign = Math.sign(object.scale.y);
    y = y || x;

    object.scale.set(xSign * Math.abs(x), ySign * Math.abs(y));
}

// toggles grayscale and input on an object
export function pixiSetEnabled(object: DisplayObject, enabled: boolean) {
    // if enabled, remove filters
    if (enabled) {
        object.filters = [];
        // else add grayscale filter
    } else {
        const grayscale = new ColorMatrixFilter();
        grayscale.grayscale(0.4, false);
        object.filters = [grayscale];
    }

    // toggle input
    object.interactiveChildren = enabled;
}

// info
//-----------------------------------------------------------------------------
export function pixiGetDt(): number {
    return Ticker.system.deltaMS / 1000;
}

// state
//-----------------------------------------------------------------------------
// registers app's root scene with lib framework
export function pixiSetScene(scene: Container) {
    _scene = scene;
}
export function pixiGetScene(): Container {
    return _scene;
}

// support
//-----------------------------------------------------------------------------
// setInterval wrapper that
//  - expires when given pixi object despawns
//  - seconds not ms
//  - calls handler at start
export function pixiSetInterval(
    object: DisplayObject,
    handler: (timer: ReturnType<typeof setInterval>) => void,
    seconds: number,
): ReturnType<typeof setInterval> {
    // start interval
    const timer = setInterval(
        () => {
            if (object.inScene()) handler(timer);
            else clearInterval(timer);
        },
        Math.ceil(seconds * 1000),
    );

    // initial step
    handler(timer);

    return timer;
}

// internal
//-----------------------------------------------------------------------------
export function pixiSolveQuadVertices(object: DisplayObject, width: number, height: number): Float32Array {
    const vertices = new Float32Array(8);

    const wt = object.transform.worldTransform;
    const a = wt.a;
    const b = wt.b;
    const c = wt.c;
    const d = wt.d;
    const tx = wt.tx;
    const ty = wt.ty;

    vertices[0] = tx;
    vertices[1] = ty;
    vertices[2] = a * width + tx;
    vertices[3] = b * width + ty;
    vertices[4] = a * width + c * height + tx;
    vertices[5] = d * height + b * width + ty;
    vertices[6] = c * height + tx;
    vertices[7] = d * height + ty;

    return vertices;
}
