import { NineSlicePlane, Sprite, Texture } from 'pixi.js';

import app from '../../../../index.entry';
import { BasicAsyncHandler } from '../../../../lib/defs/types';
import { pixiSetInterval } from '../../../../lib/pixi/pixiTools';
import { uiAlignCenter } from '../../../../lib/pixi/uiTools';
import { TimedStepType } from '../../../../replicant/defs/bakery';
import { sleep } from '../../../../replicant/util/jsTools';
import { timeFormatCountdown, timeToComponents } from '../../../../replicant/util/timeTools';
import { BasicText } from '../text/BasicText';
import { BasicPopup } from './BasicPopup';

const HEIGHT = 600;

// types
//-----------------------------------------------------------------------------
export type WaitStepPopupOptions = {
    onOk: BasicAsyncHandler;
    finishTime: number;
    type: TimedStepType;
    text: string;
};

const manifest = {
    ovenWaitIcon: 'icon.wait.oven.png',
    fridgeWaitIcon: 'icon.wait.fridge.png',
    saucepotWaitIcon: 'icon.wait.saucepot.png',
    // ovenWaitIcon: 'icon.bake.oven.png',
    timerFrame: 'frame.inset.light.png',
    timer: 'icon.timer.png',
};

const waitMap: Record<TimedStepType, { icon: string }> = {
    oven: {
        icon: manifest.ovenWaitIcon,
    },
    fridge: {
        icon: manifest.fridgeWaitIcon,
    },
    saucepot: {
        icon: manifest.saucepotWaitIcon,
    },
};

export class WaitStepPopup extends BasicPopup {
    private _finishTime: number;
    private _playTimer: boolean;

    public override preload() {
        return [...super.preload(), ...app.resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: WaitStepPopupOptions) {
        super.spawning({
            width: 690,
            height: HEIGHT,
            underlay: 0.7,
            ...options,
        });

        const { type, text } = options;
        this._playTimer = false;

        this._finishTime = options.finishTime;

        const waitText = new BasicText({
            text,
            style: {
                fill: 0x00,
                fontSize: 34,
                fontWeight: 'bold',
                lineJoin: 'round',
                stroke: 0xdf886e,
                strokeThickness: 4,
                align: 'center',
            },
        });
        waitText.pivot.set(0, waitText.height / 2);

        const icon = Sprite.from(waitMap[type].icon);
        const timer = this._spawnTimer();

        // spawn
        this.main.addContent({
            main: {
                content: icon,
                styles: {
                    position: 'topCenter',
                    marginTop: 180,
                },
            },
            description: {
                content: waitText,
                styles: {
                    position: 'topCenter',
                    marginTop: 120,
                },
            },
            timeLeft: {
                content: timer,
                styles: {
                    position: 'topCenter',
                    marginTop: 370,
                },
            },
        });
    }

    public override async spawned(): Promise<void> {
        await super.spawned();
    }

    public override async despawning() {
        this._stopTimerSfx();
        await super.despawning();
    }

    public override async despawned() {
        super.despawned();
    }

    private _spawnTimer() {
        const timerFrame = new NineSlicePlane(Texture.from(manifest.timerFrame), 30, 30, 30, 30);
        timerFrame.width = 258;
        timerFrame.height = 70;
        const timerIcon = Sprite.from(manifest.timer);

        timerFrame.addChild(timerIcon);
        uiAlignCenter(timerFrame, timerIcon);
        timerIcon.x -= 92;

        const time = new BasicText({
            text: '',
            style: {
                fill: 0x00,
                fontSize: 28,
                fontWeight: 'bold',
                lineJoin: 'round',
                stroke: 0xdf886e,
                strokeThickness: 4,
                align: 'center',
            },
        });

        timerFrame.addChild(time);

        if (this._finishTime - app.server.now() > 0) {
            this._playTimerSfx();
        }

        const update = () => {
            const timeLeft = this._finishTime - app.server.now();
            if (timeLeft > 0) {
                time.text = timeFormatCountdown(timeToComponents(this._finishTime - app.server.now()));
            } else {
                this._stopTimerSfx();
                time.text = '[buttonDone]';
            }

            uiAlignCenter(timerFrame, time, 10);
        };

        // start timer
        update();
        pixiSetInterval(this.base, () => update(), 0.9);

        return timerFrame;
    }

    private async _playTimerSfx() {
        this._playTimer = true;
        while (this._playTimer) {
            await app.sound.play('timer.ogg', { volume: 1.6, dupes: 0 });
            await sleep(0.4);
        }
    }

    private _stopTimerSfx() {
        this._playTimer = false;
    }
}
