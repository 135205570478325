import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';

export class StartFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();

    // init
    //-------------------------------------------------------------------------

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._checkAdminMessages();

        this._actionComplete(true);
        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    // TODO implement
    private async _checkAdminMessages() {
        // for (const item of app.server.state.adminMessages) {
        //     const claimPromise = new NakedPromise();
        //     app.nav.open('adminClaimPopup', {
        //         item,
        //         onClaim: () => claimPromise.resolve(),
        //     });
        //     await claimPromise;
        //     const event = await app.server.invoke.grantAdminItem();
        //     if (event) {
        //         const { eventType, eventProperties } = event;
        //         analytics.pushEvent(eventType, eventProperties);
        //     }
        //     app.nav.close('adminClaimPopup');
        // }
    }
}
