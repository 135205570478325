import type { Component, Entity2D } from '@play-co/odie';

import { CoffinBlockProps } from '../defs/block';
import { CoffinBlockView } from '../views/blocks/CoffinBlockView';

export type BasicBlockComponentOptions = {
    props: CoffinBlockProps;
};

export class CoffinBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockCoffin';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: CoffinBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: BasicBlockComponentOptions): void {
        this.view = new CoffinBlockView(options.props);
    }
}
