import { waitAFrame } from '@play-co/astro';
import { gsap } from 'gsap';
import { Container, Sprite } from 'pixi.js';

import { getBoundsCenter, PositionType } from '../../../lib/defs/types';
import TaskQueue from '../../../lib/pattern/TaskQueue';
import { pixiGetRelativeBounds } from '../../../lib/pixi/pixiTools';
import { uiScaleToFit } from '../../../lib/pixi/uiTools';
import { flex } from '../../../lib/ui/mods/flexMod';
import { BasicText } from '../../lib/ui/text/BasicText';
import { config } from '../match2-odie/defs/config';
import { GoalId, goalMap } from '../match2-odie/defs/goal';
import { BlockEntity } from '../match2-odie/entities/BlockEntity';

// types
//-----------------------------------------------------------------------------
// public
export type PuzzleGoalIconOptions = {
    id: GoalId;
    count: number;
};
const manifest = {
    check: 'check.green.small.png',
};

/*
    puzzle goal icon
*/
export class PuzzleGoalIcon extends Container {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _goalId: GoalId;
    // scene
    private _icon: Sprite;
    private _tick: Sprite;
    private _countText: BasicText;
    private _updateQueue = new TaskQueue();

    // init
    //-------------------------------------------------------------------------
    constructor(options: PuzzleGoalIconOptions) {
        super();
        flex(this, { width: config.tile.size, height: config.tile.size });

        // set fields
        this._goalId = options.id;

        // spawn icon
        this._spawnIcon();

        // spawn count
        this._spawnCount(options.count);
    }

    static assets(options: Partial<PuzzleGoalIconOptions>): string[] {
        return [goalMap[options.id], ...Object.values(manifest)];
    }

    // api
    //-------------------------------------------------------------------------
    public async queueUpdate(value: () => number, sourceBlock?: BlockEntity) {
        // determine from position. do this early or sourceBlock may get despawned.
        const from = sourceBlock ? getBoundsCenter(pixiGetRelativeBounds(sourceBlock.view, this)) : undefined;

        // queue update
        this._updateQueue.add(async () => {
            // add 1 frame wait
            await waitAFrame();

            // if source animate
            if (sourceBlock) {
                // animate async
                this._animateSource(from).then(async () => {
                    // animate bounce view icon
                    await this._animateBounce();

                    this._updateCountText(value());
                });
                // else direct update
            } else {
                this._updateCountText(value());
            }
        });
    }

    private _updateCountText(value: number) {
        const isComplete = value === 0;
        const stringValue = value.toString();

        this._countText.text = stringValue;
        this._countText.visible = !isComplete;

        this._tick.visible = isComplete;
    }

    // private: scene
    //-------------------------------------------------------------------------
    public _spawnIcon() {
        const icon = (this._icon = this._createIcon());
        flex(icon, { width: 1, height: 1, centerY: 0, centerX: 0 });
        icon.anchor.set(0.5);
        icon.scale.set(1.3);
        uiScaleToFit(icon, 100, 100);
        this.addChild(icon);
    }

    public _spawnCount(count: number) {
        const text = (this._countText = flex(
            new BasicText({
                text: count.toString(),
                style: {
                    fill: 'white',
                    fontSize: 50,
                    lineJoin: 'round',
                    stroke: '#5A2D26',
                    strokeThickness: 8,
                    fontWeight: 'bold',
                    padding: 6,
                    dropShadow: true,
                    dropShadowBlur: 3,
                    dropShadowAlpha: 0.4,
                    dropShadowDistance: 4,
                    //fontSize: 44,
                    //strokeThickness: 6,
                    //lineJoin: 'round',
                },
            }),
            { width: 1, bottom: -46, right: -22 },
        ));
        text.view.anchor.x = 1;
        this.addChild(text);

        this._tick = Sprite.from(manifest.check);
        this._tick.scale.set(1.9);
        this._tick.x = config.tile.size - 10;
        this._tick.y = config.tile.size + 2;
        this._tick.anchor.set(0.5);
        this._tick.visible = false;
        this.addChild(this._tick);
    }

    // private: animation
    //-------------------------------------------------------------------------
    private async _animateSource(from: PositionType) {
        const to = this._icon;

        // spawn another icon
        const icon = this._createIcon();
        icon.scale.set(0.2);
        icon.anchor.set(0.5);
        icon.position.set(from.x, from.y);
        this.addChild(icon);

        // animate icon move to our location
        await gsap
            .timeline()
            .to(icon.scale, { x: 1, y: 1, duration: 0.3, ease: 'power1.out' })
            .to(icon, { x: to.x, y: to.y, duration: 0.8, ease: 'power2.inOut' });

        // despawn icon
        this.removeChild(icon);
    }

    private async _animateBounce() {
        // animate bounce on view icon
        await gsap
            .timeline()
            .to(this._icon.scale, { x: 1.4, y: 1.4, duration: 0.1, ease: 'power1.out' })
            .repeat(1)
            .yoyo(true);

        // above may overlap so ensure ends at 1
        this._icon.scale.set(1);
    }

    // private: factory
    //-------------------------------------------------------------------------
    public _createIcon(): Sprite {
        const image = goalMap[this._goalId];
        const icon = Sprite.from(image);
        return icon;
    }
}
