import { analytics } from '@play-co/gcinstant';

import app from '../../../index.entry';

export function trackPuzzleStart() {
    const puzzleLevel = app.server.state.puzzle.level;
    analytics.pushEvent('PuzzleStart', { puzzleLevel });
}

export function trackPuzzleFinish(opts: {
    puzzleLevel: number;
    puzzleForMaxLevel: boolean;
    result: 'success' | 'fail' | 'quit';
    puzzleDuration: number;
    remainingMoves: number;
    limitMoves: number;
}) {
    analytics.pushEvent('PuzzleFinish', opts);
}
