import { waitFor } from '@play-co/astro';

import app from '../../../../index.entry';
import NakedPromise from '../../../../lib/pattern/NakedPromise';
import TaskQueue from '../../../../lib/pattern/TaskQueue';
import UpdateObserver from '../../../../lib/pattern/UpdateObserver';
import { IScreenController } from '../../../../plugins/nav/IScreenController';
import { BoosterId, boosterIds } from '../../../../replicant/defs/booster';
import gameConfig from '../../../../replicant/defs/gameConfig';
import { sleep } from '../../../../replicant/util/jsTools';
//import { PuzzleTipHelper } from './PuzzleTipHelper';
import { PuzzleCompleteFlow } from '../../../flows/PuzzleCompleteFlow';
import { PuzzleTutorialFlow } from '../../../flows/PuzzleTutorialFlow';
import { EventId, GameEvent, GoalEvent, MoveEvent, RewardEvent, RoundEvent } from '../../match2-odie/defs/event';
import { GoalId } from '../../match2-odie/defs/goal';
import { GoalsDef, MapDef } from '../../match2-odie/defs/map';
import { MysteryRewardId } from '../../match2-odie/defs/reward';
import { BlockEntity } from '../../match2-odie/entities/BlockEntity';
import { mapGetAssets } from '../../match2-odie/util/mapTools';
import { PuzzleGoalsPanel } from '../PuzzleGoalsPanel';
import { PuzzleScreen } from '../PuzzleScreen';
import { PuzzleReactionController } from './PuzzleReactionController';
import { PuzzleTipHelper } from './PuzzleTipHelper';

// types
//-----------------------------------------------------------------------------
export type PuzzleCompleteHandler = (results: PuzzleResults) => void;

export type PuzzleControllerOptions = {
    editorMode?: boolean;
    tutorialMode?: boolean;
    onComplete?: PuzzleCompleteHandler;
};

export type RewardState = { [key in MysteryRewardId]?: boolean };

// DO NOT change result types from 'success', 'fail' and 'quit' since these are used for analytics as well
// Additional result types can be added if needed
export type PuzzleResults =
    | {
          result: 'success';
          rewards: RewardState;
      }
    | { result: 'fail' }
    | { result: 'quit' };

type BoosterCounts = { [key in BoosterId]?: number };

// constants
//-----------------------------------------------------------------------------
const musicMap = ['the-weekend-1.ogg', 'jazz-cafe-2.ogg', 'street-food-3.ogg'];

/*
    puzzle screen controller. this is a high level control of the game that ties
    in the surrounding UI, and external command flows.
*/
export class PuzzleController implements IScreenController {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _screen: PuzzleScreen;
    private _editorMode: boolean;
    private _tutorialMode: boolean;
    private _onComplete?: PuzzleCompleteHandler;
    // state
    private _complete: boolean;
    //private _goalCounts: { [key in GoalId]?: number };
    private _rewardState: RewardState;
    private _mapDef: MapDef;
    private _mapLevel: number;
    private _taskQueue: TaskQueue;
    private _time: number;
    private _idlePromise: NakedPromise;
    private _exiting: boolean;
    // components
    private _updates = new UpdateObserver();
    private _tips: PuzzleTipHelper;
    private _reactions: PuzzleReactionController;
    // stats
    private _attackCount: number;
    private _giftCount: number;
    private _continueCount: number;
    private _usedBoosterCounts: BoosterCounts;
    // handlers
    private _gameEventHandler = (event: GameEvent) => this._gameEventHandlers[event.id]?.call(this, event);
    // maps
    private readonly _gameEventHandlers: { [key in EventId]?: (event: GameEvent) => void } = {
        goal: this._onGameGoalEvent,
        move: this._onGameMoveEvent,
        reward: this._onGameRewardEvent,
        round: this._onGameRoundEvent,
        ungoal: this._onGameUngoalEvent,
    };

    // properties
    //-------------------------------------------------------------------------
    public get active(): boolean {
        return !!this.screen.scene?.active;
    }

    public get editorMode(): boolean {
        return this._editorMode;
    }

    public get tutorialMode(): boolean {
        return this._tutorialMode;
    }

    public get moves(): number {
        return this._screen.scene.sessionEntity.c.phase.moves;
    }

    private set moves(moves: number) {
        this._screen.scene.sessionEntity.c.phase.moves = moves;
    }

    public get moveCounter(): number {
        return this._screen.scene.sessionEntity.c.phase.moveCounter;
    }

    public get usedBoosters(): BoosterCounts {
        return this._usedBoosterCounts;
    }

    public get map(): MapDef {
        return this._mapDef;
    }

    public get mapLevel(): number {
        return this._mapLevel;
    }

    public get screen(): PuzzleScreen {
        return this._screen;
    }

    public get time(): number {
        return this._time;
    }

    public get attackCount(): number {
        return this._attackCount;
    }

    public get giftCount(): number {
        return this._giftCount;
    }

    public get continueCount(): number {
        return this._continueCount;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(screen: PuzzleScreen) {
        this._screen = screen;
        this._tips = new PuzzleTipHelper(screen);
        this._reactions = new PuzzleReactionController(screen);
    }

    // impl
    //-------------------------------------------------------------------------
    public async assets(options?: PuzzleControllerOptions): Promise<string[]> {
        const { map } = await this._selectMap(options);
        return map ? [...PuzzleGoalsPanel.goalAssets(map.goals), ...mapGetAssets(map, false)] : [];
    }

    public async restart() {
        // set exiting state
        this._exiting = true;

        // queue quit
        await this._taskQueue.add(async () => {
            // wait for idle
            await this._idlePromise;

            // reopen
            await app.nav.close('puzzle');
            //TODO: shouldnt need this. but some actions (like dog effect) happening in puzzle still, may need new 'exiting' phase.
            await waitFor(2000);
            await app.nav.open('puzzle', {
                editorMode: true,
            });
        });
    }

    public async prepare(options?: PuzzleControllerOptions) {
        const screen = this._screen;
        const scene = screen.scene;

        // set fields
        this._editorMode = !!options?.editorMode;
        this._tutorialMode = !!options?.tutorialMode;
        this._onComplete = options?.onComplete;

        // select map
        const { map, level } = await this._selectMap(options);

        // start preloading effects
        app.resource.loadAssets(mapGetAssets(map, true));

        // init views
        screen.header.init();

        // init state
        this._mapDef = map;
        this._mapLevel = level;
        this._complete = false;
        this._rewardState = {};
        this._initGoalState(map.goals, level);
        this._taskQueue = new TaskQueue();
        this._time = app.server.now();
        this._attackCount = 0;
        this._giftCount = 0;
        this._continueCount = 0;
        this._usedBoosterCounts = this._getEmptyBoosterCounts();
        this._idlePromise = new NakedPromise();
        this._exiting = false;

        // start scene
        await scene.startGame(map);

        // post scene init state
        this._initMovesState(map.moves);

        // register updates
        // this._updates.listen(
        //     () => app.game.player.boosters,
        //     () => this._updateBoosters(),
        // );

        // register events
        scene.events.subscribe(this._gameEventHandler);

        // play puzzle music
        app.music.play(musicMap[(level - 1) % musicMap.length]);

        // start components
        this._updates.start();
        this._reactions.start();

        // notify
        //app.core.messages.publish({ id: 'puzzleStarted' });

        // initial tips
        sleep(0).then(async () => {
            // puzzle tutorial
            if (level === 1 && !app.server.state.tutorial.puzzle) {
                new PuzzleTutorialFlow(this).execute();
            } else {
                this._tips.tipBlock();
            }
        });
    }

    public hidden() {
        const scene = this._screen.scene;

        // stop components
        this._reactions.stop();
        this._updates.stop();

        // pop stop puzzle music
        app.music.stop();

        // unregister events
        scene.events.unsubscribe(this._gameEventHandler);

        // stop game
        scene.stopGame();
    }

    // api
    //-------------------------------------------------------------------------
    public grantContinue() {
        // reset complete state
        this._complete = false;

        // add moves
        this.moves += gameConfig.puzzle.continue.moves;

        // update stats
        ++this._continueCount;

        // update ui
        this._updateMoves();
    }

    public forceComplete() {
        // complete goals
        this._screen.scene.sessionEntity.c.map.completeGoals();

        // update
        this._idlePromise.resolve();
        this._updateComplete();
    }

    public forceFail() {
        // complete goals
        this.moves = 0;

        // update
        this._idlePromise.resolve();
        this._updateComplete();
    }

    // private: init
    //-------------------------------------------------------------------------
    private async _initGoalState(goalDef: GoalsDef, level: number) {
        // init ui
        const goals = this._screen.header.goals;
        goals.setGoals(goalDef);
        goals.setLevel(level);
    }

    private _initMovesState(moves: number) {
        // init fields
        this.moves = moves;

        // init ui
        this._screen.header.moves.moves = moves;
    }

    private async _selectMap(options?: PuzzleControllerOptions): Promise<{ map: MapDef; level: number }> {
        const mapService = app.puzzleMap;

        // if editor mode, return editor map
        if (options?.editorMode) {
            return {
                map: mapService.editorMap,
                level: 0,
            };
            // else return current player map
        }

        const level = app.server.state.puzzle.level;
        return {
            map: await mapService.getMap(level),
            level,
        };
    }

    private _getEmptyBoosterCounts(): BoosterCounts {
        return boosterIds.reduce((acc: BoosterCounts, id: BoosterId) => {
            acc[id] = 0;
            return acc;
        }, {});
    }

    // private: events
    //-------------------------------------------------------------------------
    private _onGameGoalEvent(event: GoalEvent) {
        // update goal
        this._updateGoal(event.goalId, event.source);
    }

    private _onGameMoveEvent(event: MoveEvent) {
        // if not auto event, reset idle promise
        if (event.source !== 'auto') {
            this._idlePromise = new NakedPromise();
        }

        // if have enough moves and tap or auto source
        if (this.moves >= 0 && (event.source === 'tap' || event.source === 'auto')) {
            // update ui
            this._updateMoves();
        }
    }

    private _onGameRewardEvent(event: RewardEvent) {
        /*
        // handle reward
        switch (event.rewardId) {
            case 'attack':
                this._actionAttack(event.block);
                break;
            case 'gift':
                this._actionGift(event.block);
                break;
            default:
                this._setReward(event.rewardId, event.block);
        }
        */
    }

    private _onGameRoundEvent(event: RoundEvent) {
        // update complete state
        this._updateComplete();

        // resolve idle promise
        this._idlePromise.resolve();

        // show block tips if nothing else happening
        if (this._taskQueue.pending === 0) this._tips.tipBlock();
    }

    private _onGameUngoalEvent(event: GoalEvent) {
        // update goal
        this._updateGoal(event.goalId, event.source);
    }

    // private: updates
    //-------------------------------------------------------------------------
    private _updateComplete() {
        // get remaining goals
        const remainingGoals = Object.values(this._screen.scene.sessionEntity.c.map.goals).reduce(
            (total, remaining) => total + remaining,
            0,
        );

        // if not already complete and if remaining goals is 0 or moves is 0
        if (!this._complete && (remainingGoals === 0 || this.moves === 0)) {
            // set complete
            this._complete = true;

            // enter none state
            this._screen.scene.phaseSystem.phase = 'none';

            // run complete action
            this._actionComplete(
                remainingGoals === 0
                    ? {
                          result: 'success',
                          rewards: this._rewardState,
                      }
                    : { result: 'fail' },
            );
        }
    }

    private _updateGoal(goalId: GoalId, source?: BlockEntity) {
        // get icon view for goal
        const icon = this._screen.header.goals.goalIcons[goalId];

        // queue update view with current count
        icon.queueUpdate(() => this._screen.scene.sessionEntity.c.map.goals[goalId], source);
    }

    private _updateMoves() {
        // update ui
        this._screen.header.moves.moves = this.moves;
    }

    private _updateBoosters() {}

    // private: actions
    //-------------------------------------------------------------------------
    private _actionComplete(results: PuzzleResults) {
        // notify complete
        this._onComplete?.(results);
        // app.core.messages.publish({ id: 'puzzleComplete', results });

        // if not tutorial or editor mode
        if (!this._editorMode && !this._tutorialMode) {
            // queue complete action
            this._taskQueue.add(async () => {
                // wait for idle
                await this._idlePromise;

                // fade music to low volume. so it doesnt interfere with complete sounds.
                app.music.fade(0.6, 0.05);

                // if not exiting, execute complete command
                if (!this._exiting) await new PuzzleCompleteFlow(results, this).execute();
            });
        }
    }
}
