import { SB } from '@play-co/replicant';

// state
//-----------------------------------------------------------------------------
export const tutorialState = {
    // ftue tips
    tips: SB.map(SB.boolean()).default({}),

    tutorial: SB.object({
        step: SB.int().default(0),
        complete: SB.boolean().default(false),
        puzzle: SB.boolean().default(false),
    }),
};
