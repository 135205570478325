import { Sprite, Texture } from 'pixi.js';

import { CubeEffect } from '../../actions/effects/CubeEffect';
import { ColorId, CubeBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-------------------------------------------------------------------------
const colorMap: { [key in ColorId]?: string } = {
    blue: 'block.cube.blue.png',
    green: 'block.cube.green.png',
    purple: 'block.cube.purple.png',
    red: 'block.cube.red.png',
    yellow: 'block.cube.yellow.png',
};

/*
    cube block view
*/
export class CubeBlockView extends Sprite {
    // init
    //-------------------------------------------------------------------------
    constructor(props: CubeBlockProps) {
        // create base view
        super(Texture.from(colorMap[props.color]));
        blockPositionView(this, props);
    }

    static assets(props: Partial<CubeBlockProps>, effects: boolean): string[] {
        return [colorMap[props.color], ...(effects ? CubeEffect.assets({ color: props.color }) : [])];
    }
}
