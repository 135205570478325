import { action } from '@play-co/replicant';

import { MutableState, State, SyncActionAPI } from '../../defs/replicant';
import { undefinedPerson } from '../../objects/Person';
import { Player } from '../../objects/Player';
// actions
//-----------------------------------------------------------------------------
export const playerActions = {
    // set name
    playerSetName: action((state: MutableState, options: { name: string }, api: SyncActionAPI) => {
        // update name
        state.name = options.name;
    }),
};

// api
//-----------------------------------------------------------------------------
export function playerFromState(state: State, id: string): Player {
    // determine person
    //const person = npcAccessByState(state, id)?.person || undefinedPerson;
    const person = undefinedPerson;

    // generate player from state
    return {
        ...person,
        id,
        name: state.name,
        avatar: undefined, //TODO
    };
}
