import type { Component, Entity2D } from '@play-co/odie';

import type { MysteryRewardId } from '../defs/reward';

/*
    tracks game state
*/
export class StateComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'state';

    // fields
    //-------------------------------------------------------------------------
    // public
    public cageThrowsRemaining: number;
    // injected
    public entity!: Entity2D;
    // state
    private _rewards: { [key in MysteryRewardId]?: number };

    // impl
    //-------------------------------------------------------------------------
    init(): void {
        // reset state
        this._rewards = {};
        this.cageThrowsRemaining = 0;
    }

    // api
    //-------------------------------------------------------------------------
    // gifts
    public getReward(id: MysteryRewardId): number {
        return this._rewards[id] || 0;
    }
}
