import { TextureAtlas } from '@pixi-spine/all-4.1';
import {
    Assets,
    BaseTexture,
    ExtensionType,
    LoadAsset,
    Loader,
    LoaderParser,
    resolveTextureUrl,
    ResolveURLParser,
    settings,
    utils,
} from 'pixi.js';

export const loadAtlas = {
    extension: ExtensionType.LoadParser,
    name: 'loadAtlas',
    test(url: string): boolean {
        return url.includes('.atlas') && url.indexOf('~') < 0;
    },
    async load(url: string, loadAsset?: LoadAsset, loader?: Loader): Promise<any> {
        const response = await fetch(url);
        const rawAtlas = await response.text();

        let basePath = utils.path.dirname(loadAsset.src);
        if (basePath && basePath.lastIndexOf('/') !== basePath.length - 1) {
            basePath += '/';
        }

        return new Promise((resolve) => {
            const textureAtlas = new TextureAtlas(rawAtlas, textureLoader(utils.path.dirname(url)), () => {
                resolve(textureAtlas);
            });
        });
    },
} as LoaderParser;

function textureLoader(baseUrl: string) {
    if (baseUrl && baseUrl.lastIndexOf('/') !== baseUrl.length - 1) {
        baseUrl += '/';
    }

    return (line: string, callback: (baseTexture: BaseTexture) => void) => {
        const url = baseUrl + line;

        Assets.load(url).then((asset) => {
            callback(asset.baseTexture);
        });
    };
}

// add atlas resolve url parser so that it can parse different resolutions of spritesheets e.g. @2x, @0.5x
export const resolveAtlasUrl = {
    extension: ExtensionType.ResolveParser,
    test: (value: string): boolean => settings.RETINA_PREFIX.test(value) && value.endsWith('.atlas'),
    parse: resolveTextureUrl.parse,
    name: 'resolveAtlasUrl',
} as ResolveURLParser;
