import type { Entity, QueriesObject, QueryResults, System } from '@play-co/odie';
import { Vector2 } from '@play-co/odie';

import { SpiderEffect } from '../actions/effects/SpiderEffect';
import { BlockComponent } from '../components/BlockComponent';
import { PhaseComponent } from '../components/PhaseComponent';
import { SpiderBlockComponent } from '../components/SpiderBlockComponent';
import { config } from '../defs/config';
import { BlockEntity, SpiderBlockEntity } from '../entities/BlockEntity';
import type { GameScene } from '../GameScene';
import { blockIsFrozen } from '../util/blockTools';
import { mapToViewPosition } from '../util/mapTools';

export class SpiderBlockSystem implements System {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'blockSpider';
    public static Queries: QueriesObject = {
        blockSpider: {
            components: [BlockComponent, SpiderBlockComponent],
            added: true,
        },
        phase: {
            components: [PhaseComponent],
            modified: true,
        },
    };

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    public queries!: QueryResults;

    // impl
    //-------------------------------------------------------------------------
    public addedToQuery(entity: SpiderBlockEntity) {
        // draw web when spider blocks are first added
        this._drawWebOnSpiderPositionUpdate(entity);
    }

    public modifiedQuery(entity: Entity, component: PhaseComponent, properties: any) {
        const { phase, moved, round } = component;

        if (properties.phase && phase === 'input' && moved && round > 0) {
            this._activateSpiders();
        }
    }

    private async _activateSpiders() {
        const phase = this.scene.sessionEntity.c.phase;

        // don't activate any spiders which are frozen, reverse to emulate query forEach
        const spiderEntities: SpiderBlockEntity[] = this.queries.blockSpider.entities
            .filter((entity) => !blockIsFrozen(this.scene, entity as BlockEntity))
            .reverse() as SpiderBlockEntity[];

        if (!spiderEntities.length) {
            return;
        }

        phase.activePush();

        await new SpiderEffect(this.scene, {
            spiderEntities,
            onSpiderPositionUpdate: this._drawWebOnSpiderPositionUpdate,
        }).execute();

        await phase.activePop();
    }

    private _drawWebOnSpiderPositionUpdate(spiderEntity: SpiderBlockEntity) {
        const y = spiderEntity.view.y;
        const { y: topBlockY } = mapToViewPosition(new Vector2(0, 0));
        const topGameMargin = 16;
        const topOffset = config.tile.size / 2 + topGameMargin;
        spiderEntity.c.blockSpider.view.setWebHeight(y - topBlockY + topOffset);
    }
}
