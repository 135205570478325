import type { Entity, QueriesObject, QueryResults, System } from '@play-co/odie';

import { BlockComponent } from '../components/BlockComponent';
import { GlowComponent } from '../components/GlowComponent';
import { PhaseComponent } from '../components/PhaseComponent';
import { PowerBlockType, powerBlockTypes } from '../defs/block';
import { PowerBlockEntity } from '../entities/BlockEntity';
import type { GameScene } from '../GameScene';
import { blockIsFrozen, blockIterateNeighbors } from '../util/blockTools';

/*
    handles power block specifics such as combo glows
*/
export class PowerBlockSystem implements System {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'power';
    public static Queries: QueriesObject = {
        block: {
            components: [BlockComponent, GlowComponent],
        },
        phase: {
            components: [PhaseComponent],
            modified: true,
        },
    };

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    public queries!: QueryResults;

    // impl
    //-------------------------------------------------------------------------
    public modifiedQuery(entity: Entity, component: PhaseComponent, properties: any) {
        // if input phase update power block combo glows
        if (properties.phase && component.phase === 'input') {
            this._updateComboGlows();
        }
    }

    // private: actions
    //-------------------------------------------------------------------------
    private _updateComboGlows() {
        // update combo glows
        this.queries.block.forEach((entity: PowerBlockEntity) => this._updateBlockGlow(entity));
    }

    private _updateBlockGlow(entity: PowerBlockEntity) {
        const glow = entity.c.glow;

        // start else stop glow if has power neighbor
        this._hasPowerNeighbor(entity) ? glow.start() : glow.stop();
    }

    // private: support
    //-------------------------------------------------------------------------
    private _hasPowerNeighbor(entity: PowerBlockEntity): boolean {
        let found = false;

        // iterate direct neighbor base blocks starting at given entity
        blockIterateNeighbors(this.scene.sessionEntity.c.map, entity, (base) => {
            // if not frozen
            if (!blockIsFrozen(this.scene, base)) {
                // skip ourselves
                if (base === entity) return true;
                // found if power block
                if (powerBlockTypes.includes(base.c.block.props.type as PowerBlockType)) found = true;
            }
            // dont go past direct neighbor
            return false;
        });

        return found;
    }
}
