import { Vector2 } from '@play-co/odie';

import app from '../../../../../index.entry';
import { AxisId } from '../../defs/block';
import { BlockEntity, despawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { mapGetPan } from '../../util/mapTools';
import { IEffect } from './IEffect';
import { RocketEffect } from './RocketEffect';

// types
//-----------------------------------------------------------------------------
export type BombRocketComboEffectOptions = {
    subject: BlockEntity;
    pair: BlockEntity[];
    all: BlockEntity[];
};

/*
    bomb+rocket combo multi launch effect
*/
export class BombRocketComboEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _options: BombRocketComboEffectOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: BombRocketComboEffectOptions) {
        this._scene = scene;
        this._options = options;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        const effectPromises: Promise<void>[] = [];

        // play sound
        app.sound.play('puzzle-combo-rocket-bomb.mp3', {
            pan: mapGetPan(this._options.subject.c.position.mapPosition),
        });

        // despawn all blocks
        for (const block of this._options.all) {
            despawnBlockEntity(this._scene, block);
        }

        // execute 3 vertical rocket effects
        for (let x = -1; x <= 1; ++x) this._executeRocketEffect(effectPromises, x, 0, 'vertical');

        // execute 3 horizontal rocket effects
        for (let y = -1; y <= 1; ++y) this._executeRocketEffect(effectPromises, 0, y, 'horizontal');

        // wait on effect promises
        await Promise.all(effectPromises);
    }

    // private: support
    //-------------------------------------------------------------------------
    private _executeRocketEffect(effectPromises: Promise<void>[], x: number, y: number, axis: AxisId) {
        // get base position
        const basePosition = this._options.subject.c.position.mapPosition;

        // get launch position
        const position = new Vector2(basePosition.x + x, basePosition.y + y);

        // if valid map position
        if (this._scene.sessionEntity.c.map.isPositionOnMap(position)) {
            // create and execute rocket effect
            const effect = new RocketEffect(this._scene, { position, axis }).execute();

            // add to effect promises
            effectPromises.push(effect);
        }
    }
}
