import type { Component, Entity2D } from '@play-co/odie';

import { KettleBlockProps } from '../defs/block';
import { KettleBlockView } from '../views/blocks/KettleBlockView';

// types
//-----------------------------------------------------------------------------
export type KettleBlockComponentOptions = {
    props: KettleBlockProps;
};

/*
    kettle block component
*/
export class KettleBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockKettle';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: KettleBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: KettleBlockComponentOptions): void {
        // create kettle block view
        this.view = new KettleBlockView(options.props);
    }
}
