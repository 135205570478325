import { Container, Sprite, Texture } from 'pixi.js';

import { IAnimation } from '../../../../lib/pattern/IAnimation';
import { Color } from '../../../../lib/pixi/Color';
import { MaterialSprite } from '../../../../lib/pixi/scene/MaterialSprite';
import { tween } from '../../../../lib/util/tweens';
import { sleep } from '../../../../replicant/util/jsTools';

// types
//-----------------------------------------------------------------------------
export type CubeBeamAnimationOptions = {
    color: number;
    length: number;
    fromGlow: boolean;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    map: 'fxmap.3.jpg',
    beam: 'fx.cube.beam.png',
    glow: 'fx.cube.glow.png',
};

/*
    cube effect beam animation
*/
export class CubeBeamAnimation extends Container implements IAnimation {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _length: number;
    // scene
    private _map: Sprite;
    private _beam: MaterialSprite;
    private _glowyFrom: Sprite;
    private _glowyTo: MaterialSprite;

    // init
    //-------------------------------------------------------------------------
    constructor(options: CubeBeamAnimationOptions) {
        super();

        // set fields
        this._length = options.length;

        // spawn beam
        this._spawnBeam(options);

        // spawn from glowy
        if (options.fromGlow) {
            this._spawnGlowyFrom();
        }
    }

    static assets(): string[] {
        return Object.values(manifest);
    }

    // impl
    //-------------------------------------------------------------------------
    public async start(): Promise<void> {
        // animate extend
        await this._animateExtend();
    }

    public stop() {}

    // private: scene
    //-------------------------------------------------------------------------
    private _spawnBeam(options: CubeBeamAnimationOptions) {
        // prepare hsl color
        const hsl = Color.from(options.color).toHsl();
        hsl.saturation = 3;
        hsl.luminance = 1;

        // prepare texture
        const texture = Texture.from(manifest.beam);
        const pivot = texture.height / 2;

        // create beam
        this._beam = this.addChild(
            new MaterialSprite(texture, {
                displace: { map: Texture.from(manifest.map), scale: { x: 0, y: 0.05 }, amp: 1 },
                hsl: { hsl },
            }).props({
                pivot: { x: 0, y: pivot },
                scale: { x: 1, y: 2 },
            }),
        );

        // create glowy
        this._glowyTo = this.addChild(
            new MaterialSprite(Texture.from(manifest.glow), {
                displace: { map: Texture.from(manifest.map), scale: { x: 0.2, y: 0.2 }, amp: 0.5 },
                hsl: { hsl },
            }).props({
                scale: { x: 1.2, y: 1.2 },
                pivot: { x: pivot, y: pivot },
            }),
        );
    }

    private _spawnGlowyFrom() {
        const glowyFrom = (this._glowyFrom = this.addChild(Sprite.from(manifest.glow)));
        glowyFrom.scale.set(6);
        glowyFrom.anchor.set(0.5);
    }

    // private: animation
    //-------------------------------------------------------------------------
    private async _animateExtend() {
        const duration = 0.7;
        const loop = duration * 3;
        const offsetX = Math.random();
        const offsetY = Math.random();
        this._glowyTo
            .animate()
            .add(this._glowyTo.material, { disOffsetX: -0.01, disOffsetY: -0.01 }, duration, tween.linear)
            .and(this._glowyTo, { x: this._length }, tween.linear);

        this._beam
            .animate()
            .set(this._beam.material, { disOffsetX: offsetX, disOffsetY: offsetY })
            .add(this._beam.material, { disOffsetX: offsetX - 0.1, disOffsetY: offsetY - 0.1 }, loop, tween.linear)
            .layer()
            .add(this._beam.material, { disScaleX: this._length * 0.000375 }, duration, tween.linear)
            .and(this._beam, { width: this._length }, tween.linear);
        await sleep(duration);
    }
}
