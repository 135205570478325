import type { EntityType } from '@play-co/odie';
import { createEntity, DefineEntity, Entity, Vector2 } from '@play-co/odie';

import { arrayIterate2 } from '../../../../replicant/util/jsTools';
import { MapComponent } from '../components/MapComponent';
import { PhaseComponent } from '../components/PhaseComponent';
import { StateComponent } from '../components/StateComponent';
import { MapDef } from '../defs/map';
import type { GameScene } from '../GameScene';
import { despawnBlockEntity, spawnBlockEntity } from './BlockEntity';
import { despawnTileEntity, spawnTileEntity } from './TileEntity';

// types
//-----------------------------------------------------------------------------
const SessionEntityDef = DefineEntity(Entity, PhaseComponent, MapComponent, StateComponent);

export type SessionEntity = EntityType<typeof SessionEntityDef>;

// api
//-----------------------------------------------------------------------------
export function createSessionEntity(mapDef: MapDef): SessionEntity {
    // create session entity and register as singleton
    return createEntity(SessionEntityDef, {
        map: mapDef,
    });
}

export function spawnSessionEntity(scene: GameScene, sessionEntity: SessionEntity) {
    // spawn session entity
    scene.addToScene(sessionEntity);

    // spawn map
    _spawnMap(scene, sessionEntity.c.map);
}

export function despawnSessionEntity(scene: GameScene, sessionEntity: SessionEntity) {
    // despawn map
    _despawnMap(scene, sessionEntity.c.map);

    // despawn session entity
    scene.removeFromScene(sessionEntity);
}

// private: map
//-----------------------------------------------------------------------------
function _spawnMap(scene: GameScene, mapComponent: MapComponent) {
    const mapDef = mapComponent.mapDef;
    const gridDef = mapDef.grid;

    // for each row and column of map definition
    for (let column = -1; column <= gridDef.columns; ++column) {
        for (let row = -1; row <= gridDef.rows; ++row) {
            const position = new Vector2(column, row);

            // spawn tile
            spawnTileEntity(scene, position);

            // if valid block position
            if (mapComponent.isPositionOnMap(position)) {
                // get cell def
                const cellDef = gridDef.cells[column][row];

                // if enabled
                if (cellDef?.enabled) {
                    // spawn block if specified
                    if (cellDef.blocks) {
                        for (const blockDef of cellDef.blocks) {
                            spawnBlockEntity(scene, blockDef, position);
                        }
                    }
                }
            }
        }
    }
}

function _despawnMap(scene: GameScene, mapComponent: MapComponent) {
    // iterate map grid
    arrayIterate2(mapComponent.grid, (cell) => {
        // despawn tile
        despawnTileEntity(scene, cell.tile);

        // despawn block
        if (cell.base) despawnBlockEntity(scene, cell.base.entity);

        // despawn overlay
        if (cell.overlay) despawnBlockEntity(scene, cell.overlay.entity);
    });
}
