import { Container, Sprite } from 'pixi.js';

import { DirectionId, DogBlockProps } from '../../defs/block';
import { config } from '../../defs/config';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    image: 'block.dog.png',
};

/*
    dog block view
*/
export class DogBlockView extends Container {
    // fields
    //-------------------------------------------------------------------------
    private _direction: DirectionId;
    private _image: Sprite;

    // properties
    //-------------------------------------------------------------------------
    public get direction(): DirectionId {
        return this._direction;
    }

    public set direction(direction: DirectionId) {
        this._direction = direction;

        // update x scale based on direction
        this._image.scale.x = direction === 'right' ? 1 : -1;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(props: DogBlockProps) {
        super();

        // spawn this way to let direction scale work right
        const image = (this._image = this.addChild(Sprite.from(manifest.image)));
        image.anchor.set(0.5);
        image.position.set(config.tile.size / 2);
    }

    static assets(): string[] {
        return [manifest.image];
    }
}
