import { floatRandom } from '../../../../replicant/util/mathTools';
import { DynamicNumber, importDynamicNumber } from '../../../defs/types';
import { pixiVectorFromAngle } from '../../pixiTools';
import { Particle } from '../Particle';
import { IBehavior } from './IBehavior';

// types
//-----------------------------------------------------------------------------
export type ExplodeBehaviorOptions = {
    type: 'explode';
    magnitude: DynamicNumber;
};

/*
    radial explosion. set initial velocity to add direction.
*/
export class ExplodeBehavior implements IBehavior {
    // fields
    //-------------------------------------------------------------------------
    private _particle: Particle;
    private _options: ExplodeBehaviorOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(particle: Particle, options: ExplodeBehaviorOptions) {
        this._particle = particle;
        this._options = options;
    }

    // impl
    //-------------------------------------------------------------------------
    public start() {
        const angle = floatRandom(0, Math.PI * 2);

        // solve magnitude
        const magnitude = importDynamicNumber(this._options.magnitude);

        // create random velocity
        this._particle.velocity.add(pixiVectorFromAngle(angle).multiplyScalar(magnitude));
    }
}
