import { Container } from 'pixi.js';

import app from '../../../../index.entry';
import { DynamicXy } from '../../../../lib/defs/types';
import { IAnimation } from '../../../../lib/pattern/IAnimation';
import { ParticleEmitter } from '../../../../lib/pixi/particles/ParticleEmitter';

// types
//-----------------------------------------------------------------------------
export type IceDustAnimationOptions = {
    amount?: number;
    scale?: DynamicXy;
    velocity?: DynamicXy;
    tint?: number;
};

/*
    ice dust animation
*/
export class IceDustAnimation extends Container implements IAnimation {
    // fields
    //-------------------------------------------------------------------------
    private _options: IceDustAnimationOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(options: IceDustAnimationOptions) {
        super();
        this._options = options;
    }

    public static assets(): string[] {
        return ['fx.basic.sparkle.png'];
    }

    // impl
    //-------------------------------------------------------------------------
    public async start(): Promise<void> {
        // create dust emitter
        const dustEmitter = this._createDustEmitter();

        // animate dust
        await this._animateDust(dustEmitter);
    }

    public stop() {}

    // private: animators
    //-------------------------------------------------------------------------
    private async _animateDust(dust: ParticleEmitter) {
        // spawn dust
        this.addChild(dust.view);

        // start
        await dust.start();

        // despawn dust
        this.removeChild(dust.view);
    }

    // private: factory
    //-------------------------------------------------------------------------
    private _createDustEmitter(): ParticleEmitter {
        return app.particles.create({
            textures: ['fx.basic.sparkle.png'],
            rate: 0,
            limit: this._options.amount ?? 10,
            emitterDuration: 0,
            duration: 1.5,
            velocity: this._options.velocity,
            tint: this._options.tint,
            scale: this._options.scale,
            behaviors: [
                { type: 'explode', magnitude: [60, 180] },
                { type: 'gravity', gravity: { x: 0, y: 700 } },
                { type: 'scale', to: 0 },
                { type: 'kinematic' },
            ],
        });
    }
}
