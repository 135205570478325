import { Sprite } from 'pixi.js';

import app from '../../../../index.entry';
import { BasicText } from '../text/BasicText';
import { LevelPopup, LevelPopupOptions } from './LevelPopup';

// types
//-----------------------------------------------------------------------------
export type LevelFailedPopupOptions = {} & LevelPopupOptions;

const manifest = {
    header: 'panel.level.header.png',
    lifeLost: 'icon.life.lost.png',
};

/*
    Failed popup
*/
export class LevelFailedPopup extends LevelPopup {
    public override preload() {
        return [...super.preload(), ...app.resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: LevelFailedPopupOptions) {
        super.spawning({
            ...options,
            okButton: '[buttonTryAgain]',
        });

        const failed = new BasicText({
            text: '[popupLevelFailed]',
            style: {
                fill: 0xd03555,
                fontSize: 42,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'center',
            },
        });

        const heart = Sprite.from(manifest.lifeLost);
        heart.scale.set(1.16);

        // spawn
        this.main.addContent({
            failed: {
                content: failed,
                styles: {
                    position: 'centerTop',
                    marginTop: 216,
                },
            },
            heart: {
                content: heart,
                styles: {
                    position: 'centerTop',
                    marginTop: 270,
                },
            },
        });
    }

    public override async spawned(): Promise<void> {
        await super.spawned();
    }

    public override async despawned() {
        super.despawned();
    }
}
