import { Sprite, Texture } from 'pixi.js';

import { ColorId, HatBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-------------------------------------------------------------------------
const colorMap: { [key in ColorId]?: string } = {
    all: 'block.hat.all.png',
    blue: 'block.hat.blue.png',
    green: 'block.hat.green.png',
    purple: 'block.hat.purple.png',
    red: 'block.hat.red.png',
    yellow: 'block.hat.yellow.png',
};

/*
    hat block view
*/
export class HatBlockView extends Sprite {
    // init
    //-------------------------------------------------------------------------
    constructor(props: HatBlockProps) {
        // create base view
        super(Texture.from(colorMap[props.color]));
        blockPositionView(this, props);
    }

    static assets(props: HatBlockProps): string[] {
        return [colorMap[props.color]];
    }
}
