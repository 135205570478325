import { i18n } from '@play-co/astro';
import { Container, ITextStyle, Text } from 'pixi.js';

import { textLocaleFormat } from '../../../../lib/util/textTools';
import { languageFontMap, systemFontMap, TextLanguageId } from '../../../defs/text';

// types
//-----------------------------------------------------------------------------
export type BasicTextOptions = {
    // formatted text
    text?: string;
    // text style
    style: Partial<ITextStyle>;
    // use given text directly instead of parsing it
    literal?: boolean; // default: false
    // use system font
    system?: boolean; // default: false
};

/*
    ui: basic text
*/
export class BasicText extends Container {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _textFormat: string;
    private _literal: boolean;
    // scene
    private _text: Text;

    // properties
    //-------------------------------------------------------------------------
    // text
    public get text(): string {
        return this._text.text;
    }

    public set text(format: string) {
        // if changing, update text format and corresponding text content
        if (this._textFormat !== format) {
            this._textFormat = format;
            this._text.text = this._literal ? format : textLocaleFormat(format || '');
        }
    }

    // text
    public get view(): Text {
        return this._text;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: BasicTextOptions) {
        super();

        // set font family
        if (!options.style.fontFamily) {
            const language = i18n.language as TextLanguageId;
            options.style.fontFamily = options.system ? systemFontMap[language] : languageFontMap[language].fontName;
        }

        // init base
        this._text = this.addChild(new Text(undefined, options.style));

        // set fields
        this._literal = options.literal || false;

        // initial updates
        this.text = options.text;
    }
}
