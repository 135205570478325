import { analytics } from '@play-co/gcinstant';

export function trackPerformance(opts: {
    // Is this first session event
    isFirst: boolean;

    // Whole session avr fps
    fps: number;
    // Whole session avr variance
    choppiness: number;
    // Whole session avr sample variance
    choppinessSample: number;

    // Average FPS between current performance events and previous performance events
    realtimeFPS: number;
    // Average DT variance between current performance events and previous performance events
    realtimeChoppiness: number;
    // Average DT sample variance between current performance events and previous performance events
    realtimeChoppinessSample: number;

    jsHeapSizeLimit: number | null;
    jsHeapSizeTotal: number | null;
    jsHeapSizeUsed: number | null;
}) {
    analytics.pushEvent('Performance', opts);
}
