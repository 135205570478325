import { CustomerId } from 'aws-sdk/clients/directoryservice';

import NakedPromise from '../../../lib/pattern/NakedPromise';
import bakery from '../../../replicant/defs/bakery';
import { HomeScreen } from '../../main/home/HomeScreen';

export abstract class CustomerFlow {
    protected readonly _complete = new NakedPromise<boolean>();
    protected _screen: HomeScreen;
    protected _loadPromise: Promise<any[]>;

    // init
    //-------------------------------------------------------------------------
    constructor(opts: { screen: HomeScreen }) {
        this._screen = opts.screen;
    }

    protected async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    // Do not call this before the this._loadPromise is resolved
    protected async _speechSequence(opts: {
        entryId: number;
        dialogs: { customer: boolean; speech: string }[];
        extraCallBacks?: Record<number, () => void>;
    }) {
        const { entryId, dialogs, extraCallBacks } = opts;
        const customer = bakery.customers[entryId];
        for (let i = 0; i < dialogs.length; i++) {
            const tapPromise = new NakedPromise();
            if (dialogs[i].customer) {
                this._screen.spawnCustomerAnimation(customer.customerId, tapPromise);
            }

            if (!!extraCallBacks && !!extraCallBacks[i]) {
                // extra changes in the scene on top of the dialogs, glow-, table item spawn etc
                extraCallBacks[i]();
            }

            await this._screen.spawnBubbleTap(dialogs[i], tapPromise);
            await tapPromise;
        }
    }

    protected _preloadCustomer(entryId: number) {
        const customer = bakery.customers[entryId];
        const preloadMap: Record<CustomerId, () => Promise<any[]>> = {
            girl: () => this._screen.preloadGirl(),
            grandma: () => this._screen.preloadGrandma(),
            lady: () => this._screen.preloadLady(),
            oldMan: () => this._screen.preloadOldman(),
            woman: () => this._screen.preloadWoman(),
        };

        this._loadPromise = preloadMap[customer.customerId]();
    }
}
