import { CollideEvent, CollideId } from '../../defs/collide';
import type { ThrowerBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { InvalidEffect } from '../effects/InvalidEffect';
import { ThrowerEffect } from '../effects/ThrowerEffect';
import { CollideHandler, ICollision } from './ICollision';

// fields
//-----------------------------------------------------------------------------
//const sounds = ['puzzle-lock-destroy.mp3', 'puzzle-lock-poke.mp3'];

/*
    thrower block collision resolver
*/
export class ThrowerCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: ThrowerBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        attack: this._resolveBreak,
        block: this._resolveBreak,
        tap: this._resolveTap,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: ThrowerBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        // invalid effect
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveBreak(event: CollideEvent) {
        await this._attack();
        return true;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _attack() {
        // play sound based on component count
        //app.sound.play(sounds[component.count], { pan: mapGetPan(this._entity.c.position.mapPosition) });

        // execute thrower effect
        await new ThrowerEffect(this._scene, {
            subject: this._entity,
        }).execute();
    }
}
