import { Sprite } from 'pixi.js';

import app from '../../../../index.entry';
import { BasicAsyncHandler } from '../../../../lib/defs/types';
import { BasicText } from '../text/BasicText';
import { BasicPopup } from './BasicPopup';

const HEIGHT = 584;

// types
//-----------------------------------------------------------------------------
export type PuzzlePopupOptions = {
    onOk: BasicAsyncHandler;
    type: 'checkLater' | 'playPuzzle';
};

const manifest = {
    pattern: 'icon.pattern.png',
    puzzleIcon: 'icon.puzzle.large.png',
    button: 'button.red.small.png',
};

export class PuzzlePopup extends BasicPopup {
    public override preload() {
        return [...super.preload(), ...app.resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: CakePopupOptions) {
        super.spawning({
            width: 690,
            height: HEIGHT,
            underlay: 0.7,
            ...options,
        });

        const mainText = new BasicText({
            text: `[${options.type}]`,
            style: {
                align: 'center',
                fill: '000',
                fontSize: 34,
                fontWeight: 'bold',
                lineJoin: 'round',
                wordWrap: true,
                wordWrapWidth: this.main.width - 80,
            },
        });

        const redText = new BasicText({
            text: '[developmentTodo]',
            style: {
                align: 'center',
                fill: '#E90000',
                fontSize: 32,
                fontWeight: 'bold',
                lineJoin: 'round',
                wordWrap: true,
                wordWrapWidth: this.main.width - 80,
            },
        });

        const puzzle = Sprite.from(manifest.puzzleIcon);

        const pattern = Sprite.from(manifest.pattern);
        // spawn
        this.main.addContent({
            pattern: {
                content: pattern,
                styles: {
                    position: 'topCenter',
                    marginTop: 130,
                },
            },
            main: {
                content: puzzle,
                styles: {
                    position: 'topCenter',
                    marginTop: 110,
                },
            },
            done: {
                content: mainText,
                styles: {
                    position: 'topCenter',
                    marginTop: 330,
                },
            },
            red: {
                content: redText,
                styles: {
                    position: 'topCenter',
                    marginTop: 410,
                },
            },
        });
    }

    public override async spawned(): Promise<void> {
        await super.spawned();
    }

    public override async despawned() {
        super.despawned();
    }
}
