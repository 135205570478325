import { SB } from '@play-co/replicant';

import { livesState } from './components/lives';
import { puzzleState } from './components/puzzle';
import { bakeryState } from './defs/bakery';
import { coreState } from './defs/core';
import { firstSessionState } from './defs/firstSession';
import { playerState } from './defs/player';
import { settingsState } from './defs/settings';
import { tutorialState } from './defs/tutorial';

// combined state
//-----------------------------------------------------------------------------
export const stateSchema = SB.object({
    ...bakeryState,
    ...coreState,
    ...livesState,
    ...playerState,
    ...puzzleState,
    ...settingsState,
    ...firstSessionState,
    ...tutorialState,
});
