import app from '../../../../../index.entry';
import { BlockEntity, despawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { mapGetPan } from '../../util/mapTools';
import { CubeEffect } from './CubeEffect';
import { IEffect } from './IEffect';

// types
//-----------------------------------------------------------------------------
export type CubeCubeComboEffectOptions = {
    subject: BlockEntity;
    pair: BlockEntity[];
    all: BlockEntity[];
};

/*
    cube+cube combo clear all basic blocks effect
*/
export class CubeCubeComboEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _options: CubeCubeComboEffectOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: CubeCubeComboEffectOptions) {
        this._scene = scene;
        this._options = options;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        const position = this._options.subject.c.position.mapPosition;

        // play sound
        app.sound.play('puzzle-combo-cube-cube.mp3', { rate: 1.4, pan: mapGetPan(position) });

        // despawn all blocks
        for (const block of this._options.all) {
            despawnBlockEntity(this._scene, block);
        }

        // execute cube effect
        return new CubeEffect(this._scene, {
            position,
            color: 'all',
        }).execute();
    }
}
