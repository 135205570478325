import { IMaskTarget, SpriteMaskFilter } from 'pixi.js';

import fragment from './mask.frag';

/*
    mask filter with minor shader tweak
*/
export class MaskFilter extends SpriteMaskFilter {
    constructor(sprite: IMaskTarget) {
        super(undefined, fragment);
        this.maskSprite = sprite;
    }
}
