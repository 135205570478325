import { SB } from '@play-co/replicant';

const defaultNameEN = 'Rei’s Patisserie';
const defaultNameJA = 'レイズパティセリー';

// state
//-----------------------------------------------------------------------------
export const playerState = {
    // general
    name: SB.string().default(''),

    // gained from puzzles and consumed in baking
    stars: SB.int().min(0), // tutorial uses mock stars and it should be 0 once tutorial is finished
};

export default {
    defaultNameEN,
    defaultNameJA,
};
