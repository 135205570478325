import type { Component, Entity2D } from '@play-co/odie';

import { RocketAnimation } from '../animations/RocketAnimation';
import { DirectionId } from '../defs/block';
import { blockPositionView, blockUpdateDirection } from '../util/blockTools';

// types
//-----------------------------------------------------------------------------
export type RocketComponentOptions = {
    direction: DirectionId;
};

/*
    rocket component
*/
export class RocketComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'rocket';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // state
    private _direction: DirectionId;
    // scene
    public view!: RocketAnimation;

    // properties
    //-------------------------------------------------------------------------
    // direction
    public get direction(): DirectionId {
        return this._direction;
    }

    // init
    //-------------------------------------------------------------------------
    static assets(): string[] {
        return RocketAnimation.assets();
    }

    // impl
    //-------------------------------------------------------------------------
    init(options: RocketComponentOptions): void {
        // set direction
        this._direction = options.direction;

        // create view
        const view = (this.view = new RocketAnimation());
        blockPositionView(view, { width: 0, height: 0 });

        // update sprite direction
        blockUpdateDirection(view, options.direction);
    }

    start() {
        // start animation
        this.view.start();
    }

    stop() {
        // stop animation
        this.view.stop();
    }
}
