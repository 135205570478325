import { arrayCreate2 } from '../../../../replicant/util/jsTools';
import { BlockId } from './block';
import { config } from './config';
import { GoalId } from './goal';
import { MysteryItemRewardId } from './reward';

// reward definitions
//-----------------------------------------------------------------------------
export type RewardsDef = { [key in MysteryItemRewardId]?: RewardDef };

// goal definitions
//-----------------------------------------------------------------------------
export type GoalsDef = { [key in GoalId]?: number };

// grid definitions
//-----------------------------------------------------------------------------
//TODO: use string instead
export type BlockOption = number;

export type BlockDef = {
    id: BlockId;
    option?: BlockOption;
};

export type CellDef = {
    enabled: boolean;
    blocks?: BlockDef[];
};

export type GridDef = {
    columns: number;
    rows: number;
    cells: CellDef[][];
};

export type RewardDef = {
    limit: number;
    odds: number;
};

// map definitions
//-----------------------------------------------------------------------------
export type MapDef = {
    moves: number;
    goals: GoalsDef;
    rewards: RewardsDef;
    spawns: BlockId[];
    grid: GridDef;
};

// defaults
//-----------------------------------------------------------------------------
export const defaultMapDef: MapDef = {
    moves: 10,
    goals: {
        basicRed: 10,
    },
    rewards: {
        gift: {
            limit: 1,
            odds: 0.3,
        },
        treasure: {
            limit: 1,
            odds: 0.3,
        },
    },
    spawns: ['basicRed'],
    grid: {
        columns: config.map.columns,
        rows: config.map.rows,
        cells: arrayCreate2<CellDef>(config.map.columns, config.map.rows, (column, row) => ({ enabled: true })),
    },
};
