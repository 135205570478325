import type { Component, Entity2D } from '@play-co/odie';

import { CubeBlockProps } from '../defs/block';
import { CubeBlockView } from '../views/blocks/CubeBlockView';

// types
//-----------------------------------------------------------------------------
export type CubeBlockComponentOptions = {
    props: CubeBlockProps;
};

/*
    cube block component
*/
export class CubeBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockCube';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: CubeBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: CubeBlockComponentOptions): void {
        // create cube block view
        this.view = new CubeBlockView(options.props);
    }
}
