import { Filter } from 'pixi.js';

import { Color } from '../../Color';
import { HSL } from '../../HSL';
import fragment from './hsl.frag';

/*
    pixi: HSL colorization filter
*/
export class HslFilter extends Filter {
    // properties
    //-------------------------------------------------------------------------
    // hue
    public get hue(): number {
        return this.uniforms.uHue;
    }

    public set hue(value: number) {
        this.uniforms.uHue = value;
    }

    // saturation
    public get saturation(): number {
        return this.uniforms.uSaturation;
    }

    public set saturation(value: number) {
        this.uniforms.uSaturation = value;
    }

    // luminance
    public get luminance(): number {
        return this.uniforms.uLuminance;
    }

    public set luminance(value: number) {
        this.uniforms.uLuminance = value;
    }

    // color
    public set color(color: number) {
        this.uniforms.uHue = Color.from(color).toHsl().hue;
    }

    // hsl
    public get hsl(): HSL {
        return new HSL(this.hue, this.luminance, this.saturation);
    }

    public set hsl(hsl: HSL) {
        this.hue = hsl.hue;
        this.luminance = hsl.luminance;
        this.saturation = hsl.saturation;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(hue = 0, saturation = 0, luminance = 1) {
        super(undefined, fragment, {
            uHue: hue,
            uSaturation: saturation,
            uLuminance: luminance,
        });
    }

    // builders
    //-------------------------------------------------------------------------
    static fromColor(color: number): HslFilter {
        return new HslFilter(Color.from(color).toHsl().hue, 1);
    }

    static fromColor2(color: number): HslFilter {
        const hsl = Color.from(color).toHsl();
        return new HslFilter(hsl.hue, hsl.saturation, hsl.luminance);
    }

    static fromHSL(hsl: HSL): HslFilter {
        return new HslFilter(hsl.hue, hsl.saturation, hsl.luminance);
    }
}
