import { DisplayObject, TextMetrics, Ticker } from 'pixi.js';

import { ITemporal } from '../../pattern/ITemporal';
import { AnimationComponent } from '../components/AnimationComponent';
import { pixiGetDt, pixiGetScene } from '../pixiTools';

// DisplayObject
//-----------------------------------------------------------------------------
Object.defineProperty(DisplayObject.prototype, 'props', {
    value(props: any) {
        Object.assign(this, props);
        return this;
    },
});

Object.defineProperty(DisplayObject.prototype, 'inScene', {
    value() {
        const scene = pixiGetScene();
        for (let object = this; (object = object.parent); ) {
            if (object === scene) return true;
        }
        return false;
    },
});

Object.defineProperty(DisplayObject.prototype, 'removeSelf', {
    value() {
        this.parent?.removeChild(this);
        return this;
    },
});

Object.defineProperty(DisplayObject.prototype, 'animate', {
    value() {
        return new AnimationComponent(this);
    },
});

Object.defineProperty(DisplayObject.prototype, 'temporal', {
    value(temporal: ITemporal = this) {
        // declare stepper
        const stepper = () => {
            // if in scene, and not yet complete, continue stepping
            // else stop temporal and unregister frame stepper
            if (!this.inScene() || !temporal.step(pixiGetDt())) {
                temporal.stop?.();
                Ticker.system.remove(stepper);
            }
        };

        // start temporal
        temporal.start?.().then(() => {
            // register frame stepper
            Ticker.system.add(stepper);
        });

        return this;
    },
});

/*TEST: disables all filters for comparing GPU performance
Object.defineProperty(DisplayObject.prototype, 'filters', {
    get: () => [],
    set: (v) => {},
});
//*/

// TextMetrics
//-----------------------------------------------------------------------------
TextMetrics.measureFont = function (font: string): {
    ascent: number;
    descent: number;
    fontSize: number;
} {
    const context = TextMetrics._context;
    context.font = font;
    const metrics = context.measureText(TextMetrics.METRICS_STRING + TextMetrics.BASELINE_SYMBOL);
    return {
        ascent: metrics.actualBoundingBoxAscent,
        descent: metrics.actualBoundingBoxDescent,
        fontSize: metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent,
    };
};
