import { SB } from '@play-co/replicant';

import { timeFromComponents } from '../util/timeTools';

// state
//-----------------------------------------------------------------------------
// TODO: Better naming, the state had to be split out in a separate file to allow game to launch
// NOTE: Game does not launch if this state definition is in the same file as the rest.
export const coreState = {
    // last enter time
    // enterTime: SB.int().defaul(0),
    // total enters
    enterCount: SB.int().default(0),
    // cooldown map, for example invite launch popup
    cooldowns: SB.map(SB.object({ startTimestamp: SB.int() })),
};

// constants
//-----------------------------------------------------------------------------
export default {
    // time when user is marked as inactivte
    inactiveTime: timeFromComponents({ days: 30 }),
};
