import { Sprite } from 'pixi.js';

import app from '../../../../index.entry';
import { uiAlignCenter } from '../../../../lib/pixi/uiTools';
import { BasicText } from '../text/BasicText';
import { BasicPopup, BasicPopupOptions } from './BasicPopup';

// const HEIGHT = 640;

// types
//-----------------------------------------------------------------------------
export type LevelPopupOptions = {
    level: number;
} & BasicPopupOptions;

const manifest = {
    header: 'panel.level.header.png',
};

/*
    Level popup with level banner, subclass this for specific level design
*/
export abstract class LevelPopup extends BasicPopup {
    static defaultHeight = 640;
    public override preload(object?: any) {
        return [...super.preload(), ...app.resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: LevelPopupOptions) {
        super.spawning({
            width: 690,
            height: LevelPopup.defaultHeight,
            underlay: 0.7,
            ...options,
        });

        const title = new BasicText({
            text: `[popupLevelTitle|${options.level}]`,
            style: {
                fill: 0xffffff,
                fontSize: 56,
                fontWeight: 'bold',
                lineJoin: 'round',
                strokeThickness: 10,
                stroke: 0x05a2d26,
                align: 'center',

                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowBlur: 3,
                dropShadowAlpha: 0.2,
                dropShadowDistance: 5,
            },
        });

        const header = Sprite.from(manifest.header);
        header.addChild(title);
        uiAlignCenter(header, title, 0, -7);
        // spawn
        this.main.addContent({
            header: {
                content: header,
                styles: {
                    position: 'topCenter',
                    marginTop: options.height ? (LevelPopup.defaultHeight - options.height) * 0.5 - 40 : -40,
                },
            },
        });
    }

    public override async spawned(): Promise<void> {
        await super.spawned();
    }

    public override async despawned() {
        super.despawned();
    }
}
