import { SB } from '@play-co/replicant';

import gameConfig from '../defs/gameConfig';
import { MutableState, ScheduledActionAPI, State, SyncActionAPI } from '../defs/replicant';
import { numberClamp } from '../util/mathTools';

// constants
//-----------------------------------------------------------------------------
const livesFullEvent = 'livesFull';

// state
//-----------------------------------------------------------------------------
export const livesState = {
    // lives
    lives: SB.object({
        count: SB.int().default(gameConfig.lives.max),
        time: SB.int().default(0),
    }),
};

// actions
//-----------------------------------------------------------------------------
// export const livesActions = {};

// scheduled actions
//-----------------------------------------------------------------------------
export const livesScheduledActionsSchema = {
    livesFull: SB.object({}),
};
export const livesScheduledActions = {
    // when lives are full
    livesFull: (state: State, args: {}, api: ScheduledActionAPI) => {
        // const lang = state.language as LanguageId;
        // const creativeText = getCreativeText(lang, 'full_lives', api.math.random);
        // api.chatbot.sendMessage(
        //     state.id,
        //     chatbotMessageTemplates.staticOA({
        //         args: {
        //             imageKey: lang === 'en' ? 'fullLivesEN' : 'fullLivesJA',
        //             title: creativeText.text,
        //             cta: creativeText.cta,
        //         },
        //         payload: {
        //             ...generateChatbotPayload('full_life'),
        //             $creativeAssetID: creativeText.id,
        //             $creativeTextID: creativeText.id,
        //             $creativeCTA: creativeText.cta,
        //         },
        //     }),
        // );
    },
};

// api
//-----------------------------------------------------------------------------
export function livesGet(state: State, now: number): number {
    // get state lives
    const lives = state.lives.count;

    // get interval lives
    const intervalLives = Math.max(Math.floor((now - state.lives.time) / gameConfig.lives.interval), 0);
    // return total livee
    return numberClamp(lives + intervalLives, 0, gameConfig.lives.max);
}

export function livesSet(state: MutableState, lives: number, now: number) {
    // update lives count
    state.lives.count = numberClamp(lives, 0, gameConfig.lives.max);

    // reset regen
    if (state.lives.count === gameConfig.lives.max || state.lives.count === 0) {
        state.lives.time = state.lives.count === 0 ? now : now - gameConfig.lives.interval;
        return;
    }

    // reset refill time to include only most recent interval
    state.lives.time = now - ((now - state.lives.time) % gameConfig.lives.interval);
}

export function livesAdd(state: MutableState, lives: number, now: number) {
    livesSet(state, livesGet(state, now) + lives, now);
}

export function livesTimeToFull(state: State, now: number): number {
    const fullTime = (gameConfig.lives.max - state.lives.count) * gameConfig.lives.interval + (state.lives.time || now);
    return fullTime - now;
}

export function livesTimeToNext(state: State, now: number): number {
    const totalLives = livesGet(state, now);
    if (totalLives >= gameConfig.lives.max) return 0;

    // get regen lives not added to state yet and subtract state count to calculate next regen
    const regenerated = totalLives - state.lives.count;
    const interval = gameConfig.lives.interval;
    const next = interval * regenerated + (state.lives.time || now);
    return interval - (now - next);
}

// events
//-----------------------------------------------------------------------------
export function onLivesInit(api: SyncActionAPI, state: MutableState) {
    // reset lives full event
    api.scheduledActions.unschedule(livesFullEvent);
}

export function onLivesExit(api: ScheduledActionAPI, state: State) {
    // get time remaining to full lives
    const remaining = livesTimeToFull(state, api.date.now());

    // if positive, schedule lives full event
    if (remaining > 0) {
        api.scheduledActions.schedule.livesFull({
            args: {},
            notificationId: livesFullEvent,
            delayInMS: remaining,
        });
    }
}
