import type { Component, Entity2D } from '@play-co/odie';

import { ComboGlowAnimation } from '../animations/ComboGlowAnimation';
import { blockPositionView2 } from '../util/blockTools';

// types
//-----------------------------------------------------------------------------
export type GlowComponentOptions = {};

/*
    glowy highlight for other blocks
*/
export class GlowComponent implements Component {
    // constants
    //---------------------------------------------------------------------------
    static readonly NAME = 'glow';

    // fields
    //---------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view: ComboGlowAnimation;

    // impl
    //---------------------------------------------------------------------------
    init(options: GlowComponentOptions): void {
        this.view = new ComboGlowAnimation();

        // position view
        blockPositionView2(this.view, { width: 1, height: 1 });
    }

    shutdown() {
        this.view.stop();
    }

    // api
    //---------------------------------------------------------------------------
    public start() {
        this.view.start();
    }

    public stop() {
        this.view.stop();
    }
}
