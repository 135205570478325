/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
enum PhoneType {
    android,
    unknown,
    iPhoneSE2,
    iPhone11ProMax_or_XsMax,
    iPhone11_or_Xr,
    iPhone11Pro_or_X_or_Xs,
    iPhone6Plus_or_6sPlus_or_7Plus_or_8Plus,
    iPhone6_or_6s_or_7_or_8,
    iPhone5_or_5s_or_5c_or_SE,
    iPhone4_or_4s,
    iPhone1_or_2G_or_3G_or_3GS,
    iPhoneUnknown,
}

function isIphone() {
    return !!/iPhone/i.exec(navigator.userAgent);
}

function getPhoneType(): PhoneType {
    if (!isIphone()) {
        return PhoneType.android;
    }
    const h = window.screen.height;
    const w = window.screen.width;
    const pr = window.devicePixelRatio;

    if (h === 896 && w === 414 && pr === 3) {
        return PhoneType.iPhone11ProMax_or_XsMax;
    }
    if (h === 896 && w === 414 && pr === 2) {
        return PhoneType.iPhone11_or_Xr;
    }
    if (h === 812 && w == 375 && pr === 3) {
        return PhoneType.iPhone11Pro_or_X_or_Xs;
    }
    if (h == 736 && w === 414 && pr === 3) {
        return PhoneType.iPhone6Plus_or_6sPlus_or_7Plus_or_8Plus;
    }
    if (h == 667 && w === 375 && pr === 3) {
        return PhoneType.iPhone6Plus_or_6sPlus_or_7Plus_or_8Plus;
    }
    if (h === 667 && w === 375 && pr === 2) {
        return PhoneType.iPhone6_or_6s_or_7_or_8;
    }
    if (h / w === 1.775 && pr === 2) {
        return PhoneType.iPhone5_or_5s_or_5c_or_SE;
    }
    if (h / w === 1.5 && pr === 2) {
        return PhoneType.iPhone4_or_4s;
    }
    if (h / w === 1.5 && pr === 1) {
        return PhoneType.iPhone1_or_2G_or_3G_or_3GS;
    }

    return PhoneType.iPhoneUnknown;
}

export function getCoreCount(phoneType?: PhoneType): number {
    if (phoneType === undefined) {
        phoneType = getPhoneType();
    }
    switch (phoneType) {
        case PhoneType.unknown:
            return 1;
        case PhoneType.android: {
            if (typeof navigator === 'undefined') {
                return 1;
            }
            if (typeof navigator.hardwareConcurrency === 'undefined') {
                return 1;
            }

            return navigator.hardwareConcurrency;
        }
        case PhoneType.iPhone11ProMax_or_XsMax:
        case PhoneType.iPhone11_or_Xr:
        case PhoneType.iPhone11Pro_or_X_or_Xs:
            return 6;
        case PhoneType.iPhone6Plus_or_6sPlus_or_7Plus_or_8Plus:
        case PhoneType.iPhone6_or_6s_or_7_or_8:
        case PhoneType.iPhone5_or_5s_or_5c_or_SE:
            return 2;
        case PhoneType.iPhone4_or_4s:
        case PhoneType.iPhone1_or_2G_or_3G_or_3GS:
            return 1;
    }

    return -1;
}

export function getDeviceMemory(): number | undefined {
    if (typeof navigator === 'undefined') {
        return undefined;
    }

    // @ts-expect-error - only available in chromium browsers
    if (typeof navigator.deviceMemory === 'undefined') {
        return undefined;
    }

    // @ts-expect-error - only available in chromium browsers
    return navigator.deviceMemory * 1024 * 1024 * 1024;
}

export function computeWebGLSupport(canvas?: HTMLCanvasElement): boolean {
    canvas = canvas ?? document.createElement('canvas');
    let webGLSupported = false;

    try {
        webGLSupported = !!canvas.getContext('webgl');
    } catch (e) {
        webGLSupported = false;
    }
    if (!webGLSupported) {
        try {
            webGLSupported = !!canvas.getContext('experimental-webgl');
        } catch (e) {
            webGLSupported = false;
        }
    }

    return webGLSupported;
}

export function computeInstancingSupport(canvas?: HTMLCanvasElement): boolean {
    try {
        canvas = canvas ?? document.createElement('canvas');
        const gl = canvas.getContext('webgl');

        if (!gl) {
            return false;
        }
        const ext = gl.getExtension('ANGLE_instanced_arrays');

        return !!ext;
    } catch (e) {
        return false;
    }
}

export function computeVertexTextureUnits(canvas?: HTMLCanvasElement): number {
    try {
        canvas = canvas ?? document.createElement('canvas');
        const gl = canvas.getContext('webgl');

        if (!gl) {
            return -1;
        }

        const numUnits = gl.getParameter(gl.MAX_VERTEX_TEXTURE_IMAGE_UNITS) as number;

        return numUnits;
    } catch (e) {
        return -1;
    }
}

export function computeSupportsCreateImageBitmap(): boolean {
    // We add an explict check to disallow ie11 ('rv:11.0')
    return (
        typeof createImageBitmap !== 'undefined' &&
        typeof navigator !== 'undefined' &&
        typeof navigator.userAgent === 'string' &&
        navigator.userAgent.indexOf('rv:11.0') === -1
    );
}

export function getWebWorkerSupport(): boolean {
    return typeof Worker !== 'undefined';
}

export function computeAmplitudeAnalytics(): Record<string, unknown> {
    const canvas = document.createElement('canvas');
    const deviceMemory = getDeviceMemory();

    return {
        webGLSupported: computeWebGLSupport(canvas),
        deviceSupportsWebWorkers: getWebWorkerSupport(),
        deviceCPUCount: getCoreCount(),
        deviceSupportsGpuInstancing: computeInstancingSupport(canvas),
        deviceVertexTextureUnits: computeVertexTextureUnits(canvas),
        deviceScreenHeight: window.screen.height,
        deviceScreenWidth: window.screen.width,
        devicePixelRatio: window.devicePixelRatio,
        deviceSupportsCreateImageBitmap: computeSupportsCreateImageBitmap(),
        deviceMemory: deviceMemory === undefined ? -1 : deviceMemory,
    };
}
