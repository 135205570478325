import { Mesh, Texture } from 'pixi.js';

import { PositionType } from '../../defs/types';
import { UberEffecs, UberMaterial } from '../materials/UberMaterial';
import { quadShape } from '../shapes/QuadShape';

/*
    pixi: performance Sprite
*/
export class MaterialSprite extends Mesh<UberMaterial> {
    // properties
    //-------------------------------------------------------------------------
    public get anchor(): PositionType {
        const texture = this.material.texture;
        return { x: this.pivot.x / texture.width, y: this.pivot.y / texture.height };
    }

    public set anchor(anchor: PositionType) {
        const texture = this.material.texture;
        this.pivot.set(anchor.x * texture.width, anchor.y * texture.height);
    }

    // init
    //-------------------------------------------------------------------------
    constructor(texture: Texture, effects: UberEffecs = {}) {
        super(quadShape, new UberMaterial(texture, effects));
    }

    // impl
    //-----------------------------------------------------------------------------
    protected override _calculateBounds(): void {
        const bounds = this._bounds;
        const texture = this.material.texture;
        bounds.minX = bounds.minY = 0;
        bounds.maxX = texture.width;
        bounds.maxY = texture.height;
        //TODO: this is proper impl, but pixiSolveQuadVertices needs anchor (see pixijs impl for Sprite). test against offset looking scrollyfloor in battle
        // const texture = this.material.texture;
        // const vertices = pixiSolveQuadVertices(this, texture.baseTexture.width, texture.baseTexture.height);
        // this._bounds.addQuad(vertices);
    }
}
