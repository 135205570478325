import type { Component, Entity2D } from '@play-co/odie';

import { ChameleonBlockProps, ColorId, CountBlockOptionDef } from '../defs/block';
import { ChameleonBlockView, chameleonColors } from '../views/blocks/ChameleonBlockView';

// types
//-----------------------------------------------------------------------------
export type ChameleonBlockComponentOptions = {
    props: ChameleonBlockProps;
    option?: number;
};

/*
    chameleon block component
*/
export class ChameleonBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockChameleon';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: ChameleonBlockView;

    // properties
    //-------------------------------------------------------------------------
    public get activeColor(): ColorId {
        return chameleonColors[this.view.colorIndex].color;
    }

    // impl
    //-------------------------------------------------------------------------
    public init(options: ChameleonBlockComponentOptions): void {
        // get option def
        const optionDef = options.props.options[options.option || 0] as CountBlockOptionDef;

        // create chameleon block view
        this.view = new ChameleonBlockView(options.props);

        // set initial color where count is used as an index
        this.view.colorIndex = optionDef.count;
    }

    // api
    //-------------------------------------------------------------------------
    public rotateColor() {
        this.view.colorIndex = (this.view.colorIndex + 1) % chameleonColors.length;
    }
}
