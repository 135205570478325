import { MeshGeometry } from 'pixi.js';

/*
    performance quad shape
*/
class QuadShape extends MeshGeometry {
    // init
    //-------------------------------------------------------------------------
    constructor() {
        super(
            new Float32Array([0, 0, 1, 0, 1, 1, 0, 1]),
            new Float32Array([0, 0, 1, 0, 1, 1, 0, 1]),
            new Uint32Array([0, 1, 2, 0, 2, 3]),
        );
    }
}

// singleton
//-----------------------------------------------------------------------------
export const quadShape = new QuadShape();
