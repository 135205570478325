import { action } from '@play-co/replicant';

import bakery from '../defs/bakery';
import { MutableState, State, SyncActionAPI } from '../defs/replicant';

// actions
//-----------------------------------------------------------------------------
export const tutorialActions = {
    tutorialSetStep: action((state: MutableState, options: { step: number }) => {
        state.tutorial.step = options.step;
    }),

    tutorialComplete: action((state: MutableState, _, api: SyncActionAPI) => {
        state.tutorial.complete = true;
    }),

    tutorialReset: action((state: MutableState, _, api: SyncActionAPI) => {
        state.tutorial.complete = false;
        state.tutorial.step = 0;
    }),

    tutorialOven: action((state: MutableState, args: { ovenTimestamp: number }) => {
        if (state.tutorial.complete) return;
        // add apple to production state
        const steps = bakery.cakeRecipeMap[bakery.tutorialFinishCake];
        // override free produce cake 'startTimestamp' with timestamp from tutorial
        state.bakery.produce[bakery.tutorialFinishCake] = {
            customerEntryId: bakery.tutorialCustomerId, // grandma tutorial id
            step: steps.length - 1,
            startTimestamp: args.ovenTimestamp, // dont use action timestamp becuase the player might've had the popup open for a while
            tapped: true,
        };
    }),

    // mark a tip as complete
    tutorialTipComplete: action((state: MutableState, options: { id: string }) => {
        state.tips[options.id] = true;
    }),

    // mark puzzle tutorial complete
    tutorialPuzzleComplete: action((state: MutableState, _, api: SyncActionAPI) => {
        state.tutorial.puzzle = true;
    }),
};

// api
//-----------------------------------------------------------------------------
export function isFullTutorialComplete(state: State | MutableState): boolean {
    return state.tutorial.complete;
}
