import { Sprite, Texture } from 'pixi.js';

import { uiAlignCenterX } from '../../../lib/pixi/uiTools';
import { BasicText } from '../../lib/ui/text/BasicText';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    panel: 'puzzle.score.panel.png',
};

/*
    puzzle moves panel
*/
export class PuzzleMovesPanel extends Sprite {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _moves: BasicText;

    // properties
    //-------------------------------------------------------------------------
    public set moves(value: number) {
        this._moves.text = value.toString();
        uiAlignCenterX(this, this._moves, 11);
    }

    // init
    //-------------------------------------------------------------------------
    constructor() {
        // init base
        super(Texture.from(manifest.panel));

        // spawn title text
        this._spawnTitle();

        // spawn moves text
        this._spawnMoves();
    }

    static assets(): string[] {
        return Object.values(manifest);
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawnTitle() {
        // spawn
        const widget = this.addChild(
            new BasicText({
                text: '[puzzleMoves]',
                style: {
                    fill: '#FFF3D5',
                    fontSize: 34,
                    lineJoin: 'round',
                    stroke: '#421D1D',
                    strokeThickness: 4,
                    fontWeight: 'bold',
                    padding: 6,
                    dropShadow: true,
                    dropShadowBlur: 3,
                    dropShadowAlpha: 0.35,
                    dropShadowDistance: 3,
                    dropShadowAngle: Math.PI / 2,
                },
            }),
        );
        uiAlignCenterX(this, widget, 8);
        widget.y = 12;
    }

    private _spawnMoves() {
        // create
        const widget = (this._moves = this.addChild(
            new BasicText({
                style: {
                    fill: '#E14242',
                    fontSize: 74,
                    lineJoin: 'round',
                    fontWeight: 'bold',
                    padding: 10,
                },
                text: '',
            }),
        ));
        widget.y = 82;
    }
}
