import type { Component, Entity2D } from '@play-co/odie';

import { PrisonBlockProps } from '../defs/block';
import { PrisonBlockView } from '../views/blocks/PrisonBlockView';

// types
//-----------------------------------------------------------------------------
export type PrisonBlockComponentOptions = {
    props: PrisonBlockProps;
};

/*
    prison block component
*/
export class PrisonBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockPrison';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: PrisonBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: PrisonBlockComponentOptions): void {
        // create view
        this.view = new PrisonBlockView(options.props);
    }
}
