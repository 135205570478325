import app from '../../../../index.entry';
import { TipId } from '../../../defs/tips';
import { BlockId } from '../../match2-odie/defs/block';
import { BlockEntity } from '../../match2-odie/entities/BlockEntity';
import { blockIterateAll } from '../../match2-odie/util/blockTools';
import { PuzzleScreen } from '../PuzzleScreen';

// constants
//-----------------------------------------------------------------------------
const blockToTip: { [key in BlockId]?: TipId } = {
    android: 'puzzleAndroid',
    animal: 'puzzleAnimal',
    cage: 'puzzleCage',
    candle: 'puzzleCandle',
    chain: 'puzzleChain',
    chameleon: 'puzzleChameleon',
    dog: 'puzzleDog',
    doorAll: 'puzzleDoor',
    doorBlue: 'puzzleColorDoor',
    doorGreen: 'puzzleColorDoor',
    doorPurple: 'puzzleColorDoor',
    doorRed: 'puzzleColorDoor',
    doorYellow: 'puzzleColorDoor',
    firebox: 'puzzleFirebox',
    fugu: 'puzzleFugu',
    //hatAll: 'puzzleHat',
    hatBlue: 'puzzleColorHat',
    hatGreen: 'puzzleColorHat',
    hatPurple: 'puzzleColorHat',
    hatRed: 'puzzleColorHat',
    hatYellow: 'puzzleColorHat',
    hazard: 'puzzleHazard',
    ice: 'puzzleIce',
    kappa: 'puzzleKappa',
    kettle: 'puzzleKettle',
    lock: 'puzzleLock',
    pantherLarge: 'puzzlePanther',
    pantherSmall: 'puzzlePanther',
    pipe: 'puzzlePipe',
    prison: 'puzzlePrison',
    siren: 'puzzleSiren',
    skull: 'puzzleSkull',
    slime: 'puzzleSlime',
    statue: 'puzzleStatue',
    thrower: 'puzzleThrower',
    wallAll: 'puzzleWall',
    wallBlue: 'puzzleWall',
    wallGreen: 'puzzleWall',
    wallPurple: 'puzzleWall',
    wallRed: 'puzzleWall',
    wallYellow: 'puzzleWall',
};

/*
    puzzle tip helper
*/
export class PuzzleTipHelper {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _screen: PuzzleScreen;

    // init
    //-------------------------------------------------------------------------
    constructor(screen: PuzzleScreen) {
        this._screen = screen;
    }

    // api
    //-------------------------------------------------------------------------
    public async tipBlock() {
        // find a tippable block
        const found = this._findTippableBlock();

        // if found, show tip
        if (found) await app.tip.show(found.tipId, { target: found.entity.view.getBounds() });
    }

    // private: support
    //-------------------------------------------------------------------------
    private _findTippableBlock():
        | {
              tipId: TipId;
              entity: BlockEntity;
          }
        | undefined {
        let found;

        // iterate blocks
        blockIterateAll(this._screen.scene, (base, overlay) => {
            // prioritize overlay entity
            const entity = overlay || base;

            // get tip id for block
            const tipId = blockToTip[entity.c.block.blockId];

            // show tip if available and not yet shown
            if (tipId && !app.tip.isShown(tipId)) {
                found = { tipId, entity };
                return true;
            }

            return false;
        });

        return found;
    }
}
