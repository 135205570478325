import { Layout } from '@pixi/layout';
import { Container } from 'pixi.js';

import app from '../../../index.entry';
import { SizeType } from '../../../lib/defs/types';
import { IScreen } from '../../../plugins/nav/IScreen';
import { sleep } from '../../../replicant/util/jsTools';
import { pixiConfig } from '../../defs/config';

const ORIENTATION_CHANGE = 'orientationchange';

/*
    base layout screen. normalizes screen size.
*/
export class LayoutScreen2 implements IScreen {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _root: Container;
    private _base: Layout;

    // properties
    //-------------------------------------------------------------------------
    public get root(): Container {
        return this._root;
    }

    public get base(): Layout {
        return this._base;
    }

    // init
    //-------------------------------------------------------------------------
    constructor() {
        // root
        this._root = new Container();

        // base
        this._base = this._root.addChild(
            new Layout({
                id: 'root',
                styles: {
                    width: '100%',
                    height: '100%',
                },
            }),
        );
    }

    // api
    //-------------------------------------------------------------------------
    protected empty() {
        // remove all children
        this.base.removeChildren();

        // FIXME workaround to remove all children
        for (const id of this.base.content.children.keys()) {
            this.base.removeChildByID(id);
        }
    }

    protected async addOrientationListener(): Promise<void> {
        // Listen for orientation changes
        window.addEventListener(
            ORIENTATION_CHANGE,
            async () => {
                await sleep(0.4); // let the device have time to adjust to the new orientation
                this.forcedResize();
            },
            false,
        );
    }

    // impl
    //-------------------------------------------------------------------------
    public resized(size: SizeType): void {
        const appSize = pixiConfig.size;

        // scale to fit minimum app height
        if (size.height < appSize.height) {
            this._root.scale.set(size.height / appSize.height);
            size.width = Math.round((size.width * appSize.height) / size.height);
            size.height = appSize.height;
        } else {
            this._root.scale.set(1);
        }

        // layout resize
        this._base.resize(size.width, size.height);
    }

    public forcedResize(): void {
        const size = {
            width: app.stage.canvas.width,
            height: app.stage.canvas.height,
        };
        this.resized(size);
    }
}
