import { numberContain } from '../../replicant/util/mathTools';
import { HSL } from './HSL';

/*
    common color abstraction
*/
export class Color {
    // fields
    //-------------------------------------------------------------------------
    private _red: number;
    private _green: number;
    private _blue: number;

    // properties
    //-------------------------------------------------------------------------
    public get rgb(): number {
        return (this._red << 16) | (this._green << 8) | this._blue;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(red = 0, green = 0, blue = 0) {
        this._red = red;
        this._green = green;
        this._blue = blue;
    }

    // api
    //-------------------------------------------------------------------------
    public toHsl(): HSL {
        const red = this._red / 255;
        const green = this._green / 255;
        const blue = this._blue / 255;
        const min = Math.min(Math.min(red, green), blue);
        const max = Math.max(Math.max(red, green), blue);
        const sum = max + min;
        const diff = max - min;
        let hue = 0;
        let saturation = 0;

        // luminance
        const luminance = sum / 2.0;

        // if not grayscale
        if (diff > 0) {
            // saturation
            saturation = diff / (luminance < 0.5 ? sum : 2 - sum);

            // hue
            const diffR = ((max - red) / 6 + diff / 2) / diff;
            const diffG = ((max - green) / 6 + diff / 2) / diff;
            const diffB = ((max - blue) / 6 + diff / 2) / diff;
            if (red === max) hue = diffB - diffG;
            else if (green === max) hue = 1 / 3 + diffR - diffB;
            else if (blue === max) hue = 2 / 3 + diffG - diffR;
            hue = numberContain(hue, 1);
        }

        return new HSL(hue, saturation, luminance);
    }

    public toArray(): Float32Array {
        return new Float32Array([this._red / 255, this._green / 255, this._blue / 255]);
    }

    // factory
    //-------------------------------------------------------------------------
    static from(rgb: number): Color {
        const red = (rgb >> 16) & 0xff;
        const green = (rgb >> 8) & 0xff;
        const blue = rgb & 0xff;
        return new Color(red, green, blue);
    }
}
