import type { PluginOptions } from '@play-co/astro';
import { Plugin } from '@play-co/astro';

import type { App } from '../App';
//import { AirTableStore } from './tableStore/AirTableStore';
import { ITableStore } from './tableStore/ITableStore';
import { LocalTableStore } from './tableStore/LocalTableStore';

// types
//-----------------------------------------------------------------------------
export type TableStoreServiceOptions = PluginOptions;

/*
    table store service. using airtable (for now)
*/
export class TableStoreService extends Plugin {
    // fields
    //-------------------------------------------------------------------------
    private _store: ITableStore;

    // properties
    //-------------------------------------------------------------------------
    public get store(): ITableStore {
        return this._store;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(app: App, options: Partial<TableStoreServiceOptions>) {
        super(app, options);
    }

    public async init() {
        // select table store implementation
        //this._store = process.env.IS_DEVELOPMENT ? new AirTableStore() : new LocalTableStore();
        this._store = new LocalTableStore();

        // init store
        await this._store.init();
    }
}
