import { BlockType } from './block';

// types
//-----------------------------------------------------------------------------
export type SymbolId = 'bomb' | 'club' | 'cube' | 'diamond' | 'heart' | 'joker' | 'rocket' | 'spade';

type SymbolProps = {
    asset: string;
    type: BlockType;
};

// maps
//-----------------------------------------------------------------------------
export const symbolMap: Record<SymbolId, SymbolProps> = {
    bomb: {
        asset: 'symbol.bomb.png',
        type: 'bomb',
    },
    club: {
        asset: 'symbol.club.png',
        type: 'basic',
    },
    cube: {
        asset: 'symbol.cube.png',
        type: 'cube',
    },
    diamond: {
        asset: 'symbol.diamond.png',
        type: 'basic',
    },
    heart: {
        asset: 'symbol.heart.png',
        type: 'basic',
    },
    joker: {
        asset: 'symbol.joker.png',
        type: 'basic',
    },
    rocket: {
        asset: 'symbol.rocket.png',
        type: 'rocket',
    },
    spade: {
        asset: 'symbol.spade.png',
        type: 'basic',
    },
};
