import { angleContain } from '../../../../replicant/util/mathTools';
import { pixiPointToVector } from '../../pixiTools';
import { Particle } from '../Particle';
import { IBehavior } from './IBehavior';

// types
//-----------------------------------------------------------------------------
export type KinematicBehaviorOptions = {
    type: 'kinematic';
};

/*
    kinematic motion
*/
export class KinematicBehavior implements IBehavior {
    // fields
    //-------------------------------------------------------------------------
    private _particle: Particle;

    // init
    //-------------------------------------------------------------------------
    constructor(particle: Particle, options: KinematicBehaviorOptions) {
        this._particle = particle;
    }

    // impl
    //-------------------------------------------------------------------------
    public step(dt: number) {
        const particle = this._particle;
        const position = pixiPointToVector(particle.position);

        // p1 = p0 + v0 * dt;
        position.add(particle.velocity.clone().multiplyScalar(dt));

        // r1 = r0 + v0 * dt;
        particle.rotation = angleContain(particle.rotation + particle.angularVelocity + dt);

        // commit
        particle.position.set(position.x, position.y);
    }
}
