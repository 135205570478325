import { action } from '@play-co/replicant';

import { MutableState, SyncActionAPI } from '../defs/replicant';

// actions
//-----------------------------------------------------------------------------
export const settingsActions = {
    // set name
    settingsSetLanguage: action((state: MutableState, options: { language: string }, api: SyncActionAPI) => {
        // update language
        state.language = options.language;
    }),
    // set bgm
    settingsSetBGM: action((state: MutableState, options: { bgm: boolean }, api: SyncActionAPI) => {
        state.bgm = options.bgm;
    }),
    // set sfx
    settingsSetSFX: action((state: MutableState, options: { sfx: boolean }, api: SyncActionAPI) => {
        state.sfx = options.sfx;
    }),
};
