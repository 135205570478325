import type { EntityType, Vector2 } from '@play-co/odie';
import { createEntity, DefineEntity, Entity2D } from '@play-co/odie';
import { Container } from 'pixi.js';

import { PositionType, SizeType } from '../../../../lib/defs/types';
import { OverlayComponent } from '../components/OverlayComponent';
import { PositionComponent } from '../components/PositionComponent';
import type { GameScene } from '../GameScene';

// types
//-----------------------------------------------------------------------------
const OverlayEntityDef = DefineEntity(Entity2D, OverlayComponent, PositionComponent);

export type OverlayEntity = EntityType<typeof OverlayEntityDef>;

// api
//-----------------------------------------------------------------------------
export function spawnOverlayEntity(
    scene: GameScene,
    position: PositionType,
    size: SizeType,
    spawn: () => Container,
): OverlayEntity {
    const container = new Container();

    // create entity
    const entity = createEntity(OverlayEntityDef, {
        view2d: { view: container, layer: 'front' },
        overlay: { spawn, size },
        position: { position: position as Vector2 },
    });

    // build scene
    container.addChild(entity.c.overlay.view);
    scene.addToScene(entity);

    return entity;
}

export function despawnOverlayEntity(scene: GameScene, entity: OverlayEntity) {
    // remove from scene
    scene.removeFromScene(entity);
}
