import { computedProperty, SB, searchableComputedProperty, teaHash } from '@play-co/replicant';

import { createComputedProperties } from './util/createComputedProperties';
import { encodePacket } from './util/encoder';

// no indexing yet
export const computedProperties = createComputedProperties({
    maskedUID: searchableComputedProperty(SB.string(), (state) => encodePacket(state.id)),
    updatedAt: searchableComputedProperty(SB.int(), (state) => {
        // Round down to latest passed hour to prevent updating index on each replication, and
        // offset with hashed user ID to prevent hourly indexing spikes:
        const offset = Math.floor(teaHash(state.id) * 1000 * 60 * 60);

        return new Date(state.updatedAt - offset).setMinutes(0, 0, 0);
    }),
    // non searchable for admin tool
    createdAt: computedProperty(SB.number(), (state) => state.createdAt),
});
