import { BaseTexture, Container, Rectangle, Sprite, Texture, TilingSprite } from 'pixi.js';

// fields
//-------------------------------------------------------------------------
export type TileSlicePlaneOptions = {
    texture: Texture;
    width: number;
    height: number;
    left?: number;
    top?: number;
    right?: number;
    bottom?: number;
};

/*
    like NineSlicePlane by tiles instead of streches
    TODO: may be improved to use a single mesh
*/
export class TileSlicePlane extends Container {
    // fields
    //-------------------------------------------------------------------------
    // scene

    // init
    //-------------------------------------------------------------------------
    constructor(options: TileSlicePlaneOptions) {
        super();

        const texture = options.texture.baseTexture;
        const tWidth = texture.realWidth;
        const tHeight = texture.realHeight;
        const width = options.width;
        const height = options.height;
        const left = options.left || 0;
        const right = options.right || 0;
        const top = options.top || 0;
        const bottom = options.bottom || 0;
        const hPad = right + left;
        const vPad = top + bottom;
        //NOTE: workaround to pixi creating a gap, bad pixi! :[]
        const topHack = top + 1;

        // top: left, right, hcenter
        this._spawnSection(0, 0, 0, 0, 0, 0, left, topHack, texture, false);
        this._spawnSection(width - right, 0, 0, 0, tWidth - right, 0, right, topHack, texture, false);
        this._spawnSection(left, 0, width - hPad, topHack, left, 0, tWidth - hPad, topHack, texture, true);
        // bottom: left, right, hcenter
        this._spawnSection(0, height - bottom, 0, 0, 0, tHeight - bottom, left, bottom, texture, false);
        this._spawnSection(
            width - right,
            height - bottom,
            0,
            0,
            tWidth - right,
            tHeight - bottom,
            right,
            bottom,
            texture,
            false,
        );
        this._spawnSection(
            left,
            height - bottom,
            width - hPad,
            bottom,
            left,
            tHeight - bottom,
            tWidth - hPad,
            bottom,
            texture,
            true,
        );
        // vcenter: left, right, hcenter
        this._spawnSection(0, top, left + 2, height - vPad, 0, top, left, tHeight - vPad, texture, true); //+1 pixi gap hax
        this._spawnSection(
            width - right,
            top,
            right,
            height - vPad,
            tWidth - right,
            top,
            right,
            tHeight - vPad,
            texture,
            true,
        );
        this._spawnSection(
            left,
            top,
            width - hPad,
            height - vPad + 1,
            left,
            top,
            tWidth - hPad,
            tHeight - vPad,
            texture,
            true,
        );
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawnSection(
        x: number,
        y: number,
        tileWidth: number,
        tileHeight: number,
        textureX: number,
        textureY: number,
        textureWidth: number,
        textureHeight: number,
        texture: BaseTexture,
        tiled: boolean,
    ): void {
        // create subtexture
        const subtexture = new Texture(texture, new Rectangle(textureX, textureY, textureWidth, textureHeight));

        // create sprite or tiled sprite
        const sprite = tiled ? new TilingSprite(subtexture, tileWidth, tileHeight) : new Sprite(subtexture);

        // set position
        sprite.position.set(x, y);

        // spawn sprite
        this.addChild(sprite);
    }
}
