import GCinstant from '@play-co/gcinstant';
import { ReplicantError } from '@play-co/replicant';
import * as Sentry from '@sentry/browser';

import { InstantGame } from './../../plugins/instantGames/InstantGame';

// The majority of unhandled FB errors come from the sync postSessionScore.
// Internally it is a promise, but the SDK does not expose it so we can't handle
// them.
const MUTED_ERRORS = [
    // Any FB call can fail when a client loses connection.
    'NETWORK_FAILURE',
    // The three main categories of unknown errors:
    // "could be network failure", no response data, tournaments fail to post to
    // timeline.
    'UNKNOWN',
    // Some clients with old versions of android do not support the tournament
    // dialog Resulting in a CLIENT_UNSUPPORTED_OPERATION with Client does not
    // support the message: showgenericdialogasync
    'CLIENT_UNSUPPORTED_OPERATION',
    // Tournaments can sometimes hang on postSessionScore
    // resulting in the failure of all subsequent requests.
    'PENDING_REQUEST',
    // The tournament API can get rate limited if a client lets the post happen
    // on every building upgrade instead of being fast enough to batch them.
    'RATE_LIMITED',
    // It may be that someone closed the dialog on the tournament
    // screen, which will be reported as unhandled rejection here. We have no
    // access to the promise, and worse have no way to differentiate between this
    // and other unhandled user errors.
    'USER_INPUT',
    // For some reason postSessionScore can lead to a SAME_CONTEXT error.
    'SAME_CONTEXT',
];

export function addSentryContext() {
    Sentry.configureScope((scope) => {
        scope.setUser({
            id: GCinstant.playerID,
            username: GCinstant.playerName,
        });
    });
}

export function captureGenericError(effect: string, cause: Error | null) {
    if (cause instanceof Error) {
        cause.message = `${effect} (${cause.message})`;

        Sentry.captureException(cause);
    } else {
        const exception: any = cause ? Error(`${effect} (non-Error: ${JSON.stringify(cause)})`) : Error(effect);

        exception['framesToPop'] = 1;

        Sentry.captureException(exception);
    }
}

/**
 * Use in experimental situations where we temporarily need strong visibility.
 */
export function captureSdkError(
    err:
        | Error
        | {
              code: string;
              message: string;
          },
) {
    if (err instanceof Error) {
        Sentry.captureException(err);
    } else {
        // Silence user input and rate limit errors
        if (err.code === 'USER_INPUT' || err.code === 'RATE_LIMITED') {
            return;
        }

        // Use an Error with a custom name for nice display in Sentry
        const exception = Error(`${err.code} (${err.message})`) as any;
        exception.name = 'SdkError';
        exception['framesToPop'] = 1;

        // Prevent different error codes from being combined together
        Sentry.withScope((scope) => {
            scope.setFingerprint(['{{ default }}', err.code]);
            Sentry.captureException(exception);
        });
    }
}

export function captureReplicantError(err: ReplicantError) {
    // Use an Error with a custom name for nice display in Sentry
    const exception: any = Error(`${err.code} (${err.message})`);
    exception.name = 'ReplicantError';
    exception['framesToPop'] = 1;

    // Prevent different error codes from being combined together
    Sentry.withScope((scope) => {
        scope.setFingerprint(['{{ default }}', err.code, err.subCode || '']);

        // In production, err.message is the requestId and we can correlate it with
        // the server-side error based on the requestId.
        if (!process.env.IS_DEVELOPMENT) {
            scope.setTag('requestId', err.message);
        }

        Sentry.captureException(exception);
    });
}

if (!process.env.SENTRY_DSN) {
    // Otherwise, we won't be able to see errors locally
    throw Error('process.env.SENTRY_DSN must be set');
}

Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    attachStacktrace: true,

    release:
        process.env.SENTRY_ENVIRONMENT === 'production'
            ? `${process.env.SENTRY_PROJECT}@${process.env.APP_VERSION}`
            : undefined,

    beforeSend: (event, hint) => {
        if (!process.env.SENTRY_ENVIRONMENT) {
            // Locally, log the event to the console for easy inspection
            console.error(hint.originalException || hint.syntheticException);

            // Locally, make sure we discard the event by returning null
            return null;
        }

        // Detect SDK unhandled rejections that we just can't control and don't
        // report them to Sentry.
        if (isUnhandledPromiseRejectionFromSDK(event, hint)) {
            return null;
        }

        // Detect "Trying to invoke <> while state is out of sync" and don't report
        // it to Sentry
        if (isInvokeWhileOutOfSyncPromiseRejection(hint)) {
            return null;
        }

        if (!event.extra) {
            event.extra = {};
        }

        if (InstantGame.replicant) {
            event.extra.replicant = InstantGame.replicant.state;
        }

        // Sentry requires we return the event to them in order to send
        return event;
    },
});

function isInvokeWhileOutOfSyncPromiseRejection(hint: Sentry.EventHint) {
    const originalException: any = hint.originalException;
    if (
        originalException?.code === 'replication_error' &&
        originalException?.subCode === 'invoking_while_out_of_sync'
    ) {
        return true;
    }

    return false;
}

function isUnhandledPromiseRejectionFromSDK(event: Sentry.Event, hint: Sentry.EventHint) {
    const originalException: any = hint.originalException;
    const code = originalException?.['code'];
    if (code && MUTED_ERRORS.includes(code)) {
        return true;
    }

    // Alternatively, we've errors for users closing an LINE dialog,
    // but the error code is not USER_INPUT.
    if (originalException?.['message'] === 'User close dialog') {
        return true;
    }

    return false;
}
