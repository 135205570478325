import { action, SB } from '@play-co/replicant';

import { getCreativeText } from '../chatbot/chatbotTexts';
import { chatbotMessageTemplates, generateChatbotPayload, RecipeStepAssetKey } from '../chatbot/messageTemplates';
import bakery, { ProduceEntry } from '../defs/bakery';
import { CakeItemId } from '../defs/items';
import player from '../defs/player';
import { MutableState, ScheduledActionAPI, State, SyncActionAPI } from '../defs/replicant';
import { LanguageId } from '../defs/settings';
import { timeFromComponents } from '../util/timeTools';

// export type CakeStatus = { step: number; startTimestamp: number };
export type CakeStatusMap = Record<CakeItemId, ProduceEntry>;

// actions
//-----------------------------------------------------------------------------
export const bakeryActions = {
    // consume customer entry and add new cake to production
    consumeCustomer: action((state: MutableState, _, api: SyncActionAPI) => {
        const entryIndex = state.bakery.newCustomers.shift();
        const customer = bakery.customers[entryIndex];

        // TODO ADD NEW CAKE TO COLLECTION ONCE PRODUCTION IS FINSIHED
        // add new cake to collection if not already there
        // if (!state.bakery.cakes[customer.cakeId]) {
        //     state.bakery.cakes[customer.cakeId] = {};
        // }

        state.bakery.produce[customer.cakeId] = {
            customerEntryId: entryIndex, // skip this if we want to bake without customer
            step: 0,
            startTimestamp: 0,
            tapped: false, // to render cake recipe glow
        };
    }),

    // forcedTimedTimestamp used to mock UI with timer before the full scripted sequence is finished
    updateBakeStep: action(
        (state: MutableState, args: { cakeId: CakeItemId; forcedTimedTimestamp?: number }, api: SyncActionAPI) => {
            const { cakeId, forcedTimedTimestamp } = args;
            const isFinished = api.date.now() >= getStepFinishTime(cakeId, state.bakery.produce[cakeId]);

            // if its fininshed then no stars required since it was consumed when adding the timestamp
            const starsRequired = bakery.cakeRecipeMap[cakeId][state.bakery.produce[cakeId].step].starCost;
            if (state.stars < starsRequired && !isFinished) {
                throw new Error('not enough stars to continue recipe step');
            }

            const totalStep = bakery.cakeRecipeMap[cakeId].length;
            const currentStep = state.bakery.produce[cakeId].step;
            // is instant or time based
            const { timedStep } = bakery.cakeRecipeMap[cakeId][currentStep];

            // lastActionRecipe
            // ------- local helper to step --
            const stepRecipe = () => {
                state.bakery.produce[cakeId].step++;
                state.bakery.produce[cakeId].startTimestamp = 0;
                if (state.bakery.produce[cakeId].step >= totalStep) {
                    // final step, add cake to collection and remove from production
                    if (!state.bakery.cakes[cakeId]) {
                        state.bakery.cakes[cakeId] = {};
                    }
                    delete state.bakery.produce[cakeId];
                }
            };
            // -------------------------------

            // mutate state
            if (timedStep) {
                if (state.bakery.produce[cakeId].startTimestamp > 0) {
                    if (isFinished) {
                        stepRecipe();
                    } else {
                        // client side should not allow this to happen, cheater tries to progress step before it's finished
                        throw new Error(`Invalid timedStep, not finished yet`);
                    }
                } else {
                    // trigger time start and consume stars
                    state.bakery.produce[cakeId].startTimestamp = forcedTimedTimestamp ?? api.date.now();
                    state.stars -= starsRequired;
                }
            } else {
                stepRecipe();
                state.stars -= starsRequired;
            }

            // store last action so we can re-render if they quit session
            const updatedStep = state.bakery.produce[cakeId]?.step ?? -1; // if it does not exist just step negative and not store it
            if (updatedStep > currentStep) {
                state.bakery.lastActionRecipe = { cakeId };
            } else {
                // remove
                state.bakery.lastActionRecipe.cakeId = '';
            }
        },
    ),
    updateTapped: action((state: MutableState, args: { cakeId: CakeItemId }, api: SyncActionAPI) => {
        const { cakeId } = args;
        if (state.bakery.produce[cakeId]) {
            state.bakery.produce[cakeId].tapped = true;
        }
    }),
};

//-----------------------------------------------------------------------------
// scheduledActions
//-----------------------------------------------------------------------------
export const bakeryScheduledActionsSchema = {
    recipeStep: SB.object({ id: SB.string() }),
};

export const bakeryScheduledActions = {
    recipeStep: async (state: MutableState, options: { id: RecipeStepAssetKey }, api: ScheduledActionAPI) => {
        sendRecipeStepOA(state, api, options.id);
    },
};
// api
//-----------------------------------------------------------------------------

// events
//-----------------------------------------------------------------------------
export function onBakeryInit(api: SyncActionAPI, state: MutableState) {
    // unschedule ANY recipe step messages
    for (const cake of Object.keys(bakery.cakeRecipeMap) as CakeItemId[]) {
        bakery.cakeRecipeMap[cake].forEach((step) => {
            if (step.timedStep?.chatbotIds) {
                const { first, second, third } = step.timedStep.chatbotIds;
                api.scheduledActions.unschedule(first);
                api.scheduledActions.unschedule(second);
                api.scheduledActions.unschedule(third);
            }
        });
    }
}

export function onBakeryExit(api: ScheduledActionAPI, state: State, token?: string) {
    // pending steps not including appleTart which does not containm chatbotIds property because its sent using firstSession logic
    const pendingStepsCakes = Object.keys(state.bakery.produce).filter(
        (key) => state.bakery.produce[key].startTimestamp > 0 && key !== 'appleTart',
    );

    for (const cakeId of pendingStepsCakes) {
        const cakeStatus = state.bakery.produce[cakeId];
        const timedStep = bakery.cakeRecipeMap[cakeId as CakeItemId][cakeStatus.step].timedStep;
        const finishTime = getStepFinishTime(cakeId as CakeItemId, state.bakery.produce[cakeId]);
        const bufferTime = timeFromComponents({ seconds: 40 });
        // if not finished, schedule 1 extra message, + extra buffer time to cover edge cases with very small delay
        const originalDelay = finishTime - api.date.now();
        const firstDelay = Math.max(0, originalDelay < 0 ? originalDelay + bufferTime : originalDelay);
        if (api.date.now() < finishTime + bufferTime) {
            api.scheduledActions.schedule.recipeStep({
                args: { id: timedStep.chatbotIds.first },
                notificationId: timedStep.chatbotIds.first,
                delayInMS: firstDelay,
            });
        }

        // can be finished or timer still ticking. if finished send only the 2 reminder messages
        api.scheduledActions.schedule.recipeStep({
            args: { id: timedStep.chatbotIds.second },
            notificationId: timedStep.chatbotIds.second,
            delayInMS: firstDelay + timeFromComponents({ hours: 4 }), // first message delay (or 0) and second (4h) for total delay
        });

        api.scheduledActions.schedule.recipeStep({
            args: { id: timedStep.chatbotIds.third },
            notificationId: timedStep.chatbotIds.third,
            delayInMS: firstDelay + timeFromComponents({ hours: 12 }), // first message delay (or 0) and second (4h) and third 8h for total delay
        });
    }
}

export function sendRecipeStepOA(state: MutableState, api: ScheduledActionAPI | SyncActionAPI, id: RecipeStepAssetKey) {
    const lang = state.language as LanguageId;
    // chatbot text and asset key shares id
    const creativeText = getCreativeText(lang, id, api.math.random);
    // Default aspectRatio 1:1
    const aspectRatio = '3:2';

    const isEn = lang === 'en';
    const preFilledName = isEn ? player.defaultNameEN : player.defaultNameJA;

    api.chatbot.sendMessage(
        state.id,
        chatbotMessageTemplates.flexBubbleMessage({
            args: {
                imageKey: id,
                aspectRatio,
                text: creativeText.text,
                cta: creativeText.cta,
                senderName: state.name ? state.name : preFilledName,
            },
            payload: {
                ...generateChatbotPayload({ feature: 'recipe_step', api }),
                $creativeAssetID: id,
            },
        }),
    );
}

export function parseCakes(state: State): CakeStatusMap {
    const cakes = {} as CakeStatusMap;
    Object.keys(state.bakery.cakes).forEach((cakeId: CakeItemId) => {
        cakes[cakeId] = {
            // -1 done, ignore
            step: -1,
            startTimestamp: -1,
            tapped: true,
        };
    });

    Object.keys(state.bakery.produce).forEach((cakeId: CakeItemId) => {
        cakes[cakeId] = state.bakery.produce[cakeId];
    });

    return cakes;
}

export function getCakeUnlockLevels(state: State): Record<CakeItemId, number> {
    const unlockLevels = {} as Record<CakeItemId, number>;
    const unlocks = getCustomerUnlocks(state);
    for (const puzzleLevel of Object.keys(unlocks)) {
        const parsedLevel = Number(puzzleLevel);
        // get cake id
        const cakeId = bakery.customers[unlocks[parsedLevel]].cakeId;
        unlockLevels[cakeId] = parsedLevel;
    }

    return unlockLevels;
}

export function getStepFinishTime(cakeId: CakeItemId, cakeStatus: ProduceEntry) {
    if (!cakeStatus) return 0;
    if (cakeStatus.startTimestamp === -1) return 0;
    const duration = bakery.cakeRecipeMap[cakeId][cakeStatus.step]?.timedStep?.time ?? 0;
    return cakeStatus.startTimestamp + duration;
}

export function getCustomerUnlocks(state: State) {
    return bakery.puzzleCustomerUnlocks;
}
