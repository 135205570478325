import app from '../../../../../index.entry';
import { CollideEvent, CollideId } from '../../defs/collide';
import type { PipeBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { mapGetPan } from '../../util/mapTools';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

/*
    pipe block collision resolver
*/
export class PipeCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: PipeBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        block: this._resolveBlock,
        attack: this._resolveAttack,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: PipeBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        // invalid effect
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveBlock(event: CollideEvent) {
        // trigger pipe
        this._trigger();
        return true;
    }

    private async _resolveAttack(event: CollideEvent) {
        // trigger pipe
        this._trigger();
        return true;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _trigger() {
        //TODO: effect

        // play sound
        app.sound.play('puzzle-pipe-poke.mp3', { dupes: 2, pan: mapGetPan(this._entity.c.position.mapPosition) });

        // trigger goal
        this._scene.sessionSystem.triggerGoal('pipe', this._entity);
    }
}
