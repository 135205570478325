import { Sprite, Texture } from 'pixi.js';

import { KettleBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    image: 'block.kettle.png',
};

/*
    kettle block view
*/
export class KettleBlockView extends Sprite {
    // init
    //-------------------------------------------------------------------------
    constructor(props: KettleBlockProps) {
        // spawn
        super(Texture.from(manifest.image));

        // position
        blockPositionView(this, props);
    }

    static assets(): string[] {
        return [manifest.image];
    }
}
