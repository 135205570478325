import type { QueriesObject, QueryResults, System } from '@play-co/odie';

import { BasicBlockComponent } from '../components/BasicBlockComponent';
import { BlockComponent } from '../components/BlockComponent';
import { WallBlockComponent } from '../components/WallBlockComponent';
import { BasicBlockProps, WallBlockProps } from '../defs/block';
import { BasicBlockEntity, WallBlockEntity } from '../entities/BlockEntity';
import type { GameScene } from '../GameScene';

/*
    handles wall block specifics
*/
export class WallBlockSystem implements System {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'blockWall';
    public static Queries: QueriesObject = {
        wall: {
            components: [BlockComponent, WallBlockComponent],
        },
        block: {
            components: [BlockComponent, BasicBlockComponent],
            removed: true,
        },
    };

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    public queries!: QueryResults;

    // impl
    //-------------------------------------------------------------------------
    public removedFromQuery(entity: BasicBlockEntity) {
        // handle basic block destroyed
        this._onBasicBlockDestroyed(entity);
    }

    // private: events
    //-------------------------------------------------------------------------
    private _onBasicBlockDestroyed(entity: BasicBlockEntity) {
        const destroyColor = (entity.c.block.props as BasicBlockProps).color;

        // for each wall
        this.queries.wall.forEach((wall: WallBlockEntity) => {
            const wallColor = (wall.c.block.props as WallBlockProps).color;

            // if destroyed block color matches wall, goal collide wall
            if (wallColor === 'all' || destroyColor === wallColor) wall.c.block.collide('goal');
        });
    }
}
