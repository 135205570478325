import { renderTemplate, renderTemplatesWithAssets, SB } from '@play-co/replicant';

import { getGameUrl } from '../util/envTools';

/* eslint-disable camelcase */
const LINE_REDIRECT_URL = getGameUrl();

export const firstSessionOA = {
    firstSession0: 'private/chatbot/init/first_session_1.png',
    firstSession1: 'private/chatbot/init/first_session_2.png',
    firstSession2: 'private/chatbot/init/first_session_3.png',
    firstSession3: 'private/chatbot/init/first_session_4.png',
};
// excluding apple tart since its used for firstSession messGes
export const recipeOA = {
    mangoMousseOven0: 'private/chatbot/recipes/mangomousse_oven0.png',
    mangoMousseOven1: 'private/chatbot/recipes/mangomousse_oven1.png',
    mangoMousseOven2: 'private/chatbot/recipes/mangomousse_oven2.png',

    mangoMousseFridge0: 'private/chatbot/recipes/mangomousse_fridge0.png',
    mangoMousseFridge1: 'private/chatbot/recipes/mangomousse_fridge1.png',
    mangoMousseFridge2: 'private/chatbot/recipes/mangomousse_fridge2.png',

    frenchFlanPot0: 'private/chatbot/recipes/frenchflan_pot0.png',
    frenchFlanPot1: 'private/chatbot/recipes/frenchflan_pot1.png',
    frenchFlanPot2: 'private/chatbot/recipes/frenchflan_pot2.png',

    frenchFlanOven0: 'private/chatbot/recipes/frenchflan_oven0.png',
    frenchFlanOven1: 'private/chatbot/recipes/frenchflan_oven1.png',
    frenchFlanOven2: 'private/chatbot/recipes/frenchflan_oven2.png',

    frenchFlanFridge0: 'private/chatbot/recipes/frenchflan_fridge0.png',
    frenchFlanFridge1: 'private/chatbot/recipes/frenchflan_fridge1.png',
    frenchFlanFridge2: 'private/chatbot/recipes/frenchflan_fridge2.png',

    kouignAmannFridge0: 'private/chatbot/recipes/kouignamann_fridge0.png',
    kouignAmannFridge1: 'private/chatbot/recipes/kouignamann_fridge1.png',
    kouignAmannFridge2: 'private/chatbot/recipes/kouignamann_fridge2.png',

    kouignAmannOven0: 'private/chatbot/recipes/kouignamann_oven0.png',
    kouignAmannOven1: 'private/chatbot/recipes/kouignamann_oven1.png',
    kouignAmannOven2: 'private/chatbot/recipes/kouignamann_oven2.png',

    chocoGlazedOven0: 'private/chatbot/recipes/chocoglazed_oven0.png',
    chocoGlazedOven1: 'private/chatbot/recipes/chocoglazed_oven1.png',
    chocoGlazedOven2: 'private/chatbot/recipes/chocoglazed_oven2.png',

    chocoGlazedPot0: 'private/chatbot/recipes/chocoglazed_pot0.png',
    chocoGlazedPot1: 'private/chatbot/recipes/chocoglazed_pot1.png',
    chocoGlazedPot2: 'private/chatbot/recipes/chocoglazed_pot2.png',

    chocoGlazedFridge0: 'private/chatbot/recipes/chocoglazed_fridge0.png',
    chocoGlazedFridge1: 'private/chatbot/recipes/chocoglazed_fridge1.png',
    chocoGlazedFridge2: 'private/chatbot/recipes/chocoglazed_fridge2.png',
};

const staticOA = {
    ...firstSessionOA,
    ...recipeOA,
};

export type ReplicantAssetKey = keyof typeof staticOA;
export type FirstSessionAssetKey = keyof typeof firstSessionOA;
export type RecipeStepAssetKey = keyof typeof recipeOA;

const chatbotMessageTemplates = renderTemplatesWithAssets(staticOA, {
    staticOA: renderTemplate({
        args: SB.object({
            imageKey: SB.string() as SB.Schema<ReplicantAssetKey>,
            title: SB.string(),
            cta: SB.string(),
        }),

        renderers: {
            line: ({ api, payload, args }) =>
                lineGenericTemplate({
                    payload,
                    title: args.title,
                    cta: args.cta,
                    imageUrl: api.getAssetPath(args.imageKey),
                }),
        },
    }),
    flexText: renderTemplate({
        args: SB.object({
            text: SB.string(),
            cta: SB.string(),
        }),

        renderers: {
            line: ({ api, payload, args }) =>
                lineTextTemplate({
                    payload,
                    text: args.text,
                    cta: args.cta,
                }),
        },
    }),
    default: renderTemplate({
        args: SB.object({
            imageKey: SB.string(),
            title: SB.string(),
            cta: SB.string(),
        }),

        renderers: {
            line: ({ api, payload, args }) =>
                lineGenericTemplate({
                    payload,
                    title: args.title,
                    cta: args.cta,
                    imageUrl: api.getUserAssetUrl(args.imageKey), // String, not typed
                }),
        },
    }),
    imagePlain: renderTemplate({
        args: SB.object({
            imageKey: SB.string() as SB.Schema<ReplicantAssetKey>,
            previewKey: SB.string() as SB.Schema<ReplicantAssetKey>,
        }),

        renderers: {
            line: ({ api, payload, args }) =>
                lineImageTemplate({
                    imageUrl: api.getAssetPath(args.imageKey),
                    previewUrl: api.getAssetPath(args.previewKey),
                }),
        },
    }),
    textPlain: renderTemplate({
        args: SB.object({
            text: SB.string(),
        }),

        renderers: {
            line: ({ api, payload, args }) =>
                plainTextTemplate({
                    text: args.text,
                }),
        },
    }),
    textLink: renderTemplate({
        args: SB.object({}),
        renderers: {
            line: ({ api, payload, args }) => plainLink({ payload }),
        },
    }),
    flexImage: renderTemplate({
        args: SB.object({
            imageKey: SB.string() as SB.Schema<ReplicantAssetKey>,
            text: SB.string(),
        }),

        renderers: {
            line: ({ api, payload, args }) =>
                lineFlexImageTemplate({
                    payload,
                    text: args.text,
                    imageUrl: api.getAssetPath(args.imageKey),
                }),
        },
    }),
    flexBubbleMessage: renderTemplate({
        args: SB.object({
            imageKey: SB.string() as SB.Schema<ReplicantAssetKey>,
            aspectRatio: SB.string(),
            text: SB.string(),
            title: SB.string().optional(),
            cta: SB.string(),
            senderName: SB.string(),
        }),

        renderers: {
            line: ({ api, payload, args }) =>
                lineBubbleTemplate({
                    payload,
                    text: args.text,
                    title: args?.title,
                    cta: args.cta,
                    imageUrl: api.getAssetPath(args.imageKey),
                    aspectRatio: args.aspectRatio,
                    senderName: args.senderName,
                }),
        },
    }),
});

// https://developers.line.biz/en/reference/messaging-api/#template-messages
function lineGenericTemplate(opts: {
    title: string;
    subtitle?: string;
    imageUrl: string;
    cta: string;
    payload: object;
    lineUserId?: string;
    url?: string;
}) {
    let url = opts.url || LINE_REDIRECT_URL;

    const payload = opts.payload;
    let payloadStr = '';

    if (payload) {
        payloadStr = JSON.stringify(payload);
    }

    if (payloadStr) {
        if (url.indexOf('?') < 0) {
            url += `?payload=${encodeURIComponent(payloadStr)}`;
        } else {
            url += `&payload=${encodeURIComponent(payloadStr)}`;
        }
    }

    return {
        type: 'template',
        altText: opts.title.slice(0, 40),
        template: {
            type: 'buttons',
            thumbnailImageUrl: opts.imageUrl,
            image_aspect_ratio: 'square',
            imageSize: 'cover',
            imageBackgroundColor: '#FFFFFF',
            // Line allows a maximum length of 40 of the title (not used here)
            // Text allows a maximum length of 60
            text: `${opts.title}`.slice(0, 60),
            defaultAction: {
                type: 'uri',
                label: opts.cta,
                uri: url,
            },
            actions: [
                {
                    type: 'uri',
                    label: opts.cta,
                    uri: url,
                },
            ],
        },
    };
}

// https://developers.line.biz/en/reference/messaging-api/#flex-message
function lineTextTemplate(opts: { text: string; cta: string; payload: object; url?: string }) {
    let url = opts.url || LINE_REDIRECT_URL;

    const payload = opts.payload;
    let payloadStr = '';

    if (payload) {
        payloadStr = JSON.stringify(payload);
    }

    if (payloadStr) {
        if (url.indexOf('?') < 0) {
            url += `?payload=${encodeURIComponent(payloadStr)}`;
        } else {
            url += `&payload=${encodeURIComponent(payloadStr)}`;
        }
    }
    return {
        type: 'flex',
        // alttext 400 limit
        altText: opts.text.slice(0, 400),
        contents: {
            type: 'bubble',
            body: {
                type: 'box',
                layout: 'vertical',
                contents: [
                    {
                        type: 'text',
                        text: opts.text,
                        wrap: true,
                        color: '#000000',
                        action: {
                            type: 'uri',
                            label: opts.cta, // Not used
                            uri: url,
                        },
                    },
                    {
                        type: 'button',
                        action: {
                            type: 'uri',
                            label: opts.cta,
                            uri: url,
                        },
                        margin: 'xxl',
                        style: 'secondary', // primary -> white button text
                        color: '#33FF94',
                    },
                ],
            },
        },
    };
}

// https://developers.line.biz/en/reference/messaging-api/#flex-message
function lineFlexImageTemplate(opts: { text: string; payload: object; imageUrl: string }) {
    let url = LINE_REDIRECT_URL;

    const payload = opts.payload;
    let payloadStr = '';

    if (payload) {
        payloadStr = JSON.stringify(payload);
    }

    if (payloadStr) {
        if (url.indexOf('?') < 0) {
            url += `?payload=${encodeURIComponent(payloadStr)}`;
        } else {
            url += `&payload=${encodeURIComponent(payloadStr)}`;
        }
    }
    return {
        type: 'flex',
        // alttext 400 limit
        altText: opts.text.slice(0, 400), // seen in the notification
        contents: {
            type: 'bubble',
            action: {
                type: 'uri',
                uri: url,
            },
            body: {
                type: 'box',
                layout: 'vertical',
                contents: [
                    {
                        type: 'image',
                        url: opts.imageUrl,
                        size: 'full',
                        aspectMode: 'fit',
                        aspectRatio: '1:1',
                        gravity: 'center',
                    },
                ],
                paddingAll: '0px',
            },
        },
    };
}

// image, title, text, button
function lineBubbleTemplate(opts: {
    text: string;
    title?: string;
    cta: string;
    payload: object;
    imageUrl: string;
    aspectRatio: string;
    senderName: string;
}) {
    let url = LINE_REDIRECT_URL;

    const payload = opts.payload;
    let payloadStr = '';

    if (payload) {
        // login token added to url
        payloadStr = JSON.stringify(payload);
    }

    if (payloadStr) {
        if (url.indexOf('?') < 0) {
            url += `?payload=${encodeURIComponent(payloadStr)}`;
        } else {
            url += `&payload=${encodeURIComponent(payloadStr)}`;
        }
    }

    const defaultContent = [
        {
            type: 'box',
            layout: 'vertical',
            margin: 'lg',
            spacing: 'sm',
            contents: [
                {
                    type: 'box',
                    layout: 'baseline',
                    spacing: 'sm',
                    contents: [
                        {
                            type: 'text',
                            text: opts.text,
                            color: '#aaaaaa',
                            size: 'sm',
                            wrap: true,
                        },
                    ],
                },
            ],
        },
    ];

    let textContent;
    if (opts.title) {
        textContent = [
            {
                type: 'text',
                text: opts.title,
                weight: 'bold',
                size: 'xl',
            },
            ...defaultContent,
        ];
    } else {
        // No title
        textContent = defaultContent;
    }

    return {
        sender: {
            // name has a limit of 20 characters, thrown in sentry if exceeded
            name: opts.senderName.substring(0, 20),
        },
        type: 'flex',
        // alttext 400 limit
        altText: opts.text.slice(0, 400), // seen in the notification
        contents: {
            type: 'bubble',
            action: {
                type: 'uri',
                uri: url,
            },
            hero: {
                type: 'image',
                size: 'full',
                aspectRatio: opts.aspectRatio,
                aspectMode: 'cover',
                action: {
                    type: 'uri',
                    uri: url,
                },
                url: opts.imageUrl,
            },
            body: {
                type: 'box',
                layout: 'vertical',
                contents: textContent,
            },
            footer: {
                type: 'box',
                layout: 'vertical',
                spacing: 'sm',
                contents: [
                    {
                        type: 'button',
                        style: 'primary',
                        action: {
                            type: 'uri',
                            label: opts.cta,
                            uri: url,
                        },
                        height: 'sm',
                    },
                ],
                flex: 0,
            },
        },
    };
}

function lineImageTemplate(opts: { imageUrl: string; previewUrl: string }) {
    // Just image, no payload or link for wallpaper promos etc.
    return {
        type: 'image',
        originalContentUrl: opts.imageUrl,
        previewImageUrl: opts.previewUrl,
    };
}

// https://developers.line.biz/en/reference/messaging-api/#text-message
function plainTextTemplate(opts: { text: string }) {
    return {
        type: 'text',
        text: opts.text,
    };
}

function plainLink(opts: { payload: object }) {
    let url = LINE_REDIRECT_URL;

    const payload = opts.payload;
    let payloadStr = '';

    if (payload) {
        // login token added to url
        payloadStr = JSON.stringify(payload);
    }

    if (payloadStr) {
        if (url.indexOf('?') < 0) {
            url += `?payload=${encodeURIComponent(payloadStr)}`;
        } else {
            url += `&payload=${encodeURIComponent(payloadStr)}`;
        }
    }

    return {
        type: 'text',
        text: url,
    };
}

export function generateChatbotPayload(args: { feature: string; api: any; subFeature?: string }) {
    const { feature, api, subFeature } = args;
    return {
        $channel: 'CHATBOT',
        feature,
        $subFeature: subFeature || null,
    };
}

export { chatbotMessageTemplates };
