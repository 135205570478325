import { CollideEvent, CollideId } from '../../defs/collide';
import type { AndroidBlockEntity } from '../../entities/BlockEntity';
import { despawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { BreakEffect } from '../effects/BreakEffect';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

// fields
//-----------------------------------------------------------------------------
//const sounds = ['puzzle-lock-destroy.mp3', 'puzzle-lock-poke.mp3'];

/*
    android block collision resolver
*/
export class AndroidCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: AndroidBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        attack: this._resolveAttack,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: AndroidBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        // invalid effect
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveAttack(event: CollideEvent) {
        const collider = event.collider;

        // attack if bomb or rocket collider
        if (collider?.type === 'bomb' || collider?.type === 'rocket') {
            await this._attack();
            return true;
        }

        return false;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _attack() {
        const entity = this._entity;
        const position = entity.c.position.mapPosition;
        const component = entity.c.blockAndroid;

        // play sound based on component count
        //app.sound.play(sounds[component.count], { pan: mapGetPan(this._entity.c.position.mapPosition) });

        // if count is 0
        if (component.count <= 0) {
            // despawn block
            despawnBlockEntity(this._scene, this._entity);

            // else decrement count
        } else {
            --component.count;
        }

        // execute break effect
        return new BreakEffect(this._scene, {
            position,
            size: entity.c.block.props,
            color: 0x444444,
        }).execute();
    }
}
