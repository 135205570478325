import { PositionType } from '../defs/types';
import { Rotation2 } from './Rotation2';

// types
//-----------------------------------------------------------------------------
export type ManhattanDirection = 'up' | 'down' | 'left' | 'right' | 'none';

/*
    2D vector
*/
export class Vector2 implements PositionType {
    // properties
    //-------------------------------------------------------------------------
    public x: number;
    public y: number;

    // init
    //-------------------------------------------------------------------------
    constructor(x = 0, y = 0) {
        this.x = x;
        this.y = y;
    }

    // conversions
    //-------------------------------------------------------------------------
    static from(xy: PositionType): Vector2 {
        return new Vector2(xy.x, xy.y);
    }

    public clone(): Vector2 {
        return new Vector2(this.x, this.y);
    }

    // equality
    //-------------------------------------------------------------------------
    public equals(other: Vector2): boolean {
        return this.x === other.x && this.y === other.y;
    }

    // operations
    //-------------------------------------------------------------------------
    public set(x: number, y?: number): Vector2 {
        this.x = x;
        if (y === undefined) this.y = x;
        else this.x = x;
        return this;
    }

    public add(other: Vector2): Vector2 {
        this.x += other.x;
        this.y += other.y;
        return this;
    }

    public subtract(other: Vector2): Vector2 {
        this.x -= other.x;
        this.y -= other.y;
        return this;
    }

    public multiply(other: Vector2): Vector2 {
        this.x *= other.x;
        this.y *= other.y;
        return this;
    }

    public multiplyScalar(value: number): Vector2 {
        this.x *= value;
        this.y *= value;
        return this;
    }

    public divideScalar(value: number): Vector2 {
        this.x /= value;
        this.y /= value;
        return this;
    }

    public multiplyRotation(rotation: Rotation2): Vector2 {
        const { x, y } = this;
        this.x = rotation.cos * x - rotation.sin * y;
        this.y = rotation.sin * x + rotation.cos * y;
        return this;
    }

    public negate(): Vector2 {
        this.x = -this.x;
        this.y = -this.y;
        return this;
    }

    public negateX(): Vector2 {
        this.x = -this.x;
        return this;
    }

    public negateY(): Vector2 {
        this.y = -this.y;
        return this;
    }

    // orthogonal right
    public right(): Vector2 {
        const x = this.x;
        this.x = this.y;
        this.y = -x;
        return this;
    }

    // orthogonal left
    public left(): Vector2 {
        const x = this.x;
        this.x = -this.y;
        this.y = x;
        return this;
    }

    // accurate unit vector
    public unit(): Vector2 {
        const length = this.length();
        this.x /= length;
        this.y /= length;
        return this;
    }

    // dot product
    public dot(other: Vector2): number {
        return this.x * other.x + this.y * other.y;
    }

    // cross product
    public cross(other: Vector2): number {
        return this.x * other.y - this.y * other.x;
    }

    // angle between vectors
    public angle(other: Vector2): number {
        const sin = this.cross(other);
        const cos = this.dot(other);
        return Math.atan2(sin, cos);
    }

    // manhattan unit vector
    public munit(): Vector2 {
        const ax = Math.abs(this.x);
        const ay = Math.abs(this.y);
        if (ax > ay) {
            this.x /= ax;
            this.y = 0;
        } else {
            if (ay === 0) this.y = 0;
            else this.y /= ay;
            this.x = 0;
        }
        return this;
    }

    // manhattan direction
    public mdirection(): ManhattanDirection {
        const munit = this.munit();
        if (munit.x > 0) return 'right';
        else if (munit.x < 0) return 'left';
        if (munit.y > 0) return 'up';
        else if (munit.y < 0) return 'down';
        return 'none';
    }

    // round to nearest integer
    public round(): Vector2 {
        this.x = Math.round(this.x);
        this.y = Math.round(this.y);
        return this;
    }

    // queries
    //-------------------------------------------------------------------------
    // accurate length
    public length(): number {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }

    // manhattan distance
    public mlength(): number {
        return Math.abs(this.x) + Math.abs(this.y);
    }
}
