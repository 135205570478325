//TODO: remove need for all this
import type { DisplayObject } from 'pixi.js';
import { Container, Graphics } from 'pixi.js';

import { SizeType } from '../defs/types';

// alignment
//-----------------------------------------------------------------------------
export function uiAlignCenterX(container: Partial<SizeType>, child: Container, offset = 0) {
    child.x = (container.width - child.width) / 2 + child.pivot.x * child.scale.x + offset;
}

export function uiAlignCenterY(container: SizeType, child: Container, offset = 0) {
    child.y = (container.height - child.height) / 2 + child.pivot.y * child.scale.y + offset;
}

export function uiAlignCenter(container: SizeType, child: Container, x = 0, y = 0) {
    uiAlignCenterX(container, child, x);
    uiAlignCenterY(container, child, y);
}

// align
export function uiAlignTop(container: SizeType, child: Container, padding = 0) {
    child.y = padding;
}

export function uiAlignBottom(container: Partial<SizeType>, child: Container, padding = 0) {
    child.y = container.height - child.height + padding;
}

export function uiAlignRight(container: Partial<SizeType>, child: Container, padding = 0) {
    child.x = container.width - child.width + padding;
}

export function uiAlignLeft(container: SizeType, child: Container, padding = 0) {
    child.x = padding;
}

export function uiPivotCenter(container: Container, increment?: boolean) {
    container.pivot.set(container.width / 2, container.height / 2);
    container.position.set(
        (increment ? container.x : 0) + container.width / 2,
        (increment ? container.y : 0) + container.height / 2,
    );
}

// layout
//-----------------------------------------------------------------------------
export function uiLayoutHorizontal(views: DisplayObject[], spacing = 0) {
    let x = 0;
    const containers = views as Container[];

    for (const container of containers) {
        container.x = x;
        x += container.width + spacing;
    }
}

export function uiLayoutVertical(views: DisplayObject[], spacing = 0) {
    let y = 0;
    const containers = views as Container[];

    for (const container of containers) {
        container.y = y;
        y += container.height + spacing;
    }
}

export function uiLayoutCenterY(container: Container) {
    const children = container.children;

    for (const child of children) {
        uiAlignCenterY(container, child as Container);
    }
}

export function uiLayoutBottom(container: Container) {
    const children = container.children;
    const height = container.height;

    for (const child of children) {
        uiAlignBottom({ height }, child as Container);
    }
}

// sizing
//-----------------------------------------------------------------------------
export function uiScaleToWidth(container: Container, width: number, always = false) {
    const containerWidth = container.width / container.scale.x;

    if (always || containerWidth > width) {
        container.scale.set(width / containerWidth);
    }
}

export function uiScaleToHeight(container: Container, height: number, always = false) {
    const scale = container.scale;
    const containerHeight = container.height / scale.y;

    if (always || containerHeight > height) {
        const ratio = height / containerHeight;
        scale.set(Math.sign(scale.x) * ratio, Math.sign(scale.y) * ratio);
    }
}

export function uiScaleToFit(container: Container, width: number, height: number, always = false) {
    if (container.width > width) {
        uiScaleToWidth(container, width, always);
    }
    if (container.height > height) {
        uiScaleToHeight(container, height, always);
    }
}

//TODO: switch all to scaleToX
export function uiSizeToWidth(container: Container, width: number, always = false) {
    if (always || container.width > width) {
        container.height = (width * container.height) / container.width;
        container.width = width;
    }
}

export function uiSizeToHeight(container: Container, height: number, always = false) {
    if (always || container.height > height) {
        container.width = (height * container.width) / container.height;
        container.height = height;
    }
}

export function uiSizeToFit(container: Container, width: number, height: number) {
    if (container.width > width) {
        uiSizeToWidth(container, width);
    }
    if (container.height > height) {
        uiSizeToHeight(container, height);
    }
}

export function uiSizeToFill(container: Container, width: number, height: number) {
    if (container.width / container.height > width / height) {
        uiSizeToHeight(container, height, true);
    } else {
        uiSizeToWidth(container, width, true);
    }
}

// extensions
//-----------------------------------------------------------------------------
export function uiDespawn(container: Container) {
    const parent = container?.parent;
    if (parent) parent.removeChild(container);
}

// scene
//-----------------------------------------------------------------------------
export function uiCreateMask(width: number, height: number): Graphics {
    const mask = new Graphics();

    //mask.renderable = false;
    mask.beginFill(0);
    mask.drawRect(0, 0, width, height);
    mask.endFill();

    return mask;
}

export function uiCreateQuad(color: number, alpha: number, width: number, height: number): Graphics {
    const quad = new Graphics();

    quad.beginFill(color, alpha);
    quad.drawRect(0, 0, width, height);
    quad.endFill();

    return quad;
}

export function uiCreateBox(
    color: number,
    alpha: number,
    width: number,
    height: number,
    border: number,
    borderColor: number,
): Graphics {
    return new Graphics()
        .lineStyle(border, borderColor)
        .beginFill(color, alpha)
        .drawRect(0, 0, width, height)
        .endFill();
}
