import { BlockEntity, despawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { BombEffect } from './BombEffect';
import { IEffect } from './IEffect';

// types
//-----------------------------------------------------------------------------
export type BombBombComboEffectOptions = {
    subject: BlockEntity;
    pair: BlockEntity[];
    all: BlockEntity[];
};

/*
    bomb+bomb combo explosion effect
*/
export class BombBombComboEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _options: BombBombComboEffectOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: BombBombComboEffectOptions) {
        this._scene = scene;
        this._options = options;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // despawn all blocks
        for (const block of this._options.all) {
            despawnBlockEntity(this._scene, block);
        }

        // execute large bomb effect at position of first block
        return new BombEffect(this._scene, {
            position: this._options.subject.c.position.mapPosition,
            radius: 3,
            big: true,
        }).execute();
    }
}
