// types
//-----------------------------------------------------------------------------
export const timeComponentIds = ['days', 'hours', 'minutes', 'seconds'] as const;
export type TimeComponentId = (typeof timeComponentIds)[number];

export enum Day {
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
}

// all time values are 0 based
export type TimeComponents = {
    sign?: number;
    ms?: number;
    seconds?: number;
    minutes?: number;
    hours?: number;
    // 0 = sunday in UTC context
    days?: number;
};

// constants
//-----------------------------------------------------------------------------
// public
export const timeWeek = timeFromComponents({ days: 7 });

// local
const suffixMap: Record<TimeComponentId, string> = {
    seconds: 's',
    minutes: 'm',
    hours: 'h',
    days: 'd',
};

// api
//-----------------------------------------------------------------------------
// get epoch time from time components
export function timeFromComponents({
    sign = 1,
    ms = 0,
    seconds = 0,
    minutes = 0,
    hours = 0,
    days = 0,
}: TimeComponents): number {
    const second = 1000;
    const minute = 60 * second;
    const hour = 60 * minute;
    const day = 24 * hour;

    // solve ms from components
    return ms + seconds * second + minutes * minute + hours * hour + days * day;
}

// get epoch time broken down into components
export function timeToComponents(time: number): TimeComponents {
    // solve sign
    let sign = 1;

    if (time < 0) {
        sign = -1;
        time *= -1;
    }

    // solve components
    const ms = time % 1000;
    let seconds = Math.floor(time / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    seconds %= 60;
    minutes %= 60;
    hours %= 24;

    return { sign, ms, seconds, minutes, hours, days };
}

// returns time string in the form: #d #h #m #s limited by <limit> components and leading 0 values removed.
export function timeFormatCountdown(components: TimeComponents, limit = 2): string {
    let out = '';
    let count = 0;

    // for each component in order of major first
    for (let i = 0; i < timeComponentIds.length; ) {
        const id = timeComponentIds[i];
        const value = components[id];

        // if nonzero component or last component
        if (++i === timeComponentIds.length || value > 0) {
            const svalue = value.toString();

            // pad with space
            if (count > 0) out += ` ${svalue}`;
            else out += svalue;

            // add suffix
            out += suffixMap[id];

            // end if limit
            if (++count >= limit) break;
            // end at next zero component unless seconds
        } else if (count) break;
    }

    return out;
}

// get components in UTC time for week, day
export function timeGetTimeOfDay(time: number): TimeComponents {
    const day = timeGetTimeOfWeek(time);
    day.days = 0;
    return day;
}
export function timeGetTimeOfWeek(time: number): TimeComponents {
    const date = new Date(time);
    return {
        sign: 1,
        ms: date.getUTCMilliseconds(),
        seconds: date.getUTCSeconds(),
        minutes: date.getUTCMinutes(),
        hours: date.getUTCHours(),
        days: date.getUTCDay(),
    };
}
