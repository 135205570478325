import type { Component, Entity2D } from '@play-co/odie';

import { ZombieBlockProps } from '../defs/block';
import { ZombieBlockView } from '../views/blocks/ZombieBlockView';

export type BasicBlockComponentOptions = {
    props: ZombieBlockProps;
};

export class ZombieBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockZombie';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: ZombieBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: BasicBlockComponentOptions): void {
        this.view = new ZombieBlockView(options.props);
    }
}
