import { CollideEvent, CollideId } from '../../defs/collide';
import { despawnBlockEntity, SlimeBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { BreakEffect } from '../effects/BreakEffect';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

/*
    slime block collision resolver
*/
export class SlimeCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: SlimeBlockEntity;
    // maps
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        attack: this._resolveAttack,
        block: this._resolveBlock,
        tap: this._resolveTap,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: SlimeBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveAttack(event: CollideEvent) {
        await this._damage();
        return true;
    }

    private async _resolveBlock(event: CollideEvent) {
        const collider = event.collider;

        // damage, if basic block collider
        if (collider?.type === 'block' && collider.entity.c.block.props.type === 'basic') {
            this._damage();
            return true;
        }
        return false;
    }

    private async _resolveTap(event: CollideEvent) {
        // run invalid effect
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _damage() {
        const entity = this._entity;
        const position = entity.c.position.mapPosition;

        // play sound
        //TODO: need sound asset
        //app.sound.play('puzzle-slime', { pan: mapGetPan(position) });

        // despawn block
        despawnBlockEntity(this._scene, this._entity);

        // execute break effect
        //TODO: slime specific effect
        return new BreakEffect(this._scene, {
            position,
            size: entity.c.block.props,
            color: 0x00ff00,
        }).execute();
    }
}
