//import { PuzzleFooter } from './PuzzleFooter';
//import { PuzzleHeader } from './PuzzleHeader';
import { Container, Sprite } from 'pixi.js';

import app from '../../../index.entry';
import { SizeType } from '../../../lib/defs/types';
import { uiAlignCenterX } from '../../../lib/pixi/uiTools';
import { CharacterClipId, MainCharacter } from '../../concept/MainCharacter';
import { PuzzleCompleteFlow } from '../../flows/PuzzleCompleteFlow';
import { LayoutScreen2 } from '../../lib/screens/LayoutScreen2';
//import { CellDef, MapDef } from '../match2/defs/map';
// import { Match2 } from '../match2/Match2';
import { GameScene } from '../match2-odie/GameScene';
import { PuzzleController, PuzzleControllerOptions } from './controller/PuzzleController';
import { PuzzleFooter } from './PuzzleFooter';
import { PuzzleHeader } from './PuzzleHeader';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    bg: 'b.bgpuz.png',
};

/*
    puzzle screen
*/
export class PuzzleScreen extends LayoutScreen2 {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _header: PuzzleHeader;
    private _footer: PuzzleFooter;
    private _characterView: MainCharacter;
    private _bg: Sprite;
    private _puzzleScene: GameScene;
    private _puzzleContainer: Container;
    private _controller: PuzzleController;
    public static instance: PuzzleScreen;

    // properties
    //-------------------------------------------------------------------------
    public get header(): PuzzleHeader {
        return this._header;
    }

    public get scene(): GameScene {
        return this._puzzleScene;
    }

    public get controller(): PuzzleController {
        return this._controller;
    }

    public get activeReaction(): CharacterClipId {
        return this._characterView.spine.activeId as CharacterClipId;
    }

    // init
    //-------------------------------------------------------------------------
    constructor() {
        super();
        //this.root.sortableChildren = true;
        this._controller = new PuzzleController(this);
        PuzzleScreen.instance = this;
    }

    public playCharacterReaction(id: CharacterClipId) {
        // play reaction then back to idle loop
        this._characterView.start({ clipId: id }).then(() => {
            this._characterView.start({ clipId: 'idle', loop: true });
        });
    }

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        return app.resource.loadAssets([
            ...Object.values(manifest),
            ...PuzzleHeader.assets(),
            ...PuzzleFooter.assets(),
        ]);
    }

    public async init() {
        // spawn background
        this._spawnBackground();

        // updates avatar
        this._updateAvatar();

        // spawn header
        this._spawnFooter();

        // spawn puzzle
        this._spawnPuzzle();

        // spawn header
        this._spawnHeader();
    }

    public async spawning(options?: PuzzleControllerOptions) {
        //TODO: refactor so this isnt needed
        await Promise.all(app.resource.loadAssets(await this._controller.assets(options)));
        await this._controller.prepare(options);
    }

    public async spawned() {}

    public despawned() {
        this._controller.hidden();
    }

    public step(dt: number) {}

    public override resized(size: SizeType): void {
        super.resized(size);
        //this._puzzleScene.resize(pixiConfig.size.width, pixiConfig.size.height);
        this._bg.x = (size.width - this._bg.width) / 2;
        this._footer.resized(size);
        this._footer.y = size.height - this._footer.height;
        //uiAlignCenterX(this._bg, this._puzzleContainer);
    }

    // private: update
    //-------------------------------------------------------------------------
    private _updateAvatar() {
        const scale = 0.6;

        // remove existing avatar
        this._bg.removeChild(this._characterView);

        // add character view
        const character = (this._characterView = new MainCharacter());
        character.y = 322;
        character.scale.set(scale);
        this._bg.addChild(character);
        character.x = this._bg.width / 2 + 8;

        // start idling
        this.playCharacterReaction('idle');
    }

    // private: spawn
    //-------------------------------------------------------------------------
    private _spawnBackground() {
        this._bg = this.root.addChild(Sprite.from(manifest.bg));
    }

    private _spawnPuzzle() {
        // create puzzle container
        this._puzzleContainer = new Container();

        // create puzzle scene
        const stage = new Container();
        this._puzzleScene = new GameScene({
            stage,
        });
        this._puzzleContainer.addChild(stage);

        // complete ui
        this._bg.addChild(this._puzzleContainer);

        // align
        this._puzzleContainer.x = this._bg.width / 2;
        this._puzzleContainer.y = 760;
    }

    private _spawnHeader() {
        const header = (this._header = new PuzzleHeader());
        header.zIndex = 1;
        this._bg.addChild(header);
        uiAlignCenterX(this._bg, header);
    }

    private _spawnFooter() {
        const footer = (this._footer = new PuzzleFooter({
            onClose: this._onClose.bind(this),
        }));
        this._bg.addChild(footer);
        uiAlignCenterX(this._bg, footer);
    }

    private async _onClose() {
        await new PuzzleCompleteFlow({ result: 'quit' }, this._controller).execute();
    }
}
