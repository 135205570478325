import { StateObserver } from '../../../../../lib/pattern/StateMachine';
import Timer from '../../../../../lib/pattern/Timer';
import { tween } from '../../../../../lib/util/tweens';
import { arrayIterate2, arrayRandom, sleep } from '../../../../../replicant/util/jsTools';
import { Cell } from '../../components/MapComponent';
import { matchFindGroups } from '../../util/matchTools';
import type { IPhase, PhaseSystem } from '../PhaseSystem';

// constants
//-----------------------------------------------------------------------------
const hintTime = 3;

/*
    handles game session player input phase
*/
export class InputPhase implements StateObserver, IPhase {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _system: PhaseSystem;
    private _hintTimer = new Timer(this._showHints.bind(this));
    private _hintActive = false;

    // init
    //-------------------------------------------------------------------------
    constructor(system: PhaseSystem) {
        this._system = system;
    }

    // impl
    //-------------------------------------------------------------------------
    public async enter() {
        const scene = this._system.scene;
        const phase = scene.sessionEntity.c.phase;

        //TODO: this is a bit wierd. probably better to input monitor the entire map and map that position to the cell.
        // register input with tiles
        arrayIterate2(scene.sessionEntity.c.map.grid, (cell, column, row) => {
            cell.tile?.c.view2d.view.on('pointertap', () => this._onTap(cell));
        });

        // trigger round
        phase.triggerRound();

        // start hint timer
        this._hintActive = false;
        this._hintTimer.start(hintTime);
    }

    public async leave() {
        // stop hints
        this._hintTimer.stop();
        this._hintActive = false;

        // unregister input with tiles
        arrayIterate2(this._system.scene.sessionEntity.c.map.grid, (cell, column, row) => {
            cell.tile?.c.view2d.view.removeAllListeners();
        });
    }

    public step() {
        // if activations exist enter active phase
        if (this._system.scene.sessionEntity.c.phase.activations > 0) {
            this._system.phase = 'active';
        }
    }

    // private: input
    //-------------------------------------------------------------------------
    private _onTap(cell: Cell) {
        // notify event, allow input if not handled
        if (!this._system.scene.events.publish({ id: 'input', cell })) {
            // get nontappable overlay else block layer
            const layer =
                cell.overlay && cell.overlay.entity.c.block.props.tappable !== true ? cell.overlay : cell.base;

            // tap collide entity
            layer?.entity.c.block.collide('tap');
        }
    }

    private async _showHints() {
        const scale = 1.15;
        const duration = 0.4;
        const interval = 1.8;

        const matchGroups = matchFindGroups(this._system.scene);
        if (matchGroups.length > 0) {
            const blocks = arrayRandom(matchGroups).matches.map((match) => match.base);

            // set active
            this._hintActive = true;

            // animation loop
            while (this._hintActive) {
                for (const block of blocks) {
                    const view = block.c.blockBasic.view;
                    view.animate()
                        .add(view.scale, { x: scale, y: scale }, duration, tween.pow2In)
                        .add(view.scale, { x: 1, y: 1 }, duration, tween.pow2Out);
                }
                await sleep(interval);
            }
        }
    }
}
