import { Vector2 } from '@play-co/odie';
import { gsap } from 'gsap';

import { StateObserver } from '../../../../../lib/pattern/StateMachine';
import { config } from '../../defs/config';
import type { BlockEntity } from '../../entities/BlockEntity';
import type { FallAction } from '../../util/MapFaller';
import { MapFaller } from '../../util/MapFaller';
import { mapToViewPosition } from '../../util/mapTools';
import type { IPhase, PhaseSystem } from '../PhaseSystem';

export const getGravityDropTweenVars = (fromY: number, toY: number): GSAPTweenVars => {
    // solve duration for travel distance of square (gravity) ease
    const duration = Math.sqrt(toY - fromY) / config.sim.gravity;
    return { y: toY, duration, ease: 'power1.in' };
};

export const getGravityBounceTweenVars = (toY: number): GSAPTweenVars => ({
    y: toY - config.sim.bounce,
    duration: config.sim.bounceDuration,
    ease: 'square.in',
    repeat: 1,
    yoyo: true,
});

/*
    handles game session fall phase
*/
export class FallPhase implements StateObserver, IPhase {
    // fields
    //-------------------------------------------------------------------------
    private readonly _system: PhaseSystem;

    // init
    //-------------------------------------------------------------------------
    constructor(system: PhaseSystem) {
        this._system = system;
    }
    // impl

    //-------------------------------------------------------------------------
    public async enter() {
        // do fall action
        await this._actionFall();
    }

    public step() {}

    // private: actions
    //-------------------------------------------------------------------------
    private async _actionFall(): Promise<void> {
        const mapFaller = new MapFaller(this._system.scene);

        // build fall actions
        const fallActions = mapFaller.build();

        // if have fall actions
        if (fallActions.length > 0) {
            // animate all fall actions
            await Promise.all(fallActions.map((action) => this._actionAnimateFallAction(action)));

            // enter active phase
            this._system.phase = 'active';
        } else {
            // enter shuffle phase
            this._system.phase = 'shuffle';
        }
    }

    private async _actionAnimateFallAction(request: FallAction): Promise<void> {
        const entity = request.entity;
        const position = entity.c.position.mapPosition;
        const fromPosition = new Vector2(position.x, request.fromRow);
        const toPosition = new Vector2(position.x, request.toRow);

        // get from and to view positions
        const fromViewPosition = mapToViewPosition(fromPosition);
        const toViewPosition = mapToViewPosition(toPosition);

        // animate to requested view position
        await this._animateFall(entity, fromViewPosition, toViewPosition);

        // set fall collision
        entity.c.block.collide('fall');
    }

    // private: animators
    //-------------------------------------------------------------------------
    private async _animateFall(blockEntity: BlockEntity, from: Vector2, to: Vector2): Promise<void> {
        const view = blockEntity.c.view2d.view;

        // set from position
        view.x = from.x;
        view.y = from.y;

        // animate to requested view position
        await gsap
            .timeline()
            // gravity drop
            .to(view, getGravityDropTweenVars(from.y, to.y))
            // bounce: inverse drop then drop
            .to(view, getGravityBounceTweenVars(to.y));
    }
}
