import { DisplayObject } from 'pixi.js';

import { Animation } from '../../../lib/animator/Animation';
import { tween } from '../../../lib/util/tweens';

const SCALE = 0.94;
const DURATION = 0.2;

export function onTapAnimation(obj: DisplayObject, scale?: number): Animation {
    obj.scale.set(1);
    return obj
        .animate()
        .add(obj.scale, { x: scale ?? SCALE, y: scale ?? SCALE }, DURATION, tween.backIn())
        .add(obj.scale, { x: 1, y: 1 }, DURATION, tween.backOut());
}

export function onDownAnimation(obj: DisplayObject, scale?: number): Animation {
    obj.scale.set(1);
    return obj.animate().add(obj.scale, { x: scale ?? SCALE, y: scale ?? SCALE }, DURATION, tween.backIn());
}

export function onUpAnimation(obj: DisplayObject): Animation {
    obj.scale.set(1);
    return obj.animate().add(obj.scale, { x: 1, y: 1 }, DURATION, tween.backIn());
}
