import { blockColorMap, HatBlockProps } from '../../defs/block';
import { CollideEvent, CollideId } from '../../defs/collide';
import type { HatBlockEntity } from '../../entities/BlockEntity';
import { despawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { BreakEffect } from '../effects/BreakEffect';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

/*
    hat block collision resolver
*/
export class HatCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: HatBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        block: this._resolveBlock,
        attack: this._resolveAttack,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: HatBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        // invalid effect
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveBlock(event: CollideEvent) {
        const collider = event.collider;

        // if block collider
        if (collider?.type === 'block') {
            const colliderProps = collider.entity.c.block.props;

            // if basic block collider
            if (colliderProps.type === 'basic') {
                const hatProps = this._entity.c.block.props as HatBlockProps;

                // attack hat if its all color or matches color of collider
                if (hatProps.color === 'all' || hatProps.color === colliderProps.color) {
                    this._attack();
                    return true;
                }
            }
        }
        return false;
    }

    private async _resolveAttack(event: CollideEvent) {
        // attack hat
        this._attack();
        return true;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _attack() {
        const c = this._entity.c;
        const position = c.position.mapPosition;

        // despawn hat
        despawnBlockEntity(this._scene, this._entity);

        // execute break effect
        return new BreakEffect(this._scene, {
            position,
            size: this._entity.c.block.props,
            color: blockColorMap[(c.block.props as HatBlockProps).color].base,
        }).execute();
    }
}
