// types
//-----------------------------------------------------------------------------
export type TweenFunction = (time: number) => number;

// tweens
//-----------------------------------------------------------------------------
export function tweenLinear(time: number): number {
    return time;
}

export function tweenPower2Out(time: number): number {
    return 1 - Math.pow(time - 1, 2);
}

export function tweenPower4Out(time: number): number {
    return 1 - Math.pow(time - 1, 4);
}

export function tweenPower2In(time: number): number {
    return Math.pow(time, 2);
}
